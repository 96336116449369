import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { blankOrder, Order } from "./orders.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchOrdersResults extends UseFetchManyResults {
  orders: Order[];
  numberOfOrders: number;
  totalNumberOfOrders: number;
}

interface UseFetchOrderResults extends UseFetchOneResults {
  order: Order;
}

const keys: FetcherKeys<UseFetchOrdersResults, UseFetchOrderResults> = {
  service: "orders",
  totalNumber: "totalNumberOfOrders",
  numberOf: "numberOfOrders",
  records: "orders",
  record: "order",
};

export async function fetchOrders(
  props: FetchManyProps
): Promise<FetchManyResults<Order>> {
  return fetchMany<Order>(props, keys.service);
}

export function useFetchOrders(props: FetchManyProps): UseFetchOrdersResults {
  return useFetchMany(props, fetchOrders, keys);
}

export async function fetchOrder(props: FetchOneProps): Promise<Order> {
  return fetchOne<Order, UseFetchOrderResults>(props, blankOrder, keys);
}

export function useFetchOrder(props: FetchOneProps): UseFetchOrderResults {
  return useFetchOne(props, fetchOrder, blankOrder, keys);
}

async function saveOrder(
  original: Order,
  changes: Partial<Order>,
  $role: true | false | string
): Promise<Order> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteOrder(original: Order, $role: true | false | string) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveOrder = makeSaver(saveOrder, true);
export const adminDeleteOrder = makeDeleter(deleteOrder, true);
export const userSaveOrder = makeSaver(saveOrder, false);
export const userDeleteOrder = makeDeleter(deleteOrder, false);
