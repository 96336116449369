import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  FetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  UseFetchManyResults,
  useFetchOne,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import { makeDeleter, makeSaver } from "./types";
import { blankUser, User } from "./users.model";

interface UseFetchUsersResults extends UseFetchManyResults {
  users: User[];
  numberOfUsers: number;
  totalNumberOfUsers: number;
}

interface UseFetchUserResults extends UseFetchOneResults {
  user: User;
}

const keys: FetcherKeys<UseFetchUsersResults, UseFetchUserResults> = {
  service: "users",
  totalNumber: "totalNumberOfUsers",
  numberOf: "numberOfUsers",
  records: "users",
  record: "user",
};

export async function fetchUsers(
  props: FetchManyProps
): Promise<FetchManyResults<User>> {
  return fetchMany<User>(props, keys.service);
}

export function useFetchUsers(props: FetchManyProps): UseFetchUsersResults {
  return useFetchMany(props, fetchUsers, keys);
}

export async function fetchUser(props: FetchOneProps): Promise<User> {
  return fetchOne<User, UseFetchUserResults>(props, blankUser, keys);
}

export function useFetchUser(props: FetchOneProps): UseFetchUserResults {
  return useFetchOne(props, fetchUser, blankUser, keys);
}

async function saveUser(
  original: User,
  changes: Partial<User>,
  $role: true | false | string
): Promise<User> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteUser(original: User, $role: true | false | string) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveUser = makeSaver(saveUser, true);
export const adminDeleteUser = makeDeleter(deleteUser, true);
