import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import {
  blankProductProperty,
  ProductProperty,
} from "./productProperties.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchProductPropertiesResults extends UseFetchManyResults {
  productProperties: ProductProperty[];
  numberOfProductProperties: number;
  totalNumberOfProductProperties: number;
}

interface UseFetchProductPropertyResults extends UseFetchOneResults {
  productProperty: ProductProperty;
}

const keys: FetcherKeys<
  UseFetchProductPropertiesResults,
  UseFetchProductPropertyResults
> = {
  service: "product-properties",
  totalNumber: "totalNumberOfProductProperties",
  numberOf: "numberOfProductProperties",
  records: "productProperties",
  record: "productProperty",
};

export async function fetchProductProperties(
  props: FetchManyProps
): Promise<FetchManyResults<ProductProperty>> {
  return fetchMany<ProductProperty>(props, keys.service);
}

export function useFetchProductProperties(
  props: FetchManyProps
): UseFetchProductPropertiesResults {
  return useFetchMany(props, fetchProductProperties, keys);
}

export async function fetchProductProperty(
  props: FetchOneProps
): Promise<ProductProperty> {
  return fetchOne<ProductProperty, UseFetchProductPropertyResults>(
    props,
    blankProductProperty,
    keys
  );
}

export function useFetchProductProperty(
  props: FetchOneProps
): UseFetchProductPropertyResults {
  return useFetchOne(props, fetchProductProperty, blankProductProperty, keys);
}

async function saveProductProperty(
  original: ProductProperty,
  changes: Partial<ProductProperty>,
  $role: true | false | string
): Promise<ProductProperty> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteProductProperty(
  original: ProductProperty,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveProductProperty = makeSaver(saveProductProperty, true);
export const adminDeleteProductProperty = makeDeleter(
  deleteProductProperty,
  true
);
export const userSaveProductProperty = makeSaver(saveProductProperty, false);
export const userDeleteProductProperty = makeDeleter(
  deleteProductProperty,
  false
);
