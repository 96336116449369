import React, { CSSProperties } from "react";
import "./ProgressBar.scss";

export default function ProgressBar({
  progress,
  style,
}: {
  progress: number;
  style?: CSSProperties;
}): JSX.Element {
  return (
    <div className="ProgressBar" style={style}>
      <div
        className="progress"
        style={{
          width: `${Math.floor(progress + 0.5)}%`,
        }}
      />
    </div>
  );
}
