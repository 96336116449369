import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DateTimeInput from "../components/DateTimeInput";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import config from "../config";
import CustomerSelect from "../selects/CustomerSelect";
import OrderStateSelect from "../selects/OrderStateSelect";
import UserSelect from "../selects/UserSelect";
import { useCombinedStore } from "../state/combinedStore";
import {
  adminDeleteOrder,
  adminSaveOrder,
  useFetchOrder,
} from "../state/orders.api";
import { Order } from "../state/orders.model";
import { UserAddress } from "../state/userAddresses.model";
import { useLocationId } from "../utils/queryStringState";
import OrderLineListPage from "./OrderLineListPage";
import { UserAddressDetailRows } from "./UserAddressDetailPage";

interface ParamProps {
  orderid: string;
}

export default function OrderDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.orderid);
  const { order, fetching, error } = useFetchOrder({ id, admin: true });

  return (
    <>
      <DetailScreen<Order>
        defaults={(location.state as { defaults?: Partial<Order> })?.defaults}
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={order}
        isNew={match.params.orderid === "new"}
        route={[{ Orders: "/admin/orders" }]}
        renderForm={(props) => <OrderDetailForm {...props} />}
        onSave={adminSaveOrder}
        onDelete={adminDeleteOrder}
      />

      {!!id && <OrderLineListPage orderId={id} />}
    </>
  );
}

function OrderDetailForm(props: FormCallbacks<Order>) {
  const { getField, changeField, changeFieldValue, readOnly } = props;
  const superuser = useCombinedStore(
    (store) => store.login.permissions.superuser
  );

  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="customerId">Customer</Label>
            <CustomerSelect
              admin
              isDisabled={readOnly}
              id="customerId"
              value={getField("customerId")}
              onChange={(newValue) =>
                changeFieldValue("customerId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        {superuser && (
          <Col md={6}>
            <FormGroup>
              <Label for="userId">User</Label>
              <UserSelect
                admin
                isDisabled={readOnly}
                id="userId"
                value={getField("userId")}
                onChange={(newValue) =>
                  changeFieldValue("userId", null, newValue)
                }
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="state">State</Label>
            <OrderStateSelect
              id="state"
              isDisabled={readOnly}
              value={getField("state")}
              onChange={(newValue) => changeFieldValue("state", null, newValue)}
            />
            <FormText>
              If you cancel an order, stock is returned. Note that you cannot
              un-cancel an order.
            </FormText>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="total">Total</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="total"
              id="total"
              value={getField("total")}
              onChange={(event) => changeField("total", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="confirmationSentAt">Confirmation Sent At</Label>
            <DateTimeInput
              readOnly={readOnly}
              name="confirmationSentAt"
              id="confirmationSentAt"
              value={getField("confirmationSentAt")}
              callbacks={config.DATE_TIME_CALLBACKS}
              onChange={(newValue) =>
                changeFieldValue("confirmationSentAt", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <UserAddressDetailRows
        {...((props as unknown) as FormCallbacks<UserAddress>)}
      />
      <AdminCommonDetailRow<Order>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
