export interface ApiState {
  state?: "started" | "completed" | "failed";
  error?: string;
}

export const STARTED: ApiState = { state: "started" };
export const FAILED = (error: string | undefined): ApiState => ({
  state: "failed",
  error,
});
export const COMPLETED: ApiState = { state: "completed" };

export type Id = string | number;

export interface ModelBase {
  id: Id;
  updatedAt?: string;
  createdAt?: string;
  deletedAt?: string;
}

export function equalIds(a: Id | undefined, b: Id | undefined): boolean {
  // eslint-disable-next-line eqeqeq
  return a == b;
}

export function makeSaver<T>(
  saver: (
    original: T,
    changes: Partial<T>,
    $role: true | false | string,
    progressCallback?: (percentage: number) => void
  ) => Promise<T>,
  $role: true | false | string
): (
  original: T,
  changes: Partial<T>,
  progressCallback?: (percentage: number) => void
) => Promise<T> {
  return (
    original: T,
    changes: Partial<T>,
    progressCallback?: (percentage: number) => void
  ): Promise<T> => {
    return saver(original, changes, $role, progressCallback);
  };
}

export function makeDeleter<T>(
  deleter: (original: T, $role: true | false | string) => Promise<void>,
  $role: true | false | string
): (original: T) => Promise<void> {
  return (original: T): Promise<void> => {
    return deleter(original, $role);
  };
}
