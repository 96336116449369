import React, {
  FormEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CustomInput,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import BasketLineRow, { BasketLineRowProps } from "../components/BasketLineRow";
import CardHeaderTitle from "../components/CardHeaderTitle";
import FormErrorText from "../components/FormErrorText";
import LoadingIndicator from "../components/LoadingIndicator";
import { WorkingOverlay } from "../components/LoadingOverlay";
import PageTitle from "../components/PageTitle";
import PaymentRequestRow from "../components/PaymentRequestRow";
import { StyledSelect } from "../components/StyledSelect";
import config from "../config";
import CountrySelect from "../selects/CountrySelect";
import UserAddressSelect from "../selects/UserAddressSelect";
import basketActions from "../state/basket.actions";
import { useFetchBasket } from "../state/basket.api";
import {
  AnyPaymentRequest,
  BasketLine,
  DraftOrderLineProperty,
} from "../state/basket.model";
import { useCombinedStore } from "../state/combinedStore";
import { ProductAttributeOption } from "../state/productAttributeOptions.model";
import {
  ProductAttributeInput,
  ProductAttributeType,
} from "../state/productAttributes.model";
import {
  ProductAttributeIdToValue,
  ProductPropertyValue,
} from "../state/productProperties.model";
import { Id } from "../state/types";
import {
  useFetchUserAddresses,
  userSaveUserAddress,
} from "../state/userAddresses.api";
import { blankUserAddress, UserAddress } from "../state/userAddresses.model";
import { useAutofocus } from "../utils/dom";
import { getSelectOptionByValue } from "../utils/reactSelect";

function productAttributeOptionsToSelect(
  options: Pick<ProductAttributeOption, "id" | "title">[]
) {
  return options.map((option) => ({
    value: String(option.id),
    label: option.title,
  }));
}

export const TEST_PAYMENT_REQUEST = {
  service: "worldpay",
  url: "https://secure-test.worldpay.com/wcc/purchase",
  instId: 1132949,
  cartId: `Order 123456`,
  amount: "1",
  currency: "GBP",
  email: "mark@hrunk.com",
  CM_paymentId: "j2hd9h119hd9h84hd",
  address1: "123 Fake Street",
  address2: "",
  address3: "",
  town: "Faketon",
  region: "",
  country: "GB",
  postcode: "AB12 3CD",
  tel: "07000000000",
  testMode: 100,
  fixContact: true,
  hideContact: true,
  // amountLimit: json.amount_limit,
  // intervalUnit: 2,
  // intervalMult: json.interval_weeks,
  // noOfPayments : 0,
  // futurePayType: "limited"
};

export default function CheckoutScreen(): JSX.Element {
  const {
    basketLines,
    missingUserPropertyIds,
    userProperties,
    basketTotal,
    error: fetchBasketError,
    updatingBasket,
  } = useFetchBasket(config.ORGANISATION_ID);

  const [placeOrderError, setPlaceOrderError] = useState("");

  const dispatch = useDispatch();

  const onDeleteBasketLine = useCallback(
    (line: BasketLine) => {
      dispatch(basketActions.removeBasketLine(config.ORGANISATION_ID, line.id));
    },
    [dispatch]
  );

  const isLoggedIn = useCombinedStore((store) => store.login.isLoggedIn);
  const user = useCombinedStore((store) => store.login.user);

  const {
    userAddresses,
    fetching: fetchingUserAddress,
  } = useFetchUserAddresses({
    query: {
      userId: user.id,
    },
  });

  const [selectedAddressId, setSelectedAddressId] = useState<Id | undefined>();

  useEffect(() => {
    if (!selectedAddressId && userAddresses?.length) {
      setSelectedAddressId(userAddresses[0].id);
    }
    // eslint-disable-next-line
  }, [selectedAddressId, userAddresses, userAddresses?.length]);

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const onAcceptedTermsChanged = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setAcceptedTerms(event.currentTarget.checked);
    },
    []
  );

  const [isCreatingAddress, setIsCreatingAddress] = useState(false);
  const onCreateAddressToggle = useCallback(
    () => setIsCreatingAddress((value) => !value),
    []
  );

  const onCreateAddressClicked = useCallback(() => {
    setIsCreatingAddress(true);
  }, []);

  const onAddressCreated = useCallback((address: UserAddress) => {
    setIsCreatingAddress(false);
    setSelectedAddressId(address.id);
  }, []);

  const [paymentRequest, setPaymentRequest] = useState<
    AnyPaymentRequest | undefined
  >(undefined);

  const [errorModalText, setErrorModalText] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const error = placeOrderError || fetchBasketError || undefined;

  const onBuyNowClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (error) {
        setErrorModalText(String(error));
        setShowErrorModal(true);
        return;
      }

      if (basketLines.length === 0) {
        setErrorModalText("Your basket is empty.");
        setShowErrorModal(true);
        return;
      }

      if (!selectedAddressId || isCreatingAddress) {
        setErrorModalText("Please enter a billing address.");
        setShowErrorModal(true);
        return;
      }

      if (!acceptedTerms) {
        setErrorModalText("You must accept the terms and conditions.");
        setShowErrorModal(true);
        return;
      }

      if (missingUserPropertyIds.length) {
        // TODO: describe said fields
        setErrorModalText(`Please complete the required fields`);
        setShowErrorModal(true);
        return;
      }

      dispatch(
        basketActions.placeOrder(
          config.ORGANISATION_ID,
          selectedAddressId,
          config.PAYMENT_METHOD_ID,
          (error, basket) => {
            if (error) {
              setPlaceOrderError(String(error));
              return;
            }

            basket && setPaymentRequest(basket.paymentRequest);
          }
        )
      );
    },
    [
      dispatch,
      selectedAddressId,
      missingUserPropertyIds,
      acceptedTerms,
      basketLines.length,
      error,
      isCreatingAddress,
    ]
  );

  const requiredProperties = useMemo(() => {
    const attrs = new Map<Id, DraftOrderLineProperty>();
    for (const prop of userProperties) {
      attrs.set(prop.productAttributeId, prop);
    }

    return attrs;
  }, [userProperties]);

  const [
    requiredPropertyValues,
    setRequiredPropertyValues,
  ] = useState<ProductAttributeIdToValue>({});

  const setPropertyText = useCallback(
    (propertyAttributeId: Id, value: string) => {
      setRequiredPropertyValues({
        ...requiredPropertyValues,
        [propertyAttributeId]: { value },
      });
    },
    [requiredPropertyValues, setRequiredPropertyValues]
  );
  const setPropertyOption = useCallback(
    (propertyAttributeId: Id, productAttributeOptionId: Id) => {
      setRequiredPropertyValues({
        ...requiredPropertyValues,
        [propertyAttributeId]: { productAttributeOptionId },
      });
    },
    [requiredPropertyValues, setRequiredPropertyValues]
  );

  const getPropertyValue = useCallback(
    (propertyAttributeId: Id): ProductPropertyValue | undefined => {
      return requiredPropertyValues.hasOwnProperty(propertyAttributeId)
        ? requiredPropertyValues[propertyAttributeId]
        : requiredProperties.get(propertyAttributeId) || undefined;
    },
    [requiredProperties, requiredPropertyValues]
  );

  const [requiredPropertiesDirty, setRequiredPropertiesDirty] = useState(false);

  useEffect(() => {
    if (!Object.keys(requiredPropertyValues).length) {
      setRequiredPropertiesDirty(false);
      return;
    }

    setRequiredPropertiesDirty(true);
    let timeout: ReturnType<typeof setTimeout> | undefined = setTimeout(() => {
      timeout = undefined;

      setRequiredPropertiesDirty(false);
      dispatch(
        basketActions.setBasketProductAttributes(
          config.ORGANISATION_ID,
          requiredPropertyValues
        )
      );
    }, 500);

    return () => timeout && clearTimeout(timeout);
  }, [requiredPropertyValues, dispatch]);

  const onShowErrorModalToggle = useCallback(() => {
    setShowErrorModal((value) => !value);
  }, []);

  const [
    showTermsAndConditionsModal,
    setShowTermsAndConditionsModal,
  ] = useState(false);
  const onTermsAndConditionsToggle = useCallback(() => {
    setShowTermsAndConditionsModal((value) => !value);
  }, []);

  const onAcceptTermsAndConditionsFromModal = useCallback(() => {
    setAcceptedTerms(true);
    setShowTermsAndConditionsModal(false);
  }, []);

  return (
    <Container>
      <PageTitle title="Checkout" />

      {/* <pre>{JSON.stringify(basketLines, null, '\t')}</pre> */}

      {paymentRequest ? (
        <PaymentRequestRow request={paymentRequest} />
      ) : error ? (
        <Alert color="danger">
          Unable to update your basket
          <br />
          <small>{error}</small>
        </Alert>
      ) : (
        <Row>
          <Col lg={6}>
            <Card className="mb-3">
              <CardHeaderTitle>Basket</CardHeaderTitle>
              <CardBody>
                <ListGroup>
                  {basketLines.length ? (
                    <>
                      {basketLines.map((line, _index) => {
                        return (
                          <ListGroupBasketLine
                            key={line.id}
                            text={line.description}
                            detail={line.detail}
                            price={line.price}
                            onDelete={() => onDeleteBasketLine(line)}
                            outOfStock={line.outOfStock}
                          />
                        );
                      })}
                      <ListGroupBasketLine
                        text="Total"
                        price={basketTotal}
                        bold={true}
                      />
                    </>
                  ) : (
                    <h3 className="text-center text-muted mb-0">
                      Your basket is empty
                    </h3>
                  )}
                </ListGroup>
              </CardBody>
            </Card>
            {requiredProperties.size !== 0 && (
              <Card className="mb-3">
                <CardHeaderTitle>Additional Information</CardHeaderTitle>
                <CardBody>
                  {Array.from(requiredProperties).map(([_key, prop]) => {
                    const placeholder =
                      prop.product_attribute?.input ===
                      ProductAttributeInput.REQUIRED
                        ? "Required"
                        : "Optional";
                    if (
                      prop.product_attribute?.type ===
                      ProductAttributeType.OPTION
                    ) {
                      const selects = productAttributeOptionsToSelect(
                        prop.product_attribute.product_attribute_options || []
                      );

                      return (
                        <FormGroup>
                          <Label>{prop.product_attribute?.title || ""}</Label>
                          <StyledSelect
                            value={getSelectOptionByValue(
                              selects,
                              String(
                                getPropertyValue(prop.productAttributeId)
                                  ?.productAttributeOptionId || ""
                              )
                            )}
                            options={selects}
                            onChange={(newValue) => {
                              const selection =
                                newValue && "value" in newValue
                                  ? newValue.value
                                  : null;
                              selection &&
                                setPropertyOption(
                                  prop.productAttributeId,
                                  selection
                                );
                            }}
                            placeholder={placeholder}
                          />
                        </FormGroup>
                      );
                    } else {
                      return (
                        <FormGroup>
                          <Label>{prop.product_attribute?.title || ""}</Label>
                          <Input
                            type="text"
                            value={
                              getPropertyValue(prop.productAttributeId)
                                ?.value || ""
                            }
                            onChange={(event) => {
                              event.preventDefault();
                              setPropertyText(
                                prop.productAttributeId,
                                event.currentTarget.value
                              );
                            }}
                            placeholder={placeholder}
                          />
                        </FormGroup>
                      );
                    }
                  })}
                </CardBody>
              </Card>
            )}
          </Col>
          <Col lg={6}>
            {isLoggedIn && basketLines.length > 0 && (
              <Card className="mb-3">
                <CardHeaderTitle>Billing Address</CardHeaderTitle>
                <CardBody>
                  {fetchingUserAddress ? (
                    <LoadingIndicator />
                  ) : !userAddresses?.length || isCreatingAddress ? (
                    <UserAddressForm
                      onCancel={
                        userAddresses?.length
                          ? onCreateAddressToggle
                          : undefined
                      }
                      onCreated={onAddressCreated}
                      prompt="This address will be used by your card issuer for verification and to contact you about your order."
                    />
                  ) : (
                    <UserAddressSelect
                      query={{ userId: user.id }}
                      value={selectedAddressId}
                      onChange={setSelectedAddressId}
                      disableClear
                      createLabel="Add another address..."
                      onCreate={onCreateAddressClicked}
                    />
                  )}
                </CardBody>
              </Card>
            )}
            {isLoggedIn && basketLines.length > 0 && (
              <Card className="mb-3">
                <CardHeaderTitle>Payment Method</CardHeaderTitle>
                <CardBody>
                  <div className="text-center mb-3">
                    <img
                      src={require("../media/cards/poweredByWorldPay.gif")}
                      alt="American Express Logo"
                    />
                  </div>
                  <div className="text-center">
                    <img
                      src={require("../media/cards/VISA.gif")}
                      alt="American Express Logo"
                    />
                    <img
                      src={require("../media/cards/mastercard.gif")}
                      alt="American Express Logo"
                    />
                    <img
                      src={require("../media/cards/maestro.gif")}
                      alt="American Express Logo"
                    />
                    <img
                      src={require("../media/cards/JCB.gif")}
                      alt="American Express Logo"
                    />
                    {/* <img src={require('../media/cards/amex-logo2.gif')} alt="American Express Logo" /> */}
                  </div>
                </CardBody>
              </Card>
            )}
            {isLoggedIn && basketLines.length > 0 && (
              <Card className="mb-3">
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="text-center">
                        <div className="disable-selection">
                          <CustomInput
                            id="accept_terms"
                            type="checkbox"
                            checked={acceptedTerms}
                            onChange={onAcceptedTermsChanged}
                            label={
                              <>
                                I accept the{" "}
                                <button
                                  onClick={onTermsAndConditionsToggle}
                                  className="link-button"
                                >
                                  Terms and Conditions
                                </button>
                              </>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="button-container text-right">
                    {basketLines.length ? (
                      <div className="button-container">
                        <Button
                          size="lg"
                          color="primary"
                          onClick={onBuyNowClicked}
                          disabled={
                            !basketLines.length ||
                            updatingBasket ||
                            requiredPropertiesDirty
                          }
                        >
                          Buy Now
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      )}
      <Modal isOpen={showErrorModal} centered unmountOnClose>
        <ModalHeader toggle={onShowErrorModalToggle}>Error</ModalHeader>
        <ModalBody>{errorModalText}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onShowErrorModalToggle}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showTermsAndConditionsModal} unmountOnClose size="lg">
        <ModalHeader toggle={onTermsAndConditionsToggle}>
          Terms and Conditions
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>Enrolments</strong>. When enrolling onto any of our drumming
            courses at school your payment is securing a place for your child
            for any given half term period. Enrolment is to be made on our
            website in advance only.
          </p>
          <p>
            <strong>Payment</strong>. Rockley Music will automatically debit
            your card via FuturePay prior to the start of each subsequent half
            term (six times per academic year). You can cancel your FuturePay
            agreement by contacting us at any time.
          </p>
          <p>
            <strong>Refund policy</strong>: If money is taken in error during
            any half term period a full refund will be issued. Please note a
            half term’s notice is required to cancel your child’s place.
          </p>
          <p>
            <strong>Absence</strong>. If students for any reason miss a lesson
            no reimbursement can be made. This includes illness, school trips,
            closures or any other absences. However if a tutor misses a lesson
            another slot will be arranged or a reimbursement will be issued. We
            do however always make continued effort to provide catch up time for
            those who have missed lessons throughout the term wherever possible.
          </p>
          <p>
            <strong>Legal</strong>. All our tutors are CRB/ DBS checked, insured
            and professionally trained under Rockley Music Education.
          </p>
          <p>
            <strong>Making a complaint</strong>. Please contact Rockley Music
            directly by email at info@rockleymusic.co.uk or call our office on
            01949 829285.
          </p>
          <p>
            <strong>Privacy Policy</strong>. Our privacy policy can be found{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.rockleymusic.co.uk/privacy-policy/"
            >
              here
            </a>
            .
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onTermsAndConditionsToggle}>
            Close
          </Button>
          <Button color="primary" onClick={onAcceptTermsAndConditionsFromModal}>
            Accept
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

function ListGroupBasketLine(props: BasketLineRowProps) {
  return (
    <ListGroupItem tag="div" action>
      <BasketLineRow {...props} />
    </ListGroupItem>
  );
}

interface UserAddressFormProps {
  onCancel?: () => void;
  onCreated?: (address: UserAddress) => void;
  prompt: string;
}

function UserAddressForm({
  onCancel,
  onCreated,
  prompt,
}: UserAddressFormProps) {
  const user = useCombinedStore((store) => store.login.user);

  const [fields, setFields] = useState<UserAddress>({
    ...blankUserAddress,
    firstName: user.firstName,
    lastName: user.lastName,
    country: "GB",
    // email: user.email,
  });

  const setField = useCallback(
    (fieldName: keyof typeof fields, value: string) => {
      setFields({
        ...fields,
        [fieldName]: value,
      });
    },
    [fields]
  );

  const onChangeField = useCallback(
    (
      event: FormEvent<HTMLInputElement>,
      fieldName: keyof UserAddress // can't use typeof fields as eslint complains about the missing hook
    ) => {
      event.preventDefault();
      setField(fieldName, event.currentTarget.value);
    },
    [setField]
  );

  const [working, setWorking] = useState(false);
  const [errors, setErrors] = useState<Record<string, unknown>>({});
  const [posted, setPosted] = useState(false);

  const onSaveClicked = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const errors: Record<string, unknown> = {};

      setPosted(true);

      if (fields.firstName.trim() === "" || fields.lastName.trim() === "") {
        errors["firstName"] = "You full name is required";
      }
      if (fields.address1.trim() === "") {
        errors["address1"] = "First line of address is required";
      }
      if (
        [fields.address2, fields.address3, fields.address3].join("").trim() ===
        ""
      ) {
        errors["address2"] = "Town is required";
      }
      if (fields.postalCode.trim() === "") {
        errors["postalCode"] = "Post code is required";
      }
      if (fields.phoneNumber.trim() === "") {
        errors["phoneNumber"] = "Phone number is required";
      }
      if (fields.country.trim() === "") {
        errors["country"] = "Country is required";
      }

      setErrors(errors);
      if (Object.keys(errors).length) {
        return;
      }

      setWorking(true);

      const createdAddress = await userSaveUserAddress(
        blankUserAddress,
        fields
      );

      onCreated && onCreated(createdAddress);
      setWorking(false);
    },
    [fields, onCreated]
  );

  const onCancelClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onCancel && onCancel();
    },
    [onCancel]
  );

  const autofocus = useAutofocus();

  return (
    <WorkingOverlay working={working}>
      <Row className="mb-3">
        <Col>{prompt}</Col>
      </Row>
      <Row>
        <Col md={6} lg={12} xl={6}>
          <FormGroup>
            <Label>First Name</Label>
            <Input
              type="text"
              id="firstName"
              name="firstName"
              value={fields.firstName}
              onChange={(event) => onChangeField(event, "firstName")}
              autoComplete="given-name"
              spellCheck={false}
              autoCapitalize="words"
            />
            <FormErrorText error={posted && errors.firstName} />
          </FormGroup>
        </Col>
        <Col md={6} lg={12} xl={6}>
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              type="text"
              id="lastName"
              name="lastName"
              value={fields.lastName}
              onChange={(event) => onChangeField(event, "lastName")}
              autoComplete="family-name"
              spellCheck={false}
              autoCapitalize="words"
            />
            <FormErrorText error={posted && errors.lastName} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label>Address</Label>
            <Input
              innerRef={autofocus}
              type="text"
              id="address1"
              name="address1"
              value={fields.address1}
              onChange={(event) => onChangeField(event, "address1")}
              autoComplete="address-line1"
              autoCapitalize="words"
            />
            <FormErrorText error={posted && errors.address1} />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              id="address2"
              name="address2"
              value={fields.address2}
              onChange={(event) => onChangeField(event, "address2")}
              autoComplete="address-line2"
              autoCapitalize="words"
            />
            <FormErrorText error={posted && errors.address2} />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              id="address3"
              name="address3"
              value={fields.address3}
              onChange={(event) => onChangeField(event, "address3")}
              autoComplete="address-line3"
              autoCapitalize="words"
            />
            <FormErrorText error={posted && errors.address3} />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              id="address4"
              name="address4"
              value={fields.address4}
              onChange={(event) => onChangeField(event, "address4")}
              autoComplete="address-level2"
              autoCapitalize="words"
            />
            <FormErrorText error={posted && errors.address4} />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              id="address5"
              name="address5"
              value={fields.address5}
              onChange={(event) => onChangeField(event, "address5")}
              autoComplete="address-level2"
              autoCapitalize="words"
            />
            <FormErrorText error={posted && errors.address5} />
          </FormGroup>
        </Col>
        <Col md={6} lg={12} xl={6}>
          <FormGroup>
            <Label>Post Code</Label>
            <Input
              type="text"
              id="postalCode"
              name="postalCode"
              value={fields.postalCode}
              onChange={(event) => onChangeField(event, "postalCode")}
              autoComplete="postal-code"
              spellCheck={false}
              autoCapitalize="characters"
            />
            <FormErrorText error={posted && errors.postalCode} />
          </FormGroup>
        </Col>
        <Col md={6} lg={12} xl={6}>
          <FormGroup>
            <Label>Country</Label>
            <CountrySelect
              id="country"
              name="country"
              value={fields.country || ""}
              onChange={(newValue) => setField("country", newValue || "")}
              isClearable
            />
            <FormErrorText error={posted && errors.country} />
          </FormGroup>
        </Col>
        <Col md={6} lg={12} xl={6}>
          <FormGroup>
            <Label>Phone</Label>
            <Input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={fields.phoneNumber}
              onChange={(event) => onChangeField(event, "phoneNumber")}
              autoComplete="tel-national"
            />
            <FormErrorText error={posted && errors.phoneNumber} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="button-container text-right">
            {onCancel && (
              <Button color="secondary" onClick={onCancelClicked}>
                Cancel
              </Button>
            )}
            <Button color="success" onClick={onSaveClicked}>
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </WorkingOverlay>
  );
}
