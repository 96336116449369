import format from "date-fns/format";
import parse from "date-fns/parse";
import find from "lodash/find";
import keyBy from "lodash/keyBy";
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Modal,
} from "reactstrap";
import CardHeaderTitle from "../components/CardHeaderTitle";
import { AddChildModal, SelectChildModal } from "../components/SelectChild";
import config from "../config";
import { SkuSlot, SlotProductSku } from "../rockley";
import { LoginOrRegisterForm } from "../screens/LoginOrRegisterScreen";
import basketActions from "../state/basket.actions";
import { useFetchBasket } from "../state/basket.api";
import { useCombinedStore } from "../state/combinedStore";
import { useFetchFamily } from "../state/family.api";
import { FamilyUser } from "../state/family.model";
import { blankProductSku } from "../state/productSkus.model";
import { equalIds, Id } from "../state/types";
import { User } from "../state/users.model";

export interface SlotSelectorProps {
  renderNoSlotsMessage: JSX.Element;
  dates?: { date: string; group: SkuSlot[] }[];
  fetching: boolean;
  onSkusSelectedChange: (skusSelected: boolean) => void;
  onBasketTotalChange: (basketTotal: string) => void;
  onFetchingChange: (fetching: boolean) => void;
}

export default function SlotSelector({
  renderNoSlotsMessage,
  dates,
  fetching,
  onSkusSelectedChange,
  onBasketTotalChange,
  onFetchingChange,
}: SlotSelectorProps): JSX.Element | null {
  const dispatch = useDispatch();

  const { basketLines, basketTotal } = useFetchBasket(config.ORGANISATION_ID);

  useEffect(() => onBasketTotalChange(basketTotal), [
    onBasketTotalChange,
    basketTotal,
  ]);

  const isLoggedIn = useCombinedStore((state) => state.login.isLoggedIn);
  const user = useCombinedStore<User>((state) => state.login.user);

  const [showSelectChildModal, setShowSelectChildModal] = useState(false);
  const onSelectChildModalToggle = useCallback(
    () => setShowSelectChildModal((value) => !value),
    []
  );

  const [showLoginOrRegisterModal, setShowLoginOrRegisterModal] = useState(
    false
  );
  const onLoginOrRegisterModalToggle = useCallback(
    () => setShowLoginOrRegisterModal((value) => !value),
    []
  );

  const isSkuSelected: { [id: string]: Id | undefined } = useMemo(() => {
    const result: { [id: string]: Id | undefined } = {};

    for (const line of basketLines) {
      result[line.productSkuId] = line.recipientId;
    }

    return result;
  }, [basketLines]);

  // The only purpose of this is to animate the buttons when they're deselected
  const [everDeselected, setEverDeselected] = useState<Record<number, boolean>>(
    {}
  );

  const updateBasket = useCallback(
    (sku: SlotProductSku, childId: Id | undefined) => {
      let found = false;

      if (!childId) {
        setEverDeselected({ ...everDeselected, [sku.id]: true });
      }

      for (const line of basketLines) {
        if (equalIds(line.productSkuId, sku.id)) {
          if (equalIds(line.recipientId, childId)) {
            found = true;
            break;
          } else if (childId) {
            const updatedLine = { ...line, recipientId: childId };
            dispatch(
              basketActions.updateBasketLine(
                config.ORGANISATION_ID,
                updatedLine
              )
            );
            found = true;
            break;
          } else {
            dispatch(
              basketActions.removeBasketLine(config.ORGANISATION_ID, line.id)
            );
            found = true;
            break;
          }
        }
      }

      if (!found && childId) {
        dispatch(
          basketActions.addBasketLine({
            organisationId: config.ORGANISATION_ID,
            product_sku: { ...blankProductSku, ...sku },
            productSkuId: sku.id,
            recipientId: childId,
            quantity: 1,
            description: ``,
            price: ``,
          })
        );
      }
    },
    [basketLines, dispatch, everDeselected]
  );

  const [skuToSelect, setSkuToSelect] = useState<SlotProductSku | undefined>();
  const [isSkuAlreadySelected, setSkuAlreadySelected] = useState(false);

  const { fetching: fetchingFamily, children } = useFetchFamily();

  const childrenById = useMemo(() => keyBy(children, "id"), [children]);

  const [justLoggedIn, setJustLoggedIn] = useState(false);

  const onSelectClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>, sku: SlotProductSku) => {
      event.preventDefault();

      setSkuToSelect(sku);
      setSkuAlreadySelected(isSkuSelected[sku.id] ? true : false);

      if (!isLoggedIn) {
        setShowLoginOrRegisterModal(true);
        return;
      }

      if (isSkuSelected[sku.id]) {
        setShowSelectChildModal(true);
        return;
      }

      if (children.length === 1) {
        updateBasket(sku, children[0].id);
        return;
      }

      if (children.length) {
        setShowSelectChildModal(true);
      } else {
        setShowAddChildModal(true);
      }
    },
    [isLoggedIn, children, isSkuSelected, updateBasket]
  );

  const onShowChildModalFromLogin = useCallback(() => {
    if (children.length || user.familyId) {
      setShowSelectChildModal(true);
    } else {
      setShowAddChildModal(true);
    }
  }, [children, user]);

  const onLoggedIn = useCallback(() => {
    setShowLoginOrRegisterModal(false);
    setJustLoggedIn(true);
  }, [setJustLoggedIn]);

  useEffect(() => {
    if (justLoggedIn && !fetchingFamily) {
      setJustLoggedIn(false);
      onShowChildModalFromLogin();
    }
  }, [justLoggedIn, fetchingFamily, onShowChildModalFromLogin]);

  const [showAddChildModal, setShowAddChildModal] = useState(false);
  const onShowAddChildModalToggle = useCallback(
    () => setShowAddChildModal((value) => !value),
    []
  );

  const onAddChild = useCallback(() => {
    setShowLoginOrRegisterModal(false);
    setShowSelectChildModal(false);
    setShowAddChildModal(true);
  }, [setShowLoginOrRegisterModal]);

  const onChildAdded = useCallback(
    (child: FamilyUser) => {
      setShowAddChildModal(false);

      skuToSelect && updateBasket(skuToSelect, child.id);
    },
    [skuToSelect, updateBasket]
  );

  const onDeselectSlot = useCallback(() => {
    setShowSelectChildModal(false);
    skuToSelect && updateBasket(skuToSelect, undefined);
  }, [skuToSelect, updateBasket]);

  const onChildSelected = useCallback(
    (child: FamilyUser) => {
      setShowSelectChildModal(false);

      skuToSelect && updateBasket(skuToSelect, child.id);
    },
    [skuToSelect, updateBasket]
  );

  const noSkusSelected = !find(isSkuSelected, (selected) =>
    selected ? true : false
  );

  useEffect(() => onSkusSelectedChange(!noSkusSelected), [
    onSkusSelectedChange,
    noSkusSelected,
  ]);

  const actuallyFetching = fetching || fetchingFamily;
  useEffect(() => onFetchingChange(actuallyFetching), [
    actuallyFetching,
    onFetchingChange,
  ]);

  return actuallyFetching ? null : (
    <>
      {!!dates && dates.length === 0 && renderNoSlotsMessage}
      <div
        className="card-group-with-gaps auto-card-group auto-card-group-md-2 auto-card-group-lg-3"
        style={{ justifyContent: "space-between", clear: "both" }}
      >
        {!!dates &&
          dates.map(({ date, group }, index) => {
            return (
              <Card
                key={date}
                style={{ animationDelay: `${index * 200}ms` }}
                className="animate__animated animate__fadeIn"
              >
                <CardHeaderTitle className="text-center">
                  {format(
                    parse(date, "yyyyMMdd", 0),
                    config.DATE_FORMAT_WITH_DAY
                  )}
                </CardHeaderTitle>
                <CardBody>
                  <ListGroup>
                    {group.map((slot) => {
                      const isSelected = isSkuSelected[slot.sku.id];
                      return (
                        <ListGroupItem
                          key={slot.sku.id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            {format(slot.dateTime, "HH:mm")}
                          </div>
                          <div style={{ flex: 0 }}>
                            <Button
                              color={isSelected ? "success" : "primary"}
                              size="sm"
                              onClick={(event) =>
                                onSelectClicked(event, slot.sku)
                              }
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {isSelected
                                ? childrenById[isSelected]?.firstName.substr(
                                    0,
                                    12
                                  ) || String(isSelected)
                                : "Select"}
                            </Button>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </CardBody>
              </Card>
            );
          })}
      </div>
      <Modal
        isOpen={showLoginOrRegisterModal}
        centered
        className="no-background"
        unmountOnClose
      >
        <LoginOrRegisterForm
          onLoggedIn={onLoggedIn}
          onCancel={onLoginOrRegisterModalToggle}
        />
      </Modal>
      <SelectChildModal
        onAddChild={onAddChild}
        onDeselectSlot={onDeselectSlot}
        onChildSelected={onChildSelected}
        isOpen={showSelectChildModal}
        onToggle={onSelectChildModalToggle}
        isSkuSelected={isSkuAlreadySelected}
        childs={children}
      />
      <AddChildModal
        isOpen={showAddChildModal}
        onToggle={onShowAddChildModalToggle}
        onChildAdded={onChildAdded}
      />
    </>
  );
}
