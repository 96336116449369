import { ProductProperty } from "./productProperties.model";
import { Id, ModelBase } from "./types";

export type ProductPick = Pick<
  Product,
  "id" | "title" | "price" | "organisationId" | "product_properties"
>;

export type ProductProductProperty = Pick<
  ProductProperty,
  "id" | "productAttributeId" | "product_attribute" | "variesPerSku"
>;

export interface Product extends ModelBase {
  organisationId: Id;
  title: string;
  description: string;
  isPublic: boolean;
  price?: string;
  organisation?: {
    id: Id;
    name: string;
  };
  product_properties?: ProductProductProperty[];
}

export const blankProduct: Product = {
  id: 0,
  organisationId: 0,
  title: "",
  description: "",
  isPublic: false,
};
