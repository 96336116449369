import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import {
  blankProductAttributeOptionProperty,
  ProductAttributeOptionProperty,
} from "./productAttributeOptionProperties.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchProductAttributeOptionPropertiesResults
  extends UseFetchManyResults {
  productAttributeOptionProperties: ProductAttributeOptionProperty[];
  numberOfProductAttributeOptionProperties: number;
  totalNumberOfProductAttributeOptionProperties: number;
}

interface UseFetchProductAttributeOptionPropertyResults
  extends UseFetchOneResults {
  productAttributeOptionProperty: ProductAttributeOptionProperty;
}

const keys: FetcherKeys<
  UseFetchProductAttributeOptionPropertiesResults,
  UseFetchProductAttributeOptionPropertyResults
> = {
  service: "product-attribute-option-properties",
  totalNumber: "totalNumberOfProductAttributeOptionProperties",
  numberOf: "numberOfProductAttributeOptionProperties",
  records: "productAttributeOptionProperties",
  record: "productAttributeOptionProperty",
};

export async function fetchProductAttributeOptionProperties(
  props: FetchManyProps
): Promise<FetchManyResults<ProductAttributeOptionProperty>> {
  return fetchMany<ProductAttributeOptionProperty>(props, keys.service);
}

export function useFetchProductAttributeOptionProperties(
  props: FetchManyProps
): UseFetchProductAttributeOptionPropertiesResults {
  return useFetchMany(props, fetchProductAttributeOptionProperties, keys);
}

export async function fetchProductAttributeOptionProperty(
  props: FetchOneProps
): Promise<ProductAttributeOptionProperty> {
  return fetchOne<
    ProductAttributeOptionProperty,
    UseFetchProductAttributeOptionPropertyResults
  >(props, blankProductAttributeOptionProperty, keys);
}

export function useFetchProductAttributeOptionProperty(
  props: FetchOneProps
): UseFetchProductAttributeOptionPropertyResults {
  return useFetchOne(
    props,
    fetchProductAttributeOptionProperty,
    blankProductAttributeOptionProperty,
    keys
  );
}

async function saveProductAttributeOptionProperty(
  original: ProductAttributeOptionProperty,
  changes: Partial<ProductAttributeOptionProperty>,
  $role: true | false | string
): Promise<ProductAttributeOptionProperty> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteProductAttributeOptionProperty(
  original: ProductAttributeOptionProperty,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveProductAttributeOptionProperty = makeSaver(
  saveProductAttributeOptionProperty,
  true
);
export const adminDeleteProductAttributeOptionProperty = makeDeleter(
  deleteProductAttributeOptionProperty,
  true
);
export const userSaveProductAttributeOptionProperty = makeSaver(
  saveProductAttributeOptionProperty,
  false
);
export const userDeleteProductAttributeOptionProperty = makeDeleter(
  deleteProductAttributeOptionProperty,
  false
);
