import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import OrderLineSelect from "../selects/OrderLineSelect";
import OrderSelect from "../selects/OrderSelect";
import { useFetchOrderLineProperties } from "../state/orderLineProperties.api";
import { OrderLineProperty } from "../state/orderLineProperties.model";
import { Id } from "../state/types";
import { formatUser } from "../state/users.model";
import { useQueryStringState } from "../utils/queryStringState";

export default function OrderLinePropertyListPage({
  orderLineId: forceOrderLineId,
}: {
  orderLineId?: Id;
}): JSX.Element {
  const nested = forceOrderLineId !== undefined;
  const prefix = nested ? "orlipr_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [orderId, setOrderId] = useQueryStringState<Id | undefined>(
    "orderId",
    0,
    prefix
  );
  const [orderLineId, setOrderLineId] = useQueryStringState<Id | undefined>(
    "orderLineId",
    0,
    prefix
  );

  const {
    orderLineProperties,
    numberOfPages,
    fetching,
    error,
  } = useFetchOrderLineProperties({
    page,
    search,
    admin: true,
    query: {
      orderId: orderId || undefined,
      orderLineId: forceOrderLineId || orderLineId || undefined,
    },
  });

  return (
    <TableScreen<OrderLineProperty>
      defaults={{
        ...(forceOrderLineId ? { orderLineId: forceOrderLineId } : {}),
      }}
      nested={nested}
      fetching={fetching}
      error={error}
      title="Order Line Properties"
      singular="Order Line Property"
      columns={[
        { title: "ID" },
        { title: "Order ID" },
        { title: "Order Line" },
        { title: "Attribute" },
        { title: "Option / Value" },
        { title: "User" },
      ]}
      list={orderLineProperties}
      row={(item) => [
        item.id,
        `${item.order_line?.orderId || ""} ${formatUser(
          item.order_line?.order?.user
        )}`,
        item.orderLineId,
        item.product_attribute?.title,
        item.product_attribute_option?.title || item.value,
        item.visibleToUser ? "yes" : "no",
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/order-line-properties/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {forceOrderLineId === undefined && (
            <>
              <TableFilter label="Order">
                <OrderSelect admin value={orderId} onChange={setOrderId} />
              </TableFilter>
              <TableFilter label="Line">
                <OrderLineSelect
                  admin
                  value={orderLineId}
                  onChange={setOrderLineId}
                />
              </TableFilter>
            </>
          )}
        </>
      )}
    />
  );
}
