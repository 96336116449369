import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Row } from "reactstrap";
import loginActions from "../state/login.actions";
import { CombinedStore } from "../state/combinedStore";
import { ApiState } from "../state/types";

export default function NotVerifiedAlert(): JSX.Element | null {
  const isLoggedIn = useSelector<CombinedStore, boolean>(
    (store) => store.login.isLoggedIn
  );
  const isVerified = useSelector<CombinedStore, boolean>(
    (store) => store.login.user?.isVerified || false
  );
  const email = useSelector<CombinedStore, string>(
    (store) => store.login.user?.email || ""
  );
  const resendVerificationTokenState = useSelector<
    CombinedStore,
    ApiState | undefined
  >((store) => store.login.resendVerificationState);

  const dispatch = useDispatch();

  const resendVerifyToken = useCallback(
    (email: string) => dispatch(loginActions.resendVerificationToken(email)),
    [dispatch]
  );
  const [posted, setPosted] = useState(false);

  const didSuccessfullyPost =
    posted && resendVerificationTokenState === "completed";

  return isLoggedIn && !isVerified && !didSuccessfullyPost ? (
    <Row>
      <Col>
        <Alert color="cyan" className="mt-2">
          <Row className="align-items-center">
            <Col>
              We{"'"}ve sent you an email to verify your email address (it may
              take up to 15 minutes to arrive)
            </Col>
            <Col xs="auto">
              {posted && resendVerificationTokenState === "completed" ? (
                <>Email sent!</>
              ) : (
                <Button
                  disabled={resendVerificationTokenState === "started"}
                  color="cyan"
                  size="sm"
                  onClick={() => {
                    resendVerifyToken(email);
                    setPosted(true);
                  }}
                >
                  Resend Verification Email
                </Button>
              )}
            </Col>
          </Row>
        </Alert>
      </Col>
    </Row>
  ) : null;
}
