import React, { FormEvent, ReactNode, useCallback, useMemo } from "react";
import { CustomInput, Table } from "reactstrap";
import { closest } from "../utils/dom";

export interface Column {
  title: string;
}

export interface DataTableProps<T> {
  onClick?: (id: string) => void;
  list: T[];
  rowId: (item: T) => string;
  row: (item: T, index: number) => ReactNode[];
  columns: Column[];
  className?: string;
  pageSize?: number;
  isFetching?: boolean;
  hideEmptyRows?: boolean;
  enableSelection?: boolean;
  onRowSelectionChange?: (id: string, selected: boolean) => void;
  selectedIds?: Record<string, boolean>;
}

export default function DataTable<T>({
  onClick,
  list,
  rowId,
  row,
  columns,
  className,
  pageSize,
  isFetching,
  hideEmptyRows,
  enableSelection,
  onRowSelectionChange,
  selectedIds,
}: DataTableProps<T>): JSX.Element {
  const onTableClicked = useCallback(
    (event: FormEvent<HTMLElement>) => {
      if (
        event.target instanceof Element &&
        event.target.tagName.toLowerCase() !== "td"
      ) {
        return;
      }
      const tr = closest(event.target as Node, "tr");
      if (tr && tr instanceof HTMLTableRowElement) {
        const id = tr.dataset.id || "";
        if (onClick) {
          onClick(id);
        }
      }
    },
    [onClick]
  );

  const allTicked = useMemo(
    () =>
      selectedIds ? list.every((item) => selectedIds[rowId(item)]) : false,
    [list, rowId, selectedIds]
  );

  const toggleAllTicked = useCallback(() => {
    if (onRowSelectionChange) {
      const ids = list.map((item) => rowId(item));
      for (const id of ids) {
        onRowSelectionChange(id, !allTicked);
      }
    }
  }, [allTicked, list, onRowSelectionChange, rowId]);

  return (
    <Table responsive hover onClick={onTableClicked} className={className}>
      <thead>
        <tr>
          {enableSelection ? (
            <th>
              <CustomInput
                type="checkbox"
                name="checkAll"
                id="checkAll"
                checked={allTicked}
                onChange={toggleAllTicked}
                label=""
              />
            </th>
          ) : null}
          {columns.map((column, index) => (
            <th key={index}>{isFetching ? "\u00A0" : column.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item, index) => {
          const id = rowId(item);

          return (
            <tr key={index} data-id={id}>
              {enableSelection ? (
                <td>
                  <CustomInput
                    type="checkbox"
                    name={`checkbox${index}`}
                    id={`checkbox${index}`}
                    checked={selectedIds && selectedIds[id] ? true : false}
                    onChange={(event) => {
                      onRowSelectionChange &&
                        onRowSelectionChange(id, event.currentTarget.checked);
                    }}
                    label=""
                  />
                </td>
              ) : null}
              {row(item, index).map((string, index) => (
                <td key={index}>{string}</td>
              ))}
            </tr>
          );
        })}
        {pageSize && !hideEmptyRows
          ? Array(Math.max(0, (pageSize || 10) - list.length))
              .fill(0)
              .map((_, index) => (
                <tr key={index + 10} className="disabled">
                  {columns.map((_, index) => (
                    <td key={index}>{"\u00A0"}</td>
                  ))}
                </tr>
              ))
          : null}
      </tbody>
    </Table>
  );
}

export function DataTableDrillDown(): JSX.Element {
  return <div className="text-right">...</div>;
}
