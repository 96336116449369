import React from "react";
import { StyledSelect } from "../components/StyledSelect";
import { getSelectOptionByValue } from "../utils/reactSelect";
import { COUNTRY_OPTIONS } from "../countryOptions";

interface CountrySelectProps {
  id?: string;
  value?: string;
  onChange: (newValue: string | undefined) => void;
  [key: string]: unknown;
}

export default function CountrySelect({
  id,
  value,
  onChange,
  ...props
}: CountrySelectProps): JSX.Element {
  return (
    <StyledSelect
      id={id}
      options={COUNTRY_OPTIONS}
      value={getSelectOptionByValue(COUNTRY_OPTIONS, value || "")}
      onChange={(newValue) =>
        onChange(newValue && "value" in newValue ? newValue.value : undefined)
      }
      isClearable
      {...props}
    />
  );
}
