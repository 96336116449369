import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import AttributeValueFields from "../selects/AttributeValueFields";
import ProductAttributeOptionSelect from "../selects/ProductAttributeOptionSelect";
import {
  adminDeleteProductAttributeOptionProperty,
  adminSaveProductAttributeOptionProperty,
  useFetchProductAttributeOptionProperty,
} from "../state/productAttributeOptionProperties.api";
import { ProductAttributeOptionProperty } from "../state/productAttributeOptionProperties.model";
import { useLocationId } from "../utils/queryStringState";

interface ParamProps {
  productattributeoptionpropertyid: string;
}

export default function ProductAttributeOptionPropertyDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(
    match.params.productattributeoptionpropertyid
  );
  const {
    productAttributeOptionProperty,
    fetching,
    error,
  } = useFetchProductAttributeOptionProperty({ id, admin: true });

  return (
    <>
      <DetailScreen<ProductAttributeOptionProperty>
        defaults={
          (location.state as {
            defaults?: Partial<ProductAttributeOptionProperty>;
          })?.defaults
        }
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={productAttributeOptionProperty}
        isNew={match.params.productattributeoptionpropertyid === "new"}
        route={[
          {
            "Product Attribute Option Properties":
              "/admin/product-attribute-option-properties",
          },
        ]}
        renderForm={ProductAttributeOptionPropertyDetailForm}
        onSave={adminSaveProductAttributeOptionProperty}
        onDelete={adminDeleteProductAttributeOptionProperty}
      />
    </>
  );
}

function ProductAttributeOptionPropertyDetailForm({
  getField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<ProductAttributeOptionProperty>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="parentProductAttributeOptionId">Parent Option</Label>
            <ProductAttributeOptionSelect
              admin
              isDisabled={readOnly}
              id="parentProductAttributeOptionId"
              value={getField("parentProductAttributeOptionId")}
              onChange={(newValue) =>
                changeFieldValue(
                  "parentProductAttributeOptionId",
                  null,
                  newValue
                )
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AttributeValueFields
            readOnly={readOnly}
            productAttributeId={getField("productAttributeId")}
            onProductAttributeIdChange={(newValue) =>
              changeFieldValue("productAttributeId", null, newValue)
            }
            productAttributeOptionId={getField("productAttributeOptionId")}
            onProductAttributeOptionIdChange={(newValue) =>
              changeFieldValue("productAttributeOptionId", null, newValue)
            }
            value={getField("value")}
            onValueChange={(newValue) =>
              changeFieldValue("value", null, newValue)
            }
          />
        </Col>
      </Row>
      <AdminCommonDetailRow<ProductAttributeOptionProperty>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
