import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import AttributeValueFields from "../selects/AttributeValueFields";
import ProductSkuSelect from "../selects/ProductSkuSelect";
import {
  adminDeleteProductSkuProperty,
  adminSaveProductSkuProperty,
  useFetchProductSkuProperty,
} from "../state/productSkuProperties.api";
import { ProductSkuProperty } from "../state/productSkuProperties.model";
import { useLocationId } from "../utils/queryStringState";

interface ParamProps {
  productskupropertyid: string;
}

export default function ProductSkuPropertyDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.productskupropertyid);
  const { productSkuProperty, fetching, error } = useFetchProductSkuProperty({
    id,
    admin: true,
  });

  return (
    <DetailScreen<ProductSkuProperty>
      defaults={
        (location.state as { defaults?: Partial<ProductSkuProperty> })?.defaults
      }
      onIdChange={setId}
      fetching={fetching}
      error={error}
      original={productSkuProperty}
      isNew={match.params.productskupropertyid === "new"}
      route={[{ "Product SKU Properties": "/admin/product-sku-properties" }]}
      renderForm={ProductSkuPropertyDetailForm}
      onSave={adminSaveProductSkuProperty}
      onDelete={adminDeleteProductSkuProperty}
    />
  );
}

function ProductSkuPropertyDetailForm({
  getField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<ProductSkuProperty>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="productSkuId">Product SKU</Label>
            <ProductSkuSelect
              admin
              isDisabled={readOnly}
              id="productSkuId"
              value={getField("productSkuId")}
              onChange={(newValue) =>
                changeFieldValue("productSkuId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AttributeValueFields
            readOnly={readOnly}
            productAttributeId={getField("productAttributeId")}
            onProductAttributeIdChange={(newValue) =>
              changeFieldValue("productAttributeId", null, newValue)
            }
            productAttributeOptionId={getField("productAttributeOptionId")}
            onProductAttributeOptionIdChange={(newValue) =>
              changeFieldValue("productAttributeOptionId", null, newValue)
            }
            value={getField("value")}
            onValueChange={(newValue) =>
              changeFieldValue("value", null, newValue)
            }
          />
        </Col>
      </Row>
      <AdminCommonDetailRow<ProductSkuProperty>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
