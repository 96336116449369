import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

export default function InternalOrExternalLink({
  link,
  children,
  ...rest
}: {
  link: string;
  children: ReactNode;
  [id: string]: unknown;
}): JSX.Element {
  if (link.indexOf("://") < 0) {
    return (
      <Link to={link} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  );
}
