import React, { FormEvent } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default function DirectionsModal({
  toggle,
  isOpen,
}: {
  toggle: (event?: FormEvent<HTMLButtonElement>) => void;
  isOpen: boolean;
}): JSX.Element {
  return (
    <Modal toggle={toggle} isOpen={isOpen} centered>
      <ModalHeader toggle={toggle}>East Bridgford Studio</ModalHeader>
      <ModalBody>
        Unit 3 {"&"} 3a
        <br />
        1 Hackers Close
        <br />
        East Bridgford
        <br />
        Nottingham
        <br />
        NG13 8PU
        <br />
        <div className="mt-3">
          <Button
            size="sm"
            color="secondary"
            tag="a"
            href="https://goo.gl/maps/bW56oBpxqQm6Zfam7"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open in Google Maps
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
}
