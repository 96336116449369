import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Alert, Button, Col, Container, Fade, Row } from "reactstrap";
import CustomerDetailPage from "./admin/CustomerDetailPage";
import CustomerListPage from "./admin/CustomerListPage";
import OrderDetailPage from "./admin/OrderDetailPage";
import OrderLineDetailPage from "./admin/OrderLineDetailPage";
import OrderLineListPage from "./admin/OrderLineListPage";
import OrderLinePropertyDetailPage from "./admin/OrderLinePropertyDetailPage";
import OrderLinePropertyListPage from "./admin/OrderLinePropertyListPage";
import OrderListPage from "./admin/OrderListPage";
import OrganisationDetailPage from "./admin/OrganisationDetailPage";
import OrganisationListPage from "./admin/OrganisationListPage";
import OrganisationMemberDetailPage from "./admin/OrganisationMemberDetailPage";
import OrganisationMemberListPage from "./admin/OrganisationMemberListPage";
import PaymentAssignmentDetailPage from "./admin/PaymentAssignmentDetailPage";
import PaymentAssignmentListPage from "./admin/PaymentAssignmentListPage";
import PaymentDetailPage from "./admin/PaymentDetailPage";
import PaymentListPage from "./admin/PaymentListPage";
import PaymentMethodDetailPage from "./admin/PaymentMethodDetailPage";
import PaymentMethodListPage from "./admin/PaymentMethodListPage";
import ProductAttributeDetailPage from "./admin/ProductAttributeDetailPage";
import ProductAttributeListPage from "./admin/ProductAttributeListPage";
import ProductAttributeOptionDetailPage from "./admin/ProductAttributeOptionDetailPage";
import ProductAttributeOptionListPage from "./admin/ProductAttributeOptionListPage";
import ProductAttributeOptionPropertyDetailPage from "./admin/ProductAttributeOptionPropertyDetailPage";
import ProductAttributeOptionPropertyListPage from "./admin/ProductAttributeOptionPropertyListPage";
import ProductDetailPage from "./admin/ProductDetailPage";
import ProductListPage from "./admin/ProductListPage";
import ProductPropertyDetailPage from "./admin/ProductPropertyDetailPage";
import ProductPropertyListPage from "./admin/ProductPropertyListPage";
import ProductSkuDetailPage from "./admin/ProductSkuDetailPage";
import ProductSkuListPage from "./admin/ProductSkuListPage";
import ProductSkuPropertyDetailPage from "./admin/ProductSkuPropertyDetailPage";
import ProductSkuPropertyListPage from "./admin/ProductSkuPropertyListPage";
import UploadDetailPage from "./admin/UploadDetailPage";
import UploadListPage from "./admin/UploadListPage";
import UserAddressDetailPage from "./admin/UserAddressDetailPage";
import UserAddressListPage from "./admin/UserAddressListPage";
import UserDetailPage from "./admin/UserDetailPage";
import UserListPage from "./admin/UserListPage";
import Navigation, { Screen } from "./components/Navigation";
import NotVerifiedAlert from "./components/NotVerifiedAlert";
import PageTitle from "./components/PageTitle";
import RequireLogin from "./components/RequireLogin";
import WarnIE from "./components/WarnIE";
import wrapScreen from "./components/wrapScreen";
import CheckoutScreen from "./screens/CheckoutScreen";
import FamilyScreen from "./screens/FamilyScreen";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import OneToOneInPersonSlotScreen from "./screens/OneToOneInPersonSlotScreen";
import OneToOneInPersonTeacherScreen from "./screens/OneToOneInPersonTeacherScreen";
import VerifyTokenScreen from "./screens/VerifyTokenScreen";
import loginActions from "./state/login.actions";
import { useCombinedStore } from "./state/combinedStore";
import MessageTemplateDetailPage from "./admin/MessageTemplateDetailPage";
import MessageTemplateListPage from "./admin/MessageTemplateListPage";

const SCREENS: Screen[] = [
  { title: "Home", path: "/", component: HomeScreen },
  { path: "/one2one/teacher/", component: OneToOneInPersonTeacherScreen },
  {
    path: "/one2one/teacher/:teacherid/",
    component: OneToOneInPersonSlotScreen,
  },
  { path: "/family", component: wrapScreen(FamilyScreen, RequireLogin) },
  { path: "/checkout", component: CheckoutScreen },
  {
    permission: "manageCustomers",
    path: "/admin/customers/:customerid",
    component: wrapScreen(CustomerDetailPage, RequireLogin),
  },
  {
    permission: "manageCustomers",
    path: "/admin/customers",
    title: "Customers",
    component: wrapScreen(CustomerListPage, RequireLogin),
  },
  {
    permission: "manageOrders",
    path: "/admin/orders/:orderid",
    component: wrapScreen(OrderDetailPage, RequireLogin),
  },
  {
    permission: "manageOrders",
    path: "/admin/orders",
    title: "Orders",
    component: wrapScreen(OrderListPage, RequireLogin),
  },
  {
    permission: "manageOrders",
    path: "/admin/order-lines/:orderlineid",
    component: wrapScreen(OrderLineDetailPage, RequireLogin),
  },
  {
    permission: "manageOrders",
    path: "/admin/order-lines",
    title: "Order Lines",
    component: wrapScreen(OrderLineListPage, RequireLogin),
  },
  {
    permission: "manageOrders",
    path: "/admin/order-line-properties/:orderlinepropertyid",
    component: wrapScreen(OrderLinePropertyDetailPage, RequireLogin),
  },
  {
    permission: "manageOrders",
    path: "/admin/order-line-properties",
    component: wrapScreen(OrderLinePropertyListPage, RequireLogin),
  },
  {
    permission: "managePayments",
    path: "/admin/payments/:paymentid",
    component: wrapScreen(PaymentDetailPage, RequireLogin),
  },
  {
    permission: "managePayments",
    path: "/admin/payments",
    title: "Payments",
    component: wrapScreen(PaymentListPage, RequireLogin),
  },
  {
    permission: "managePayments",
    path: "/admin/payment-assignments/:paymentassignmentid",
    component: wrapScreen(PaymentAssignmentDetailPage, RequireLogin),
  },
  {
    permission: "managePayments",
    path: "/admin/payment-assignments",
    component: wrapScreen(PaymentAssignmentListPage, RequireLogin),
  },
  {
    permission: "manageProducts",
    path: "/admin/products/:productid",
    component: wrapScreen(ProductDetailPage, RequireLogin),
  },
  {
    permission: "manageProducts",
    path: "/admin/products",
    title: "Products",
    component: wrapScreen(ProductListPage, RequireLogin),
  },
  {
    permission: "manageProducts",
    path: "/admin/product-properties/:productpropertyid",
    component: wrapScreen(ProductPropertyDetailPage, RequireLogin),
  },
  {
    permission: "manageProducts",
    path: "/admin/product-properties",
    component: wrapScreen(ProductPropertyListPage, RequireLogin),
  },
  {
    permission: "manageProducts",
    path: "/admin/product-skus/:productskuid",
    component: wrapScreen(ProductSkuDetailPage, RequireLogin),
  },
  {
    permission: "manageProducts",
    path: "/admin/product-skus",
    component: wrapScreen(ProductSkuListPage, RequireLogin),
  },
  {
    permission: "manageProducts",
    path: "/admin/product-sku-properties/:productskupropertyid",
    component: wrapScreen(ProductSkuPropertyDetailPage, RequireLogin),
  },
  {
    permission: "manageProducts",
    path: "/admin/product-sku-properties",
    component: wrapScreen(ProductSkuPropertyListPage, RequireLogin),
  },
  {
    permission: "manageProductAttributes",
    path: "/admin/product-attributes/:productattributeid",
    component: wrapScreen(ProductAttributeDetailPage, RequireLogin),
  },
  {
    permission: "manageProductAttributes",
    path: "/admin/product-attributes",
    title: "Product Attributes",
    component: wrapScreen(ProductAttributeListPage, RequireLogin),
  },
  {
    permission: "manageProductAttributes",
    path: "/admin/product-attribute-options/:productattributeoptionid",
    component: wrapScreen(ProductAttributeOptionDetailPage, RequireLogin),
  },
  {
    permission: "manageProductAttributes",
    path: "/admin/product-attribute-options",
    component: wrapScreen(ProductAttributeOptionListPage, RequireLogin),
  },
  {
    permission: "manageProductAttributes",
    path:
      "/admin/product-attribute-option-properties/:productattributeoptionpropertyid",
    component: wrapScreen(
      ProductAttributeOptionPropertyDetailPage,
      RequireLogin
    ),
  },
  {
    permission: "manageProductAttributes",
    path: "/admin/product-attribute-option-properties",
    component: wrapScreen(ProductAttributeOptionPropertyListPage, RequireLogin),
  },
  {
    permission: "manageUsers",
    path: "/admin/organisations/:organisationid",
    component: wrapScreen(OrganisationDetailPage, RequireLogin),
  },
  {
    permission: "manageUsers",
    path: "/admin/organisations",
    title: "Organisations",
    component: wrapScreen(OrganisationListPage, RequireLogin),
  },
  {
    permission: "manageUsers",
    path: "/admin/organisation-members/:organisationmemberid",
    component: wrapScreen(OrganisationMemberDetailPage, RequireLogin),
  },
  {
    permission: "manageUsers",
    path: "/admin/organisation-members",
    title: "Organisation Members",
    component: wrapScreen(OrganisationMemberListPage, RequireLogin),
  },
  {
    permission: "managePaymentMethods",
    path: "/admin/payment-methods/:paymentmethodid",
    component: wrapScreen(PaymentMethodDetailPage, RequireLogin),
  },
  {
    permission: "managePaymentMethods",
    path: "/admin/payment-methods",
    title: "Payment Methods",
    component: wrapScreen(PaymentMethodListPage, RequireLogin),
  },
  {
    permission: "manageUploads",
    path: "/admin/uploads/:uploadid",
    component: wrapScreen(UploadDetailPage, RequireLogin),
  },
  {
    permission: "manageUploads",
    path: "/admin/uploads",
    title: "Uploads",
    component: wrapScreen(UploadListPage, RequireLogin),
  },
  {
    permission: "manageUploads", // TODO: permission?
    path: "/admin/messagetemplates/:messageTemplateId",
    component: wrapScreen(MessageTemplateDetailPage, RequireLogin),
  },
  {
    permission: "manageUploads", // TODO: permission?
    path: "/admin/messagetemplates",
    title: "Message Templates",
    component: wrapScreen(MessageTemplateListPage, RequireLogin),
  },
  {
    permission: "superuser",
    path: "/admin/users/:userid",
    component: wrapScreen(UserDetailPage, RequireLogin),
  },
  {
    permission: "superuser",
    path: "/admin/users",
    title: "Users",
    component: wrapScreen(UserListPage, RequireLogin),
  },
  {
    permission: "superuser",
    path: "/admin/user-addresses/:useraddressid",
    component: wrapScreen(UserAddressDetailPage, RequireLogin),
  },
  {
    permission: "superuser",
    path: "/admin/user-addresses",
    component: wrapScreen(UserAddressListPage, RequireLogin),
  },
];

function NotPermitted(): JSX.Element {
  return (
    <RequireLogin>
      <PageTitle title="Not Permitted" />
      You do not have permission to access this page
    </RequireLogin>
  );
}

export default function App(): JSX.Element {
  const isLoggedIn = useCombinedStore((store) => store.login.isLoggedIn);
  const permissions = useCombinedStore((store) => store.login.permissions);

  return (
    <div className="App">
      <Navigation screens={SCREENS} />
      <main className={isLoggedIn ? "" : "not-logged-in"}>
        <div className="watermark" />
        <Container>
          <NotVerifiedAlert />
          <WarnIE />
          <Messages />
        </Container>
        <Switch>
          <Route path="/verify/:token" component={VerifyTokenScreen} />
          <Route path="/login" component={LoginScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/resetpassword/:token" component={ResetPasswordScreen} />
          <Route path="/resetpassword" component={ResetPasswordScreen} />
          {SCREENS.map((screen) =>
            !screen.permission !== undefined || permissions.anyManager ? (
              <Route
                exact
                key={screen.path}
                path={screen.path}
                component={screen.component}
              />
            ) : (
              <Route
                exact
                key={screen.path}
                path={screen.path}
                component={NotPermitted}
              />
            )
          )}
        </Switch>
      </main>
      <footer>
        <Container>
          <div className="copyright">
            Copyright &copy; 2020-2021 Rockley Music Ltd. Website by{" "}
            <a href="https://www.hrunk.com/">Hrunk</a>.
          </div>
        </Container>
      </footer>
    </div>
  );
}

export function Messages(): JSX.Element {
  const messages = useCombinedStore((store) => store.login.messages);
  const dispatch = useDispatch();

  const [currentMessage, setCurrentMessage] = useState<string | undefined>();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (messages.length && !currentMessage) {
      setCurrentMessage(messages[0].message);
      dispatch(loginActions.dequeueMessage());
      setVisible(true);
    }
  }, [messages, currentMessage, dispatch, visible]);

  return (
    <>
      <Fade in={visible} onExited={() => setCurrentMessage("")}>
        {currentMessage && (
          <Alert color="info" className="mt-2">
            <Row className="align-items-center">
              <Col>{currentMessage}</Col>
              <Col xs="auto">
                <Button
                  color="info"
                  onClick={() => setVisible(false)}
                  size="sm"
                >
                  OK
                </Button>
              </Col>
            </Row>
          </Alert>
        )}
      </Fade>
    </>
  );
}
