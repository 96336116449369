import api from "./api";
import { AsyncAction } from "./combinedStore";
import { Family, FamilyUser } from "./family.model";
import { FamilyAction, FamilyActions } from "./family.reducer";
import { LoginActions, SetFamilyIdLoginAction } from "./login.reducer";

function fetchFamily(
  callback?: (error: unknown | undefined, family?: Family) => void
): AsyncAction<FamilyAction> {
  return async (dispatch) => {
    dispatch({ type: FamilyActions.STARTED_FETCH_FAMILY });

    try {
      const family = (await api
        .service("family-manager")
        .get("family")) as Family;

      dispatch({ type: FamilyActions.COMPLETED_FETCH_FAMILY, family });
      callback && callback(null, family);
    } catch (error) {
      dispatch({ type: FamilyActions.FAILED_FETCH_FAMILY, error });
      callback && callback(error);
    }
  };
}

function addChild(
  firstName: string,
  lastName: string,
  _metadata: Record<string, unknown>,
  callback?: (error: unknown | undefined, child?: FamilyUser) => void
): AsyncAction<FamilyAction | SetFamilyIdLoginAction> {
  return async (dispatch) => {
    dispatch({ type: FamilyActions.STARTED_ADD_CHILD });

    try {
      const { child, familyId } = await api.service("family-manager").create({
        action: "add-child",
        child: {
          firstName,
          lastName,
        },
      });

      dispatch({ type: FamilyActions.COMPLETED_ADD_CHILD, child });
      dispatch({ type: LoginActions.SET_FAMILY_ID, familyId });
      callback && callback(null, child);
    } catch (error) {
      dispatch({ type: FamilyActions.FAILED_ADD_CHILD, error });
      callback && callback(error);
    }
  };
}

const familyActions = {
  fetchFamily,
  addChild,
};

export default familyActions;
