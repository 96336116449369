import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { ValueType } from "react-select";
import {
  FetchManyProps,
  FetchManyResults,
  fetchOneFromCache,
  FetchOneProps,
} from "../components/GenericFetchers";
import {
  SelectOption,
  StyledAsyncSelect,
  StyledAsyncSelectProps,
} from "../components/StyledSelect";
import { Id } from "../state/types";
import { useUpdateListener } from "../updateListeners";

const MAX_AGE = 60 * 1000;

export interface GenericSelectOption<T> extends SelectOption<Id> {
  isCreate?: boolean;
  record?: T;
}

export interface GenericSelectProps<T>
  extends Omit<StyledAsyncSelectProps<GenericSelectOption<T>>, "onChange"> {
  value?: Id;
  placeholder?: string;
  onChange: (newValue: Id | undefined) => void;
  query?: Record<string, unknown>;
  admin?: boolean;
  disableClear?: boolean;
  onCreate?: (searchTerm: string, setSelection: (newValue: Id) => void) => void;
  createLabel?: string;
  notSearcehdCreatedLabel?: string; // used instead of createLabel when minimumSearch isn't met
  disableCreateWithoutSearch?: boolean;
  renderAfter?: (selected: T) => ReactNode;
  minimumSearch?: number;
}

const DEFAULT_CREATE_LABEL = "Create new...";

export default function GenericSelect<T extends { id: Id }>(
  {
    value,
    onChange,
    placeholder,
    query,
    admin,
    disableClear,
    createLabel,
    notSearcehdCreatedLabel,
    onCreate,
    renderAfter,
    minimumSearch,
    disableCreateWithoutSearch,
    ...props
  }: GenericSelectProps<T>,
  oneFetcher: (props: FetchOneProps) => Promise<T>,
  manyFetcher: (props: FetchManyProps) => Promise<FetchManyResults<T>>,
  service: string,
  blankRecord: T,
  format: (record: T) => string
): JSX.Element {
  // For some reason I've forgotten, I couldn't just use the useFetchXYZ for the service, so have to replicate it all here

  const lastRefreshed = useUpdateListener(service);

  const [record, setRecord] = useState<T>(
    (fetchOneFromCache({ id: value as Id, query, admin }, service)
      ?.record as T) || blankRecord
  );

  useEffect(() => {
    if (!value || value === "0") {
      setRecord({ ...blankRecord });
      return;
    }

    let cancelled = false;

    const cached = fetchOneFromCache(
      { id: value as Id, query, admin },
      service,
      MAX_AGE,
      lastRefreshed
    );
    if (cached) {
      setRecord({ ...(cached.record as T) });
    } else {
      oneFetcher({ id: value as Id, query, admin })
        .then((record: T) => {
          if (!cancelled) {
            setRecord(record);
          }
        })
        .catch((error: unknown) => {
          if (!cancelled) {
            console.error(`Error fetching ${service}: `, error);
            setRecord({ ...blankRecord });
          }
        });
    }

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, JSON.stringify(query), admin, lastRefreshed]);

  const [search, setSearch] = useState("");

  const loadOptionsCallback = useCallback(
    async (search: string): Promise<unknown> => {
      const searchTrimmed = search.trim();
      setSearch(search);
      if (searchTrimmed.length < (minimumSearch || 0)) {
        return onCreate && !disableCreateWithoutSearch
          ? [
              {
                label: " ",
                options: [
                  {
                    value: "I don't know",
                    label:
                      notSearcehdCreatedLabel ||
                      createLabel ||
                      DEFAULT_CREATE_LABEL,
                    isCreate: true,
                  },
                ],
              },
            ]
          : [];
      }
      const results = await manyFetcher({
        query: {
          ...(query || {}),
          ...(searchTrimmed.length > 0 && { $search: searchTrimmed }),
        },
        admin,
      });
      const options = results.records.map((item) => ({
        value: String(item.id),
        label: format(item),
        record: item,
      }));
      return onCreate
        ? [
            {
              label: null,
              options,
            },
            {
              label: " ",
              options: [
                {
                  value: "I don't know",
                  label: createLabel || DEFAULT_CREATE_LABEL,
                  isCreate: true,
                },
              ],
            },
          ]
        : options;
    },
    [
      minimumSearch,
      manyFetcher,
      query,
      admin,
      onCreate,
      createLabel,
      disableCreateWithoutSearch,
      notSearcehdCreatedLabel,
      format,
    ]
  );

  const onChangeCallback = useCallback(
    (newValue: ValueType<GenericSelectOption<T>, false>) => {
      if (!newValue) {
        onChange(undefined);
      } else if ("value" in newValue) {
        if (newValue.record) {
          setRecord(newValue.record);
          onChange(newValue ? newValue.value : undefined);
        } else if (newValue.isCreate) {
          onCreate && onCreate(search, onChange);
        }
      }
    },
    [onChange, onCreate, search]
  );

  return (
    <>
      <StyledAsyncSelect<GenericSelectOption<T>>
        {...props}
        key={JSON.stringify({ query, admin })}
        cacheOptions
        defaultOptions
        loadOptions={loadOptionsCallback}
        value={{
          value: String(value),
          label: record.id ? format(record) : placeholder || ``,
        }}
        onChange={onChangeCallback}
        isClearable={!disableClear}
      />
      {renderAfter ? renderAfter(record) : null}
    </>
  );
}
