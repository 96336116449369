import { Id, ModelBase } from "./types";

export interface MessageTemplate extends ModelBase {
  text: string;
  description: string;
  title: string;
  contentType: "html" | "raw";
  outerId?: Id;
  organisationId?: Id;
  assignment?: string;
  templateLanguage: "hbs" | "ejs" | "text";
  skipForPlainText?: boolean;
}

export const blankMessageTemplate: MessageTemplate = {
  id: 0,
  text: "",
  description: "",
  title: "",
  contentType: "html",
  templateLanguage: "hbs",
};
