import React, { FormEvent, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import DirectionsModal from "../components/DirectionsModal";
import PageTitle from "../components/PageTitle";
import TeacherCard from "../components/TeacherCard";
import { RockleyProductAttributeId } from "../rockley";
import { useFetchProductAttributeOptions } from "../state/productAttributeOptions.api";
import { spaceship } from "../utils/data";

export default function OneToOneVirtualTeacherScreen(): JSX.Element {
  // Use this if you want all teachers to be listed
  // const { product } = useFetchOneToOneLessonsProduct();
  // const teachers = extractTeachersFromProduct(product);

  const title = "1-2-1 Lessons";

  const [showDirections, setShowDirections] = useState(false);

  const toggleDirections = useCallback(
    (event?: FormEvent<HTMLButtonElement>) => {
      event && event.preventDefault();
      setShowDirections((value) => !value);
    },
    []
  );

  const {
    productAttributeOptions: teacherOptions,
  } = useFetchProductAttributeOptions({
    query: {
      productAttributeId: RockleyProductAttributeId.TEACHER,
      $limit: 100,
    },
  });

  const teachers = teacherOptions
    .filter((teacher) =>
      (
        teacher.product_attribute?.product_attribute_option_properties || []
      ).find(
        (option) =>
          option.productAttributeId ===
            RockleyProductAttributeId.AVAILABLE_FOR_STUDIO_LESSONS &&
          option.value === "1"
      )
        ? true
        : false
    )
    .sort((a, b) => spaceship(a.title, b.title));

  return (
    <Container>
      <PageTitle title={title} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <h1 className="fancy">{title}</h1>
              {/* <p className="mb-3">
                Our lessons are COVID-secure, with distancing and face masks
              </p> */}
              <p className="lead">Select your teacher</p>
              <div className="card-group-with-gaps auto-card-group auto-card-group-xs-2 auto-card-group-md-4">
                {teachers.map((teacher, index) => (
                  <Link
                    key={teacher.id}
                    to={`${teacher.id}/`}
                    className="zoom-on-hover"
                  >
                    <TeacherCard teacher={teacher} animationIndex={index} />
                  </Link>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DirectionsModal toggle={toggleDirections} isOpen={showDirections} />
    </Container>
  );
}
