import { applyMiddleware, compose, createStore, Middleware } from "redux";
import { createLogger } from "redux-logger";
import ReduxThunk from "redux-thunk";
import config from "../config";
import { initPersist } from "./persist";
import combinedStore from "./combinedStore";

const middlewares: Middleware[] = [ReduxThunk];

if (config.ENABLE_LOGGING) {
  middlewares.push(createLogger({}));
}

const store = createStore(
  combinedStore,
  undefined,
  compose(applyMiddleware(...middlewares))
);

initPersist(store);

export default store;
