import { useEffect, useRef, useState } from "react";

export function usePrevious<T>(value: T | undefined): T | undefined {
  const ref = useRef<T | undefined>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debounced, setDebounced] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debounced;
}

export function useMounted(): boolean {
  const [hasMounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  return hasMounted;
}
