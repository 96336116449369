import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import {
  adminDeleteOrganisation,
  adminSaveOrganisation,
  useFetchOrganisation,
} from "../state/organisations.api";
import { Organisation } from "../state/organisations.model";
import { useLocationId } from "../utils/queryStringState";
import OrganisationMemberListPage from "./OrganisationMemberListPage";

interface ParamProps {
  organisationid: string;
}

export default function OrganisationDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.organisationid);
  const { organisation, fetching, error } = useFetchOrganisation({
    id,
    admin: true,
  });

  return (
    <>
      <DetailScreen<Organisation>
        defaults={
          (location.state as { defaults?: Partial<Organisation> })?.defaults
        }
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={organisation}
        isNew={match.params.organisationid === "new"}
        route={[{ Organisations: "/admin/organisations" }]}
        renderForm={OrganisationDetailForm}
        onSave={adminSaveOrganisation}
        onDelete={adminDeleteOrganisation}
      />

      {!!id && <OrganisationMemberListPage organisationId={id} />}
    </>
  );
}

function OrganisationDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<Organisation>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="name"
              id="name"
              value={getField("name")}
              onChange={(event) => changeField("name", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<Organisation>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
