import React, {
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import PageTitle from "../components/PageTitle";
import Paginator from "../components/Paginator";
import { usePrevious } from "../utils/hooks";
import DataTable, {
  Column as DataTableColumn,
  DataTableProps,
} from "./DataTable";
import { LoadingOverlay } from "./LoadingOverlay";
import "./TableScreen.scss";

export type Column = DataTableColumn;

export interface TablePaginatorProps {
  currentPage?: number;
  numberOfPages?: number;
  onSelectPage?: (pageNumber: number) => void;
}

export interface TableScreenProps<T>
  extends DataTableProps<T>,
    TablePaginatorProps {
  fetching?: boolean;
  error?: unknown;
  nested?: boolean;
  detailPath?: string;
  title: string;
  renderFilters?: () => ReactNode;
  renderButtons?: () => ReactNode;
  singular: string;
  search?: string;
  onSearchChange?: (newSearch: string) => void;
  defaults?: Partial<T>;
}

export default function TableScreen<T>({
  currentPage,
  defaults,
  detailPath,
  error,
  fetching,
  nested,
  numberOfPages,
  onClick,
  onSearchChange,
  onSelectPage,
  renderButtons,
  renderFilters,
  search,
  singular,
  title,
  pageSize,
  ...rest
}: TableScreenProps<T>): JSX.Element {
  const [debounceFetching, setDebounceFetching] = useState(false);
  const history = useHistory();

  pageSize = pageSize || 10;

  const onDataTableClick = useCallback(
    (id: string) => {
      if (onClick) {
        onClick(id);
      } else if (detailPath) {
        history.push(detailPath.replace(/:id/g, encodeURIComponent(id)));
      }
    },
    [history, onClick, detailPath]
  );

  const onCreateClicked = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (detailPath) {
        history.push({
          pathname: detailPath.replace(/:id/g, encodeURIComponent("new")),
          state: defaults ? { defaults: defaults } : undefined,
        });
      }
    },
    [detailPath, defaults, history]
  );

  const onSearchInputChanged = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      event.preventDefault();
      onSearchChange && onSearchChange(event.currentTarget.value);
      onSelectPage && onSelectPage(0);
    },
    [onSearchChange, onSelectPage]
  );

  const wasFetching = usePrevious<boolean>(fetching || false);
  useEffect(() => {
    if (!fetching && wasFetching) {
      setDebounceFetching(true);
    }
  }, [fetching, wasFetching, setDebounceFetching]);

  const isFetching = fetching && !debounceFetching;

  const buttons = useMemo(
    () => (
      <>
        {renderButtons && renderButtons()}

        <Button color="primary" onClick={onCreateClicked}>
          Create {singular}
        </Button>
      </>
    ),
    [onCreateClicked, renderButtons, singular]
  );

  const card = useMemo(
    () => (
      <Card className="TableScreen-card">
        {nested ? (
          <CardHeader className="buttons-container">
            {nested ? buttons : null}
          </CardHeader>
        ) : null}
        <CardHeader>
          <div className="filters-container">
            {renderFilters && renderFilters()}
            {onSearchChange && search !== undefined ? (
              <div className="filter-container search-container">
                <Input
                  type="text"
                  placeholder="Search"
                  value={search || ""}
                  onChange={onSearchInputChanged}
                />
              </div>
            ) : null}
          </div>
        </CardHeader>
        <CardBody className="position-relative card-body-table">
          {error ? <Alert color="danger">{String(error)}</Alert> : null}
          <DataTable
            pageSize={pageSize}
            isFetching={isFetching}
            className="my-0 table-sm"
            {...rest}
            onClick={onDataTableClick}
          />
          {!isFetching && rest.list.length === 0 && <NoRecords />}
          {isFetching ? <LoadingOverlay /> : null}
        </CardBody>
        {numberOfPages !== undefined &&
          currentPage !== undefined &&
          onSelectPage !== undefined && (
            <CardFooter>
              <Paginator
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                onSelectPage={onSelectPage}
              />
            </CardFooter>
          )}
      </Card>
    ),
    [
      buttons,
      currentPage,
      error,
      isFetching,
      nested,
      numberOfPages,
      onDataTableClick,
      onSearchChange,
      onSearchInputChanged,
      onSelectPage,
      pageSize,
      renderFilters,
      rest,
      search,
    ]
  );

  if (nested) {
    return <>{card}</>;
  }

  return (
    <Container>
      <PageTitle title={title} disableBackButton={nested} route={[]}>
        {buttons}
      </PageTitle>
      <Row>
        <Col xs={12}>{card}</Col>
      </Row>
    </Container>
  );
}

export function TableFilter({
  children,
  maxWidth,
  label,
}: {
  children: ReactNode;
  maxWidth?: number;
  label?: string;
}): JSX.Element {
  return (
    <div className="filter-container" style={{ maxWidth: `${maxWidth}rem` }}>
      {label && <label>{label}</label>}
      {children}
    </div>
  );
}

export function TableFilterControl({
  children,
  label,
}: {
  children: ReactNode;
  label?: string;
}): JSX.Element {
  return (
    <div className="filter-container-control">
      {label && <label>{label}</label>}
      {children}
    </div>
  );
}

export function TableFilterSpacer(): JSX.Element {
  return <div className="filter-container-spacer" />;
}

function NoRecords() {
  return (
    <div className="TableScreen-no-records">
      <div>No records</div>
    </div>
  );
}
