import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  FetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  UseFetchManyResults,
  useFetchOne,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { notifyUpdateListeners } from "../updateListeners";
import { makeSaver, makeDeleter } from "./types";
import api from "./api";
import {
  blankOrganisationMember,
  OrganisationMember,
} from "./organisationMembers.model";

interface UseFetchOrganisationMembersResults extends UseFetchManyResults {
  organisationMembers: OrganisationMember[];
  numberOfOrganisationMembers: number;
  totalNumberOfOrganisationMembers: number;
}

interface UseFetchOrganisationMemberResults extends UseFetchOneResults {
  organisationMember: OrganisationMember;
}

const keys: FetcherKeys<
  UseFetchOrganisationMembersResults,
  UseFetchOrganisationMemberResults
> = {
  service: "organisation-members",
  totalNumber: "totalNumberOfOrganisationMembers",
  numberOf: "numberOfOrganisationMembers",
  records: "organisationMembers",
  record: "organisationMember",
};

export async function fetchOrganisationMembers(
  props: FetchManyProps
): Promise<FetchManyResults<OrganisationMember>> {
  return fetchMany<OrganisationMember>(props, keys.service);
}

export function useFetchOrganisationMembers(
  props: FetchManyProps
): UseFetchOrganisationMembersResults {
  return useFetchMany(props, fetchOrganisationMembers, keys);
}

export async function fetchOrganisationMember(
  props: FetchOneProps
): Promise<OrganisationMember> {
  return fetchOne<OrganisationMember, UseFetchOrganisationMemberResults>(
    props,
    blankOrganisationMember,
    keys
  );
}

export function useFetchOrganisationMember(
  props: FetchOneProps
): UseFetchOrganisationMemberResults {
  return useFetchOne(
    props,
    fetchOrganisationMember,
    blankOrganisationMember,
    keys
  );
}

async function saveOrganisationMember(
  original: OrganisationMember,
  changes: Partial<OrganisationMember>,
  $role: true | false | string
): Promise<OrganisationMember> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteOrganisationMember(
  original: OrganisationMember,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveOrganisationMember = makeSaver(
  saveOrganisationMember,
  true
);
export const adminDeleteOrganisationMember = makeDeleter(
  deleteOrganisationMember,
  true
);
export const userSaveOrganisationMember = makeSaver(
  saveOrganisationMember,
  false
);
export const userDeleteOrganisationMember = makeDeleter(
  deleteOrganisationMember,
  false
);
