import { ModelBase } from "./types";

/// Anything with a ? was added in a later API version
export interface Organisation extends ModelBase {
  name: string;
}

export const blankOrganisation: Organisation = {
  id: 0,
  name: "",
};
