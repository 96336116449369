import React, { ReactNode, useEffect, useState } from "react";
import LoadingIndicator from "./LoadingIndicator";
import "./LoadingOverlay.scss";
import ProgressBar from "./ProgressBar";

interface Props {
  className?: string;
  waitMillis?: number;
  progress?: number;
}

export function LoadingOverlay({
  className,
  waitMillis,
  progress,
}: Props): JSX.Element {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined = setTimeout(() => {
      setVisible(true);
      timeout = undefined;
    }, waitMillis || 200);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [waitMillis]);

  return (
    <div
      className={`${className || ""} fade-on-visible loading-overlay ${
        visible ? "visible" : ""
      }`}
    >
      <LoadingIndicator>
        {progress === undefined ? null : <ProgressBar progress={progress} />}
      </LoadingIndicator>
    </div>
  );
}

export function WorkingOverlay({
  children,
  working,
  progress,
}: {
  children: ReactNode;
  working: boolean;
  progress?: number;
}): JSX.Element {
  return (
    <>
      {children}
      {working && <LoadingOverlay progress={progress} />}
    </>
  );
}
