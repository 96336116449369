import omit from "lodash/omit";
import api from "./api";
import { Basket, BasketLine, BasketWithOrder } from "./basket.model";
import { BasketAction, BasketActions } from "./basket.reducer";
import { AsyncAction } from "./combinedStore";
import { ProductAttributeIdToValue } from "./productProperties.model";
import { Id } from "./types";

let alreadyFetching = false;

function fetchBasket(
  basketOrganisationId: Id,
  callback?: (error: unknown | undefined, basket?: Basket) => void
): AsyncAction<BasketAction> {
  return async (dispatch, getState) => {
    if (alreadyFetching) {
      return;
    }

    alreadyFetching = true;
    dispatch({ type: BasketActions.STARTED_UPDATE });

    try {
      const login = getState().login;
      if (!login.isLoggedIn || !login.user.id) {
        dispatch({ type: BasketActions.FAILED_UPDATE, error: "Not logged in" });
        callback && callback(null, undefined);
        return;
      }

      const basket: Basket = await api.service("basket-manager").create({
        action: "refresh",
        basketOrganisationId,
      });

      dispatch({ type: BasketActions.COMPLETED_UPDATE, basket });
      callback && callback(null, basket);
    } catch (error) {
      dispatch({ type: BasketActions.FAILED_UPDATE, error });
      callback && callback(error);
    }

    alreadyFetching = false;
  };
}

function addBasketLine(
  basketLine: Omit<
    BasketLine,
    "id" | "userId" | "outOfStock" | "basket_line_properties"
  >,
  callback?: (error: unknown | undefined, basket?: Basket) => void
): AsyncAction<BasketAction> {
  return async (dispatch) => {
    dispatch({ type: BasketActions.STARTED_UPDATE });

    try {
      const basket: Basket = await api.service("basket-manager").create({
        action: "add-line",
        basketLine,
        basketOrganisationId: basketLine.organisationId,
      });

      dispatch({ type: BasketActions.COMPLETED_UPDATE, basket });
      callback && callback(null, basket);
    } catch (error) {
      dispatch({ type: BasketActions.FAILED_UPDATE, error });
      callback && callback(error);
    }
  };
}

function updateBasketLine(
  basketOrganisationId: Id,
  basketLine: Omit<BasketLine, "userId" | "outOfStock">,
  callback?: (error: unknown | undefined, basket?: Basket) => void
): AsyncAction<BasketAction> {
  return async (dispatch) => {
    dispatch({ type: BasketActions.STARTED_UPDATE });

    try {
      const basket: Basket = await api.service("basket-manager").create({
        action: "update-line",
        basketLine,
        basketOrganisationId,
      });

      dispatch({ type: BasketActions.COMPLETED_UPDATE, basket });
      callback && callback(null, basket);
    } catch (error) {
      dispatch({ type: BasketActions.FAILED_UPDATE, error });
      callback && callback(error);
    }
  };
}

function placeOrder(
  basketOrganisationId: Id,
  billingAddressId: Id,
  paymentMethodId: Id,
  callback?: (error: unknown | undefined, basket?: BasketWithOrder) => void
): AsyncAction<BasketAction> {
  return async (dispatch) => {
    dispatch({ type: BasketActions.STARTED_UPDATE });

    try {
      const basket: Basket = await api.service("basket-manager").create({
        action: "place-order",
        basketOrganisationId,
        billingAddressId,
        paymentMethodId,
      });

      dispatch({
        type: BasketActions.COMPLETED_UPDATE,
        basket: omit(basket, "paymentRequest"),
      });
      callback && callback(null, basket);
    } catch (error) {
      dispatch({ type: BasketActions.FAILED_UPDATE, error });
      callback && callback(error);
    }
  };
}

function removeBasketLine(
  basketOrganisationId: Id,
  basketLineId: Id
): AsyncAction<BasketAction> {
  return async (dispatch) => {
    dispatch({ type: BasketActions.STARTED_UPDATE });

    try {
      const basket: Basket = await api.service("basket-manager").create({
        action: "remove-line",
        basketLineId,
        basketOrganisationId,
      });

      dispatch({ type: BasketActions.COMPLETED_UPDATE, basket });
    } catch (error) {
      dispatch({ type: BasketActions.FAILED_UPDATE, error });
    }
  };
}

function setBasketProductAttributes(
  basketOrganisationId: Id,
  attributes: ProductAttributeIdToValue
): AsyncAction<BasketAction> {
  return async (dispatch) => {
    dispatch({ type: BasketActions.STARTED_UPDATE });

    try {
      const basket: Basket = await api.service("basket-manager").create({
        action: "set-attributes",
        attributes,
        basketOrganisationId,
      });

      dispatch({ type: BasketActions.COMPLETED_UPDATE, basket });
    } catch (error) {
      dispatch({ type: BasketActions.FAILED_UPDATE, error });
    }
  };
}

const basketActions = {
  fetchBasket,
  addBasketLine,
  updateBasketLine,
  placeOrder,
  removeBasketLine,
  setBasketProductAttributes,
};

export default basketActions;
