import { Id, ModelBase } from "./types";

export enum PaymentService {
  UNKNOWN = "",
  WORLDPAY = "worldpay",
  PAYPAL = "paypal",
}

export interface PaymentMethod extends ModelBase {
  title: string;
  organisationId: Id;
  organisation?: {
    id: Id;
    name: string;
  };
  service: PaymentService;
  apiEndpoint?: string;
  apiClientId?: string;
  apiClientSecret?: string;
  apiMerchantId?: string;
  apiCancelUrl?: string;
  apiReturnUrl?: string;
  isTest: boolean;
  isPublic: boolean;
}

export const blankPaymentMethod: PaymentMethod = {
  id: 0,
  title: "",
  organisationId: 0,
  service: PaymentService.UNKNOWN,
  isTest: false,
  isPublic: false,
};
