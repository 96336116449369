import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import { StyledSelect } from "../components/StyledSelect";
import {
  adminDeleteUser,
  adminSaveUser,
  useFetchUser,
} from "../state/users.api";
import { User } from "../state/users.model";
import { useLocationId } from "../utils/queryStringState";
import { getSelectOptionByValue } from "../utils/reactSelect";
import OrganisationMemberListPage from "./OrganisationMemberListPage";
import UserAddressListPage from "./UserAddressListPage";

const USER_ROLES_OPTIONS = [
  { value: "", label: "Regular User" },
  { value: "superuser", label: "Global Administrator (superuser)" },
];

function UserDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<User>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="firstName"
              id="firstName"
              value={getField("firstName")}
              onChange={(event) => changeField("firstName", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="lastName"
              id="lastName"
              value={getField("lastName")}
              onChange={(event) => changeField("lastName", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              readOnly={readOnly}
              type="email"
              name="email"
              id="email"
              value={getField("email")}
              onChange={(event) => changeField("email", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="role">Role</Label>
            <StyledSelect
              isDisabled={readOnly}
              id="role"
              options={USER_ROLES_OPTIONS}
              value={getSelectOptionByValue<string>(
                USER_ROLES_OPTIONS,
                getField("role")
              )}
              onChange={(newValue) =>
                changeFieldValue(
                  "role",
                  null,
                  newValue && "value" in newValue ? newValue.value : ""
                )
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="password">Password (when creating)</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="password"
              id="password"
              value={getField("password")}
              onChange={(event) => changeField("password", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<User>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}

export default function UserDetailPage({
  match,
  location,
}: RouteComponentProps<{ userid: string }>): JSX.Element {
  const [id, setId] = useLocationId(match.params.userid);
  const { user, fetching, error } = useFetchUser({ id, admin: true });

  return (
    <>
      <DetailScreen<User>
        defaults={(location.state as { defaults?: Partial<User> })?.defaults}
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={user}
        isNew={match.params.userid === "new"}
        route={[{ Users: "/admin/users" }]}
        renderForm={UserDetailForm}
        onSave={adminSaveUser}
        onDelete={adminDeleteUser}
      />

      {!!id && <OrganisationMemberListPage userId={id} />}
      {!!id && <UserAddressListPage userId={id} />}
    </>
  );
}
