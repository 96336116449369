import each from "lodash/each";
import feathers from "@feathersjs/client";
import querystring from "querystring";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  FetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  UseFetchManyResults,
  useFetchOne,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { notifyUpdateListeners } from "../updateListeners";
import { makeDeleter, makeSaver } from "./types";
import api, { REST_ROOT } from "./api";
import { blankUpload, Upload } from "./uploads.model";

interface UseFetchUploadsResults extends UseFetchManyResults {
  uploads: Upload[];
  numberOfUploads: number;
  totalNumberOfUploads: number;
}

interface UseFetchUploadResults extends UseFetchOneResults {
  upload: Upload;
}

const keys: FetcherKeys<UseFetchUploadsResults, UseFetchUploadResults> = {
  service: "uploads",
  totalNumber: "totalNumberOfUploads",
  numberOf: "numberOfUploads",
  records: "uploads",
  record: "upload",
};

export async function fetchUploads(
  props: FetchManyProps
): Promise<FetchManyResults<Upload>> {
  return fetchMany<Upload>(props, keys.service);
}

export function useFetchUploads(props: FetchManyProps): UseFetchUploadsResults {
  return useFetchMany(props, fetchUploads, keys);
}

export async function fetchUpload(props: FetchOneProps): Promise<Upload> {
  return fetchOne<Upload, UseFetchUploadResults>(props, blankUpload, keys);
}

export function useFetchUpload(props: FetchOneProps): UseFetchUploadResults {
  return useFetchOne(props, fetchUpload, blankUpload, keys);
}

async function saveUpload(
  original: Upload,
  changes: Partial<Upload>,
  $role: true | false | string,
  progressCallback?: (percentage: number) => void
): Promise<Upload> {
  const params = {
    query: {
      $role,
    },
  };

  const form = new FormData();
  let haveFile = false;
  each(changes, (value, key) => {
    if (key === "files") {
      const files = value as FileList;
      if (value !== null && files.length > 0) {
        haveFile = true;
        form.append(key, files[0]);
      }
    } else if (key !== "id") {
      form.append(key, String(value));
    }
  });

  if (!haveFile) {
    // If we're not uploading a file, send it over socket.io
    const newUpload = original.id
      ? await api.service(keys.service).patch(original.id, changes, params)
      : await api.service(keys.service).create(changes, params);

    notifyUpdateListeners(keys.service);
    return newUpload;
  }

  // Otherwise, send it over multipart/form-data

  const { accessToken } = await api.get("authentication");

  const promise = new Promise<Upload>((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable && event.total) {
        progressCallback &&
          progressCallback((event.loaded * 100) / event.total);
      }
    });

    request.addEventListener("load", (_event) => {
      let json: Upload & { message?: string };
      try {
        json = JSON.parse(request.responseText);
        if (Math.floor(request.status / 100) !== 2) {
          throw (
            feathers.errors.convert(json) ||
            new Error(json.message || "Upload failed (no error message)")
          );
        }

        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
    request.addEventListener("error", (_event) => {
      reject(new Error("Upload failed"));
    });
    request.addEventListener("abort", (_event) => {
      reject(new Error("Upload aborted"));
    });

    if (original.id) {
      request.open(
        "PATCH",
        `${REST_ROOT}/uploads/${original.id}?${querystring.encode(
          params.query
        )}`
      );
    } else {
      request.open(
        "POST",
        `${REST_ROOT}/uploads?${querystring.encode(params.query)}`
      );
    }
    request.setRequestHeader("Authorization", `Bearer ${accessToken}`);
    request.setRequestHeader("Accept", `application/json`);

    request.send(form);
  });

  const newRecord = await promise;
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteUpload(original: Upload, $role: true | false | string) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveUpload = makeSaver(saveUpload, true);
export const adminDeleteUpload = makeDeleter(deleteUpload, true);
export const userSaveUpload = makeSaver(saveUpload, false);
export const userDeleteUpload = makeDeleter(deleteUpload, false);
