import each from "lodash/each";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import api from "../state/api";
import { useCombinedStore } from "../state/combinedStore";
import queryCacheActions from "../state/queryCache.actions";
import { Id } from "../state/types";
import { useUpdateListener } from "../updateListeners";

const PER_PAGE = 10;

export interface UseFetchManyResults {
  numberOfPages: number;
  fetching: boolean;
  error?: Error;
}

export interface UseFetchOneResults {
  fetching: boolean;
  error?: Error;
}

export interface FetchManyResults<T> extends UseFetchManyResults {
  totalNumberOfRecords: number;
  numberOfRecords: number;
  records: T[];
}

// TODO: could this be a template so we have the keys for query?
export interface FetchManyProps {
  page?: number;
  search?: string;
  query?: Record<string, unknown>;
  admin?: boolean | string;
  sort?: Record<string, number>;
  cacheKey?: string;
  paused?: boolean;
}

export interface FetchManyResultsKeys<T> {
  service: string;
  totalNumber: keyof T;
  numberOf: keyof T;
  records: keyof T;
}

export interface FetchOneProps {
  maxAge?: number;
  id?: Id;
  admin?: boolean | string;
  query?: Record<string, unknown>;
}

export interface FetchOneResultsKeys<T> {
  service: string;
  record: keyof T;
}

export interface FetcherKeys<TManyResults, TOneResults>
  extends FetchManyResultsKeys<TManyResults>,
    FetchOneResultsKeys<TOneResults> {}

export async function fetchMany<T>(
  { query, admin, search, page, sort }: FetchManyProps,
  service: string
): Promise<FetchManyResults<T>> {
  const trimmedSearch = search ? search.trim() : "";

  const json = await api.service(service).find({
    query: {
      ...(query || {}),
      ...(admin && { $role: admin }),
      ...(trimmedSearch.length && { $search: trimmedSearch }),
      $skip: (page || 0) * PER_PAGE,
      $limit: query?.$limit || PER_PAGE,
      ...(!query?.$sort && {
        $sort: {
          ...(sort || { createdAt: -1 }),
        },
      }),
    },
  });

  return {
    totalNumberOfRecords: json.total,
    numberOfPages: Math.ceil(json.total / json.limit),
    numberOfRecords: json.data.length,
    records: json.data,
    fetching: false,
    error: undefined,
  };
}

export function useFetchMany<
  T,
  TProps extends FetchManyProps,
  TResults extends UseFetchManyResults
>(
  props: TProps,
  manyFetcher: (props: TProps) => Promise<FetchManyResults<T>>,
  keys: FetchManyResultsKeys<TResults>,
  forceUpdate?: unknown
): TResults {
  const dispatch = useDispatch();

  const cache = useCombinedStore((state) =>
    props.cacheKey
      ? (state.queryCache.cache[props.cacheKey] as FetchManyResults<T>)
      : undefined
  );

  const [numberOfPages, setNumberOfPages] = useState(cache?.numberOfPages || 0);
  const [totalNumber, setTotalNumber] = useState(
    cache?.totalNumberOfRecords || 0
  );
  const [numberOf, setNumberOf] = useState(cache?.numberOfRecords || 0);
  const [records, setRecords] = useState<T[]>(cache?.records || []);
  const [fetching, setFetching] = useState(cache === undefined);
  const [error, setError] = useState<unknown | undefined>(undefined);

  const lastRefreshed = useUpdateListener(keys.service);

  useEffect(
    () => {
      if (props.paused) {
        return;
      }

      let cancelled = false;

      manyFetcher(props)
        .then((results: FetchManyResults<T>) => {
          if (!cancelled) {
            setNumberOfPages(results.numberOfPages);
            setTotalNumber(results.totalNumberOfRecords);
            setNumberOf(results.numberOfRecords);
            setRecords(results.records);
            if (props.cacheKey) {
              dispatch(queryCacheActions.cacheQuery(props.cacheKey, results));
            }
            setFetching(false);
            setError(undefined);
          }
        })
        .catch((err: unknown) => {
          if (!cancelled) {
            console.error(`Error fetching ${keys.service}: `, err);
            setNumberOfPages(0);
            setTotalNumber(0);
            setNumberOf(0);
            setRecords([]);
            setFetching(false);
            setError(err);
          }
        });

      return () => {
        cancelled = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      lastRefreshed,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(props),
      forceUpdate,
      manyFetcher,
      dispatch,
      keys.service,
    ]
  );

  const results: TResults = ({
    numberOfPages,
    [keys.totalNumber]: totalNumber,
    [keys.numberOf]: numberOf,
    [keys.records]: records,
    fetching,
    error,
  } as unknown) as TResults;

  return results;
}

export interface CacheEntry {
  record: Record<string, unknown>;
  time: number;
}

const cache: { [id: string]: CacheEntry } = {};

function makeCacheKey(
  { id, admin, query }: FetchOneProps,
  service: string
): string {
  return JSON.stringify([service, id || 0, admin || false, query || {}]);
}

export function fetchOneFromCache(
  props: FetchOneProps,
  service: string,
  maxAge?: number,
  afterDateMillis?: number
): CacheEntry | undefined {
  const cached = cache[makeCacheKey(props, service)];
  if (!cached) {
    return undefined;
  }

  if (afterDateMillis) {
    const since = Date.now() - afterDateMillis;
    maxAge = maxAge ? Math.min(maxAge, since) : since;
  }

  return maxAge === undefined || Date.now() - cached.time < maxAge
    ? cached
    : undefined;
}

export async function fetchOne<T, TResults>(
  props: FetchOneProps,
  blankRecord: T,
  keys: FetchOneResultsKeys<TResults>
): Promise<T> {
  const { id, admin, query } = props;

  if (!id || id === "0" || id === "new") {
    return { ...blankRecord };
  }

  const result = await api.service(keys.service).get(id, {
    query: {
      ...(query || {}),
      ...(admin && { $role: true }),
    },
  });

  if (Object.keys(cache).length > 50) {
    const now = Date.now();
    const toDelete: string[] = [];

    each(cache, (value, key) => {
      if (now - value.time > 60 * 1000) {
        toDelete.push(key);
      }
    });

    for (const key of toDelete) {
      delete cache[key];
    }
  }

  cache[makeCacheKey(props, keys.service)] = {
    record: result,
    time: Date.now(),
  };
  return result;
}

export function useFetchOne<
  T,
  TResults extends UseFetchOneResults,
  TProps extends FetchOneProps
>(
  props: TProps,
  oneFetcher: (props: TProps) => Promise<T>,
  blankRecord: T,
  keys: FetchOneResultsKeys<TResults>,
  forceUpdate?: unknown
): TResults {
  const cached = fetchOneFromCache(props, keys.service);
  const [results, setResults] = useState<TResults>(({
    [keys.record]: {
      ...(cached?.record || blankRecord),
    },
    error: undefined,
    fetching: cached === undefined,
  } as unknown) as TResults);
  const lastRefreshed = useUpdateListener(keys.service);

  useEffect(
    () => {
      let cancelled = false;

      const cached = fetchOneFromCache(
        props,
        keys.service,
        props.maxAge || -1,
        lastRefreshed
      );

      if (cached) {
        setResults(({
          [keys.record]: cached.record,
          error: undefined,
          fetching: false,
        } as unknown) as TResults);
      } else {
        oneFetcher(props)
          .then((json: T) => {
            if (!cancelled) {
              setResults(({
                [keys.record]: json,
                error: undefined,
                fetching: false,
              } as unknown) as TResults);
            }
          })
          .catch((error: unknown) => {
            if (!cancelled) {
              console.error(`Error fetching ${keys.service}: `, error);
              setResults(({
                [keys.record]: { ...blankRecord },
                error: error,
                fetching: false,
              } as unknown) as TResults);
            }
          });
      }

      return () => {
        cancelled = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      lastRefreshed,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(props),
      forceUpdate,
      keys.service,
      keys.record,
      blankRecord,
      oneFetcher,
    ]
  );

  return results;
}

export function WrapFetch<TInput, TOutput>({
  hook,
  children,
  params,
}: {
  hook: (props: TInput) => TOutput;
  children: (output: TOutput) => ReactNode;
  params: TInput;
}): ReactNode {
  const result = hook(params);
  return children(result);
}
