import React, { CSSProperties, ReactNode, useCallback, useState } from "react";
import { Card } from "reactstrap";

export default function VideoCard({
  children,
  className,
  style,
  renderSources,
  coverImage,
}: {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  renderSources: ReactNode;
  coverImage: string;
}): JSX.Element {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const onVideoCanPlayThrough = useCallback(() => {
    setVideoLoaded(true);
  }, []);

  return (
    <Card className={className} style={style}>
      <video
        style={{
          objectFit: "cover",
          backgroundSize: "cover",
          opacity: 1,
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 0,
          top: "0%",
          left: "0px",
          display: "block",
        }}
        className="resizelistener"
        loop={true}
        autoPlay={true}
        muted={true}
        preload="auto"
        onCanPlayThrough={onVideoCanPlayThrough}
        controls={false}
        playsInline={true}
      >
        {renderSources}
      </video>
      <div
        className={`video-loading-cover ${videoLoaded ? "video-loaded" : ""}`}
        style={{
          backgroundImage: `url(${coverImage})`,
          backgroundSize: "cover",
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
      />
      {children}
    </Card>
  );
}
