import { Id, ModelBase } from "./types";
import { User } from "./users.model";

export interface UserAddressFields {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  postalCode: string;
  country: string;
  phoneNumber: string;
}

export interface UserAddress extends UserAddressFields, ModelBase {
  userId: Id;
  user?: User;
}

export const blankUserAddressFields: UserAddressFields = {
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  address5: "",
  postalCode: "",
  country: "",
  phoneNumber: "",
};

export const blankUserAddress: UserAddress = {
  ...blankUserAddressFields,
  id: 0,
  userId: 0,
};
