import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BasketLine, DraftOrderLineProperty } from "./basket.model";
import { useCombinedStore } from "./combinedStore";
import basketActions from "./basket.actions";
import { Id } from "./types";

export function useFetchBasket(
  basketOrganisationId: Id
): {
  basketLines: BasketLine[];
  basketTotal: string;
  error?: string;
  missingUserPropertyIds: number[];
  userProperties: DraftOrderLineProperty[];
  updatingBasket: boolean;
} {
  const [error, setError] = useState<string | undefined>("");

  const dispatch = useDispatch();

  const userId = useCombinedStore((store) => store.login.user?.id || 0);

  useEffect(() => {
    dispatch(
      basketActions.fetchBasket(basketOrganisationId, (error) => {
        setError(
          error === null ? undefined : String(error || "Error fetching basket")
        );
      })
    );
  }, [basketOrganisationId, dispatch, userId]);

  const basketLines = useCombinedStore((store) => store.basket.basketLines);
  const basketTotal = useCombinedStore((store) => store.basket.total);
  const missingUserPropertyIds = useCombinedStore(
    (store) => store.basket.missingUserPropertyIds
  );
  const userProperties = useCombinedStore(
    (store) => store.basket.userProperties
  );
  const updatingBasket = useCombinedStore(
    (store) => store.basket.updateBasketState === "started"
  );

  return {
    basketLines,
    basketTotal,
    error,
    missingUserPropertyIds,
    userProperties,
    updatingBasket,
  };
}
