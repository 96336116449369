import React from "react";
import { FormText } from "reactstrap";
import { getErrorDescription } from "../utils/data";

interface Props {
  error: unknown;
}

export default function FormErrorText({ error }: Props): JSX.Element | null {
  return error ? (
    <FormText color="danger">{getErrorDescription(error)}</FormText>
  ) : null;
}
