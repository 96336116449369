import React, { FormEvent, useCallback, useState } from "react";
import { useRouteMatch } from "react-router";
import DirectionsModal from "../components/DirectionsModal";
import TeacherSlotSelectScreen from "../components/TeacherSlotSelectScreen";

interface RouteParams {
  teacherid?: string;
}

export default function OneToOneVirtualSlotScreen(): JSX.Element | null {
  const {
    params: { teacherid },
  } = useRouteMatch<RouteParams>();

  const [showDirections, setShowDirections] = useState(false);

  const toggleDirections = useCallback(
    (event?: FormEvent<HTMLButtonElement>) => {
      event && event.preventDefault();
      setShowDirections((value) => !value);
    },
    []
  );

  return (
    <>
      <TeacherSlotSelectScreen
        teacherid={teacherid}
        title="1-2-1 Lessons"
        // locationQuery={{ $neqOption: RockleyLocationOptionId.ONLINE }}
        renderInstructions={
          <>
            <p className="lead">
              You can select multiple slots for one or more children
            </p>
            {/* <p>
              You can select whether you want on-line or in-person lessons at
              checkout
            </p>
            <p
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              className="mb-3"
            >
              <span className="mr-2">Lessons are held online</span>
              <Button size="sm" color="primary" onClick={toggleDirections}>
                Directions
              </Button>
            </p> */}
          </>
        }
      />
      <DirectionsModal toggle={toggleDirections} isOpen={showDirections} />
    </>
  );
}
