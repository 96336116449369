import classNames from "classnames";
import React, {
  ElementType,
  FormEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import PageTitle, { RouteItem } from "../components/PageTitle";
import Spinner from "../components/Spinner";
import { Id } from "../state/types";
import { getErrorDescription } from "../utils/data";
import { htmlFieldValue } from "../utils/dom";
import { useQueryStringState } from "../utils/queryStringState";
import { WorkingOverlay } from "./LoadingOverlay";

export interface FormCallbacks<Type> {
  getField: <T extends unknown = string>(
    name: keyof Type | "$original",
    ifMissing?: T
  ) => T;
  changeField: (name: keyof Type, event: FormEvent<HTMLInputElement>) => void;
  changeFileField: (
    name: keyof Type,
    event: FormEvent<HTMLInputElement>
  ) => void;
  changeFieldValue: (
    name: keyof Type,
    event: FormEvent<HTMLInputElement> | null,
    value: unknown
  ) => void;
  readOnly: boolean;
}

export interface DetailScreenTab {
  id: string;
  title: string;
  component: ReactNode;
}

interface Props<Type extends { id: Id }> {
  onIdChange: (newId: Id) => void;
  fetching?: boolean;
  error?: unknown;
  route: RouteItem[];
  isNew: boolean;
  original: Type | undefined;
  titleField?: keyof Type;
  titleIfNew?: string;
  renderForm: ElementType<FormCallbacks<Type>>;
  renderPageLinks?: ReactNode;
  onSave: (
    original: Type,
    changes: Partial<Type>,
    progressCallback?: (percentage: number) => void
  ) => Promise<Type>;
  onDelete: (original: Type) => Promise<void>;
  extraButtons?: ReactNode;
  defaults?: Partial<Type>;
  tabs?: DetailScreenTab[];
  tabTitle?: string;
  onlyRenderActiveTab?: boolean;
}

export default function DetailScreen<Type extends { id: Id }>({
  onIdChange,
  fetching,
  error,
  route,
  isNew,
  original,
  titleField,
  titleIfNew,
  renderForm: RenderForm,
  renderPageLinks,
  onSave,
  onDelete,
  extraButtons,
  defaults,
  tabs = [],
  tabTitle = "Home",
  onlyRenderActiveTab = false,
}: Props<Type>): JSX.Element {
  const [editing, setEditing] = useState(isNew);
  const [edited, setEdited] = useState<Record<string, unknown>>({});
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [working, setWorking] = useState(false);
  const [errorState, setErrorState] = useState<string | undefined>();
  const [activeTab, setActiveTab] = useQueryStringState("tab", "");

  const getOriginal = useCallback((): Type => original || ({ id: 0 } as Type), [
    original,
  ]);

  const getField = useCallback(
    <T extends unknown>(field: keyof Type | "$original", ifMissing?: T): T => {
      const original = getOriginal();

      if (field === "$original") {
        return original as T;
      }

      if (field in edited) {
        const stringField = `${field}__string`;
        if (stringField in edited) {
          return edited[stringField] as T;
        }

        return edited[field as string] as T;
      }

      if (isNew && field in (defaults || {})) {
        return ((defaults || {}) as Record<keyof Type, unknown>)[field] as T;
      }

      return field in original
        ? (original[field] as T)
        : ((ifMissing as unknown) as T);
    },
    [getOriginal, edited, defaults, isNew]
  );

  const toggleEdit = useCallback(() => {
    if (editing) {
      // TODO: an "are you sure" warning would be user friendly here
      setEditing(false);
      setEdited({});

      if (!getField("id", 0)) {
        // history.goBack();
      }
    } else {
      setEditing(true);
    }
  }, [editing, getField]);

  const changeFileField = useCallback(
    (field: keyof Type, event: FormEvent<HTMLInputElement>) => {
      if (!editing) {
        event.preventDefault();
      } else {
        const files = event.currentTarget.files;
        setEdited((current) => ({ ...current, [field]: files }));
      }
    },
    [editing]
  );

  const changeFieldValue = useCallback(
    (
      name: keyof Type,
      event: FormEvent<HTMLInputElement> | null,
      newValue: unknown
    ) => {
      if (!editing) {
        if (event) {
          event.preventDefault();
        }
        return;
      }

      setEdited((current) => ({
        ...current,
        [name]: newValue === undefined ? null : newValue,
      }));
    },
    [editing]
  );

  const changeField = useCallback(
    (field: keyof Type, event: FormEvent<HTMLInputElement>) => {
      if (!editing) {
        event.preventDefault();
        return;
      }

      changeFieldValue(field, null, htmlFieldValue(event.currentTarget));
    },
    [editing, changeFieldValue]
  );

  const onCancelClicked = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      toggleEdit();
    },
    [toggleEdit]
  );

  const onEditClicked = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setActiveTab("");
      toggleEdit();
    },
    [toggleEdit, setActiveTab]
  );

  const [uploadProgress, setUploadProgress] = useState<number | undefined>(
    undefined
  );

  const onSavePressed = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      const original = getOriginal();

      setWorking(true);
      setErrorState(undefined);

      try {
        const newRecord = await onSave(
          original,
          {
            ...(defaults || {}),
            ...edited,
          } as Partial<Type>,
          setUploadProgress
        );

        if (newRecord && newRecord.id && newRecord.id !== getOriginal().id) {
          onIdChange(newRecord.id);
        }

        setWorking(false);
        setEditing(false);
        setEdited({});
      } catch (error) {
        // Don't stop editing
        setWorking(false);
        setErrorState(getErrorDescription(error));
      } finally {
        setUploadProgress(undefined);
      }
    },
    [getOriginal, onSave, defaults, edited, onIdChange]
  );

  const onDeletePressed = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setConfirmingDelete(true);
    },
    [setConfirmingDelete]
  );

  const onCancelDeletePressed = useCallback(
    (event?: FormEvent<HTMLButtonElement>) => {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      setConfirmingDelete(false);
    },
    [setConfirmingDelete]
  );

  const onConfirmDeletePressed = useCallback(
    async (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setWorking(true);
      setErrorState(undefined);
      setConfirmingDelete(false);

      try {
        await onDelete(getOriginal());

        setEditing(false);
        setEdited({});
        setWorking(false);
      } catch (error) {
        // Don't stop editing
        setWorking(false);
        setErrorState(getErrorDescription(error));
      }
    },
    [getOriginal, onDelete]
  );

  const readOnly = !editing;

  const callbacks = useMemo<FormCallbacks<Type>>(
    () => ({
      getField,
      changeField,
      changeFileField,
      changeFieldValue,
      readOnly,
    }),
    [getField, changeField, changeFileField, changeFieldValue, readOnly]
  );

  const title = fetching
    ? ""
    : getField(titleField || "id", "") || titleIfNew || "New";

  const card = useMemo(() => {
    return (
      <Card>
        <CardBody>
          <RenderForm {...callbacks} />
          {editing && !isNew && onDelete ? (
            <Button className="mr-2" color="danger" onClick={onDeletePressed}>
              Delete
            </Button>
          ) : null}
        </CardBody>
      </Card>
    );
  }, [callbacks, editing, isNew, onDelete, onDeletePressed]);

  return original ? (
    <Container fluid="lg">
      <PageTitle title={title} route={route} renderPageLinks={renderPageLinks}>
        {extraButtons}
        {editing ? (
          <Button color="secondary" onClick={onCancelClicked} key="cancel-edit">
            Cancel
          </Button>
        ) : (
          <Button color="primary" onClick={onEditClicked} key="edit">
            Edit
          </Button>
        )}
        {editing ? (
          <>
            <Button color="success" onClick={onSavePressed}>
              Save
            </Button>
          </>
        ) : null}
      </PageTitle>
      <WorkingOverlay working={working} progress={uploadProgress}>
        {/* <WorkingOverlay working={true || working} progress={uploadProgress || 30}> */}
        <Row>
          <Col xs={12}>
            {errorState && <Alert color="danger">{errorState}</Alert>}
            {error ? (
              <Alert color="danger">{getErrorDescription(error)}</Alert>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="horz-scrolling-tabs">
              {tabs?.length || undefined ? (
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      to=""
                      onClick={() => setActiveTab("")}
                      className={classNames({ active: activeTab === "" })}
                    >
                      {tabTitle}
                    </NavLink>
                  </NavItem>
                  {tabs?.map((tab) => (
                    <NavItem key={tab.id}>
                      <NavLink
                        disabled={editing}
                        to=""
                        onClick={() => setActiveTab(tab.id)}
                        className={classNames({
                          active: activeTab === tab.id,
                        })}
                      >
                        {tab.title}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              ) : null}
            </div>
            {tabs?.length || undefined ? (
              <TabContent activeTab={activeTab || "home"}>
                <TabPane tabId="home">{card}</TabPane>
                {tabs?.map((tab) =>
                  activeTab === tab.id || !onlyRenderActiveTab ? (
                    <TabPane key={tab.id} tabId={tab.id}>
                      {tab.component}
                    </TabPane>
                  ) : null
                )}
              </TabContent>
            ) : (
              card
            )}
          </Col>
        </Row>
      </WorkingOverlay>
      <Modal centered isOpen={confirmingDelete}>
        <ModalHeader toggle={onCancelDeletePressed}>
          Confirm Deletion
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this record?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancelDeletePressed}>
            Cancel
          </Button>
          <Button color="danger" onClick={onConfirmDeletePressed}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  ) : (
    <Spinner />
  );
}
