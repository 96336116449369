import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { blankCustomer, Customer } from "./customers.model";
import { makeDeleter, makeSaver } from "./types";

interface UseFetchCustomersResults extends UseFetchManyResults {
  customers: Customer[];
  numberOfCustomers: number;
  totalNumberOfCustomers: number;
}

interface UseFetchCustomerResults extends UseFetchOneResults {
  customer: Customer;
}

const keys: FetcherKeys<UseFetchCustomersResults, UseFetchCustomerResults> = {
  service: "customers",
  totalNumber: "totalNumberOfCustomers",
  numberOf: "numberOfCustomers",
  records: "customers",
  record: "customer",
};

export async function fetchCustomers(
  props: FetchManyProps
): Promise<FetchManyResults<Customer>> {
  return fetchMany<Customer>(props, keys.service);
}

export function useFetchCustomers(
  props: FetchManyProps
): UseFetchCustomersResults {
  return useFetchMany(props, fetchCustomers, keys);
}

export async function fetchCustomer(props: FetchOneProps): Promise<Customer> {
  return fetchOne<Customer, UseFetchCustomerResults>(
    props,
    blankCustomer,
    keys
  );
}

export function useFetchCustomer(
  props: FetchOneProps
): UseFetchCustomerResults {
  return useFetchOne(props, fetchCustomer, blankCustomer, keys);
}

async function saveCustomer(
  original: Customer,
  changes: Partial<Customer>,
  $role: true | false | string
): Promise<Customer> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteCustomer(
  original: Customer,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveCustomer = makeSaver(saveCustomer, true);
export const adminDeleteCustomer = makeDeleter(deleteCustomer, true);
export const userSaveCustomer = makeSaver(saveCustomer, false);
export const userDeleteCustomer = makeDeleter(deleteCustomer, false);
