import range from "lodash/range";
import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

interface PaginatorProps {
  numberOfPages: number;
  currentPage: number;
  onSelectPage: (pageNumber: number) => void;
  className?: string;
}

export function NonResponsivePaginator({
  numberOfPages,
  currentPage,
  onSelectPage,
  className,
  width,
  sides,
  nextPrev,
}: PaginatorProps & {
  width: number;
  sides: number;
  nextPrev?: boolean;
}): JSX.Element {
  let chunkStart = currentPage - Math.floor(width / 2);
  let chunkEnd = currentPage + width - Math.floor(width / 2);
  numberOfPages = Math.ceil(numberOfPages);

  if (chunkStart < sides) {
    chunkEnd += sides - chunkStart;
    chunkStart = sides;
  }

  if (chunkEnd > numberOfPages - sides) {
    chunkStart = Math.max(
      sides,
      chunkStart - (chunkEnd - (numberOfPages - sides))
    );
    chunkEnd = Math.max(numberOfPages - sides, sides);
  }

  return (
    <>
      <Pagination aria-label="Page navigation" className={className}>
        {nextPrev ? (
          <PaginationItem disabled={currentPage === 0}>
            <PaginationLink onClick={() => onSelectPage(currentPage - 1)}>
              Previous
            </PaginationLink>
          </PaginationItem>
        ) : null}
        {range(0, Math.min(sides, numberOfPages)).map((pageNumber) => {
          const disabled =
            pageNumber === Math.max(1, sides - 1) && chunkStart > sides;
          return (
            <PaginationItem
              active={pageNumber === currentPage}
              key={pageNumber}
              disabled={disabled}
            >
              <PaginationLink onClick={() => onSelectPage(pageNumber)}>
                {disabled ? "..." : pageNumber + 1}
              </PaginationLink>
            </PaginationItem>
          );
        })}
        {range(chunkStart, chunkEnd).map((pageNumber) => (
          <PaginationItem active={pageNumber === currentPage} key={pageNumber}>
            <PaginationLink onClick={() => onSelectPage(pageNumber)}>
              {pageNumber + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        {range(Math.max(numberOfPages - sides, sides), numberOfPages, 1).map(
          (pageNumber) => {
            const disabled =
              pageNumber === numberOfPages - Math.max(sides, 2) &&
              chunkEnd < numberOfPages - sides;
            return (
              <PaginationItem
                active={pageNumber === currentPage}
                key={pageNumber}
                disabled={disabled}
              >
                <PaginationLink onClick={() => onSelectPage(pageNumber)}>
                  {disabled ? "..." : pageNumber + 1}
                </PaginationLink>
              </PaginationItem>
            );
          }
        )}
        {nextPrev ? (
          <PaginationItem disabled={currentPage >= numberOfPages - 1}>
            <PaginationLink onClick={() => onSelectPage(currentPage + 1)}>
              Next
            </PaginationLink>
          </PaginationItem>
        ) : null}
      </Pagination>
    </>
  );
}

export default function Paginator({
  className,
  ...props
}: PaginatorProps): JSX.Element {
  return (
    <>
      <NonResponsivePaginator
        width={3}
        sides={1}
        className={`${className || ""} d-sm-none`}
        {...props}
      />
      <NonResponsivePaginator
        nextPrev
        width={3}
        sides={1}
        className={`${className || ""} d-none d-sm-block d-md-none`}
        {...props}
      />
      <NonResponsivePaginator
        nextPrev
        width={7}
        sides={2}
        className={`${className || ""} d-none d-md-block`}
        {...props}
      />
    </>
  );
}
