import React, { MouseEvent, useCallback, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import CardHeaderTitle from "../components/CardHeaderTitle";
import PageTitle from "../components/PageTitle";
import { AddChildModal } from "../components/SelectChild";
import { useFetchFamily } from "../state/family.api";

export default function FamilyScreen(): JSX.Element {
  const { family, error } = useFetchFamily();

  const [showAddChildModal, setShowAddChildModal] = useState(false);
  const onAddChildModalToggle = useCallback(
    () => setShowAddChildModal((value) => !value),
    []
  );

  const onAddChildClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setShowAddChildModal(true);
    },
    []
  );

  return (
    <Container>
      <PageTitle title="Your Family" />

      {error ? <Alert color="danger">{String(error)}</Alert> : null}

      <Row>
        <Col md={6}>
          <Card>
            <CardHeaderTitle>Family Members</CardHeaderTitle>
            <CardBody>
              <ListGroup>
                {family.users.map((user) => {
                  return (
                    <ListGroupItem
                      tag="a"
                      href="#"
                      action
                      key={user.id}
                      className="d-flex justify-content-between align-items-center"
                    >
                      {`${user.firstName} ${user.lastName}`}
                      {user.role === "child" && (
                        <Badge color="success">Child</Badge>
                      )}
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
              <p className="mt-3">
                <strong>Note</strong>: The ability to invite another person to
                manage your family and place orders is coming soon.
              </p>
            </CardBody>
            <CardFooter className="text-right">
              <div className="button-container">
                <Button color="primary" onClick={onAddChildClicked}>
                  Add Child
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>

      <AddChildModal
        isOpen={showAddChildModal}
        onToggle={onAddChildModalToggle}
        onChildAdded={onAddChildModalToggle}
      />
    </Container>
  );
}
