import { fetchOrder, fetchOrders } from "../state/orders.api";
import { blankOrder, Order } from "../state/orders.model";
import { formatUser } from "../state/users.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

const format = (item: Order): string =>
  `#${item.id} ${formatUser(item.customer?.user)}`;

export default function OrderSelect(
  props: GenericSelectProps<Order>
): JSX.Element {
  return GenericSelect<Order>(
    props,
    fetchOrder,
    fetchOrders,
    "orders",
    blankOrder,
    format
  );
}
