import React from "react";
import { RouteComponentProps } from "react-router";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import { StyledSelect } from "../components/StyledSelect";
import CustomerSelect from "../selects/CustomerSelect";
import PaymentMethodSelect from "../selects/PaymentMethodSelect";
import UserSelect from "../selects/UserSelect";
import { useCombinedStore } from "../state/combinedStore";
import {
  adminDeletePayment,
  adminSavePayment,
  useFetchPayment,
} from "../state/payments.api";
import { Payment } from "../state/payments.model";
import { useLocationId } from "../utils/queryStringState";
import { getSelectOptionByValue } from "../utils/reactSelect";
import PaymentAssignmentListPage from "./PaymentAssignmentListPage";

interface ParamProps {
  paymentid: string;
}

const STATE_OPTIONS = [
  { value: "", label: "Created" },
  { value: "paid", label: "Paid" },
  { value: "failed", label: "Failed" },
  { value: "cancelled", label: "Cancelled" },
];

export default function PaymentDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.paymentid);
  const { payment, fetching, error } = useFetchPayment({ id, admin: true });

  return (
    <>
      <DetailScreen<Payment>
        defaults={(location.state as { defaults?: Partial<Payment> })?.defaults}
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={payment}
        isNew={match.params.paymentid === "new"}
        route={[{ Payments: "/admin/payments" }]}
        renderForm={(props) => <PaymentDetailForm {...props} />}
        onSave={adminSavePayment}
        onDelete={adminDeletePayment}
      />

      {!!id && <PaymentAssignmentListPage paymentId={id} />}
    </>
  );
}

function PaymentDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<Payment>) {
  const superuser = useCombinedStore(
    (store) => store.login.permissions.superuser
  );

  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="customerId">Customer</Label>
            <CustomerSelect
              admin
              isDisabled={readOnly}
              id="customerId"
              value={getField("customerId")}
              onChange={(newValue) =>
                changeFieldValue("customerId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        {superuser && (
          <Col md={6}>
            <FormGroup>
              <Label for="userId">User</Label>
              <UserSelect
                admin
                isDisabled={readOnly}
                id="userId"
                value={getField("userId")}
                onChange={(newValue) =>
                  changeFieldValue("userId", null, newValue)
                }
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="paymentMethodId">Payment Method</Label>
            <PaymentMethodSelect
              admin
              isDisabled={readOnly}
              id="paymentMethodId"
              value={getField("paymentMethodId")}
              onChange={(newValue) =>
                changeFieldValue("paymentMethodId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="state">State</Label>
            <StyledSelect
              id="state"
              isDisabled={readOnly}
              options={STATE_OPTIONS}
              value={getSelectOptionByValue<string>(
                STATE_OPTIONS,
                getField("state")
              )}
              onChange={(newValue) =>
                changeFieldValue(
                  "state",
                  null,
                  newValue && "value" in newValue ? newValue.value : ""
                )
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="amount">Amount</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="amount"
              id="amount"
              value={getField("amount")}
              onChange={(event) => changeField("amount", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="reference">Reference</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="reference"
              id="reference"
              value={getField("reference")}
              onChange={(event) => changeField("reference", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="servicePaymentId">Payment ID</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="servicePaymentId"
              id="servicePaymentId"
              value={getField("servicePaymentId")}
              onChange={(event) => changeField("servicePaymentId", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="serviceTransactionId">Transaction ID</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="serviceTransactionId"
              id="serviceTransactionId"
              value={getField("serviceTransactionId")}
              onChange={(event) => changeField("serviceTransactionId", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="serviceTransactionResult">Transaction Result</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="serviceTransactionResult"
              id="serviceTransactionResult"
              value={getField("serviceTransactionResult")}
              onChange={(event) =>
                changeField("serviceTransactionResult", event)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <Label>Options</Label>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="isTest"
              id="isTest"
              checked={getField("isTest")}
              onChange={(event) => changeField("isTest", event)}
              label="Is Test"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="serviceRecurringId">Recurring ID</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="serviceRecurringId"
              id="serviceRecurringId"
              value={getField("serviceRecurringId")}
              onChange={(event) => changeField("serviceRecurringId", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="paymentDateTime">Payment Date/Time</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="paymentDateTime"
              id="paymentDateTime"
              value={getField("paymentDateTime")}
              onChange={(event) => changeField("paymentDateTime", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<Payment>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
