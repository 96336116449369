import { AnyAction, Store } from "redux";
import config from "../config";
import { AnyDispatch } from "./combinedStore";

export enum PersistActions {
  RESTORE_STATE = `persist.RESTORE_STATE`,
  PERSIST_STATE = `persist.PERSIST_STATE`,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PersistedState = Record<string, any>;

export interface RestoreStateAction {
  type: PersistActions.RESTORE_STATE;
  restore: PersistedState;
}
export interface PersistStateAction {
  type: PersistActions.PERSIST_STATE;
}
export type PersistAction = RestoreStateAction | PersistStateAction;

const persistedState: PersistedState = {};

let store: Store | undefined;

let persistingStore = false;
let persistStoreRequested = false;

let dispatchQueue: AnyAction[] = [];

function loadPersistedState(): PersistedState {
  if (
    typeof window === "undefined" ||
    typeof window.localStorage === "undefined"
  ) {
    return {};
  }

  try {
    const jsonState = localStorage.getItem(config.STATE_KEY);
    if (jsonState === null) {
      return {};
    }

    const parsed = JSON.parse(jsonState);

    return typeof parsed === "object" ? parsed : {};
  } catch (error) {
    console.log(`Error restoring state: ${error}`);
    return {};
  }
}

function storePersistedState() {
  if (
    typeof window === "undefined" ||
    typeof window.localStorage === "undefined"
  ) {
    return;
  }

  try {
    const jsonState = JSON.stringify(persistedState);
    localStorage.setItem(config.STATE_KEY, jsonState);
  } catch (error) {
    console.log(`Error preserving state: ${error}`);
  }
}

function persistStoreIfRequested() {
  if (persistingStore || !store || !persistStoreRequested) {
    return;
  }

  persistingStore = true;
  persistStoreRequested = false;

  (store.dispatch as AnyDispatch<PersistAction>)(async (dispatch) => {
    dispatch({ type: PersistActions.PERSIST_STATE });

    storePersistedState();
    persistingStore = false;
  });
}

export function persistState(incoming: PersistedState): void {
  Object.assign(persistedState, incoming);
}

export function requestPersist(): void {
  persistStoreRequested = true;
}

export function queueDispatch(action: AnyAction): void {
  dispatchQueue.push(action);
}

export function initPersist(aStore: Store): void {
  store = aStore;
  store.dispatch<RestoreStateAction>({
    type: PersistActions.RESTORE_STATE,
    restore: loadPersistedState(),
  });
  store.subscribe(() => {
    persistStoreIfRequested();
    const queued = dispatchQueue;
    dispatchQueue = [];
    for (const queue of queued) {
      aStore.dispatch(queue);
    }
  });

  if (typeof window !== "undefined") {
    window.addEventListener("storage", () => {
      if (!persistingStore) {
        aStore.dispatch<RestoreStateAction>({
          type: PersistActions.RESTORE_STATE,
          restore: loadPersistedState(),
        });
      }
    });
  }
}
