import {
  blankOrganisationMember,
  OrganisationMember,
} from "../state/organisationMembers.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";
import {
  fetchOrganisationMember,
  fetchOrganisationMembers,
} from "../state/organisationMembers.api";

const format = (item: OrganisationMember): string =>
  `#${item.id} ${item.user?.firstName} ${item.user?.lastName}`;

export default function OrganisationMemberSelect(
  props: GenericSelectProps<OrganisationMember>
): JSX.Element {
  return GenericSelect<OrganisationMember>(
    props,
    fetchOrganisationMember,
    fetchOrganisationMembers,
    "organisation-members",
    blankOrganisationMember,
    format
  );
}
