import React, { ReactNode } from "react";
import {
  CardBody,
  CardBodyProps,
  CardHeader,
  CardHeaderProps,
} from "reactstrap";
import logo from "../media/logo.png";

export function LogoCardHeader(props: CardHeaderProps): JSX.Element {
  return (
    <CardHeader className="text-center bg-light px-3 py-3" {...props}>
      <img src={logo} width="100%" style={{ maxWidth: 444 }} alt="Logo" />
    </CardHeader>
  );
}

export function LogoCardBody({
  style,
  children,
  ...rest
}: CardBodyProps & { children: ReactNode }): JSX.Element {
  return (
    <CardBody {...rest} style={{ ...(style || {}), maxWidth: 492 }}>
      {children}
    </CardBody>
  );
}
