import React from "react";
import TableScreen from "../components/TableScreen";
import { useFetchUsers } from "../state/users.api";
import { User } from "../state/users.model";
import { useQueryStringState } from "../utils/queryStringState";

export default function UserListPage(): JSX.Element {
  const [page, setPage] = useQueryStringState("page", 0);
  const [search, setSearch] = useQueryStringState("search", "");
  // TODO const [organisationId, setOrganisationId] = useState<Id | undefined>();

  const { users, numberOfPages, fetching, error } = useFetchUsers({
    page,
    search,
    admin: true,
  });

  return (
    <TableScreen<User>
      fetching={fetching}
      error={error}
      title="Users"
      singular="User"
      columns={[
        { title: "ID" },
        { title: "Email" },
        { title: "Role" },
        { title: "First Name" },
        { title: "Last Name" },
      ]}
      list={users}
      row={(item) => [
        item.id || "",
        item.email || "",
        item.role || "",
        item.firstName || "",
        item.lastName || "",
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/users/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
    />
  );
}
