import keys from "lodash/keys";
import qs from "qs";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useLocation } from "react-router";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import FormErrorText from "../components/FormErrorText";
import { LoadingOverlay } from "../components/LoadingOverlay";
import { LogoCardBody, LogoCardHeader } from "../components/LogoCardHeader";
import { useCombinedStore } from "../state/combinedStore";
import loginActions from "../state/login.actions";
import { isTrue } from "../utils/data";

export default function ResetPasswordScreen({
  match,
}: RouteComponentProps<{ token: string }>): JSX.Element {
  const token = match.params.token;
  const location = useLocation();

  const dispatch = useDispatch();

  const passwordResetRequestState = useCombinedStore(
    (state) => state.login.requestPasswordResetState
  );
  const passwordResetState = useCombinedStore(
    (state) => state.login.passwordResetState
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [posted, setPosted] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const isNewUser = useRef(isTrue(qs.parse(location.search.substr(1)).newuser))
    .current;

  const onEmailChanged = useCallback((event: FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  }, []);

  const onPasswordChanged = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setPassword(event.currentTarget.value);
    },
    []
  );

  const onPassword2Changed = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setPassword2(event.currentTarget.value);
    },
    []
  );

  const onRequestPasswordResetPressed = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const errors: Record<string, string> = {};

      if (email.indexOf("@") < 1) {
        errors["email"] = "Please enter your email address";
      }

      setErrors(errors);
      setPosted(true);

      if (keys(errors).length > 0) {
        return;
      }

      dispatch(loginActions.requestPasswordReset(email));
    },
    [email, dispatch]
  );

  const onResetPasswordPressed = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const errors: Record<string, string> = {};

      if (password.length < 8) {
        errors["password"] = "Password must be at least 8 characters";
      } else if (password2 !== password) {
        errors["password2"] = "Passwords do not match";
      }

      setErrors(errors);
      setPosted(true);

      if (keys(errors).length > 0) {
        return;
      }

      dispatch(loginActions.resetPassword(token, password));
    },
    [password, password2, dispatch, token]
  );

  const onTryAgainClicked = useCallback(
    (event: FormEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setPosted(false);
    },
    []
  );

  return (
    <Container className="mt-5 mb-5 ResetPasswordScreen">
      <Row className="justify-content-center">
        <Col xs="auto">
          <Card className="position-relative">
            <LogoCardHeader />
            <LogoCardBody className="p-4">
              {(token || "") === "" ? (
                posted && passwordResetRequestState === "failed" ? (
                  <>
                    <p className="text-center">
                      We{`'`}ve been unable to process your request. No further
                      details are available. You may{" "}
                      <a href="/" onClick={onTryAgainClicked}>
                        try again
                      </a>
                      .
                    </p>
                  </>
                ) : posted && passwordResetRequestState === "completed" ? (
                  <>
                    <p className="text-center">
                      Your request has been submitted. Please check your email
                      for further instructions.
                    </p>
                  </>
                ) : (
                  <>
                    <h4 className="text-center">
                      Enter your email address and we{`'`}ll send you an email
                      with instructions on how to reset your password
                    </h4>
                    <Form>
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          onChange={onEmailChanged}
                          value={email}
                        />
                        <FormErrorText error={posted && errors.email} />
                      </FormGroup>
                    </Form>
                    <div className="text-center mt-4">
                      <Button
                        color="primary"
                        onClick={onRequestPasswordResetPressed}
                      >
                        Request Password Reset
                      </Button>
                    </div>
                  </>
                )
              ) : posted && passwordResetState === "failed" ? (
                <>
                  <p>
                    We{`'`}ve been unable to reset your password. No further
                    details are available. You may{" "}
                    <a href="/" onClick={onTryAgainClicked}>
                      try again
                    </a>
                    .
                  </p>
                </>
              ) : posted && passwordResetState === "completed" ? (
                <>
                  {!isNewUser ? (
                    <p>
                      Your password has been reset. Please{" "}
                      <Link to="/login">sign in</Link>.
                    </p>
                  ) : (
                    <p>
                      Your password has been created. Please{" "}
                      <Link to="/login">sign in</Link>.
                    </p>
                  )}
                </>
              ) : (
                <>
                  <h4 className="text-center">
                    {!isNewUser ? `Enter a new password` : `Create a password`}
                  </h4>
                  <Form>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        onChange={onPasswordChanged}
                        value={password}
                      />
                      <FormErrorText error={posted && errors.password} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="password2">Password again, to confirm</Label>
                      <Input
                        type="password"
                        name="password2"
                        onChange={onPassword2Changed}
                        value={password2}
                      />
                      <FormErrorText error={posted && errors.password2} />
                    </FormGroup>
                  </Form>
                  <div className="text-center mt-4">
                    <Button color="primary" onClick={onResetPasswordPressed}>
                      Reset Password
                    </Button>
                  </div>
                </>
              )}
            </LogoCardBody>
            {(token || "") === "" && (
              <CardFooter className="text-center">
                Remembered your password? <Link to="/login">Sign in here</Link>
              </CardFooter>
            )}
            {(passwordResetRequestState === "started" ||
              passwordResetState === "started") && <LoadingOverlay />}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
