import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  FetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  UseFetchManyResults,
  useFetchOne,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { notifyUpdateListeners } from "../updateListeners";
import { checkRequiredFields } from "../utils/validate";
import api from "./api";
import {
  blankMessageTemplate,
  MessageTemplate,
} from "./messageTemplates.model";
import { makeDeleter, makeSaver } from "./types";

interface UseFetchMessageTemplatesResults extends UseFetchManyResults {
  messageTemplates: MessageTemplate[];
  numberOfMessageTemplates: number;
  totalNumberOfMessageTemplates: number;
}

interface UseFetchMessageTemplateResults extends UseFetchOneResults {
  messageTemplate: MessageTemplate;
}

const keys: FetcherKeys<
  UseFetchMessageTemplatesResults,
  UseFetchMessageTemplateResults
> = {
  service: "message-templates",
  totalNumber: "totalNumberOfMessageTemplates",
  numberOf: "numberOfMessageTemplates",
  records: "messageTemplates",
  record: "messageTemplate",
};

export async function fetchMessageTemplates(
  props: FetchManyProps
): Promise<FetchManyResults<MessageTemplate>> {
  return fetchMany<MessageTemplate>(props, keys.service);
}

export function useFetchMessageTemplates(
  props: FetchManyProps
): UseFetchMessageTemplatesResults {
  return useFetchMany(props, fetchMessageTemplates, keys);
}

export async function fetchMessageTemplate(
  props: FetchOneProps
): Promise<MessageTemplate> {
  return fetchOne<MessageTemplate, UseFetchMessageTemplateResults>(
    props,
    blankMessageTemplate,
    keys
  );
}

export function useFetchMessageTemplate(
  props: FetchOneProps
): UseFetchMessageTemplateResults {
  return useFetchOne(props, fetchMessageTemplate, blankMessageTemplate, keys);
}

async function saveMessageTemplate(
  original: MessageTemplate,
  changes: Partial<MessageTemplate>,
  $role: true | false | string
): Promise<MessageTemplate> {
  checkRequiredFields(
    changes,
    {
      description: "Description",
    },
    !original.id
  );

  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteMessageTemplate(
  original: MessageTemplate,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveMessageTemplate = makeSaver(saveMessageTemplate, true);
export const adminDeleteMessageTemplate = makeDeleter(
  deleteMessageTemplate,
  true
);
export const userSaveMessageTemplate = makeSaver(saveMessageTemplate, false);
export const userDeleteMessageTemplate = makeDeleter(
  deleteMessageTemplate,
  false
);
