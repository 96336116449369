import feathers from "@feathersjs/client";
import io from "socket.io-client";
import config from "../config";
import { LoginActions } from "./login.reducer";
import store from "./store";

const api = feathers();

const PORT = window.location.port ? `:${window.location.port}` : "";

const SERVER_ROOT = `${window.location.protocol}//${window.location.hostname}${PORT}`;
const DEV_SERVER_ROOT = `http://localhost:8332`;

export const REST_ROOT = `${SERVER_ROOT}/${config.API_PATH}`;
export const SOCKETIO_ROOT = config.IS_PRODUCTION
  ? SERVER_ROOT
  : DEV_SERVER_ROOT;

!config.IS_PRODUCTION && console.log({ REST_ROOT, SOCKETIO_ROOT });

if (config.USE_SOCKETIO) {
  const socket = io(SOCKETIO_ROOT, {
    path: `/${config.API_PATH}/socket.io/`,
    transports: ["websocket"],
    forceNew: true,
  });

  api.configure(feathers.socketio(socket, { timeout: 30000 }));
} else {
  const restClient = feathers.rest(REST_ROOT);

  api.configure(restClient.fetch(fetch));
}

api.configure(
  feathers.authentication({
    storageKey: `${config.STATE_KEY}_auth`,
    path: "/authentication",
  })
);

api
  .reAuthenticate()
  .then(() => {
    console.log(`REAUTHENTICATED`);

    (async () => {
      const { user, accessToken } = await api.get("authentication");

      store.dispatch({
        type: LoginActions.LOGIN_COMPLETED,
        user,
        accessToken,
      });
    })();
  })
  .catch((error) => {
    console.log(`NOT REAUTHENTICATED`, error);
    store.dispatch({ type: LoginActions.LOGOUT_COMPLETED });
  });

export default api;
