import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import CustomerSelect from "../selects/CustomerSelect";
import OrderSelect from "../selects/OrderSelect";
import { useFetchOrderLines } from "../state/orderLines.api";
import { OrderLine } from "../state/orderLines.model";
import { Id } from "../state/types";
import { formatUser } from "../state/users.model";
import { useQueryStringState } from "../utils/queryStringState";

export default function OrderLineListPage({
  orderId: forceOrderId,
  customerId: forceCustomerId,
}: {
  orderId?: Id;
  customerId?: Id;
}): JSX.Element {
  const nested = forceOrderId !== undefined || forceCustomerId !== undefined;
  const prefix = nested ? "orli_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [orderId, setOrderId] = useQueryStringState<Id | undefined>(
    "orderId",
    0,
    prefix
  );
  const [customerId, setCustomerId] = useQueryStringState<Id | undefined>(
    "customerId",
    0,
    prefix
  );

  const { orderLines, numberOfPages, fetching, error } = useFetchOrderLines({
    page,
    search,
    admin: true,
    query: {
      orderId: forceOrderId || orderId || undefined,
      customerId: forceCustomerId || customerId || undefined,
    },
  });

  return (
    <TableScreen<OrderLine>
      defaults={{
        ...(forceOrderId ? { orderId: forceOrderId } : {}),
        ...(forceCustomerId ? { customerId: forceCustomerId } : {}),
      }}
      nested={nested}
      fetching={fetching}
      error={error}
      title="Order Lines"
      singular="Order Line"
      columns={[
        { title: "ID" },
        { title: "Order ID" },
        { title: "Order State" },
        { title: "Recipient" },
        { title: "Product" },
        { title: "Product SKU" },
      ]}
      list={orderLines}
      row={(item) => [
        item.id,
        item.orderId,
        item.order?.state || "",
        formatUser(item.customer?.user),
        item.product_sku?.product?.title,
        `${item.productSkuId} ${item.all_sku_properties}`,
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/order-lines/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <>
              <TableFilter label="Order">
                <OrderSelect admin value={orderId} onChange={setOrderId} />
              </TableFilter>
              <TableFilter label="Customer">
                <CustomerSelect
                  admin
                  value={customerId}
                  onChange={setCustomerId}
                />
              </TableFilter>
            </>
          )}
        </>
      )}
    />
  );
}
