import {
  fetchProductAttribute,
  fetchProductAttributes,
} from "../state/productAttributes.api";
import {
  blankProductAttribute,
  ProductAttribute,
} from "../state/productAttributes.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

const format = (item: ProductAttribute): string => `#${item.id} ${item.title}`;

export default function ProductAttributeSelect(
  props: GenericSelectProps<ProductAttribute>
): JSX.Element {
  return GenericSelect<ProductAttribute>(
    props,
    fetchProductAttribute,
    fetchProductAttributes,
    "product-attributes",
    blankProductAttribute,
    format
  );
}
