import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { Button, CardFooter } from "reactstrap";
import { formatPrice } from "../utils/decimal";

export default function SlotCardFooter({
  canCheckout,
  basketTotal,
}: {
  canCheckout: boolean;
  basketTotal: string;
}): JSX.Element {
  return (
    <CardFooter className="py-3">
      <div className="button-container">
        <div>
          <h3 className="mb-0">
            <span className="text-muted">Total</span>&nbsp;&nbsp;
            {formatPrice(basketTotal)}
          </h3>
        </div>
        <Button
          tag={Link}
          to="/checkout"
          color="primary"
          className={classNames({
            "animate__animated animate__pulse": canCheckout,
          })}
          disabled={!canCheckout}
        >
          Continue to Checkout
        </Button>
      </div>
    </CardFooter>
  );
}
