/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from "react";

/// Puts a screen inside another component, e.g., a RequiredLogin.
export default function wrapScreen(
  Screen: any,
  Wrapper: any,
  wrapperProps?: any
): FunctionComponent<any> {
  const WrappedComponent = (props: any): JSX.Element => (
    <Wrapper {...(wrapperProps || {})}>
      <Screen {...props} />
    </Wrapper>
  );

  WrappedComponent.title = ((Screen as unknown) as Record<
    string,
    unknown
  >).title;

  return WrappedComponent;
}
