import {
  fetchOrganisation,
  fetchOrganisations,
} from "../state/organisations.api";
import { blankOrganisation, Organisation } from "../state/organisations.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

const format = (item: Organisation): string => `#${item.id} ${item.name}`;

export default function OrganisationSelect(
  props: GenericSelectProps<Organisation>
): JSX.Element {
  return GenericSelect<Organisation>(
    props,
    fetchOrganisation,
    fetchOrganisations,
    "organisations",
    blankOrganisation,
    format
  );
}
