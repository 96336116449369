import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { usePrevious } from "./hooks";

export function closest(element: Node | null, tag: string): Node | null {
  const upperTag = tag.toUpperCase();
  while (element) {
    if (element instanceof Element && element.tagName === upperTag) {
      return element;
    }

    element = element.parentNode;
  }

  return null;
}

/**
 * Returns a ref which when set causes that control to be focussed
 *
 * @param isOpen Delay autofocus until this is true
 */
export function useAutofocus<T extends { focus(): void } = HTMLInputElement>(
  isOpen = true
): MutableRefObject<T | null> {
  const ref = useRef<T | null>(null);

  useEffect((): (() => void) => {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    if (isOpen) {
      timeout = setTimeout(() => {
        timeout = undefined;
        (ref.current as T)?.focus();
      }, 1);
    }

    return () => timeout && clearTimeout(timeout);
  }, [ref, isOpen]);

  return ref;
}

export function useModalAutofocus<
  T extends { focus(): void } = HTMLInputElement
>(isOpen: boolean): MutableRefObject<T | null> {
  const autofocus = useAutofocus<T>();

  const wasOpen = usePrevious(isOpen);
  useEffect(() => {
    if (isOpen && !wasOpen) {
      // Need to wait until the control is visible
      setTimeout(() => autofocus.current?.focus(), 20);
    }
  }, [isOpen, wasOpen, autofocus]);

  return autofocus;
}

export async function sleep(milliseconds: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), milliseconds);
  });
}

export function getScrollY(): number {
  if (!window) {
    return 0;
  }
  return (
    window.pageYOffset ||
    ((): number => {
      return document.documentElement ? document.documentElement.scrollTop : 0;
    })()
  );
}

export function setScrollY(y: number): void {
  if (!window) {
    return;
  }
  window.requestAnimationFrame
    ? window.requestAnimationFrame(() => window.scrollTo(0, y))
    : setTimeout(() => window.scrollTo(0, y), 1);
}

export function htmlFieldValue(element: HTMLInputElement): string | boolean {
  if (element.type === "checkbox") {
    return element.checked;
  }

  return element.value;
}

export function useVisibility<T extends HTMLElement = HTMLElement>(
  proportion = 0.5,
  startupProportion = 0.1
): [MutableRefObject<T | null>, boolean] {
  const container = useRef<T | null>(null);

  const [visible, setVisible] = useState(false);

  const [isFirstUpdate] = useState({ firstUpdate: true });

  const updateVisibility = useCallback(() => {
    if (!container.current) {
      return;
    }

    const firstUpdate = isFirstUpdate.firstUpdate;
    isFirstUpdate.firstUpdate = false;

    const windowTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const windowBottom = windowHeight + windowTop;

    const containerTop = container.current.offsetTop || 0;
    const containerHeight = container.current.offsetHeight || 0;
    const containerBottom = containerTop + containerHeight;

    if (containerTop >= windowBottom || containerBottom <= windowTop) {
      setVisible(false);
      return;
    }

    const clampedHeight = Math.min(windowHeight, containerHeight);

    const visibleProportion =
      containerBottom > windowBottom
        ? (windowBottom - containerTop) / clampedHeight
        : containerTop < windowTop
        ? (containerBottom - windowTop) / clampedHeight
        : 1;

    if (visibleProportion > (firstUpdate ? startupProportion : proportion)) {
      console.log({ firstUpdate, visibleProportion });
      setVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstUpdate, startupProportion, proportion, container.current]);

  useEffect(() => {
    window.addEventListener("scroll", updateVisibility);

    return () => window.removeEventListener("scroll", updateVisibility);
  }, [updateVisibility, proportion]);

  useEffect(() => {
    updateVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container.current]);

  return [container, visible];
}
