import React from "react";
import { RouteComponentProps } from "react-router";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import OrganisationSelect from "../selects/OrganisationSelect";
import UserSelect from "../selects/UserSelect";
import { REST_ROOT } from "../state/api";
import {
  adminDeleteUpload,
  adminSaveUpload,
  useFetchUpload,
} from "../state/uploads.api";
import { Upload } from "../state/uploads.model";
import { pathJoin } from "../utils/paths";
import { useLocationId } from "../utils/queryStringState";
import ReactPlayer from "react-player";

interface ParamProps {
  uploadid: string;
}

function fixUploadUri(uri: string): string {
  if (uri.indexOf("://") >= 0) {
    return uri;
  }
  return pathJoin(REST_ROOT, "files", uri.replace(/ /g, "%20"));
}

function UploadDetailForm({
  getField,
  changeField,
  changeFileField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<Upload>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="organisationId">Organisation</Label>
            <OrganisationSelect
              admin
              isDisabled={readOnly}
              id="organisationId"
              value={getField("organisationId")}
              onChange={(newValue) =>
                changeFieldValue("organisationId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="canonicalLink">Link</Label>
            <Input
              readOnly
              type="text"
              name="canonicalLink"
              id="canonicalLink"
              value={fixUploadUri(getField("uri"))}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="description"
              id="description"
              value={getField("description")}
              onChange={(event) => changeField("description", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="userId">User</Label>
            <UserSelect
              isDisabled={readOnly}
              admin
              id="userId"
              value={getField("userId")}
              onChange={(newValue) =>
                changeFieldValue("userId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="originalName">Original Name</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="originalName"
              id="originalName"
              value={getField("originalName")}
              onChange={(event) => changeField("originalName", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="uri">Location</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="uri"
              id="uri"
              value={getField("uri")}
              onChange={(event) => changeField("uri", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="mimeType">MIME Type</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="mimeType"
              id="mimeType"
              value={getField("mimeType") || ""}
              onChange={(event) => changeField("mimeType", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="size">Size</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="size"
              id="size"
              value={getField("size") || ""}
              onChange={(event) => changeField("size", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="width">Width</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="width"
              id="width"
              value={getField("width") || ""}
              onChange={(event) => changeField("width", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="height">Height</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="height"
              id="height"
              value={getField("height") || ""}
              onChange={(event) => changeField("height", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="viewAs">View As (e.g., image or video)</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="viewAs"
              id="viewAs"
              value={getField("viewAs") || ""}
              onChange={(event) => changeField("viewAs", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="link">Open Link instead of File</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="link"
              id="link"
              value={getField("link") || ""}
              onChange={(event) => changeField("link", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="processingState">Processing State</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="processingState"
              id="processingState"
              value={getField("processingState") || ""}
              onChange={(event) => changeField("processingState", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="mediaConvertJobId">MediaConvert Job ID</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="mediaConvertJobId"
              id="mediaConvertJobId"
              value={getField("mediaConvertJobId") || ""}
              onChange={(event) => changeField("mediaConvertJobId", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="videoUri">Video URI</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="videoUri"
              id="videoUri"
              value={getField("videoUri") || ""}
              onChange={(event) => changeField("videoUri", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="thumbnailUri">Thumbnail URI</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="thumbnailUri"
              id="thumbnailUri"
              value={getField("thumbnailUri") || ""}
              onChange={(event) => changeField("thumbnailUri", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="files">File</Label>
            <CustomInput
              type="file"
              id="files"
              name="files"
              onChange={(event) => changeFileField("files", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<Upload>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
      {getField("viewAs") === "video" && (getField("videoUri") ? true : false) && (
        <Row>
          <Col>
            <div className="embed-responsive embed-responsive-16by9">
              <div className="embed-responsive-item">
                <ReactPlayer
                  width="100%"
                  height="100%"
                  url={getField("videoUri")}
                  controls
                  options={{
                    file: {
                      forceHLS: true,
                    },
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Form>
  );
}

export default function UploadDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.uploadid);
  const { upload, error, fetching } = useFetchUpload({ id, admin: true });

  return (
    <DetailScreen<Upload>
      defaults={(location.state as { defaults?: Partial<Upload> })?.defaults}
      onIdChange={setId}
      error={error}
      fetching={fetching}
      original={upload}
      isNew={match.params.uploadid === "new"}
      route={[{ Uploads: "/admin/uploads" }]}
      renderForm={UploadDetailForm}
      onSave={adminSaveUpload}
      onDelete={adminDeleteUpload}
    />
  );
}
