import React from "react";
import { RouteComponentProps } from "react-router";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import OrganisationSelect from "../selects/OrganisationSelect";
import UserSelect from "../selects/UserSelect";
import { useCombinedStore } from "../state/combinedStore";
import {
  adminDeleteOrganisationMember,
  adminSaveOrganisationMember,
  useFetchOrganisationMember,
} from "../state/organisationMembers.api";
import { OrganisationMember } from "../state/organisationMembers.model";
import { formatUser } from "../state/users.model";
import { useLocationId } from "../utils/queryStringState";

interface ParamProps {
  organisationmemberid: string;
}

export default function OrganisationMemberDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.organisationmemberid);
  const { organisationMember, fetching, error } = useFetchOrganisationMember({
    id,
    admin: true,
  });

  return (
    <DetailScreen<OrganisationMember>
      defaults={
        (location.state as { defaults?: Partial<OrganisationMember> })?.defaults
      }
      onIdChange={setId}
      fetching={fetching}
      error={error}
      original={organisationMember}
      isNew={match.params.organisationmemberid === "new"}
      route={[{ "Organisation Members": "/admin/organisation-members" }]}
      renderForm={(props) => <OrganisationMemberDetailForm {...props} />}
      onSave={adminSaveOrganisationMember}
      onDelete={adminDeleteOrganisationMember}
    />
  );
}

function OrganisationMemberDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<OrganisationMember>) {
  const superuser = useCombinedStore(
    (store) => store.login.permissions.superuser
  );

  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="userId">User</Label>
            {superuser ? (
              <UserSelect
                admin
                isDisabled={readOnly}
                id="userId"
                value={getField("userId")}
                onChange={(newValue) =>
                  changeFieldValue("userId", null, newValue)
                }
              />
            ) : (
              <Input readOnly value={formatUser(getField("user"))} />
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="organisationId">Organisation</Label>
            <OrganisationSelect
              admin
              isDisabled={readOnly}
              id="organisationId"
              value={getField("organisationId")}
              onChange={(newValue) =>
                changeFieldValue("organisationId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="pb-2">
          <Label>Permissions</Label>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="manageCalendar"
              id="manageCalendar"
              checked={getField("manageCalendar")}
              onChange={(event) => changeField("manageCalendar", event)}
              label="Manage Calendar"
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="manageCustomers"
              id="manageCustomers"
              checked={getField("manageCustomers")}
              onChange={(event) => changeField("manageCustomers", event)}
              label="Manage Customers"
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="manageOrders"
              id="manageOrders"
              checked={getField("manageOrders")}
              onChange={(event) => changeField("manageOrders", event)}
              label="Manage Orders"
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="managePayments"
              id="managePayments"
              checked={getField("managePayments")}
              onChange={(event) => changeField("managePayments", event)}
              label="Manage Payments"
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="manageProducts"
              id="manageProducts"
              checked={getField("manageProducts")}
              onChange={(event) => changeField("manageProducts", event)}
              label="Manage Products"
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="manageProductAttributes"
              id="manageProductAttributes"
              checked={getField("manageProductAttributes")}
              onChange={(event) =>
                changeField("manageProductAttributes", event)
              }
              label="Manage Product Attributes"
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="managePaymentMethods"
              id="managePaymentMethods"
              checked={getField("managePaymentMethods")}
              onChange={(event) => changeField("managePaymentMethods", event)}
              label="Manage Payment Methods"
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="manageUsers"
              id="manageUsers"
              checked={getField("manageUsers")}
              onChange={(event) => changeField("manageUsers", event)}
              label="Manage Organisation Members"
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="manageUploads"
              id="manageUploads"
              checked={getField("manageUploads")}
              onChange={(event) => changeField("manageUploads", event)}
              label="Manage Uploads"
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<OrganisationMember>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
