import isValid from "date-fns/isValid";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";

export type DateToString = (date: number) => string;
export type StringToDate = (date: string) => number | undefined;

export interface DateTimeInputCallbacks {
  hasTime: boolean;
  format: DateToString;
  parse: StringToDate;
  formatForDisplay: DateToString;
  parseFromDisplay: StringToDate;
}

export interface DateTimeProps {
  name?: string;
  id?: string;
  value?: string | number;
  onChange?: (newValue: string | number) => void;
  readOnly?: boolean;
  callbacks: DateTimeInputCallbacks;
  className?: string;
  disabled?: boolean;
}

function parseOrUndefined(
  value: string,
  parse: StringToDate
): number | undefined {
  const parsed = parse(value);
  return isValid(parsed) ? parsed : undefined;
}

function valueToMillis(
  value: string | number | undefined,
  parse: StringToDate
): number | undefined {
  return typeof value === "string"
    ? value.trim() === ""
      ? undefined
      : parseOrUndefined(value, parse)
    : value
    ? +value
    : 0;
}

function valueToString(
  value: number | undefined | string,
  format: DateToString
): string {
  return typeof value === "string" ? value : !value ? "" : format(value);
}

export default function DateTimeInput({
  name,
  id,
  value,
  onChange,
  readOnly,
  callbacks,
  className,
  disabled,
  ...rest
}: DateTimeProps): JSX.Element {
  const millis = valueToMillis(value, callbacks.parse);
  const [text, setText] = useState(
    valueToString(millis, callbacks.formatForDisplay)
  );
  const [bad, setBad] = useState(false);
  const [blurred, setBlurred] = useState(false);
  const [lastParsed, setLastParsed] = useState<number | undefined>(millis);

  useEffect(() => {
    const millis = valueToMillis(value, callbacks.parse);
    if (millis !== lastParsed) {
      setText(valueToString(millis, callbacks.formatForDisplay));
      setLastParsed(millis);
    }
  }, [value, lastParsed, callbacks]);

  const changeText = useCallback(
    (input: string) => {
      setText(input);

      if (input.trim() === "") {
        setBad(false);
        setBlurred(false);
        setLastParsed(undefined);
        onChange && onChange("");
      } else {
        const parsed = callbacks.parseFromDisplay(input);
        if (parsed) {
          setLastParsed(parsed);
          setBad(false);
          setBlurred(false);
          onChange &&
            onChange(
              typeof value === "number" ? parsed : callbacks.format(parsed)
            );
        } else {
          setBad(true);
        }
      }
    },
    [onChange, callbacks, value]
  );

  const onTextChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      changeText(event.currentTarget.value);
    },
    [changeText]
  );

  const onBlur = useCallback(
    (_event: FormEvent<HTMLInputElement>) => {
      setBlurred(bad);
      setText(lastParsed ? callbacks.formatForDisplay(lastParsed) : "");
    },
    [bad, callbacks, lastParsed]
  );

  const onDatePickerChange = useCallback(
    (date: Date | null) => {
      changeText(
        date ? valueToString(date.getTime(), callbacks.formatForDisplay) : ""
      );
    },
    [callbacks.formatForDisplay, changeText]
  );

  return (
    <>
      <InputGroup>
        <Input
          type="text"
          name={name}
          id={id}
          value={text}
          onChange={onTextChange}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
          className={`${className || ""} ${bad && blurred ? "is-invalid" : ""}`}
          {...rest}
        />

        <InputGroupAddon addonType="append">
          <DatePicker
            selected={lastParsed ? new Date(lastParsed) : null}
            onChange={onDatePickerChange}
            // showYearDropdown
            // showFullMonthYearPicker
            // showMonthDropdown
            // showYearDropdown
            timeFormat="HH:mm"
            timeIntervals={15}
            showTimeSelect={callbacks.hasTime}
            // customTimeInput={<CustomTimeInput />}
            // showTimeInput={callbacks.hasTime}
            customInput={
              <Button color="primary" onClick={() => undefined}>
                ...
              </Button>
            }
          />
        </InputGroupAddon>
      </InputGroup>
      {/* <pre>{JSON.stringify({ bad, blurred, lastParsed, editing, value })}</pre> */}
    </>
  );
}
