import React from "react";
import { ProductAttributeOption } from "../state/productAttributeOptions.model";
import { Card } from "reactstrap";
import classNames from "classnames";

export default function TeacherCard({
  teacher,
  animationIndex,
  size = "lg",
}: {
  teacher: Pick<ProductAttributeOption, "title" | "imageUrl" | "id">;
  animationIndex?: number;
  size?: "sm" | "lg";
}): JSX.Element {
  const headerClass = size === "sm" ? "h5" : "h1";
  const justifyName = size === "sm" ? "center" : "flex-start";
  const padding = size === "sm" ? "0.5rem" : "1rem";

  return (
    <Card
      key={teacher.id}
      style={{
        backgroundImage: `url('${teacher.imageUrl || ""}')`,
        backgroundSize: "cover",
        animationDelay:
          animationIndex === undefined
            ? undefined
            : `${animationIndex * 100}ms`,
      }}
      className={classNames(
        `text-light bg-dark aspect-ratio aspect-ratio-9by10 animate__animated animate__fadeIn`
      )}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
          textShadow: "0px 0px 0.2rem rgba(0,0,0,255)",
          justifyContent: justifyName,
          padding,
        }}
      >
        <div className={`fancy mb-0 ${headerClass}`}>{teacher.title}</div>
      </div>
    </Card>
  );
}
