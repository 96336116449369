import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import {
  blankOrderLineProperty,
  OrderLineProperty,
} from "./orderLineProperties.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchOrderLinePropertiesResults extends UseFetchManyResults {
  orderLineProperties: OrderLineProperty[];
  numberOfOrderLineProperties: number;
  totalNumberOfOrderLineProperties: number;
}

interface UseFetchOrderLinePropertyResults extends UseFetchOneResults {
  orderLineProperty: OrderLineProperty;
}

const keys: FetcherKeys<
  UseFetchOrderLinePropertiesResults,
  UseFetchOrderLinePropertyResults
> = {
  service: "order-line-properties",
  totalNumber: "totalNumberOfOrderLineProperties",
  numberOf: "numberOfOrderLineProperties",
  records: "orderLineProperties",
  record: "orderLineProperty",
};

export async function fetchOrderLineProperties(
  props: FetchManyProps
): Promise<FetchManyResults<OrderLineProperty>> {
  return fetchMany<OrderLineProperty>(props, keys.service);
}

export function useFetchOrderLineProperties(
  props: FetchManyProps
): UseFetchOrderLinePropertiesResults {
  return useFetchMany(props, fetchOrderLineProperties, keys);
}

export async function fetchOrderLineProperty(
  props: FetchOneProps
): Promise<OrderLineProperty> {
  return fetchOne<OrderLineProperty, UseFetchOrderLinePropertyResults>(
    props,
    blankOrderLineProperty,
    keys
  );
}

export function useFetchOrderLineProperty(
  props: FetchOneProps
): UseFetchOrderLinePropertyResults {
  return useFetchOne(
    props,
    fetchOrderLineProperty,
    blankOrderLineProperty,
    keys
  );
}

async function saveOrderLineProperty(
  original: OrderLineProperty,
  changes: Partial<OrderLineProperty>,
  $role: true | false | string
): Promise<OrderLineProperty> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteOrderLineProperty(
  original: OrderLineProperty,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveOrderLineProperty = makeSaver(
  saveOrderLineProperty,
  true
);
export const adminDeleteOrderLineProperty = makeDeleter(
  deleteOrderLineProperty,
  true
);
export const userSaveOrderLineProperty = makeSaver(
  saveOrderLineProperty,
  false
);
export const userDeleteOrderLineProperty = makeDeleter(
  deleteOrderLineProperty,
  false
);
