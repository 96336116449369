import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import ProductAttributeSelect from "../selects/ProductAttributeSelect";
import ProductSelect from "../selects/ProductSelect";
import { useFetchProductAttributeOptions } from "../state/productAttributeOptions.api";
import { ProductAttributeOption } from "../state/productAttributeOptions.model";
import { Id } from "../state/types";
import { useQueryStringState } from "../utils/queryStringState";

export default function ProductAttributeOptionListPage({
  productAttributeId: forceProductAttributeId,
}: {
  productAttributeId?: Id;
}): JSX.Element {
  const nested = forceProductAttributeId !== undefined;
  const prefix = nested ? "pratop_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [productAttributeId, setProductAttributeId] = useQueryStringState<
    Id | undefined
  >("productAttributeId", 0, prefix);
  const [productId, setProductId] = useQueryStringState<Id | undefined>(
    "productId",
    0,
    prefix
  );

  const {
    productAttributeOptions,
    numberOfPages,
    fetching,
    error,
  } = useFetchProductAttributeOptions({
    page,
    search,
    admin: true,
    query: {
      productAttributeId:
        forceProductAttributeId || productAttributeId || undefined,
      productId: productId || undefined,
    },
  });

  return (
    <TableScreen<ProductAttributeOption>
      defaults={{
        ...(forceProductAttributeId
          ? { productAttributeId: forceProductAttributeId }
          : {}),
      }}
      nested={nested}
      fetching={fetching}
      error={error}
      title="Product Attribute Options"
      singular="Product Attribute Option"
      columns={[
        { title: "ID" },
        { title: "Attribute" },
        { title: "Product" },
        { title: "Title" },
      ]}
      list={productAttributeOptions}
      row={(item) => [
        item.id,
        item.product_attribute?.title,
        item.product_attribute?.product?.title,
        item.title,
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/product-attribute-options/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <>
              <TableFilter label="Attribute">
                <ProductAttributeSelect
                  admin
                  value={productAttributeId}
                  onChange={setProductAttributeId}
                />
              </TableFilter>
              <TableFilter label="Product">
                <ProductSelect
                  admin
                  value={productId}
                  onChange={setProductId}
                />
              </TableFilter>
            </>
          )}
        </>
      )}
    />
  );
}
