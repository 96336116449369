import { ProductAttributeOptionPick } from "./productAttributeOptions.model";
import { ProductAttributePick } from "./productAttributes.model";
import { ProductPick } from "./products.model";
import { Id, ModelBase } from "./types";

export type ProductPropertyValue = Pick<
  ProductProperty,
  "value" | "productAttributeOptionId"
>;

export type ProductAttributeIdToValue = {
  [productAttributeId in Id]: ProductPropertyValue;
};

export interface ProductProperty extends ModelBase {
  variesPerSku: boolean;
  productId: Id;
  product?: ProductPick;
  productAttributeId: Id;
  product_attribute?: ProductAttributePick;
  productAttributeOptionId?: Id;
  product_attribute_option?: ProductAttributeOptionPick;
  value?: string;
}

export const blankProductProperty: ProductProperty = {
  id: 0,
  variesPerSku: false,
  productId: 0,
  productAttributeId: 0,
};
