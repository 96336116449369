import { OrderState } from "./orders.model";
import { Id, ModelBase } from "./types";

export interface OrderLine extends ModelBase {
  orderId: Id;
  userId: Id;
  customerId: Id;
  productSkuId: Id;
  quantity: number;
  unitCost: string;
  order?: {
    id: Id;
    state: OrderState;
    user?: {
      id: Id;
      firstName: string;
      lastName: string;
    };
  };
  product_sku?: {
    id: Id;
    product?: {
      id: Id;
      title: string;
    };
  };
  customer?: {
    id: Id;
    userId: Id;
    user?: {
      id: Id;
      firstName: string;
      lastName: string;
    };
  };
  all_sku_properties?: string; // comma separated list of all SKU properties, added for admin requests, and searchable
}

export const blankOrderLine: OrderLine = {
  id: 0,
  orderId: 0,
  userId: 0,
  customerId: 0,
  quantity: 1,
  unitCost: "",
  productSkuId: 0,
};
