import React, { MouseEvent, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Modal, Row, Container } from "reactstrap";
import InternalOrExternalLink from "../components/InternalOrExternalLink";
import PageTitle from "../components/PageTitle";
import VideoCard from "../components/VideoCard";
import videoCoverImage from "../media/2019-promo-1-cover.jpg";
import logoWhite from "../media/logo-white.png";
import { CombinedStore } from "../state/combinedStore";
import { LoginOrRegisterForm } from "./LoginOrRegisterScreen";

export default function HomeScreen(): JSX.Element {
  const isLoggedIn = useSelector(
    (store: CombinedStore) => store.login.isLoggedIn
  );
  const user = useSelector((store: CombinedStore) => store.login.user);

  const [showLoginOrRegisterModal, setShowLoginOrRegisterModal] = useState(
    false
  );
  const [loginOrRegisterMode, setLoginOrRegisterMode] = useState<
    "login" | "register"
  >("login");

  const onLoginOrRegisterClicked = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, mode: "login" | "register") => {
      event.preventDefault();
      setLoginOrRegisterMode(mode);
      setShowLoginOrRegisterModal(true);
    },
    []
  );

  const onLoginOrRegisterModalToggle = useCallback(
    () => setShowLoginOrRegisterModal((value) => !value),
    []
  );

  return (
    <Container>
      <PageTitle
        title={
          isLoggedIn ? `Hi, ${user.firstName}!` : "Welcome to Rockley Music!"
        }
      >
        {!isLoggedIn && (
          <>
            <Link
              onClick={(event) => onLoginOrRegisterClicked(event, "login")}
              to="/login"
              className="btn btn-primary"
            >
              Sign In
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link
              onClick={(event) => onLoginOrRegisterClicked(event, "register")}
              to="/register"
              className="btn btn-primary"
            >
              Create an Account
            </Link>
          </>
        )}
      </PageTitle>
      <div className="card-group-with-gaps auto-card-group auto-card-group-md-2">
        <VideoCard
          className=" bg-dark overflow-hidden embed-responsive embed-responsive-21by9 position-relative text-white full-width-card animate__animated animate__fadeIn"
          coverImage={videoCoverImage}
          renderSources={
            <source
              src="https://www.rockleymusic.co.uk/wp-content/uploads/2019/09/2019-promo-1.mp4"
              type="video/mp4"
            />
          }
        >
          <CardBody
            className="text-white position-relative"
            style={{ zIndex: 99 }}
          >
            <img
              className="animate__animated animate__jackInTheBox"
              style={{ opacity: 0.9 }}
              src={logoWhite}
              height={64}
              alt="Logo"
            />
          </CardBody>
        </VideoCard>
        <Card className="animate__animated animate__delay-1s animate__fadeIn">
          <div className="watermark" />
          <CardBody>
            <h1 className="fancy">Virtual Drum Lessons</h1>
            <p className="lead">
              30 minute lessons online via Zoom, Skype or WhatsApp.
            </p>
            <p className="mt-3">
              <Button
                tag={Link}
                to="/one2one/teacher/"
                color="primary"
                size="lg"
              >
                Book Here
              </Button>
            </p>
          </CardBody>
        </Card>
        <Card className="animate__animated animate__delay-2s animate__fadeIn">
          <CardBody>
            <h1 className="fancy">Video Tutorials</h1>
            <p className="lead">
              There are dozens of videos included in your Rockley Music
              membership that you can access any time.
            </p>
            <p>
              <Button
                tag="a"
                href="https://www.rockleymusic.co.uk/account/enrol"
                color="primary"
                size="lg"
              >
                Enrol for Video Lessons
              </Button>
            </p>
            <p>
              Already a member? Visit our{" "}
              <a
                href="https://www.rockleymusic.co.uk/videolessons/"
                rel="nofollower noreferer"
              >
                Video Lessons
              </a>{" "}
              page to request an access code.
            </p>
          </CardBody>
        </Card>
        <Card className="animate__animated animate__delay-3s animate__fadeIn">
          <CardBody>
            <h1 className="fancy">Latest on YouTube</h1>
            <ul>
              <li>Paradiddle challenge!</li>
              <li>Bloopers!</li>
              <li>Wooden spoons and pillows!</li>
            </ul>
            <p>
              <Button
                tag="a"
                href="https://www.youtube.com/channel/UCwHU4pI3NlGu6JQJc2uuWeg"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
              >
                Go to YouTube
              </Button>
            </p>
          </CardBody>
        </Card>
      </div>
      <PageTitle title="" />
      <Row>
        {CARDS.map((card) => {
          return (
            <Col xs={card.small} md={card.large} key={card.image}>
              <InternalOrExternalLink
                link={card.link}
                className="zoom-on-hover"
              >
                <Card
                  className="mb-3"
                  style={{
                    backgroundImage: `url(${card.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                    height: "10rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                      textShadow: "0px 0px 0.2rem rgba(0,0,0,255)",
                      justifyContent: "flex-start",
                      padding: "1rem",
                    }}
                    className="text-white"
                  >
                    <div className={`fancy mb-0 h4`}>{card.text}</div>
                  </div>
                </Card>
              </InternalOrExternalLink>
            </Col>
          );
        })}
      </Row>
      <Modal
        isOpen={showLoginOrRegisterModal}
        centered
        className="no-background"
        unmountOnClose
      >
        <LoginOrRegisterForm
          mode={loginOrRegisterMode}
          onCancel={onLoginOrRegisterModalToggle}
          onLoggedIn={onLoginOrRegisterModalToggle}
        />
      </Modal>
    </Container>
  );
}

const CARDS: {
  image: string;
  text: string;
  link: string;
  small: number;
  large: number;
}[] = [
  {
    small: 6,
    large: 4,
    image: require("../media/drumba_tile.jpg"),
    text: "",
    link: "https://www.drumba.co.uk/",
  },
  {
    small: 6,
    large: 3,
    image: require("../media/drumless_tracks_tile.jpg"),
    text: "Drumless Tracks",
    link: "https://www.rockleymusic.co.uk/drumless-tracks/",
  },
  {
    small: 6,
    large: 2,
    image: require("../media/facebook_tile.jpg"),
    text: "Facebook",
    link: "https://www.facebook.com/Rockley-Music-1574991516120546",
  },
  {
    small: 6,
    large: 3,
    image: require("../media/sheet_music_tile.jpg"),
    text: "Sheet Music",
    link: "https://www.rockleymusic.co.uk/sheet-music/",
  },
  {
    small: 6,
    large: 2,
    image: require("../media/sticks_tile.jpg"),
    text: "Buy Sticks",
    link: "https://www.rockleymusic.co.uk/shop/",
  },
  {
    small: 6,
    large: 4,
    image: require("../media/studio_lessons_tile.jpg"),
    text: "One-to-One Lessons",
    link: "/one2one/teacher/",
  },
  {
    small: 6,
    large: 3,
    image: require("../media/top20_tile.jpg"),
    text: "Top-20 Tracks",
    link: "https://www.rockleymusic.co.uk/top20/",
  },
  {
    small: 6,
    large: 3,
    image: require("../media/videos_tile.jpg"),
    text: "Videos",
    link: "https://www.rockleymusic.co.uk/videos/",
  },
];
