import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import config from "../config";
import OrganisationSelect from "../selects/OrganisationSelect";
import { useFetchProducts } from "../state/products.api";
import { Product } from "../state/products.model";
import { Id } from "../state/types";
import { formatPrice } from "../utils/decimal";
import { useQueryStringState } from "../utils/queryStringState";

export default function ProductListPage(): JSX.Element {
  const [page, setPage] = useQueryStringState("page", 0);
  const [search, setSearch] = useQueryStringState("search", "");
  const [organisationId, setOrganisationId] = useQueryStringState<
    Id | undefined
  >("organisationId", 0);

  const { products, numberOfPages, fetching, error } = useFetchProducts({
    page,
    search,
    admin: true,
    query: {
      organisationId: organisationId || undefined,
    },
  });

  return (
    <TableScreen<Product>
      defaults={{
        organisationId: config.ORGANISATION_ID,
      }}
      fetching={fetching}
      error={error}
      title="Products"
      singular="Product"
      columns={[
        { title: "ID" },
        { title: "Title" },
        { title: "Price" },
        { title: "Organisation" },
      ]}
      list={products}
      row={(item) => [
        item.id,
        item.title,
        formatPrice(item.price || ""),
        item.organisation?.name,
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/products/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          <TableFilter label="Organisation">
            <OrganisationSelect
              admin
              value={organisationId}
              onChange={setOrganisationId}
            />
          </TableFilter>
        </>
      )}
    />
  );
}
