import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import OrganisationSelect from "../selects/OrganisationSelect";
import ProductSelect from "../selects/ProductSelect";
import { useFetchProductAttributes } from "../state/productAttributes.api";
import { ProductAttribute } from "../state/productAttributes.model";
import { useQueryStringState } from "../utils/queryStringState";
import config from "../config";
import { Id } from "../state/types";

export default function ProductAttributeListPage({
  productId: forceProductId,
}: {
  productId?: Id;
}): JSX.Element {
  const nested = forceProductId !== undefined;
  const prefix = nested ? "prat_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [organisationId, setOrganisationId] = useQueryStringState<
    Id | undefined
  >("organisationId", 0, prefix);
  const [productId, setProductId] = useQueryStringState<Id | undefined>(
    "productId",
    0,
    prefix
  );

  const {
    productAttributes,
    numberOfPages,
    fetching,
    error,
  } = useFetchProductAttributes({
    page,
    search,
    admin: true,
    query: {
      organisationId: organisationId || undefined,
      productId: forceProductId || productId || undefined,
    },
  });

  return (
    <TableScreen<ProductAttribute>
      defaults={{
        ...(forceProductId ? { productId: forceProductId } : {}),
        organisationId: config.ORGANISATION_ID,
      }}
      nested={nested}
      fetching={fetching}
      error={error}
      title="Product Attributes"
      singular="Product Attribute"
      columns={[
        { title: "ID" },
        { title: "Title" },
        { title: "Product" },
        { title: "Public" },
        { title: "Multiple" },
        { title: "Action" },
        { title: "Input" },
        { title: "Pull Through" },
      ]}
      list={productAttributes}
      row={(item) => [
        item.id,
        item.title,
        item.product?.title || `(any ${item.organisation?.name || ""})`,
        item.isHidden ? "no" : "yes",
        item.isMultiple ? "yes" : "no",
        item.action,
        item.input,
        item.pullThrough,
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/product-attributes/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <>
              <TableFilter label="Organisation">
                <OrganisationSelect
                  admin
                  value={organisationId}
                  onChange={setOrganisationId}
                />
              </TableFilter>
              <TableFilter label="Product">
                <ProductSelect
                  admin
                  value={productId}
                  onChange={setProductId}
                />
              </TableFilter>
            </>
          )}
        </>
      )}
    />
  );
}
