import { useSelector } from "react-redux";
import { AnyAction, combineReducers } from "redux";
import basket from "./basket.reducer";
import family from "./family.reducer";
import login from "./login.reducer";
import queryCache from "./queryCache.reducer";

const reducers = {
  login,
  family,
  basket,
  queryCache,
};

const combinedStore = combineReducers(reducers);

type ReducersType = typeof reducers;

export type CombinedStore = {
  [P in keyof ReducersType]: ReturnType<ReducersType[P]>;
};

export default combinedStore;

export function useCombinedStore<T>(selector: (store: CombinedStore) => T): T {
  return useSelector<CombinedStore, T>(selector);
}

export type AnyDispatch<A extends AnyAction = AnyAction> = {
  <T extends A>(action: T): T;
  <T extends A>(action: AsyncAction<T>): void;
};

export type AsyncAction<T extends AnyAction = AnyAction> = (
  dispatch: AnyDispatch<T>,
  getState: () => CombinedStore
) => Promise<void>;
