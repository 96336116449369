import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import config from "../config";
import CustomerSelect from "../selects/CustomerSelect";
import PaymentMethodSelect from "../selects/PaymentMethodSelect";
import { useFetchPayments } from "../state/payments.api";
import { Payment } from "../state/payments.model";
import { Id } from "../state/types";
import { formatUser } from "../state/users.model";
import { formatPrice } from "../utils/decimal";
import { useQueryStringState } from "../utils/queryStringState";

export default function PaymentListPage({
  customerId: forceCustomerId,
}: {
  customerId?: Id;
}): JSX.Element {
  const nested = forceCustomerId !== undefined;
  const prefix = nested ? "pa_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [customerId, setCustomerId] = useQueryStringState<Id | undefined>(
    "customerId",
    0,
    prefix
  );
  const [paymentMethodId, setPaymentMethodId] = useQueryStringState<
    Id | undefined
  >("paymentMethodId", 0, prefix);

  const { payments, numberOfPages, fetching, error } = useFetchPayments({
    page,
    search,
    admin: true,
    query: {
      customerId: forceCustomerId || customerId || undefined,
      paymentMethodId: paymentMethodId || undefined,
    },
  });

  return (
    <TableScreen<Payment>
      defaults={{
        ...(forceCustomerId ? { customerId: forceCustomerId } : {}),
      }}
      fetching={fetching}
      error={error}
      title="Payments"
      singular="Payment"
      columns={[
        { title: "ID" },
        { title: "State" },
        { title: "Amount" },
        { title: "Customer" },
        { title: "Created" },
        { title: "Method" },
      ]}
      list={payments}
      row={(item) => [
        item.id,
        item.state,
        formatPrice(item.amount),
        formatUser(item.customer?.user),
        item.createdAt
          ? format(parseISO(item.createdAt), config.DATE_TIME_FORMAT)
          : "",
        item.payment_method?.title,
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/payments/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <TableFilter label="Customer">
              <CustomerSelect
                admin
                value={customerId}
                onChange={setCustomerId}
              />
            </TableFilter>
          )}
          <TableFilter label="Method">
            <PaymentMethodSelect
              admin
              value={paymentMethodId}
              onChange={setPaymentMethodId}
            />
          </TableFilter>
        </>
      )}
    />
  );
}
