import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import { htmlToText } from "html-to-text";
import React, { useRef } from "react";
import { RouteComponentProps } from "react-router";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import { StyledSelect } from "../components/StyledSelect";
import MessageTemplateSelect from "../selects/MessageTemplateSelect";
import OrganisationSelect from "../selects/OrganisationSelect";
import {
  adminDeleteMessageTemplate,
  adminSaveMessageTemplate,
  useFetchMessageTemplate,
} from "../state/messageTemplates.api";
import { MessageTemplate } from "../state/messageTemplates.model";
import { useLocationId } from "../utils/queryStringState";
import { getSelectOptionByValue } from "../utils/reactSelect";

const CONTENT_TYPE_OPTIONS = [
  { value: "html", label: "Rich Text" },
  { value: "html_source", label: "HTML Source" },
  { value: "text", label: "Plain Text" },
];
const TEMPLATE_LANGUAGE_OPTIONS = [
  { value: "text", label: "Plain Text" },
  { value: "hbs", label: "Handlebars" },
  { value: "ejs", label: "Embedded JavaScript" },
];

const DEFAULT_CONFIG: CKEDITOR.config = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "indent",
    "outdent",
    "|",
    "blockquote",
    "insertTable",
    "|",
    "undo",
    "redo",
  ],
};

function MessageTemplateDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<MessageTemplate>) {
  const editor = useRef<CKEDITOR.editor | null>(null);

  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="organisationId">Organisation</Label>
            <OrganisationSelect
              isDisabled={readOnly}
              admin
              id="organisationId"
              value={getField("organisationId")}
              onChange={(newValue) =>
                changeFieldValue("organisationId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="description"
              id="description"
              value={getField("description")}
              onChange={(event) => changeField("description", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="title"
              id="title"
              value={getField("title")}
              onChange={(event) => changeField("title", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="text">Content</Label>
            {(getField("contentType") || "html") === "html" ? (
              <CKEditor
                editor={ClassicEditor}
                data={getField("text")}
                onInit={(e: CKEDITOR.editor) => {
                  // console.log(Array.from((e.ui as any).componentFactory.names()));
                  editor.current = e;
                }}
                config={DEFAULT_CONFIG}
                disabled={readOnly}
                onChange={(
                  _event: CKEDITOR.eventInfo,
                  editor: CKEDITOR.editor
                ) => {
                  changeFieldValue("text", null, editor.getData());
                }}
              />
            ) : getField("contentType") === "html_source" ? (
              <Input
                readOnly={readOnly}
                type="textarea"
                value={getField("text")}
                onChange={(event) => changeField("text", event)}
                style={{ height: "12rem" }}
              />
            ) : (
              <Input
                readOnly={readOnly}
                type="textarea"
                value={getField("text")}
                onChange={(event) => changeField("text", event)}
                style={{ height: "12rem" }}
              />
            )}
            <Row className="mt-2">
              <Col md={6}>
                <StyledSelect
                  isDisabled={readOnly}
                  id="contentType"
                  options={CONTENT_TYPE_OPTIONS}
                  value={getSelectOptionByValue(
                    CONTENT_TYPE_OPTIONS,
                    getField("contentType") || "html"
                  )}
                  onChange={(newValue) =>
                    changeFieldValue(
                      "contentType",
                      null,
                      newValue && "value" in newValue ? newValue.value : ""
                    )
                  }
                />
              </Col>
              <Col md={6}>
                <StyledSelect
                  isDisabled={readOnly}
                  id="templateLanguage"
                  options={TEMPLATE_LANGUAGE_OPTIONS}
                  value={getSelectOptionByValue(
                    TEMPLATE_LANGUAGE_OPTIONS,
                    getField("templateLanguage") || "hbs"
                  )}
                  onChange={(newValue) =>
                    changeFieldValue(
                      "templateLanguage",
                      null,
                      newValue && "value" in newValue ? newValue.value : ""
                    )
                  }
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup check className="mb-2">
            <CustomInput
              type="checkbox"
              name="skipForPlainText"
              id="skipForPlainText"
              checked={getField("skipForPlainText")}
              onChange={(event) => changeField("skipForPlainText", event)}
              label={
                <>
                  <div>Bypass template for plain text emails</div>
                  {!getField("skipForPlainText") && (
                    <div className="mt-2">
                      {htmlToText(getField("text"), { wordwrap: false })
                        .split("\n")
                        .map((line) => (
                          <>
                            {line}
                            <br />
                          </>
                        ))}
                    </div>
                  )}
                </>
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="outerId">Outer Template</Label>
            <MessageTemplateSelect
              isDisabled={readOnly}
              admin
              id="outerId"
              value={getField("outerId")}
              onChange={(newValue) =>
                changeFieldValue("outerId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="assignment">Assignment</Label>
            <Input
              type="text"
              name="assignment"
              value={getField("assignment")}
              id="assignment"
              onChange={(event) => changeField("assignment", event)}
              readOnly={readOnly}
            />
            <FormText>
              Changing this value can affect critical functionality
            </FormText>
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<MessageTemplate>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}

export default function MessageTemplateDetailPage({
  match,
  location,
}: RouteComponentProps<{ messageTemplateId: string }>): JSX.Element {
  const [id, setId] = useLocationId(match.params.messageTemplateId);
  const { messageTemplate, fetching, error } = useFetchMessageTemplate({
    id,
    admin: true,
  });

  return (
    <>
      <DetailScreen<MessageTemplate>
        defaults={
          (location.state as { defaults?: Partial<MessageTemplate> })?.defaults
        }
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={messageTemplate}
        isNew={match.params.messageTemplateId === "new"}
        titleField="id"
        titleIfNew="New Message Template"
        route={[{ "Message Templates": "/admin/messagetemplates" }]}
        renderForm={MessageTemplateDetailForm}
        onSave={adminSaveMessageTemplate}
        onDelete={adminDeleteMessageTemplate}
      />
    </>
  );
}
