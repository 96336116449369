import { getErrorDescription } from "../utils/data";
import {
  blankUserPermissions,
  computeUserPermissions,
  Message,
  UserPermissions,
} from "./login.model";
import {
  PersistActions,
  persistState,
  PersistStateAction,
  requestPersist,
  RestoreStateAction,
} from "./persist";
import { ApiState, COMPLETED, FAILED, Id, STARTED } from "./types";
import { blankUser, User, UserPreferences } from "./users.model";

interface LoginStore {
  isLoggedIn: boolean;
  user: User;
  accessToken: string;
  loginState?: ApiState;
  logoutState?: ApiState;
  creatingUser: User;
  createState?: ApiState;
  newUserToken?: string;
  initialiseAccountState?: ApiState;
  resendVerificationState?: ApiState;
  verifyTokenState?: ApiState;
  verifyingToken?: string;
  requestPasswordResetState?: ApiState;
  resettingEmail?: string;
  passwordResetState?: ApiState;
  reauthenticateState?: ApiState;
  patchUserState?: ApiState;
  requestEmailChangeState?: ApiState;
  requestPasswordChangeState?: ApiState;
  patchPreferencesState?: ApiState;

  loginReturnTo: string;
  permissions: UserPermissions;
  messages: Message[];
}

const initialState: LoginStore = {
  isLoggedIn: false,
  accessToken: "",
  user: { ...blankUser },
  creatingUser: { ...blankUser },
  loginReturnTo: "/",
  permissions: { ...blankUserPermissions },
  messages: [],
};

const resetOnLoad: Partial<LoginStore> = {
  loginState: undefined,
  logoutState: undefined,
  createState: undefined,
  initialiseAccountState: undefined,
  resendVerificationState: undefined,
  verifyTokenState: undefined,
  requestPasswordResetState: undefined,
  passwordResetState: undefined,
};

// eslint-disable-next-line no-shadow
export enum LoginActions {
  LOGIN_STARTED = "login.LOGIN_STARTED",
  LOGIN_FAILED = "login.LOGIN_FAILED",
  LOGIN_COMPLETED = "login.LOGIN_COMPLETED",
  LOGOUT_STARTED = "login.LOGOUT_STARTED",
  LOGOUT_FAILED = "login.LOGOUT_FAILED",
  LOGOUT_COMPLETED = "login.LOGOUT_COMPLETED",
  CREATE_STARTED = "login.CREATE_STARTED",
  CREATE_COMPLETED = "login.CREATE_COMPLETED",
  CREATE_FAILED = "login.CREATE_FAILED",
  SET_NEW_USER_TOKEN = "login.SET_NEW_USER_TOKEN",
  PATCH_CREATING_USER = "login.PATCH_CREATING_USER",
  INITIALISE_ACCOUNT_STARTED = "login.INITIALISE_ACCOUNT_STARTED",
  INITIALISE_ACCOUNT_COMPLETED = "login.INITIALISE_ACCOUNT_COMPLETED",
  INITIALISE_ACCOUNT_FAILED = "login.INITIALISE_ACCOUNT_FAILED",
  RESEND_VERIFICATION_STARTED = "login.RESEND_VERIFICATION_STARTED",
  RESEND_VERIFICATION_COMPLETED = "login.RESEND_VERIFICATION_COMPLETED",
  RESEND_VERIFICATION_FAILED = "login.RESEND_VERIFICATION_FAILED",
  VERIFY_TOKEN_STARTED = "login.VERIFY_TOKEN_STARTED",
  VERIFY_TOKEN_COMPLETED = "login.VERIFY_TOKEN_COMPLETED",
  VERIFY_TOKEN_FAILED = "login.VERIFY_TOKEN_FAILED",
  REQUEST_PASSWORD_RESET_STARTED = "login.REQUEST_PASSWORD_RESET_STARTED",
  REQUEST_PASSWORD_RESET_COMPLETED = "login.REQUEST_PASSWORD_RESET_COMPLETED",
  REQUEST_PASSWORD_RESET_FAILED = "login.REQUEST_PASSWORD_RESET_FAILED",
  PASSWORD_RESET_STARTED = "login.PASSWORD_RESET_STARTED",
  PASSWORD_RESET_COMPLETED = "login.PASSWORD_RESET_COMPLETED",
  PASSWORD_RESET_FAILED = "login.PASSWORD_RESET_FAILED",
  REAUTHENTICATE_STARTED = "login.REAUTHENTICATE_STARTED",
  REAUTHENTICATE_COMPLETED = "login.REAUTHENTICATE_COMPLETED",
  REAUTHENTICATE_FAILED = "login.REAUTHENTICATE_FAILED",
  QUEUE_MESSAGE = "login.QUEUE_MESSAGE",
  DEQUEUE_MESSAGE = "login.DEQUEUE_MESSAGE",
  SET_FAMILY_ID = "login.SET_FAMILY_ID",
  PATCH_USER_STARTED = "login.PATCH_USER_STARTED",
  PATCH_USER_COMPLETED = "login.PATCH_USER_COMPLETED",
  PATCH_USER_FAILED = "login.PATCH_USER_FAILED",
  REQUEST_EMAIL_CHANGE_STARTED = "login.REQUEST_EMAIL_CHANGE_STARTED",
  REQUEST_EMAIL_CHANGE_COMPLETED = "login.REQUEST_EMAIL_CHANGE_COMPLETED",
  REQUEST_EMAIL_CHANGE_FAILED = "login.REQUEST_EMAIL_CHANGE_FAILED",
  REQUEST_PASSWORD_CHANGE_STARTED = "login.REQUEST_PASSWORD_CHANGE_STARTED",
  REQUEST_PASSWORD_CHANGE_COMPLETED = "login.REQUEST_PASSWORD_CHANGE_COMPLETED",
  REQUEST_PASSWORD_CHANGE_FAILED = "login.REQUEST_PASSWORD_CHANGE_FAILED",
  PATCH_PREFERENCES_STARTED = "login.PATCH_PREFERENCES_STARTED",
  PATCH_PREFERENCES_COMPLETED = "login.PATCH_PREFERENCES_COMPLETED",
  PATCH_PREFERENCES_FAILED = "login.PATCH_PREFERENCES_FAILED",
}

export interface LoginStartedLoginAction {
  type: LoginActions.LOGIN_STARTED;
}

export interface LoginFailedLoginAction {
  type: LoginActions.LOGIN_FAILED;
  error: unknown;
}

export interface LoginCompletedLoginAction {
  type: LoginActions.LOGIN_COMPLETED;
  user: User;
  accessToken: string;
}

export interface LogoutStartedLoginAction {
  type: LoginActions.LOGOUT_STARTED;
}

export interface LogoutFailedLoginAction {
  type: LoginActions.LOGOUT_FAILED;
  error: unknown;
}

export interface LogoutCompletedLoginAction {
  type: LoginActions.LOGOUT_COMPLETED;
}

export interface CreateStartedLoginAction {
  type: LoginActions.CREATE_STARTED;
  email: string;
}

export interface CreateCompletedLoginAction {
  type: LoginActions.CREATE_COMPLETED;
}

export interface CreateFailedLoginAction {
  type: LoginActions.CREATE_FAILED;
  error: unknown;
}

export interface SetNewUserTokenLoginAction {
  type: LoginActions.SET_NEW_USER_TOKEN;
  token: string;
}

export interface PatchCreatingUserLoginAction {
  type: LoginActions.PATCH_CREATING_USER;
  patch: Partial<User>;
}

export interface InitialiseAccountStartedLoginAction {
  type: LoginActions.INITIALISE_ACCOUNT_STARTED;
}

export interface InitialiseAccountCompletedLoginAction {
  type: LoginActions.INITIALISE_ACCOUNT_COMPLETED;
}

export interface InitialiseAccountFailedLoginAction {
  type: LoginActions.INITIALISE_ACCOUNT_FAILED;
  error: unknown;
}

export interface ResendVerificationStartedLoginAction {
  type: LoginActions.RESEND_VERIFICATION_STARTED;
  email: string;
}

export interface ResendVerificationCompletedLoginAction {
  type: LoginActions.RESEND_VERIFICATION_COMPLETED;
}

export interface ResendVerificationFailedLoginAction {
  type: LoginActions.RESEND_VERIFICATION_FAILED;
  error: unknown;
}

export interface VerifyTokenStartedLoginAction {
  type: LoginActions.VERIFY_TOKEN_STARTED;
  token: string;
}

export interface VerifyTokenCompletedLoginAction {
  type: LoginActions.VERIFY_TOKEN_COMPLETED;
}

export interface VerifyTokenFailedLoginAction {
  type: LoginActions.VERIFY_TOKEN_FAILED;
  error: unknown;
}

export interface RequestPasswordResetStartedLoginAction {
  type: LoginActions.REQUEST_PASSWORD_RESET_STARTED;
  email: string;
}

export interface RequestPasswordResetCompletedLoginAction {
  type: LoginActions.REQUEST_PASSWORD_RESET_COMPLETED;
}

export interface RequestPasswordResetFailedLoginAction {
  type: LoginActions.REQUEST_PASSWORD_RESET_FAILED;
  error: unknown;
}

export interface PasswordResetStartedLoginAction {
  type: LoginActions.PASSWORD_RESET_STARTED;
}

export interface PasswordResetCompletedLoginAction {
  type: LoginActions.PASSWORD_RESET_COMPLETED;
}

export interface PasswordResetFailedLoginAction {
  type: LoginActions.PASSWORD_RESET_FAILED;
  error: unknown;
}

export interface ReauthenticateStartedLoginAction {
  type: LoginActions.REAUTHENTICATE_STARTED;
}

export interface ReauthenticateCompletedLoginAction {
  type: LoginActions.REAUTHENTICATE_COMPLETED;
}

export interface ReauthenticateFailedLoginAction {
  type: LoginActions.REAUTHENTICATE_FAILED;
  error: unknown;
}

export interface QueueMessageLoginAction {
  type: LoginActions.QUEUE_MESSAGE;
  message: { message: string };
}

export interface DequeueMessageLoginAction {
  type: LoginActions.DEQUEUE_MESSAGE;
}

export interface SetFamilyIdLoginAction {
  type: LoginActions.SET_FAMILY_ID;
  familyId: Id;
}

export interface PatchUserStartedLoginAction {
  type: LoginActions.PATCH_USER_STARTED;
}

export interface PatchUserCompletedLoginAction {
  type: LoginActions.PATCH_USER_COMPLETED;
  updatedUser: User;
}

export interface PatchUserFailedLoginAction {
  type: LoginActions.PATCH_USER_FAILED;
  error: unknown;
}

export interface RequestEmailChangeStartedLoginAction {
  type: LoginActions.REQUEST_EMAIL_CHANGE_STARTED;
}

export interface RequestEmailChangeCompletedLoginAction {
  type: LoginActions.REQUEST_EMAIL_CHANGE_COMPLETED;
}

export interface RequestEmailChangeFailedLoginAction {
  type: LoginActions.REQUEST_EMAIL_CHANGE_FAILED;
  error: unknown;
}

export interface RequestPasswordChangeStartedLoginAction {
  type: LoginActions.REQUEST_PASSWORD_CHANGE_STARTED;
}

export interface RequestPasswordChangeCompletedLoginAction {
  type: LoginActions.REQUEST_PASSWORD_CHANGE_COMPLETED;
}

export interface RequestPasswordChangeFailedLoginAction {
  type: LoginActions.REQUEST_PASSWORD_CHANGE_FAILED;
  error: unknown;
}

export interface PatchPreferencesStartedLoginAction {
  type: LoginActions.PATCH_PREFERENCES_STARTED;
}

export interface PatchPreferencesCompletedLoginAction {
  type: LoginActions.PATCH_PREFERENCES_COMPLETED;
  preferences: UserPreferences;
}

export interface PatchPreferencesFailedLoginAction {
  type: LoginActions.PATCH_PREFERENCES_FAILED;
  error: unknown;
}

export type LoginAction =
  | LoginStartedLoginAction
  | LoginFailedLoginAction
  | LoginCompletedLoginAction
  | LogoutStartedLoginAction
  | LogoutFailedLoginAction
  | LogoutCompletedLoginAction
  | CreateStartedLoginAction
  | CreateFailedLoginAction
  | CreateCompletedLoginAction
  | SetNewUserTokenLoginAction
  | PatchCreatingUserLoginAction
  | InitialiseAccountStartedLoginAction
  | InitialiseAccountFailedLoginAction
  | InitialiseAccountCompletedLoginAction
  | ResendVerificationStartedLoginAction
  | ResendVerificationFailedLoginAction
  | ResendVerificationCompletedLoginAction
  | VerifyTokenStartedLoginAction
  | VerifyTokenFailedLoginAction
  | VerifyTokenCompletedLoginAction
  | RequestPasswordResetStartedLoginAction
  | RequestPasswordResetFailedLoginAction
  | RequestPasswordResetCompletedLoginAction
  | PasswordResetStartedLoginAction
  | PasswordResetFailedLoginAction
  | PasswordResetCompletedLoginAction
  | ReauthenticateStartedLoginAction
  | ReauthenticateFailedLoginAction
  | ReauthenticateCompletedLoginAction
  | QueueMessageLoginAction
  | DequeueMessageLoginAction
  | PatchUserStartedLoginAction
  | PatchUserFailedLoginAction
  | PatchUserCompletedLoginAction
  | RequestEmailChangeStartedLoginAction
  | RequestEmailChangeFailedLoginAction
  | RequestEmailChangeCompletedLoginAction
  | RequestPasswordChangeStartedLoginAction
  | RequestPasswordChangeFailedLoginAction
  | RequestPasswordChangeCompletedLoginAction
  | PatchPreferencesStartedLoginAction
  | PatchPreferencesFailedLoginAction
  | PatchPreferencesCompletedLoginAction
  | SetFamilyIdLoginAction;

export default function login(
  state = initialState,
  action: LoginAction | RestoreStateAction | PersistStateAction
): LoginStore {
  switch (action.type) {
    case PersistActions.RESTORE_STATE:
      return {
        ...state,
        ...action.restore.login,
        permissions: {
          ...state.permissions,
          ...(action.restore.login?.permissions || {}),
        },
        ...resetOnLoad,
      };

    case PersistActions.PERSIST_STATE:
      persistState({
        login: {
          ...state,
        },
      });
      return state;

    case LoginActions.LOGIN_STARTED:
      return { ...state, loginState: STARTED };
    case LoginActions.LOGIN_FAILED:
      return {
        ...state,
        loginState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.LOGIN_COMPLETED:
      requestPersist();
      return {
        ...state,
        user: action.user,
        accessToken: action.accessToken,
        isLoggedIn: true,
        loginState: COMPLETED,
        permissions: computeUserPermissions(action.user),
      };

    case LoginActions.LOGOUT_STARTED:
      return { ...state, logoutState: STARTED };
    case LoginActions.LOGOUT_FAILED:
      return {
        ...state,
        logoutState: FAILED(getErrorDescription(action.error)),
      };

    case LoginActions.LOGOUT_COMPLETED:
      requestPersist();
      return { ...initialState, logoutState: COMPLETED };

    case LoginActions.CREATE_STARTED:
      return {
        ...state,
        createState: STARTED,
        creatingUser: { ...blankUser, email: action.email },
      };
    case LoginActions.CREATE_FAILED:
      return {
        ...state,
        createState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.CREATE_COMPLETED:
      return { ...state, createState: COMPLETED };

    case LoginActions.SET_NEW_USER_TOKEN:
      return { ...state, newUserToken: action.token };

    case LoginActions.PATCH_CREATING_USER:
      return {
        ...state,
        creatingUser: { ...state.creatingUser, ...action.patch },
      };

    case LoginActions.INITIALISE_ACCOUNT_STARTED:
      return { ...state, initialiseAccountState: STARTED };
    case LoginActions.INITIALISE_ACCOUNT_FAILED:
      return {
        ...state,
        initialiseAccountState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.INITIALISE_ACCOUNT_COMPLETED:
      requestPersist();
      return { ...state, initialiseAccountState: COMPLETED };

    case LoginActions.RESEND_VERIFICATION_STARTED:
      return {
        ...state,
        resendVerificationState: STARTED,
        creatingUser: { ...blankUser, email: action.email },
      };
    case LoginActions.RESEND_VERIFICATION_FAILED:
      return {
        ...state,
        resendVerificationState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.RESEND_VERIFICATION_COMPLETED:
      return { ...state, resendVerificationState: COMPLETED };

    case LoginActions.VERIFY_TOKEN_STARTED:
      return {
        ...state,
        verifyTokenState: STARTED,
        verifyingToken: action.token,
      };
    case LoginActions.VERIFY_TOKEN_FAILED:
      return {
        ...state,
        verifyTokenState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.VERIFY_TOKEN_COMPLETED:
      return { ...state, verifyTokenState: COMPLETED };

    case LoginActions.REQUEST_PASSWORD_RESET_STARTED:
      return {
        ...state,
        requestPasswordResetState: STARTED,
        resettingEmail: action.email,
      };
    case LoginActions.REQUEST_PASSWORD_RESET_FAILED:
      return {
        ...state,
        requestPasswordResetState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.REQUEST_PASSWORD_RESET_COMPLETED:
      return { ...state, requestPasswordResetState: COMPLETED };

    case LoginActions.PASSWORD_RESET_STARTED:
      return {
        ...state,
        passwordResetState: STARTED,
      };
    case LoginActions.PASSWORD_RESET_FAILED:
      return {
        ...state,
        passwordResetState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.PASSWORD_RESET_COMPLETED:
      return { ...state, passwordResetState: COMPLETED };

    case LoginActions.QUEUE_MESSAGE:
      return {
        ...state,
        messages: state.messages.concat(action.message),
      };

    case LoginActions.DEQUEUE_MESSAGE:
      return {
        ...state,
        messages: state.messages.slice(1),
      };

    case LoginActions.REAUTHENTICATE_STARTED:
      return { ...state, reauthenticateState: STARTED };
    case LoginActions.REAUTHENTICATE_FAILED:
      return {
        ...state,
        reauthenticateState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.REAUTHENTICATE_COMPLETED:
      return { ...state, reauthenticateState: COMPLETED };

    case LoginActions.SET_FAMILY_ID:
      requestPersist();
      return {
        ...state,
        user: {
          ...state.user,
          familyId: action.familyId,
        },
      };

    case LoginActions.PATCH_USER_STARTED:
      return { ...state, patchUserState: STARTED };
    case LoginActions.PATCH_USER_FAILED:
      return {
        ...state,
        patchUserState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.PATCH_USER_COMPLETED:
      return {
        ...state,
        patchUserState: COMPLETED,
        user: { ...state.user, ...action.updatedUser },
      };

    case LoginActions.REQUEST_EMAIL_CHANGE_STARTED:
      return { ...state, requestEmailChangeState: STARTED };
    case LoginActions.REQUEST_EMAIL_CHANGE_FAILED:
      return {
        ...state,
        requestEmailChangeState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.REQUEST_EMAIL_CHANGE_COMPLETED:
      return {
        ...state,
        requestEmailChangeState: COMPLETED,
      };

    case LoginActions.REQUEST_PASSWORD_CHANGE_STARTED:
      return { ...state, requestPasswordChangeState: STARTED };
    case LoginActions.REQUEST_PASSWORD_CHANGE_FAILED:
      return {
        ...state,
        requestPasswordChangeState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.REQUEST_PASSWORD_CHANGE_COMPLETED:
      return {
        ...state,
        requestPasswordChangeState: COMPLETED,
      };

    case LoginActions.PATCH_PREFERENCES_STARTED:
      return { ...state, patchPreferencesState: STARTED };
    case LoginActions.PATCH_PREFERENCES_FAILED:
      return {
        ...state,
        patchPreferencesState: FAILED(getErrorDescription(action.error)),
      };
    case LoginActions.PATCH_PREFERENCES_COMPLETED:
      return {
        ...state,
        patchPreferencesState: COMPLETED,
        user: {
          ...state.user,
          preferences: action.preferences,
        },
      };

    default:
      return state;
  }
}
