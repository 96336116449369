import React, { ReactNode, CSSProperties } from "react";
import { CardHeader } from "reactstrap";

export default function CardHeaderTitle({
  children,
  className,
  style,
}: {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}): JSX.Element {
  return (
    <CardHeader className={className} style={style}>
      <h5 className="mb-0">{children}</h5>
    </CardHeader>
  );
}
