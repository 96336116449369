import { Id, ModelBase } from "./types";

export interface FamilyUser extends ModelBase {
  firstName: string;
  lastName: string;
  role: "" | "child";
  organisation_members: {
    id: Id;
  };
}

export interface Family extends ModelBase {
  name: string;
  users: FamilyUser[];
}

export const blankFamily: Family = {
  id: 0,
  name: "",
  users: [],
};
