import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  ComponentType,
  MouseEvent,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Button,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import config from "../config";
import logo from "../media/logo.png";
import { LoginOrRegisterForm } from "../screens/LoginOrRegisterScreen";
import basketActions from "../state/basket.actions";
import { useFetchBasket } from "../state/basket.api";
import { BasketLine } from "../state/basket.model";
import { useCombinedStore } from "../state/combinedStore";
import loginActions from "../state/login.actions";
import { OrganisationMemberPermissions } from "../state/organisationMembers.model";
import { formatPrice, isZero } from "../utils/decimal";
import BasketLineRow, { BasketLineRowProps } from "./BasketLineRow";

export interface Screen {
  title?: string;
  path: string;
  component: ComponentType;
  permission?: keyof OrganisationMemberPermissions | "superuser";
}

interface Props {
  screens: Screen[];
}

function ScreenLink({
  to,
  children,
  dropdownItem,
}: {
  to: string;
  children: ReactNode;
  dropdownItem?: boolean;
}) {
  return dropdownItem ? (
    <DropdownItem exact activeClassName="active" tag={RouterNavLink} to={to}>
      {children}
    </DropdownItem>
  ) : (
    <NavItem>
      <NavLink exact activeClassName="active" tag={RouterNavLink} to={to}>
        {children}
      </NavLink>
    </NavItem>
  );
}

export default function Navigation({ screens }: Props): JSX.Element {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const isLoggedIn = useCombinedStore((store) => store.login.isLoggedIn);
  const hasFamily = useCombinedStore((store) => store.login.user.familyId)
    ? true
    : false;
  const permissions = useCombinedStore((store) => store.login.permissions);

  const basket = useFetchBasket(config.ORGANISATION_ID);

  const dispatch = useDispatch();

  const onDeleteBasketLine = useCallback(
    (line: BasketLine) => {
      dispatch(basketActions.removeBasketLine(config.ORGANISATION_ID, line.id));
    },
    [dispatch]
  );

  const [everRequestedLogin, setEverRequestedLogin] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const onLoginModalToggle = useCallback(
    () => setShowLoginModal((value) => !value),
    []
  );

  const onLogOutPressed = useCallback(() => dispatch(loginActions.logOut()), [
    dispatch,
  ]);
  const onLogInPressed = useCallback(() => {
    setEverRequestedLogin(true);
    setShowLoginModal(true);
  }, []);

  const onLoginButtonClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setEverRequestedLogin(true);
      setShowLoginModal(true);
    },
    []
  );

  const hasPermission = useCallback(
    (
      permission?: keyof OrganisationMemberPermissions | "superuser"
    ): boolean => {
      if (!permission) {
        return true;
      }

      if (permission === "superuser") {
        return permissions.superuser;
      }

      return permissions.anyPermission[permission] ? true : false;
    },
    [permissions]
  );

  const toggleDropdown = useCallback(
    () => setDropdownOpen((value) => !value),
    []
  );

  return (
    <>
      <nav className="navbar-wrapper bg-white xsticky-top">
        <Container>
          <Row>
            <div className="navbar-full-width-col">
              <Navbar color="white" light expand="sm">
                <NavbarBrand to="/" tag={RouterNavLink}>
                  <img src={logo} height={64} alt="Logo" />
                </NavbarBrand>
                <NavbarToggler onClick={toggleDropdown} />
                <Collapse isOpen={isDropdownOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    {screens.map((screen) =>
                      screen.path.indexOf(":") < 0 &&
                      screen.permission === undefined &&
                      screen.title ? (
                        <ScreenLink key={screen.path} to={screen.path}>
                          {screen.title}
                        </ScreenLink>
                      ) : null
                    )}

                    {permissions.anyManager && (
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          Admin
                        </DropdownToggle>
                        <DropdownMenu right>
                          {screens.map((screen) =>
                            screen.path.indexOf(":") < 0 &&
                            screen.permission &&
                            hasPermission(screen.permission) &&
                            screen.title ? (
                              <ScreenLink
                                dropdownItem
                                key={screen.path}
                                to={screen.path}
                              >
                                {screen.title}
                              </ScreenLink>
                            ) : null
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}

                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Your Account
                      </DropdownToggle>
                      <DropdownMenu right>
                        {hasFamily ? (
                          <>
                            <DropdownItem tag={RouterNavLink} to="/family">
                              Your Family
                            </DropdownItem>
                            <DropdownItem divider />
                          </>
                        ) : null}
                        {isLoggedIn ? (
                          <DropdownItem onClick={onLogOutPressed}>
                            Sign Out
                          </DropdownItem>
                        ) : (
                          <DropdownItem onClick={onLogInPressed}>
                            Sign In
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown
                      nav
                      inNavbar
                      active={basket.basketLines.length > 0}
                    >
                      <DropdownToggle nav>
                        <FontAwesomeIcon size="lg" icon={faShoppingCart} />
                        {isZero(basket.basketTotal) ? null : (
                          <span className="display-inline-block ml-1">
                            {formatPrice(basket.basketTotal)}
                          </span>
                        )}
                      </DropdownToggle>
                      <DropdownMenu right>
                        {basket.basketLines.length > 0 ? (
                          <>
                            {basket.basketLines.map((line, _index) => {
                              return (
                                <DropdownBasketLine
                                  key={line.id}
                                  text={line.description}
                                  detail={line.detail}
                                  price={line.price}
                                  onDelete={() => onDeleteBasketLine(line)}
                                  outOfStock={line.outOfStock}
                                />
                              );
                            })}
                            <DropdownItem divider />
                            <DropdownBasketLine
                              text="Total"
                              price={basket.basketTotal}
                              bold
                            />
                            <DropdownItem divider />
                            <DropdownItem tag={RouterNavLink} to="/checkout">
                              Check Out
                            </DropdownItem>
                          </>
                        ) : (
                          <DropdownItem
                            tag="div"
                            onClick={undefined}
                            className="bg-transparent py-3"
                          >
                            <strong className="text-center text-muted mb-0">
                              Your basket is empty
                            </strong>
                            {!isLoggedIn && (
                              <div className="text-center mt-2">
                                <Button
                                  onClick={onLoginButtonClicked}
                                  color="primary"
                                >
                                  Sign In
                                </Button>
                              </div>
                            )}
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
          </Row>
        </Container>
      </nav>
      {everRequestedLogin && (
        <Modal
          isOpen={showLoginModal}
          centered
          className="no-background"
          unmountOnClose
        >
          <LoginOrRegisterForm
            onCancel={onLoginModalToggle}
            onLoggedIn={onLoginModalToggle}
            mode="login"
          />
        </Modal>
      )}
    </>
  );
}

function DropdownBasketLine(props: BasketLineRowProps) {
  return (
    <DropdownItem tag="div" onClick={undefined} className="bg-transparent">
      <BasketLineRow {...props} />
    </DropdownItem>
  );
}
