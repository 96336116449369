import React from "react";
import { StyledSelect } from "../components/StyledSelect";
import { OrderState } from "../state/orders.model";
import { getSelectOptionByValue } from "../utils/reactSelect";

const STATE_OPTIONS = [
  { value: "", label: "Created" },
  { value: "paid", label: "Paid" },
  { value: "failed", label: "Failed" },
  { value: "cancelled", label: "Cancelled" },
];

interface OrderStateSelectProps {
  id?: string;
  value?: OrderState;
  onChange: (newValue: OrderState | undefined) => void;
  [key: string]: unknown;
}

export default function OrderStateSelect({
  id,
  value,
  onChange,
  ...props
}: OrderStateSelectProps): JSX.Element {
  return (
    <StyledSelect
      id={id}
      options={STATE_OPTIONS}
      value={getSelectOptionByValue(STATE_OPTIONS, value || "")}
      onChange={(newValue) =>
        onChange(
          (newValue && "value" in newValue ? newValue.value : undefined) as
            | OrderState
            | undefined
        )
      }
      isClearable
      {...props}
    />
  );
}
