import { OrganisationMember } from "./organisationMembers.model";
import { Id, ModelBase } from "./types";

export interface UserPreferences {
  tutorials: Record<string, boolean>;
}
export interface UserOrganisation {
  name: string;
  organisation_members: OrganisationMember;
}

/// Anything with a ? was added in a later API version
export interface User extends ModelBase {
  email: string;
  role: string;
  firstName: string;
  lastName: string;
  password?: string;
  isVerified: boolean;
  preferences?: UserPreferences;
  organisations: UserOrganisation[];
  familyId?: Id;
}

export type UserPick = Pick<User, "firstName" | "lastName" | "id">;

export const blankUser: User = {
  id: 0,
  email: "",
  role: "",
  firstName: "",
  lastName: "",
  isVerified: false,
  organisations: [],
};

export function formatUser(user?: {
  id: Id;
  firstName: string;
  lastName: string;
}): string {
  return (
    `${user?.firstName || ""} ${user?.lastName || ""}`.trim() ||
    `${user?.id || ""}`
  );
}
