import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import {
  blankProductAttributeOption,
  ProductAttributeOption,
} from "./productAttributeOptions.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchProductAttributeOptionsResults extends UseFetchManyResults {
  productAttributeOptions: ProductAttributeOption[];
  numberOfProductAttributeOptions: number;
  totalNumberOfProductAttributeOptions: number;
}

interface UseFetchProductAttributeOptionResults extends UseFetchOneResults {
  productAttributeOption: ProductAttributeOption;
}

const keys: FetcherKeys<
  UseFetchProductAttributeOptionsResults,
  UseFetchProductAttributeOptionResults
> = {
  service: "product-attribute-options",
  totalNumber: "totalNumberOfProductAttributeOptions",
  numberOf: "numberOfProductAttributeOptions",
  records: "productAttributeOptions",
  record: "productAttributeOption",
};

export async function fetchProductAttributeOptions(
  props: FetchManyProps
): Promise<FetchManyResults<ProductAttributeOption>> {
  return fetchMany<ProductAttributeOption>(props, keys.service);
}

export function useFetchProductAttributeOptions(
  props: FetchManyProps
): UseFetchProductAttributeOptionsResults {
  return useFetchMany(props, fetchProductAttributeOptions, keys);
}

export async function fetchProductAttributeOption(
  props: FetchOneProps
): Promise<ProductAttributeOption> {
  return fetchOne<
    ProductAttributeOption,
    UseFetchProductAttributeOptionResults
  >(props, blankProductAttributeOption, keys);
}

export function useFetchProductAttributeOption(
  props: FetchOneProps
): UseFetchProductAttributeOptionResults {
  return useFetchOne(
    props,
    fetchProductAttributeOption,
    blankProductAttributeOption,
    keys
  );
}

async function saveProductAttributeOption(
  original: ProductAttributeOption,
  changes: Partial<ProductAttributeOption>,
  $role: true | false | string
): Promise<ProductAttributeOption> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteProductAttributeOption(
  original: ProductAttributeOption,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveProductAttributeOption = makeSaver(
  saveProductAttributeOption,
  true
);
export const adminDeleteProductAttributeOption = makeDeleter(
  deleteProductAttributeOption,
  true
);
export const userSaveProductAttributeOption = makeSaver(
  saveProductAttributeOption,
  false
);
export const userDeleteProductAttributeOption = makeDeleter(
  deleteProductAttributeOption,
  false
);
