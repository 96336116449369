import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, CustomInput, Form, FormGroup, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import AttributeValueFields from "../selects/AttributeValueFields";
import ProductSelect from "../selects/ProductSelect";
import {
  adminDeleteProductProperty,
  adminSaveProductProperty,
  useFetchProductProperty,
} from "../state/productProperties.api";
import { ProductProperty } from "../state/productProperties.model";
import { useLocationId } from "../utils/queryStringState";

interface ParamProps {
  productpropertyid: string;
}

export default function ProductPropertyDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.productpropertyid);
  const { productProperty, fetching, error } = useFetchProductProperty({
    id,
    admin: true,
  });

  return (
    <DetailScreen<ProductProperty>
      defaults={
        (location.state as { defaults?: Partial<ProductProperty> })?.defaults
      }
      onIdChange={setId}
      fetching={fetching}
      error={error}
      original={productProperty}
      isNew={match.params.productpropertyid === "new"}
      route={[{ "Product Properties": "/admin/product-properties" }]}
      renderForm={ProductPropertyDetailForm}
      onSave={adminSaveProductProperty}
      onDelete={adminDeleteProductProperty}
    />
  );
}

function ProductPropertyDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<ProductProperty>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="productId">Product</Label>
            <ProductSelect
              admin
              isDisabled={readOnly}
              id="productId"
              value={getField("productId")}
              onChange={(newValue) =>
                changeFieldValue("productId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AttributeValueFields
            readOnly={readOnly}
            productAttributeId={getField("productAttributeId")}
            onProductAttributeIdChange={(newValue) =>
              changeFieldValue("productAttributeId", null, newValue)
            }
            productAttributeOptionId={getField("productAttributeOptionId")}
            onProductAttributeOptionIdChange={(newValue) =>
              changeFieldValue("productAttributeOptionId", null, newValue)
            }
            value={getField("value")}
            onValueChange={(newValue) =>
              changeFieldValue("value", null, newValue)
            }
          />
        </Col>
        <Col md={6}>
          <FormGroup check>
            <Label>Options</Label>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="variesPerSku"
              id="variesPerSku"
              checked={getField("variesPerSku")}
              onChange={(event) => changeField("variesPerSku", event)}
              label="Varies per SKU"
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<ProductProperty>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
