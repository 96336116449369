import React from "react";
import { RouteComponentProps } from "react-router";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import ProductAttributeSelect from "../selects/ProductAttributeSelect";
import {
  adminDeleteProductAttributeOption,
  adminSaveProductAttributeOption,
  useFetchProductAttributeOption,
} from "../state/productAttributeOptions.api";
import { ProductAttributeOption } from "../state/productAttributeOptions.model";
import { useLocationId } from "../utils/queryStringState";
import ProductAttributeOptionPropertyListPage from "./ProductAttributeOptionPropertyListPage";

interface ParamProps {
  productattributeoptionid: string;
}

export default function ProductAttributeOptionDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.productattributeoptionid);
  const {
    productAttributeOption,
    fetching,
    error,
  } = useFetchProductAttributeOption({ id, admin: true });

  return (
    <>
      <DetailScreen<ProductAttributeOption>
        defaults={
          (location.state as { defaults?: Partial<ProductAttributeOption> })
            ?.defaults
        }
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={productAttributeOption}
        isNew={match.params.productattributeoptionid === "new"}
        route={[
          { "Product Attribute Options": "/admin/product-attribute-options" },
        ]}
        renderForm={ProductAttributeOptionDetailForm}
        onSave={adminSaveProductAttributeOption}
        onDelete={adminDeleteProductAttributeOption}
      />

      {!!id && (
        <ProductAttributeOptionPropertyListPage
          parentProductAttributeOptionId={id}
        />
      )}
    </>
  );
}

function ProductAttributeOptionDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<ProductAttributeOption>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="productAttributeId">Product Attribute</Label>
            <ProductAttributeSelect
              admin
              isDisabled={readOnly}
              id="productAttributeId"
              value={getField("productAttributeId")}
              onChange={(newValue) =>
                changeFieldValue("productAttributeId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="title"
              id="title"
              value={getField("title")}
              onChange={(event) => changeField("title", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6} className="pb-2">
          <Label>Options</Label>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="isHidden"
              id="isHidden"
              checked={getField("isHidden")}
              onChange={(event) => changeField("isHidden", event)}
              label="Hidden (won't be shown to customers)"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="imageUrl">Image URL</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="imageUrl"
              id="imageUrl"
              value={getField("imageUrl")}
              onChange={(event) => changeField("imageUrl", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<ProductAttributeOption>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
