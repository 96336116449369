import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import {
  blankProductSkuProperty,
  ProductSkuProperty,
} from "./productSkuProperties.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchProductSkuPropertiesResults extends UseFetchManyResults {
  productSkuProperties: ProductSkuProperty[];
  numberOfProductSkuProperties: number;
  totalNumberOfProductSkuProperties: number;
}

interface UseFetchProductSkuPropertyResults extends UseFetchOneResults {
  productSkuProperty: ProductSkuProperty;
}

const keys: FetcherKeys<
  UseFetchProductSkuPropertiesResults,
  UseFetchProductSkuPropertyResults
> = {
  service: "product-sku-properties",
  totalNumber: "totalNumberOfProductSkuProperties",
  numberOf: "numberOfProductSkuProperties",
  records: "productSkuProperties",
  record: "productSkuProperty",
};

export async function fetchProductSkuProperties(
  props: FetchManyProps
): Promise<FetchManyResults<ProductSkuProperty>> {
  return fetchMany<ProductSkuProperty>(props, keys.service);
}

export function useFetchProductSkuProperties(
  props: FetchManyProps
): UseFetchProductSkuPropertiesResults {
  return useFetchMany(props, fetchProductSkuProperties, keys);
}

export async function fetchProductSkuProperty(
  props: FetchOneProps
): Promise<ProductSkuProperty> {
  return fetchOne<ProductSkuProperty, UseFetchProductSkuPropertyResults>(
    props,
    blankProductSkuProperty,
    keys
  );
}

export function useFetchProductSkuProperty(
  props: FetchOneProps
): UseFetchProductSkuPropertyResults {
  return useFetchOne(
    props,
    fetchProductSkuProperty,
    blankProductSkuProperty,
    keys
  );
}

async function saveProductSkuProperty(
  original: ProductSkuProperty,
  changes: Partial<ProductSkuProperty>,
  $role: true | false | string
): Promise<ProductSkuProperty> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteProductSkuProperty(
  original: ProductSkuProperty,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveProductSkuProperty = makeSaver(
  saveProductSkuProperty,
  true
);
export const adminDeleteProductSkuProperty = makeDeleter(
  deleteProductSkuProperty,
  true
);
export const userSaveProductSkuProperty = makeSaver(
  saveProductSkuProperty,
  false
);
export const userDeleteProductSkuProperty = makeDeleter(
  deleteProductSkuProperty,
  false
);
