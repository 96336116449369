import format from "date-fns/format";
import map from "lodash/map";
import { spaceship } from "./data";
import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";
import config from "../config";

export function groupByDate<T>(
  array: T[],
  key: keyof T
): { date: string; group: T[] }[] {
  const dates: { [date: string]: T[] } = {};

  for (const element of array) {
    const value = (element[key] as unknown) as Date | undefined;
    if (!value) {
      continue;
    }
    const date = format(value, "yyyyMMdd");
    if (!dates[date]) {
      dates[date] = [];
    }
    dates[date].push(element);
  }

  return map(dates, (group, date) => ({
    date,
    group: group.sort((a, b) => +a[key] - +b[key]),
  })).sort((a, b) => spaceship(a.date, b.date));
}

export function invalidDateToUndefined(
  date: Date | undefined | null
): Date | undefined {
  return date && isValid(date) ? date : undefined;
}

export function invalidDateToNumberOrUndefined(
  date: Date | number | undefined | null
): number | undefined {
  return date && isValid(date) ? +date : undefined;
}

export function parseApiDate(apiDate: Date | string | number): Date {
  if (typeof apiDate === "number") {
    return new Date(apiDate);
  }

  if (typeof apiDate === "object") {
    return apiDate;
  }

  return parseISO(apiDate);
}

export function formatApiDateTime(apiDate?: Date | string | number): string {
  if (!apiDate) {
    return "";
  }

  const parsed = parseApiDate(apiDate);
  if (!isValid(parsed)) {
    return "invalid";
  }

  return format(parsed, config.DATE_TIME_FORMAT);
}
