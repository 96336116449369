import { Id, ModelBase } from "./types";

export interface Customer extends ModelBase {
  userId?: Id;
  user?: {
    id: Id;
    firstName: string;
    lastName: string;
  };
  organisationId: Id;
  organisation?: {
    id: Id;
    name: string;
  };
  password?: string;
  isVerified: boolean;
}

export const blankCustomer: Customer = {
  id: 0,
  userId: undefined,
  organisationId: 0,
  isVerified: false,
};
