import {
  BasketLine,
  BasketWithOrder,
  DraftOrderLineProperty,
} from "./basket.model";
import { LoginAction, LoginActions } from "./login.reducer";
import { ApiState, COMPLETED, FAILED, STARTED } from "./types";

const initialState: {
  basketLines: BasketLine[];
  total: string;
  updateBasketState?: ApiState;
  missingUserPropertyIds: number[];
  userProperties: DraftOrderLineProperty[];
} = {
  basketLines: [],
  total: "0",
  missingUserPropertyIds: [],
  userProperties: [],
};

export type BasketStore = typeof initialState;

export enum BasketActions {
  STARTED_UPDATE = "BASKET_STARTED_UPDATE",
  COMPLETED_UPDATE = "BASKET_COMPLETED_UPDATE",
  FAILED_UPDATE = "BASKET_FAILED_UPDATE",
}

export interface StartedUpdateBasketAction {
  type: BasketActions.STARTED_UPDATE;
}
export interface CompletedUpdateBasketAction {
  type: BasketActions.COMPLETED_UPDATE;
  basket: BasketWithOrder;
}
export interface FailedUpdateBasketAction {
  type: BasketActions.FAILED_UPDATE;
  error: string;
}

export type BasketAction =
  | StartedUpdateBasketAction
  | CompletedUpdateBasketAction
  | FailedUpdateBasketAction;

const basket = (
  state = initialState,
  action: BasketAction | LoginAction
): BasketStore => {
  switch (action.type) {
    case LoginActions.LOGOUT_COMPLETED:
      return { ...initialState };

    case BasketActions.STARTED_UPDATE:
      return {
        ...state,
        updateBasketState: STARTED,
      };
    case BasketActions.COMPLETED_UPDATE: {
      const basket = action.basket;
      return {
        ...state,
        basketLines: basket.basketLines,
        total: basket.total,
        missingUserPropertyIds: basket.missingUserPropertyIds,
        userProperties: basket.userProperties,
        updateBasketState: COMPLETED,
      };
    }
    case BasketActions.FAILED_UPDATE:
      return {
        ...state,
        updateBasketState: FAILED(action.error),
      };

    default:
      return state;
  }
};

export default basket;
