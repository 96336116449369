import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import filter from "lodash/filter";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { LoadingOverlay } from "../components/LoadingOverlay";
import PageTitle from "../components/PageTitle";
import SlotCardFooter from "../components/SlotCardFooter";
import SlotSelector from "../components/SlotSelector";
import TeacherCard from "../components/TeacherCard";
import config from "../config";
import {
  extractTeachersFromProduct,
  RockleyProductAttributeId,
  SkuSlot,
  SlotProductSku,
  SlotProductSkuProperties,
  useFetchOneToOneLessonsProduct,
} from "../rockley";
import { useCombinedStore } from "../state/combinedStore";
import { fetchProductSkus } from "../state/productSkus.api";
import { equalIds } from "../state/types";
import { groupByDate } from "../utils/datetime";

function dateTimeFromSlotProductSkuProperties(
  props: SlotProductSkuProperties[]
): Date | undefined {
  for (const prop of props) {
    if (equalIds(prop.productAttributeId, RockleyProductAttributeId.DATETIME)) {
      const m = prop.value
        ? parse(prop.value, config.SERVER_DATE_TIME_FORMAT, 0)
        : undefined;
      return m && isValid(m) ? m : undefined;
    }
  }

  for (const prop of props) {
    const m = dateTimeFromSlotProductSkuProperties(
      prop.product_sku.product_sku_properties
    );
    if (m) {
      return m;
    }
  }

  return undefined;
}

function dateTimeFromSlotProductSku(sku: SlotProductSku): Date | undefined {
  return dateTimeFromSlotProductSkuProperties(sku.product_sku_properties);
}

function extractSlotsFromSlotProductSkus(
  productSkus: SlotProductSku[]
): SkuSlot[] {
  const slots: SkuSlot[] = [];

  for (const sku of productSkus) {
    const m = dateTimeFromSlotProductSku(sku);

    if (m) {
      slots.push({ dateTime: m, sku });
    }
  }

  return slots;
}

function filterOutPastSlots(slots: SlotProductSku[]): SlotProductSku[] {
  const now = Date.now();

  return filter(slots, (slot) => {
    const m = dateTimeFromSlotProductSku(slot);
    if (!m) {
      return false;
    }

    return +m >= now;
  });
}

export default function TeacherSlotSelectScreen({
  teacherid,
  title,
  locationQuery,
  renderInstructions,
}: {
  teacherid?: string;
  title: string;
  locationQuery?: Record<string, unknown>;
  renderInstructions: JSX.Element;
}): JSX.Element | null {
  const { product } = useFetchOneToOneLessonsProduct();

  const teacher = useMemo(() => {
    return extractTeachersFromProduct(
      product,
      teacherid ? +teacherid : undefined
    )[0];
  }, [product, teacherid]);

  const [fetchingProductSkus, setFetchingProductSkus] = useState(true);
  const [productSkus, setProductSkus] = useState<SlotProductSku[]>([]);

  useEffect(() => {
    if (!product.id) {
      return;
    }

    let cancelled = false;

    (async () => {
      const { records } = await fetchProductSkus({
        query: {
          productId: product.id,
          stock: { $gt: 0 },
          // These excludes mean we have to rely on the output of the whereProperty instead
          $exclude: [
            "product_skus_sku_properties",
            "product_skus_product_properties",
          ],
          $select: ["id", "productId"],
          $whereProperty: [
            { id: RockleyProductAttributeId.TEACHER, $eqOption: teacherid },
            {
              id: RockleyProductAttributeId.DATETIME,
              $gt: format(new Date(), "yyyy-MM-dd HH:mm"),
            },
            ...(locationQuery
              ? [
                  {
                    id: RockleyProductAttributeId.LOCATION,
                    ...locationQuery,
                  },
                ]
              : []),
          ],
          $limit: 300,
        },
      });

      return (records as unknown) as SlotProductSku[];
    })()
      .then((productSkus) => {
        if (!cancelled) {
          setProductSkus(productSkus);
          setFetchingProductSkus(false);
        }
      })
      .catch(() => {
        /* TODO */
      });

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.id, teacherid, Math.floor(Date.now() / 60000)]);

  const dates = useMemo(() => {
    if (fetchingProductSkus || !teacher?.id || !product.id) {
      return undefined;
    }

    return groupByDate(
      extractSlotsFromSlotProductSkus(filterOutPastSlots(productSkus)),
      "dateTime"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingProductSkus, teacher?.id, product.id, productSkus]);

  const updateBasketState = useCombinedStore(
    (store) => store.basket.updateBasketState
  );

  const [skusSelected, setSkusSelected] = useState(false);
  const [basketTotal, setBasketTotal] = useState("");
  const [fetching, setFetching] = useState(false);

  return (
    <Container>
      <PageTitle title={title} />
      <Row>
        <Col md={12}>
          <Card className="position-relative">
            <CardBody>
              <div
                style={{ width: "7rem", float: "right", marginBottom: "1rem" }}
              >
                {teacher && <TeacherCard teacher={teacher} size="sm" />}
              </div>
              <h1 className="fancy">Select Date and Time</h1>

              {renderInstructions}

              <SlotSelector
                dates={dates}
                fetching={fetchingProductSkus}
                onSkusSelectedChange={setSkusSelected}
                onBasketTotalChange={setBasketTotal}
                onFetchingChange={setFetching}
                renderNoSlotsMessage={
                  <h4>
                    Sorry, no slots are currently available for {teacher?.title}
                  </h4>
                }
              />
            </CardBody>
            <SlotCardFooter
              canCheckout={skusSelected}
              basketTotal={basketTotal}
            />
            {(updateBasketState === "started" ||
              fetchingProductSkus ||
              fetching) && <LoadingOverlay />}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
