import { ProductAttributeOptionProperty } from "./productAttributeOptionProperties.model";
import { ProductAttribute } from "./productAttributes.model";
import { Id, ModelBase } from "./types";

export type ProductAttributeOptionPick = Pick<
  ProductAttributeOption,
  "id" | "title"
>;

export interface ProductAttributeOption extends ModelBase {
  isHidden: boolean;
  productAttributeId: Id;
  product_attribute?: Pick<
    ProductAttribute,
    "id" | "title" | "product" | "organisation"
  > & {
    product_attribute_option_properties?: ProductAttributeOptionProperty[];
  };
  imageUrl?: string;
  title: string;
}

export const blankProductAttributeOption: ProductAttributeOption = {
  id: 0,
  isHidden: false,
  productAttributeId: 0,
  title: "",
};
