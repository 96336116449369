import { Product } from "./products.model";
import { ProductSkuProperty } from "./productSkuProperties.model";
import { Id, ModelBase } from "./types";

export type ProductSkuProductSkuProperty = Pick<
  ProductSkuProperty,
  | "id"
  | "productAttributeId"
  | "product_attribute"
  | "productAttributeOptionId"
  | "product_attribute_option"
  | "value"
>;

export type ProductSkuProduct = Pick<
  Product,
  "id" | "title" | "price" | "product_properties"
>;

export interface ProductSku extends ModelBase {
  stock: number;
  productId: Id;
  price?: string;
  product?: ProductSkuProduct;
  product_sku_properties?: ProductSkuProductSkuProperty[];
  isPublic: boolean;
  all_properties?: string; // comma separated list of all SKU properties, added for admin requests, and searchable
}

export const blankProductSku: ProductSku = {
  id: 0,
  stock: 0,
  productId: 0,
  isPublic: true,
};
