import { Id, ModelBase } from "./types";

export interface OrganisationMemberPermissions {
  manageCustomers: boolean;
  manageUsers: boolean;
  manageUploads: boolean;
  manageProducts: boolean;
  manageProductAttributes: boolean;
  manageOrders: boolean;
  managePayments: boolean;
  managePaymentMethods: boolean;
  manageCalendar: boolean;
}

export const ORGANISATION_MEMBERS_PERMISSIONS_KEYS: (keyof OrganisationMemberPermissions)[] = [
  "manageCustomers",
  "manageUsers",
  "manageUploads",
  "manageProducts",
  "manageProductAttributes",
  "manageOrders",
  "managePayments",
  "managePaymentMethods",
  "manageCalendar",
];

/// Anything with a ? was added in a later API version
export interface OrganisationMember
  extends OrganisationMemberPermissions,
    ModelBase {
  userId: Id;
  organisationId: Id;
  user?: {
    id: Id;
    firstName: string;
    lastName: string;
  };
  organisation?: {
    name: string;
  };
}

export const blankOrganisationMember: OrganisationMember = {
  id: 0,
  manageCustomers: false,
  manageUsers: false,
  manageUploads: false,
  manageProducts: false,
  manageProductAttributes: false,
  manageOrders: false,
  managePayments: false,
  managePaymentMethods: false,
  manageCalendar: false,
  userId: 0,
  organisationId: 0,
};
