import React from "react";
import { RouteComponentProps } from "react-router";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import ProductSelect from "../selects/ProductSelect";
import {
  adminDeleteProductSku,
  adminSaveProductSku,
  useFetchProductSku,
} from "../state/productSkus.api";
import { ProductSku } from "../state/productSkus.model";
import { useLocationId } from "../utils/queryStringState";
import ProductSkuPropertyListPage from "./ProductSkuPropertyListPage";

interface ParamProps {
  productskuid: string;
}

export default function ProductSkuDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.productskuid);
  const { productSku, fetching, error } = useFetchProductSku({
    id,
    admin: true,
  });

  return (
    <>
      <DetailScreen<ProductSku>
        defaults={
          (location.state as { defaults?: Partial<ProductSku> })?.defaults
        }
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={productSku}
        isNew={match.params.productskuid === "new"}
        route={[{ "Product SKUs": "/admin/product-skus" }]}
        renderForm={ProductSkuDetailForm}
        onSave={adminSaveProductSku}
        onDelete={adminDeleteProductSku}
      />

      {!!id && <ProductSkuPropertyListPage productSkuId={id} />}
    </>
  );
}

function ProductSkuDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<ProductSku>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="productId">Product</Label>
            {false ? (
              <Input
                readOnly={readOnly}
                type="text"
                name="productId"
                id="productId"
                value={getField("productId")}
                onChange={(event) => changeField("productId", event)}
              />
            ) : (
              <ProductSelect
                admin
                isDisabled={readOnly}
                id="productId"
                value={getField("productId")}
                onChange={(newValue) =>
                  changeFieldValue("productId", null, newValue)
                }
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="stock">Stock</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="stock"
              id="stock"
              value={getField("stock")}
              onChange={(event) => changeField("stock", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="price">
              Price (leave blank to pull-throgh from product)
            </Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="price"
              id="price"
              value={getField("price")}
              onChange={(event) => changeField("price", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="pb-2">
          <Label>Options</Label>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="isPublic"
              id="isPublic"
              checked={getField("isPublic")}
              onChange={(event) => changeField("isPublic", event)}
              label="Is Public"
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<ProductSku>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
