import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import OrderSelect from "../selects/OrderSelect";
import PaymentSelect from "../selects/PaymentSelect";
import {
  adminDeletePaymentAssignment,
  adminSavePaymentAssignment,
  useFetchPaymentAssignment,
} from "../state/paymentAssignments.api";
import { PaymentAssignment } from "../state/paymentAssignments.model";
import { useLocationId } from "../utils/queryStringState";

interface ParamProps {
  paymentassignmentid: string;
}

export default function PaymentAssignmentDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.paymentassignmentid);
  const { paymentAssignment, fetching, error } = useFetchPaymentAssignment({
    id,
    admin: true,
  });

  return (
    <DetailScreen<PaymentAssignment>
      defaults={
        (location.state as { defaults?: Partial<PaymentAssignment> })?.defaults
      }
      onIdChange={setId}
      fetching={fetching}
      error={error}
      original={paymentAssignment}
      isNew={match.params.paymentassignmentid === "new"}
      route={[{ "Payment Assignments": "/admin/payment-assignments" }]}
      renderForm={PaymentAssignmentDetailForm}
      onSave={adminSavePaymentAssignment}
      onDelete={adminDeletePaymentAssignment}
    />
  );
}

function PaymentAssignmentDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<PaymentAssignment>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="amount">Amount</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="amount"
              id="amount"
              value={getField("amount")}
              onChange={(event) => changeField("amount", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="paymentId">Payment</Label>
            <PaymentSelect
              admin
              isDisabled={readOnly}
              id="paymentId"
              value={getField("paymentId")}
              onChange={(newValue) =>
                changeFieldValue("paymentId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="orderId">Order</Label>
            <OrderSelect
              admin
              isDisabled={readOnly}
              id="orderId"
              value={getField("orderId")}
              onChange={(newValue) =>
                changeFieldValue("orderId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<PaymentAssignment>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
