import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { blankProduct, Product } from "./products.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchProductsResults extends UseFetchManyResults {
  products: Product[];
  numberOfProducts: number;
  totalNumberOfProducts: number;
}

interface UseFetchProductResults extends UseFetchOneResults {
  product: Product;
}

const keys: FetcherKeys<UseFetchProductsResults, UseFetchProductResults> = {
  service: "products",
  totalNumber: "totalNumberOfProducts",
  numberOf: "numberOfProducts",
  records: "products",
  record: "product",
};

export async function fetchProducts(
  props: FetchManyProps
): Promise<FetchManyResults<Product>> {
  return fetchMany<Product>(props, keys.service);
}

export function useFetchProducts(
  props: FetchManyProps
): UseFetchProductsResults {
  return useFetchMany(props, fetchProducts, keys);
}

export async function fetchProduct(props: FetchOneProps): Promise<Product> {
  return fetchOne<Product, UseFetchProductResults>(props, blankProduct, keys);
}

export function useFetchProduct(props: FetchOneProps): UseFetchProductResults {
  return useFetchOne(props, fetchProduct, blankProduct, keys);
}

async function saveProduct(
  original: Product,
  changes: Partial<Product>,
  $role: true | false | string
): Promise<Product> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteProduct(original: Product, $role: true | false | string) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveProduct = makeSaver(saveProduct, true);
export const adminDeleteProduct = makeDeleter(deleteProduct, true);
export const userSaveProduct = makeSaver(saveProduct, false);
export const userDeleteProduct = makeDeleter(deleteProduct, false);
