import { fetchOrderLine, fetchOrderLines } from "../state/orderLines.api";
import { blankOrderLine, OrderLine } from "../state/orderLines.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

const format = (item: OrderLine): string => `#${item.id}`;

export default function OrderLineSelect(
  props: GenericSelectProps<OrderLine>
): JSX.Element {
  return GenericSelect<OrderLine>(
    props,
    fetchOrderLine,
    fetchOrderLines,
    "order-lines",
    blankOrderLine,
    format
  );
}
