import React from "react";
import { RouteComponentProps } from "react-router";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import { StyledSelect } from "../components/StyledSelect";
import OrganisationSelect from "../selects/OrganisationSelect";
import ProductSelect from "../selects/ProductSelect";
import {
  adminDeleteProductAttribute,
  adminSaveProductAttribute,
  useFetchProductAttribute,
} from "../state/productAttributes.api";
import {
  ProductAttribute,
  ProductAttributeAction,
  ProductAttributeInput,
  ProductAttributePullThrough,
  ProductAttributeType,
} from "../state/productAttributes.model";
import { useLocationId } from "../utils/queryStringState";
import { getSelectOptionByValue } from "../utils/reactSelect";
import ProductAttributeOptionListPage from "./ProductAttributeOptionListPage";

interface ParamProps {
  productattributeid: string;
}

const PRODUCT_ATTRIBUTE_TYPES = [
  { value: ProductAttributeType.TEXT, label: "Text" },
  { value: ProductAttributeType.OPTION, label: "Predefined Options" },
  { value: ProductAttributeType.DATE, label: "Date" },
  { value: ProductAttributeType.DATE_TIME, label: "Date and Time" },
  { value: ProductAttributeType.EMAIL, label: "Email Address" },
  {
    value: ProductAttributeType.ORGANISATION_MEMBER,
    label: "Organisation Member",
  },
  {
    value: ProductAttributeType.YESNO,
    label: "Yes/No",
  },
];

const PRODUCT_ATTRIBUTE_ACTIONS = [
  { value: ProductAttributeAction.NONE, label: "None" },
  { value: ProductAttributeAction.ADD_TO_CALENDAR, label: "Add to calendar" },
  {
    value: ProductAttributeAction.SEND_ORDER_UPDATES,
    label: "Send order updates",
  },
];

const PRODUCT_ATTRIBUTE_INPUTS = [
  { value: ProductAttributeInput.NONE, label: "Not edited by user" },
  {
    value: ProductAttributeInput.OPTIONAL,
    label: "Optionally entered by user",
  },
  { value: ProductAttributeInput.REQUIRED, label: "Must be entered by user" },
];

const PRODUCT_ATTRIBUTE_PULL_THROUGHS = [
  {
    value: ProductAttributePullThrough.DEFAULT,
    label: "Default (pulls through if per-SKU, user entered or has an action)",
  },
  { value: ProductAttributePullThrough.ALWAYS, label: "Always" },
];

export default function ProductAttributeDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.productattributeid);
  const { productAttribute, fetching, error } = useFetchProductAttribute({
    id,
    admin: true,
  });

  return (
    <>
      <DetailScreen<ProductAttribute>
        defaults={
          (location.state as { defaults?: Partial<ProductAttribute> })?.defaults
        }
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={productAttribute}
        isNew={match.params.productattributeid === "new"}
        route={[{ "Product Attributes": "/admin/product-attributes" }]}
        renderForm={ProductAttributeDetailForm}
        onSave={adminSaveProductAttribute}
        onDelete={adminDeleteProductAttribute}
      />

      {!!id && productAttribute.type === ProductAttributeType.OPTION && (
        <ProductAttributeOptionListPage productAttributeId={id} />
      )}
    </>
  );
}

function ProductAttributeDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<ProductAttribute>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="organisationId">Organisation</Label>
            <OrganisationSelect
              admin
              isDisabled={readOnly}
              id="organisationId"
              value={getField("organisationId")}
              onChange={(newValue) =>
                changeFieldValue("organisationId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="productId">Product</Label>
            <ProductSelect
              admin
              isDisabled={readOnly}
              id="productId"
              value={getField("productId")}
              onChange={(newValue) =>
                changeFieldValue("productId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="title"
              id="title"
              value={getField("title")}
              onChange={(event) => changeField("title", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="type">Type</Label>
            <StyledSelect
              id="type"
              isDisabled={readOnly}
              options={PRODUCT_ATTRIBUTE_TYPES}
              value={getSelectOptionByValue<string>(
                PRODUCT_ATTRIBUTE_TYPES,
                getField("type")
              )}
              onChange={(newValue) =>
                changeFieldValue(
                  "type",
                  null,
                  newValue && "value" in newValue ? newValue.value : ""
                )
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="action">Action</Label>
            <StyledSelect
              id="action"
              isDisabled={readOnly}
              options={PRODUCT_ATTRIBUTE_ACTIONS}
              value={getSelectOptionByValue<string>(
                PRODUCT_ATTRIBUTE_ACTIONS,
                getField("action")
              )}
              onChange={(newValue) =>
                changeFieldValue(
                  "action",
                  null,
                  newValue && "value" in newValue ? newValue.value : ""
                )
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="input">Input Type</Label>
            <StyledSelect
              id="input"
              isDisabled={readOnly}
              options={PRODUCT_ATTRIBUTE_INPUTS}
              value={getSelectOptionByValue<string>(
                PRODUCT_ATTRIBUTE_INPUTS,
                getField("input")
              )}
              onChange={(newValue) =>
                changeFieldValue(
                  "input",
                  null,
                  newValue && "value" in newValue ? newValue.value : ""
                )
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="pb-2">
          <Label>Options</Label>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="isHidden"
              id="isHidden"
              checked={getField("isHidden")}
              onChange={(event) => changeField("isHidden", event)}
              label="Hidden (for internal use only)"
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="isMultiple"
              id="isMultiple"
              checked={getField("isMultiple")}
              onChange={(event) => changeField("isMultiple", event)}
              label="Allow multiple"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="pullThrough">Pulls Through to Order</Label>
            <StyledSelect
              id="pullThrough"
              isDisabled={readOnly}
              options={PRODUCT_ATTRIBUTE_PULL_THROUGHS}
              value={getSelectOptionByValue<string>(
                PRODUCT_ATTRIBUTE_PULL_THROUGHS,
                getField("pullThrough")
              )}
              onChange={(newValue) =>
                changeFieldValue(
                  "pullThrough",
                  null,
                  newValue && "value" in newValue ? newValue.value : ""
                )
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<ProductAttribute>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
