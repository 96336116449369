// eslint-disable-next-line @typescript-eslint/ban-types
export function checkRequiredFields<T extends object>(
  record: Partial<T>,
  requiedFields: Partial<Record<keyof T, string>>,
  requireAll = false
): void {
  for (const field of (Object.keys(requiedFields) as unknown) as Array<
    keyof T
  >) {
    if (!(field in record)) {
      if (requireAll) {
        throw new Error(`${requiedFields[field]} is required`);
      }

      continue;
    }

    const value = record[field];
    if (value === undefined || value === null) {
      throw new Error(`${requiedFields[field]} is required`);
    }

    if (typeof value === "string" && value.trim() === "") {
      throw new Error(`${requiedFields[field]} is required`);
    }
  }
}
