import React from "react";
import { RouteComponentProps } from "react-router";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import OrganisationSelect from "../selects/OrganisationSelect";
import {
  adminDeleteProduct,
  adminSaveProduct,
  useFetchProduct,
} from "../state/products.api";
import { Product } from "../state/products.model";
import { useLocationId } from "../utils/queryStringState";
import ProductAttributeListPage from "./ProductAttributeListPage";
import ProductPropertyListPage from "./ProductPropertyListPage";
import ProductSkuListPage from "./ProductSkuListPage";

interface ParamProps {
  productid: string;
}

export default function ProductDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.productid);
  const { product, fetching, error } = useFetchProduct({ id, admin: true });

  return (
    <>
      <DetailScreen<Product>
        defaults={(location.state as { defaults?: Partial<Product> })?.defaults}
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={product}
        isNew={match.params.productid === "new"}
        route={[{ Products: "/admin/products" }]}
        renderForm={ProductDetailForm}
        onSave={adminSaveProduct}
        onDelete={adminDeleteProduct}
      />

      {!!id && <ProductSkuListPage productId={id} />}
      {!!id && <ProductPropertyListPage productId={id} />}
      {!!id && <ProductAttributeListPage productId={id} />}
    </>
  );
}

function ProductDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<Product>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="organisationId">Organisation</Label>
            <OrganisationSelect
              admin
              isDisabled={readOnly}
              id="organisationId"
              value={getField("organisationId")}
              onChange={(newValue) =>
                changeFieldValue("organisationId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6} className="pb-2">
          <Label>Options</Label>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="isPublic"
              id="isPublic"
              checked={getField("isPublic")}
              onChange={(event) => changeField("isPublic", event)}
              label="Is Public"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="title"
              id="title"
              value={getField("title")}
              onChange={(event) => changeField("title", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="price">Price</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="price"
              id="price"
              value={getField("price")}
              onChange={(event) => changeField("price", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              readOnly={readOnly}
              type="textarea"
              style={{ height: "10em" }}
              name="description"
              id="description"
              value={getField("description")}
              onChange={(event) => changeField("description", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<Product>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
