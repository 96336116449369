import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import PaymentSelect from "../selects/PaymentSelect";
import { useFetchPaymentAssignments } from "../state/paymentAssignments.api";
import { PaymentAssignment } from "../state/paymentAssignments.model";
import { Id } from "../state/types";
import { formatPrice } from "../utils/decimal";
import { useQueryStringState } from "../utils/queryStringState";

export default function PaymentAssignmentListPage({
  paymentId: forcePaymentId,
}: {
  paymentId?: Id;
}): JSX.Element {
  const nested = forcePaymentId !== undefined;
  const prefix = nested ? "payasm_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [paymentId, setPaymentId] = useQueryStringState<Id | undefined>(
    "paymentId",
    0,
    prefix
  );

  const {
    paymentAssignments,
    numberOfPages,
    fetching,
    error,
  } = useFetchPaymentAssignments({
    page,
    search,
    admin: true,
    query: {
      paymentId: forcePaymentId || paymentId || undefined,
    },
  });

  return (
    <TableScreen<PaymentAssignment>
      defaults={{
        ...(forcePaymentId ? { paymentId: forcePaymentId } : {}),
      }}
      nested={nested}
      fetching={fetching}
      error={error}
      title="Payment Assignments"
      singular="Payment Assignment"
      columns={[
        { title: "ID" },
        { title: "Payment ID" },
        { title: "Order ID" },
        { title: "Amount" },
      ]}
      list={paymentAssignments}
      row={(item) => [
        item.id,
        item.paymentId,
        item.orderId,
        formatPrice(item.amount),
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/payment-assignments/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <TableFilter label="Payment">
              <PaymentSelect admin value={paymentId} onChange={setPaymentId} />
            </TableFilter>
          )}
        </>
      )}
    />
  );
}
