import React, { ReactNode } from "react";
import classNames from "classnames";
import "./LoadingIndicator.scss";

interface Props {
  className?: string;
  children?: ReactNode;
}

export default function LoadingIndicator({
  className,
  children,
}: Props): JSX.Element {
  return (
    <div className={classNames(className, "LoadingIndicator", "loading")}>
      <div className="loading-indicator-group">
        <div className="loading-indicator loading-indicator1" />
        <div className="loading-indicator loading-indicator2" />
        <div className="loading-indicator loading-indicator3" />
      </div>
      <div className="loading-indicator-children">{children}</div>
    </div>
  );
}
