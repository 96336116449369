import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import { LogoCardBody, LogoCardHeader } from "../components/LogoCardHeader";
import loginActions from "../state/login.actions";
import { useCombinedStore } from "../state/combinedStore";

export default function VerifyTokenScreen({
  match,
}: RouteComponentProps<{ token: string }>): JSX.Element {
  const token = match.params.token;

  const verifyTokenState = useCombinedStore(
    (store) => store.login.verifyTokenState
  );
  const isLoggedIn = useCombinedStore((store) => store.login.isLoggedIn);

  const dispatch = useDispatch();
  const history = useHistory();
  const [started, setStarted] = useState(false);

  useEffect(() => {
    if (token.trim() === "") {
      return;
    }

    if (verifyTokenState !== "started" && !started) {
      setStarted(true);

      dispatch(
        loginActions.verifyToken(token, (error) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorObject = error as any;
          if (
            errorObject &&
            errorObject.code === 400 &&
            errorObject.data &&
            errorObject.data.newPasswordRequired
          ) {
            history.replace(
              `/resetpassword/${encodeURIComponent(token)}?newuser=1`
            );
            return;
          }

          if (!error) {
            dispatch(
              loginActions.queueMessage(
                "Thank you, your email address has been verified"
              )
            );
            history.replace("/");
            return;
          }
        })
      );
    }
  }, [
    token,
    dispatch,
    history,
    isLoggedIn,
    verifyTokenState,
    started,
    setStarted,
  ]);

  return (
    <Container className="mt-5 mb-5 VerifyTokenScreen">
      <Row className="justify-content-center">
        <Col lg={5}>
          <Card>
            <LogoCardHeader />
            <LogoCardBody className="p-4">
              {verifyTokenState === "started" ? (
                <h4>Please wait...</h4>
              ) : verifyTokenState === "failed" ? (
                <>
                  <h4>We were unable to record your verification</h4>
                  <p>
                    It could be that too much time has passed since we sent the
                    email, or your email address has already been verified.
                    Please try again.
                  </p>
                </>
              ) : verifyTokenState === "completed" ? (
                <>
                  <h4>Your email address has now been verified!</h4>
                  {isLoggedIn ? null : (
                    <p>
                      Please <Link to="/login">sign in</Link>.
                    </p>
                  )}
                </>
              ) : null}
            </LogoCardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
