import { Id, ModelBase } from "./types";

export enum PaymentState {
  CREATED = "",
  PAID = "paid",
  FAILED = "failed",
}

export interface Payment extends ModelBase {
  customerId: Id;
  userId: Id;
  customer?: {
    id: Id;
    userId: Id;
    user?: {
      id: Id;
      firstName: string;
      lastName: string;
    };
  };
  paymentMethodId?: Id;
  payment_method?: {
    id: Id;
    title: string;
  };
  state: PaymentState;
  amount: string;
  reference?: string;
  servicePaymentId?: string;
  serviceTransactionId?: string;
  serviceTransactionResult?: string;
  serviceRecurringId?: string;
  paymentDateTime?: string;
  isTest: boolean;
}

export const blankPayment: Payment = {
  id: 0,
  customerId: 0,
  userId: 0,
  state: PaymentState.CREATED,
  amount: "0",
  isTest: false,
};
