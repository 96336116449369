import React from "react";
import TableScreen from "../components/TableScreen";
import { useFetchOrganisations } from "../state/organisations.api";
import { Organisation } from "../state/organisations.model";
import { useQueryStringState } from "../utils/queryStringState";

export default function OrganisationListPage(): JSX.Element {
  const [page, setPage] = useQueryStringState("page", 0);
  const [search, setSearch] = useQueryStringState("search", "");

  const {
    organisations,
    numberOfPages,
    fetching,
    error,
  } = useFetchOrganisations({ page, search, admin: true });

  return (
    <TableScreen<Organisation>
      fetching={fetching}
      error={error}
      title="Organisations"
      singular="Organisation"
      columns={[{ title: "ID" }, { title: "Name" }]}
      list={organisations}
      row={(item) => [item.id, item.name]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/organisations/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
    />
  );
}
