import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import config from "../config";
import OrganisationSelect from "../selects/OrganisationSelect";
import UserSelect from "../selects/UserSelect";
import { useFetchOrganisationMembers } from "../state/organisationMembers.api";
import { OrganisationMember } from "../state/organisationMembers.model";
import { Id } from "../state/types";
import { useQueryStringState } from "../utils/queryStringState";

export default function OrganisationMemberListPage({
  organisationId: forceOrganisationId,
  userId: forceUserId,
}: {
  organisationId?: Id;
  userId?: Id;
}): JSX.Element {
  const nested = forceOrganisationId !== undefined || forceUserId !== undefined;
  const prefix = nested ? "orme_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [organisationId, setOrganisationId] = useQueryStringState<
    Id | undefined
  >("organisationId", 0, prefix);
  const [userId, setUserId] = useQueryStringState<Id | undefined>(
    "userId",
    0,
    prefix
  );

  const {
    organisationMembers,
    numberOfPages,
    fetching,
    error,
  } = useFetchOrganisationMembers({
    page,
    search,
    admin: true,
    query: {
      organisationId: forceOrganisationId || organisationId || undefined,
      userId: forceUserId || userId || undefined,
    },
  });

  return (
    <TableScreen<OrganisationMember>
      defaults={{
        ...(forceOrganisationId
          ? { organisationId: forceOrganisationId }
          : { organisationId: config.ORGANISATION_ID }),
        ...(forceUserId ? { userId: forceUserId } : {}),
      }}
      nested={nested}
      fetching={fetching}
      error={error}
      title="Organisation Members"
      singular="Organisation Member"
      columns={[
        { title: "ID" },
        { title: "Organisation" },
        { title: "First Name" },
        { title: "Last Name" },
        { title: "Role" },
      ]}
      list={organisationMembers}
      row={(item) => [
        item.id,
        item.organisation?.name,
        item.user?.firstName,
        item.user?.lastName,
        item.manageUsers ? "admin" : "",
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/organisation-members/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <TableFilter label="User">
              <UserSelect admin value={userId} onChange={setUserId} />
            </TableFilter>
          )}
          {!nested && (
            <TableFilter label="Organisation">
              <OrganisationSelect
                admin
                value={organisationId}
                onChange={setOrganisationId}
              />
            </TableFilter>
          )}
        </>
      )}
    />
  );
}
