import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  FetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  UseFetchManyResults,
  useFetchOne,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { notifyUpdateListeners } from "../updateListeners";
import { makeSaver, makeDeleter } from "./types";
import api from "./api";
import { blankOrganisation, Organisation } from "./organisations.model";

interface UseFetchOrganisationsResults extends UseFetchManyResults {
  organisations: Organisation[];
  numberOfOrganisations: number;
  totalNumberOfOrganisations: number;
}

interface UseFetchOrganisationResults extends UseFetchOneResults {
  organisation: Organisation;
}

const keys: FetcherKeys<
  UseFetchOrganisationsResults,
  UseFetchOrganisationResults
> = {
  service: "organisations",
  totalNumber: "totalNumberOfOrganisations",
  numberOf: "numberOfOrganisations",
  records: "organisations",
  record: "organisation",
};

export async function fetchOrganisations(
  props: FetchManyProps
): Promise<FetchManyResults<Organisation>> {
  return fetchMany<Organisation>(props, keys.service);
}

export function useFetchOrganisations(
  props: FetchManyProps
): UseFetchOrganisationsResults {
  return useFetchMany(props, fetchOrganisations, keys);
}

export async function fetchOrganisation(
  props: FetchOneProps
): Promise<Organisation> {
  return fetchOne<Organisation, UseFetchOrganisationResults>(
    props,
    blankOrganisation,
    keys
  );
}

export function useFetchOrganisation(
  props: FetchOneProps
): UseFetchOrganisationResults {
  return useFetchOne(props, fetchOrganisation, blankOrganisation, keys);
}

async function saveOrganisation(
  original: Organisation,
  changes: Partial<Organisation>,
  $role: true | false | string
): Promise<Organisation> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteOrganisation(
  original: Organisation,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveOrganisation = makeSaver(saveOrganisation, true);
export const adminDeleteOrganisation = makeDeleter(deleteOrganisation, true);
export const userSaveOrganisation = makeSaver(saveOrganisation, false);
export const userDeleteOrganisation = makeDeleter(deleteOrganisation, false);
