import cloneDeep from "lodash/cloneDeep";
import {
  OrganisationMemberPermissions,
  ORGANISATION_MEMBERS_PERMISSIONS_KEYS,
} from "./organisationMembers.model";
import { User } from "./users.model";

export type OrganisationMemberPermissionToBoolean = {
  [key in keyof OrganisationMemberPermissions]: boolean;
};

export interface UserPermissions {
  superuser: boolean;
  anyAdmin: boolean;
  anyManager: boolean;
  anyPermission: OrganisationMemberPermissionToBoolean;
}

export const blankUserPermissions: UserPermissions = {
  superuser: false,
  anyAdmin: false,
  anyManager: false,
  anyPermission: {} as OrganisationMemberPermissionToBoolean, // this is fine because undefined is falsey
};

export interface Message {
  message: string;
}

export function computeUserPermissions(user: User): UserPermissions {
  const permissions = cloneDeep(blankUserPermissions);

  permissions.superuser = user.role === "superuser";
  permissions.anyAdmin = permissions.superuser;
  permissions.anyManager = permissions.superuser;

  if (permissions.superuser) {
    for (const key of ORGANISATION_MEMBERS_PERMISSIONS_KEYS) {
      permissions.anyPermission[key] = true;
    }
  }

  for (const org of user.organisations) {
    if (org.organisation_members.manageUsers) {
      permissions.anyAdmin = true;
      permissions.anyManager = true;
    }

    for (const key of ORGANISATION_MEMBERS_PERMISSIONS_KEYS) {
      if (org.organisation_members[key]) {
        permissions.anyManager = true;
        permissions.anyPermission[key] = true;
      }
    }
  }

  // console.log(JSON.stringify(permissions, null, '  '));

  return permissions;
}
