import {
  blankMessageTemplate,
  MessageTemplate,
} from "../state/messageTemplates.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";
import {
  fetchMessageTemplate,
  fetchMessageTemplates,
} from "../state/messageTemplates.api";

export default function MessageTemplateSelect(
  props: GenericSelectProps<MessageTemplate>
): JSX.Element {
  return GenericSelect<MessageTemplate>(
    props,
    fetchMessageTemplate,
    fetchMessageTemplates,
    "message-templates",
    blankMessageTemplate,
    (item: MessageTemplate): string =>
      `#${props.admin ? `${item.id} ` : ``}${item.description}`
  );
}
