import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import UserSelect from "../selects/UserSelect";
import { Id } from "../state/types";
import { useFetchUserAddresses } from "../state/userAddresses.api";
import { UserAddress } from "../state/userAddresses.model";
import { formatUser } from "../state/users.model";
import { useQueryStringState } from "../utils/queryStringState";

export default function UserAddressListPage({
  userId: forceUserId,
}: {
  userId?: Id;
}): JSX.Element {
  const nested = forceUserId !== undefined;
  const prefix = nested ? "usad_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [userId, setUserId] = useQueryStringState<Id | undefined>(
    "userId",
    0,
    prefix
  );

  const {
    userAddresses,
    numberOfPages,
    fetching,
    error,
  } = useFetchUserAddresses({
    page,
    search,
    admin: true,
    query: {
      userId: forceUserId || userId || undefined,
    },
  });

  return (
    <TableScreen<UserAddress>
      defaults={{
        ...(forceUserId ? { userId: forceUserId } : {}),
      }}
      fetching={fetching}
      error={error}
      title="User Addresses"
      singular="User Address"
      columns={[
        { title: "ID" },
        { title: "User" },
        { title: "Line 1" },
        { title: "Post Code" },
      ]}
      list={userAddresses}
      row={(item) => [
        item.id,
        formatUser(item.user),
        item.address1,
        item.postalCode,
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/user-addresses/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <TableFilter label="User">
              <UserSelect admin value={userId} onChange={setUserId} />
            </TableFilter>
          )}
        </>
      )}
    />
  );
}
