import { blankFamily, Family, FamilyUser } from "./family.model";
import { LoginActions, LogoutCompletedLoginAction } from "./login.reducer";
import { PersistActions, RestoreStateAction } from "./persist";
import { ApiState, COMPLETED, FAILED, STARTED } from "./types";

const initialState: {
  family: Family;
  fetchFamilyState?: ApiState;
  familyLastFetched: number;
  addChildState?: ApiState;
} = {
  family: { ...blankFamily },
  familyLastFetched: 0,
};

export type FamilyStore = typeof initialState;

export enum FamilyActions {
  STARTED_FETCH_FAMILY = "FAMILY_STARTED_FETCH_FAMILY",
  COMPLETED_FETCH_FAMILY = "FAMILY_COMPLETED_FETCH_FAMILY",
  FAILED_FETCH_FAMILY = "FAMILY_FAILED_FETCH_FAMILY",

  STARTED_ADD_CHILD = "FAMILY_STARTED_ADD_CHILD",
  COMPLETED_ADD_CHILD = "FAMILY_COMPLETED_ADD_CHILD",
  FAILED_ADD_CHILD = "FAMILY_FAILED_ADD_CHILD",
}

export interface StartedFetchFamilyFamilyAction {
  type: FamilyActions.STARTED_FETCH_FAMILY;
}
export interface CompletedFetchFamilyFamilyAction {
  type: FamilyActions.COMPLETED_FETCH_FAMILY;
  family: Family;
}
export interface FailedFetchFamilyFamilyAction {
  type: FamilyActions.FAILED_FETCH_FAMILY;
  error: string;
}
export interface StartedAddChildFamilyAction {
  type: FamilyActions.STARTED_ADD_CHILD;
}
export interface CompletedAddChildFamilyAction {
  type: FamilyActions.COMPLETED_ADD_CHILD;
  child: FamilyUser;
}
export interface FailedAddChildFamilyAction {
  type: FamilyActions.FAILED_ADD_CHILD;
  error: string;
}

export type FamilyAction =
  | StartedFetchFamilyFamilyAction
  | CompletedFetchFamilyFamilyAction
  | FailedFetchFamilyFamilyAction
  | StartedAddChildFamilyAction
  | CompletedAddChildFamilyAction
  | FailedAddChildFamilyAction;

const resetOnLoad: Partial<FamilyStore> = {
  fetchFamilyState: undefined,
  addChildState: undefined,
};

const family = (
  state = initialState,
  action: FamilyAction | LogoutCompletedLoginAction | RestoreStateAction
): FamilyStore => {
  switch (action.type) {
    case LoginActions.LOGOUT_COMPLETED:
      return { ...initialState };

    case PersistActions.RESTORE_STATE:
      return {
        ...state,
        ...action.restore.family,
        ...resetOnLoad,
      };

    case FamilyActions.STARTED_FETCH_FAMILY:
      return {
        ...state,
        fetchFamilyState: STARTED,
      };
    case FamilyActions.COMPLETED_FETCH_FAMILY:
      return {
        ...state,
        family: action.family,
        fetchFamilyState: COMPLETED,
        familyLastFetched: Date.now(),
      };
    case FamilyActions.FAILED_FETCH_FAMILY:
      return {
        ...state,
        fetchFamilyState: FAILED(action.error),
      };

    case FamilyActions.STARTED_ADD_CHILD:
      return {
        ...state,
        addChildState: STARTED,
      };
    case FamilyActions.COMPLETED_ADD_CHILD:
      return {
        ...state,
        family: {
          ...state.family,
          users: state.family.users.concat(action.child),
        },
        addChildState: COMPLETED,
      };
    case FamilyActions.FAILED_ADD_CHILD:
      return {
        ...state,
        addChildState: FAILED(action.error),
      };

    default:
      return state;
  }
};

export default family;
