// This is the React Router version
import qs from "qs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Id } from "../state/types";

export function useQueryStringState<T>(
  key: string,
  defaultValue: T,
  prefix = ""
): [T, (newValue: T) => void] {
  const history = useHistory();
  const prefixedKey = `${prefix}${key}`;

  const [value, setValue] = useState<T>(
    (): T => {
      const query = qs.parse(history.location.search.substr(1));
      const value = query[prefixedKey];
      if (value === undefined) {
        return defaultValue;
      }

      if (typeof defaultValue === "number") {
        return (+value as unknown) as T;
      }
      if (typeof defaultValue === "string") {
        return (value as unknown) as T;
      }
      if (typeof defaultValue === "boolean") {
        return ((value === "1" || value === "true"
          ? true
          : false) as unknown) as T;
      }
      return (value as unknown) as T; //JSON.parse(value);
    }
  );

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const setter = useCallback(
    (newValue: T) => {
      setValue(newValue);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        const search =
          "?" +
          qs.stringify({
            ...(qs.parse(history.location.search.substr(1)) || {}),
            [prefixedKey]: newValue ? newValue : undefined,
          });

        history.replace(search);
      }, 200);
    },
    [history, prefixedKey]
  );

  useEffect(() => {
    return () => {
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [timeout]);

  return [value, setter];
}

export function useLocationId(id: Id): [Id, (newId: Id) => void] {
  const history = useHistory();

  const setId = useCallback(
    (newId: Id) => {
      history.replace(String(newId));
    },
    [history]
  );

  return [id, setId];
}
