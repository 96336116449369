import { fetchUser, fetchUsers } from "../state/users.api";
import { blankUser, formatUser, User } from "../state/users.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

export default function UserSelect(
  props: GenericSelectProps<User>
): JSX.Element {
  return GenericSelect<User>(
    props,
    fetchUser,
    fetchUsers,
    "users",
    blankUser,
    formatUser
  );
}
