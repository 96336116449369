import { Id, ModelBase } from "./types";

export interface PaymentAssignment extends ModelBase {
  orderId?: Id;
  paymentId: Id;
  amount: string;
}

export const blankPaymentAssignment: PaymentAssignment = {
  id: 0,
  paymentId: 0,
  amount: "",
};
