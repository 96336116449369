import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, MouseEvent } from "react";
import { Badge } from "reactstrap";
import { formatPrice } from "../utils/decimal";

export interface BasketLineRowProps {
  text: string;
  detail?: string;
  price: string;
  onDelete?: () => void;
  bold?: boolean;
  outOfStock?: boolean;
}

export default function BasketLineRow({
  text,
  onDelete,
  bold,
  price,
  detail,
  outOfStock,
}: BasketLineRowProps): JSX.Element {
  const onDeleteClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onDelete && onDelete();
    },
    [onDelete]
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
            ...(bold ? { fontWeight: "bold" } : {}),
          }}
        >
          {text}
          {outOfStock ? (
            <Badge
              style={{ marginLeft: "0.5rem", display: "inline-block" }}
              color="danger"
            >
              Out of stock
            </Badge>
          ) : null}
          {detail ? (
            <>
              <br />
              <small className="text-muted">{detail}</small>
            </>
          ) : null}
        </div>
        <div
          style={{
            flexGrow: 1,
            flexShrink: 0,
            flexBasis: "auto",
            minWidth: "6rem",
            marginLeft: "1rem",
            textAlign: "right",
          }}
        >
          {formatPrice(price)}
        </div>
        <div
          style={{
            flex: 0,
            flexBasis: "auto",
            minWidth: "2rem",
            textAlign: "right",
          }}
        >
          {onDelete ? (
            <button
              className="link-button"
              style={{ display: "inline-block", marginLeft: "1rem" }}
              onClick={onDeleteClicked}
              title="Remove from basket"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
}
