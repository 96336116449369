import {
  fetchProductAttributeOption,
  fetchProductAttributeOptions,
} from "../state/productAttributeOptions.api";
import {
  blankProductAttributeOption,
  ProductAttributeOption,
} from "../state/productAttributeOptions.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

const format = (item: ProductAttributeOption): string =>
  `#${item.id} ${item.title}`;

export default function ProductAttributeOptionSelect(
  props: GenericSelectProps<ProductAttributeOption>
): JSX.Element {
  return GenericSelect<ProductAttributeOption>(
    props,
    fetchProductAttributeOption,
    fetchProductAttributeOptions,
    "product-attribute-options",
    blankProductAttributeOption,
    format
  );
}
