import { ProductAttributeOption } from "./state/productAttributeOptions.model";
import { useFetchProduct } from "./state/products.api";
import { Product } from "./state/products.model";
import { ProductSkuProperty } from "./state/productSkuProperties.model";
import { ProductSku } from "./state/productSkus.model";
import { equalIds, Id } from "./state/types";

export enum RockleyProductAttributeId {
  TEACHER = 1,
  LOCATION = 2,
  DATETIME = 4,
  SCHOOL = 5,
  AVAILABLE_FOR_STUDIO_LESSONS = 10,
}

export enum RockleyLocationOptionId {
  ONLINE = 10,
}

export enum RockleyProductId {
  ONE_TO_ONE_LESSONS = 1,
}

export function useFetchOneToOneLessonsProduct(): ReturnType<
  typeof useFetchProduct
> {
  return useFetchProduct({
    id: RockleyProductId.ONE_TO_ONE_LESSONS,
    maxAge: 10 * 60 * 1000,
  });
}

export function extractTeachersFromProduct(
  product: Product,
  singleTeacherId?: Id
): Pick<ProductAttributeOption, "id" | "title" | "imageUrl">[] {
  const teachers: Pick<
    ProductAttributeOption,
    "id" | "title" | "imageUrl"
  >[] = [];
  for (const property of product.product_properties || []) {
    if (
      equalIds(
        property.product_attribute?.id,
        RockleyProductAttributeId.TEACHER
      )
    ) {
      for (const teacherOption of property.product_attribute
        ?.product_attribute_options || []) {
        if (singleTeacherId) {
          if (equalIds(teacherOption.id, singleTeacherId)) {
            return [teacherOption];
          }
        } else {
          teachers.push(teacherOption);
        }
      }
    }
  }

  return teachers;
}

export type SlotProductSkuProperties = Pick<
  ProductSkuProperty,
  "id" | "value" | "productAttributeId" | "productAttributeOptionId"
> & {
  product_sku: Pick<ProductSku, "id"> & {
    product_sku_properties: SlotProductSkuProperties[];
  };
};

export type SlotProductSku = Pick<ProductSku, "id" | "productId"> & {
  product: Pick<Product, "id" | "title" | "price" | "organisationId">;
} & {
  product_sku_properties: SlotProductSkuProperties[];
};

export interface SkuSlot {
  sku: SlotProductSku;
  dateTime: Date;
}
