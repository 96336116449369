import { AnyAction } from "redux";
import { LoginActions } from "./login.reducer";
// import { PersistActions, persistState, requestPersist } from "./persist";

const initialState: {
  cache: { [key: string]: unknown };
} = {
  cache: {},
};

export type QueryCacheStore = typeof initialState;

export default function queryCacheReducer(
  state = initialState,
  action: AnyAction
): QueryCacheStore {
  switch (action.type) {
    // case PersistActions.RESTORE_STATE:
    //   return {
    //     ...state,
    //     ...(action.restore.queryCache || {}),
    //   };

    // case PersistActions.PERSIST_STATE:
    //   persistState({
    //     queryCache: {
    //       ...state,
    //     },
    //   });
    //   return state;

    case LoginActions.LOGOUT_COMPLETED:
      return {
        ...initialState,
      };

    case "CACHE_QUERY":
      if (
        JSON.stringify(state.cache[action.cacheKey] || {}) ===
        JSON.stringify(action.value)
      ) {
        return state;
      }

      // requestPersist();
      return {
        ...state,
        cache: { ...state.cache, [action.cacheKey]: action.value },
      };

    default:
      return state;
  }
}
