import { blankCustomer, Customer } from "../state/customers.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";
import { fetchCustomer, fetchCustomers } from "../state/customers.api";

const format = (item: Customer): string =>
  `#${item.id} ${item.user?.firstName} ${item.user?.lastName}`;

export default function CustomerSelect(
  props: GenericSelectProps<Customer>
): JSX.Element {
  return GenericSelect<Customer>(
    props,
    fetchCustomer,
    fetchCustomers,
    "customers",
    blankCustomer,
    format
  );
}
