import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import config from "../config";
import { ProductAttributeOptionPick } from "./productAttributeOptions.model";
import { Id, ModelBase } from "./types";

export type ProductAttributePick = Pick<
  ProductAttribute,
  | "id"
  | "title"
  | "productId"
  | "product"
  | "organisationId"
  | "organisation"
  | "type"
  | "action"
  | "product_attribute_options"
  | "pullThrough"
  | "input"
  | "isHidden"
  | "isMultiple"
>;

export enum ProductAttributeType {
  TEXT = "",
  OPTION = "option",
  DATE = "date",
  DATE_TIME = "datetime",
  ORGANISATION_MEMBER = "org_member",
  EMAIL = "email",
  YESNO = "yes_no",
}

export enum ProductAttributeAction {
  NONE = "",
  ADD_TO_CALENDAR = "calendar",
  SEND_ORDER_UPDATES = "order_updates",
}

export enum ProductAttributeInput {
  NONE = "",
  OPTIONAL = "optional",
  REQUIRED = "required",
}

export enum ProductAttributePullThrough {
  DEFAULT = "",
  ALWAYS = "always",
}

export interface ProductAttribute extends ModelBase {
  organisationId: Id;
  productId?: Id;
  title: string;
  type: ProductAttributeType;
  action: ProductAttributeAction;
  input: ProductAttributeInput;
  pullThrough: ProductAttributePullThrough;
  isHidden: boolean;
  isMultiple: boolean;
  product?: {
    id: Id;
    title: string;
  };
  organisation?: {
    id: Id;
    name: string;
  };
  product_attribute_options?: ProductAttributeOptionPick[];
}

export const blankProductAttribute: ProductAttribute = {
  id: 0,
  organisationId: 0,
  title: "",
  action: ProductAttributeAction.NONE,
  pullThrough: ProductAttributePullThrough.DEFAULT,
  input: ProductAttributeInput.NONE,
  type: ProductAttributeType.TEXT,
  isHidden: false,
  isMultiple: false,
};

export function formatAttributeOrThrow(
  attribute: { title: string; type: ProductAttributeType },
  value: string
): string {
  if (attribute.type === undefined) {
    throw new Error(`Product attribute ${attribute.title || ""} missing type`);
  }

  switch (attribute.type) {
    default:
      throw new Error(`Unknown product attribute type "${attribute.type}"`);

    case ProductAttributeType.TEXT:
      return value;

    case ProductAttributeType.DATE: {
      const m1 = parse(value, config.DATE_FORMAT, 0);
      if (isValid(m1)) {
        return format(m1, config.SERVER_DATE_FORMAT);
      }

      throw new Error(
        `${attribute.title} must be formatted as "dd/mm/yyyy hh:mm" (24 hour clock, with optional seconds)`
      );
    }

    case ProductAttributeType.DATE_TIME: {
      const m1 = parse(value, config.DATE_TIME_FORMAT, 0);
      if (isValid(m1)) {
        return format(m1, config.SERVER_DATE_TIME_FORMAT);
      }

      const m2 = parse(value, config.DATE_TIME_FORMAT_WITHOUT_SECONDS, 0);
      if (isValid(m2)) {
        return format(m2, config.SERVER_DATE_TIME_FORMAT);
      }

      throw new Error(
        `${attribute.title} must be formatted as "dd/mm/yyyy hh:mm" (24 hour clock, with optional seconds)`
      );
    }
  }
}

export function getProductAttributeFormatHint(attribute: {
  type: ProductAttributeType;
}): string {
  switch (attribute.type) {
    default:
    case ProductAttributeType.TEXT:
      return "freeform text";
    case ProductAttributeType.DATE:
      return "dd/mm/yyyy";
    case ProductAttributeType.DATE_TIME:
      return "dd/mm/yyyy hh:mm with 24 hour clock and optional seconds, e.g., 4/5/2020 9:30";
  }
}
