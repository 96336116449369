import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import OrganisationSelect from "../selects/OrganisationSelect";
import UserSelect from "../selects/UserSelect";
import { useCombinedStore } from "../state/combinedStore";
import {
  adminDeleteCustomer,
  adminSaveCustomer,
  useFetchCustomer,
} from "../state/customers.api";
import { Customer } from "../state/customers.model";
import { useLocationId } from "../utils/queryStringState";
import OrderLineListPage from "./OrderLineListPage";
import OrderListPage from "./OrderListPage";
import PaymentListPage from "./PaymentListPage";

interface ParamProps {
  customerid: string;
}

export default function CustomerDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.customerid);
  const { customer, fetching, error } = useFetchCustomer({ id, admin: true });

  return (
    <>
      <DetailScreen<Customer>
        defaults={
          (location.state as { defaults?: Partial<Customer> })?.defaults
        }
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={customer}
        isNew={match.params.customerid === "new"}
        route={[{ Customers: "/admin/customers" }]}
        renderForm={(props) => <CustomerDetailForm {...props} />}
        onSave={adminSaveCustomer}
        onDelete={adminDeleteCustomer}
      />

      {!!id && <OrderListPage customerId={id} />}
      {!!id && <OrderLineListPage customerId={id} />}
      {!!id && <PaymentListPage customerId={id} />}
    </>
  );
}

function CustomerDetailForm({
  getField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<Customer>) {
  const superuser = useCombinedStore(
    (store) => store.login.permissions.superuser
  );
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="organisationId">Organisation</Label>
            <OrganisationSelect
              isDisabled={readOnly}
              admin
              id="organisationId"
              value={getField("organisationId")}
              onChange={(newValue) =>
                changeFieldValue("organisationId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        {superuser && (
          <Col md={6}>
            <FormGroup>
              <Label for="userId">User</Label>
              <UserSelect
                admin
                isDisabled={readOnly}
                id="userId"
                value={getField("userId")}
                onChange={(newValue) =>
                  changeFieldValue("userId", null, newValue)
                }
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      <AdminCommonDetailRow<Customer>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
