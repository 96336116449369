import qs from "qs";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ButtonSpacer } from "../components/ButtonSpacer";
import { LoadingOverlay } from "../components/LoadingOverlay";
import { LogoCardBody, LogoCardHeader } from "../components/LogoCardHeader";
import { CombinedStore } from "../state/combinedStore";
import loginActions from "../state/login.actions";
import { useAutofocus } from "../utils/dom";
import { usePrevious } from "../utils/hooks";

export default function LoginScreen(): JSX.Element {
  const history = useHistory();

  const onLoggedIn = useCallback(() => history.replace("/"), [history]);

  let message: string | undefined;

  const query = qs.parse(history.location.search);
  if (query.fromVerify) {
    message = "Your email address has now been verified!";
  }

  return (
    <Container className="mt-5 mb-5 LoginScreen">
      <Row className="justify-content-center">
        <Col xs="auto">
          <LoginForm message={message} onLoggedIn={onLoggedIn} />
        </Col>
      </Row>
    </Container>
  );
}

export interface LoginFormProps {
  onRegisterClicked?: () => void;
  onResetPasswordClicked?: () => void;
  onLoggedIn: () => void;
  onCancel?: () => void;
  message?: string;
}

export function LoginForm({
  onLoggedIn,
  onRegisterClicked,
  onCancel,
  onResetPasswordClicked,
  message,
}: LoginFormProps): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const loginState = useSelector<CombinedStore>(
    (store) => store.login.loginState
  );

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(loginActions.logIn(email, password));
    },
    [dispatch, email, password]
  );

  const isLoggedIn = useSelector<CombinedStore>(
    (store) => store.login.isLoggedIn
  );

  const wasLoggedIn = usePrevious(isLoggedIn);

  useEffect(() => {
    if (isLoggedIn && !wasLoggedIn) {
      onLoggedIn();
    }
  }, [isLoggedIn, wasLoggedIn, onLoggedIn]);

  const onResetPasswordLinkClicked = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      onResetPasswordClicked && onResetPasswordClicked();
    },
    [onResetPasswordClicked]
  );

  const onRegisterLinkClicked = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      onRegisterClicked && onRegisterClicked();
    },
    [onRegisterClicked]
  );

  const autofocus = useAutofocus();

  return (
    <Form onSubmit={onSubmit}>
      <Card className="position-relative">
        <LogoCardHeader />
        <LogoCardBody className="p-4">
          {message ? <p>{message}</p> : null}
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              innerRef={autofocus}
              type="email"
              name="email"
              onChange={(event) => setEmail(event.currentTarget.value)}
              value={email}
              spellCheck={false}
              autoCapitalize="off"
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              type="password"
              name="password"
              onChange={(event) => setPassword(event.currentTarget.value)}
              value={password}
              autoCapitalize="off"
              spellCheck={false}
            />
          </FormGroup>
          <div className="text-center mt-4">
            {onCancel && (
              <>
                <Button color="secondary" onClick={onCancel}>
                  Cancel
                </Button>
                <ButtonSpacer />
              </>
            )}
            <Button type="submit" color="primary">
              Sign In
            </Button>
          </div>
          <p className="mt-4 text-center">
            Please note that if you had an account on our previous website, you
            will need to create a new one
          </p>
        </LogoCardBody>
        <CardFooter className="text-center">
          <div>
            <Link
              to="/register"
              onClick={onRegisterClicked && onRegisterLinkClicked}
            >
              Create an account
            </Link>
          </div>
          <div className="mt-2">
            <Link
              to="/resetpassword"
              onClick={onResetPasswordClicked && onResetPasswordLinkClicked}
            >
              Forgotten your password?
            </Link>
          </div>
        </CardFooter>
        {loginState === "started" && <LoadingOverlay />}
      </Card>
    </Form>
  );
}
