import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import OrganisationSelect from "../selects/OrganisationSelect";
import UserSelect from "../selects/UserSelect";
import { useFetchCustomers } from "../state/customers.api";
import { Customer } from "../state/customers.model";
import { useQueryStringState } from "../utils/queryStringState";
import config from "../config";
import { Id } from "../state/types";

export default function CustomerListPage({
  organisationId: forceOrganisationId,
  userId: forceUserId,
}: {
  organisationId?: Id;
  userId?: Id;
}): JSX.Element {
  const nested = forceOrganisationId !== undefined || forceUserId !== undefined;
  const prefix = nested ? "cu_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [organisationId, setOrganisationId] = useQueryStringState<
    Id | undefined
  >("organisationId", 0, prefix);
  const [userId, setUserId] = useQueryStringState<Id | undefined>(
    "userId",
    0,
    prefix
  );

  const { customers, numberOfPages, fetching, error } = useFetchCustomers({
    page,
    search,
    admin: true,
    query: {
      organisationId: forceOrganisationId || organisationId || undefined,
      userId: forceUserId || userId || undefined,
    },
  });

  return (
    <TableScreen<Customer>
      defaults={{
        ...(forceOrganisationId
          ? { organisationId: forceOrganisationId }
          : { organisationId: config.ORGANISATION_ID }),
        ...(forceUserId ? { userId: forceUserId } : {}),
      }}
      fetching={fetching}
      error={error}
      title="Customers"
      singular="Customer"
      columns={[
        { title: "ID" },
        { title: "Organisation " },
        { title: "First Name" },
        { title: "Last Name" },
      ]}
      list={customers}
      row={(item) => [
        item.id,
        item.organisation?.name,
        item.user?.firstName,
        item.user?.lastName,
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/customers/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <TableFilter label="User">
              <UserSelect admin value={userId} onChange={setUserId} />
            </TableFilter>
          )}
          {!nested && (
            <TableFilter label="Organisation">
              <OrganisationSelect
                admin
                value={organisationId}
                onChange={setOrganisationId}
              />
            </TableFilter>
          )}
        </>
      )}
    />
  );
}
