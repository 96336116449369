import { OrderLine } from "./orderLines.model";
import { Order } from "./orders.model";
import { ProductAttributeOptionPick } from "./productAttributeOptions.model";
import { ProductAttributePick } from "./productAttributes.model";
import { Id, ModelBase } from "./types";
import { User } from "./users.model";

export interface OrderLineProperty extends ModelBase {
  orderLineId: Id;
  order_line?: Pick<OrderLine, "orderId"> & {
    order: Pick<Order, "id"> & {
      user: Pick<User, "id" | "firstName" | "lastName">;
    };
  };
  productAttributeId: Id;
  product_attribute?: ProductAttributePick;
  productAttributeOptionId?: Id;
  product_attribute_option?: ProductAttributeOptionPick;
  visibleToUser: boolean;
  value?: string;
}

export const blankOrderLineProperty: OrderLineProperty = {
  id: 0,
  orderLineId: 0,
  productAttributeId: 0,
  visibleToUser: false,
};
