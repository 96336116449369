import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import ProductSelect from "../selects/ProductSelect";
import { useFetchProductProperties } from "../state/productProperties.api";
import { ProductProperty } from "../state/productProperties.model";
import { Id } from "../state/types";
import { formatBoolean } from "../utils/data";
import { useQueryStringState } from "../utils/queryStringState";

export default function ProductPropertyListPage({
  productId: forceProductId,
}: {
  productId?: Id;
}): JSX.Element {
  const nested = forceProductId !== undefined;
  const prefix = nested ? "prpr_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [productId, setProductId] = useQueryStringState<Id | undefined>(
    "productId",
    0,
    prefix
  );

  const {
    productProperties,
    numberOfPages,
    fetching,
    error,
  } = useFetchProductProperties({
    page,
    search,
    admin: true,
    query: {
      productId: forceProductId || productId || undefined,
    },
  });

  return (
    <TableScreen<ProductProperty>
      defaults={{
        ...(forceProductId ? { productId: forceProductId } : {}),
      }}
      nested={nested}
      fetching={fetching}
      error={error}
      title="Product Properties"
      singular="Product Property"
      columns={[
        { title: "ID" },
        { title: "Product" },
        { title: "Attribute" },
        { title: "Option / Value" },
        { title: "Per SKU?" },
      ]}
      list={productProperties}
      row={(item) => [
        item.id,
        item.product?.title,
        item.product_attribute?.title,
        item.product_attribute_option?.title || item.value,
        formatBoolean(item.variesPerSku),
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/product-properties/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <TableFilter label="Product">
              <ProductSelect admin value={productId} onChange={setProductId} />
            </TableFilter>
          )}
        </>
      )}
    />
  );
}
