import React from "react";
import Select, { OptionTypeBase } from "react-select";
import AsyncSelect from "react-select/async";

export type SelectOption<T> = { label: string; value: T };

export function StyledSelect<
  OptionType extends OptionTypeBase = SelectOption<string>
>(props: Select<OptionType>["props"]): JSX.Element {
  return (
    <Select<OptionType>
      className={`bootstrap-react-select ${props.className || ""}`}
      classNamePrefix="bootstrap-react-select"
      ref={props.innerRef}
      {...props}
    />
  );
}

export type StyledAsyncSelectProps<
  OptionType
> = AsyncSelect<OptionType>["props"];

export function StyledAsyncSelect<
  OptionType extends OptionTypeBase = SelectOption<string>
>(props: StyledAsyncSelectProps<OptionType>): JSX.Element {
  return (
    <AsyncSelect<OptionType>
      className={`bootstrap-react-select ${props.className || ""}`}
      classNamePrefix="bootstrap-react-select"
      ref={props.innerRef}
      {...props}
    />
  );
}
