import React from "react";
import TableScreen from "../components/TableScreen";
import { useFetchMessageTemplates } from "../state/messageTemplates.api";
import { MessageTemplate } from "../state/messageTemplates.model";
import { useQueryStringState } from "../utils/queryStringState";

export default function MessageTemplateListPage(_props: {
  path?: string;
}): JSX.Element {
  const [page, setPage] = useQueryStringState("page", 0);
  const [search, setSearch] = useQueryStringState("search", "");

  const {
    messageTemplates,
    numberOfPages,
    fetching,
    error,
  } = useFetchMessageTemplates({ page, search, admin: true });

  return (
    <TableScreen<MessageTemplate>
      fetching={fetching}
      error={error}
      title="Message Templates"
      singular="Message Template"
      columns={[
        { title: "Description" },
        { title: "Title" },
        { title: "Assignment" },
      ]}
      list={messageTemplates}
      row={(item) => [item.description, item.title, item.assignment || ""]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/messagetemplates/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
    />
  );
}
