import { Id, ModelBase } from "./types";
import {
  UserAddressFields,
  blankUserAddressFields,
} from "./userAddresses.model";

export enum OrderState {
  CREATED = "",
  PAID = "paid",
  FAILED = "failed",
  CANCELLED = "cancelled",
}

export interface Order extends UserAddressFields, ModelBase {
  customerId: Id;
  userId: Id;
  state: OrderState;
  total: string;
  customer?: {
    id: Id;
    userId: Id;
    user?: {
      id: Id;
      firstName: string;
      lastName: string;
    };
  };
  confirmationSentAt?: string;
}

export const blankOrder: Order = {
  id: 0,
  customerId: 0,
  userId: 0,
  state: OrderState.CREATED,
  total: "",
  ...blankUserAddressFields,
};
