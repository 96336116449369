import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import CountrySelect from "../selects/CountrySelect";
import UserSelect from "../selects/UserSelect";
import {
  adminDeleteUserAddress,
  adminSaveUserAddress,
  useFetchUserAddress,
} from "../state/userAddresses.api";
import { UserAddress } from "../state/userAddresses.model";
import { useLocationId } from "../utils/queryStringState";

interface ParamProps {
  useraddressid: string;
}

export default function UserAddressDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.useraddressid);
  const { userAddress, fetching, error } = useFetchUserAddress({
    id,
    admin: true,
  });

  return (
    <>
      <DetailScreen<UserAddress>
        defaults={
          (location.state as { defaults?: Partial<UserAddress> })?.defaults
        }
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={userAddress}
        isNew={match.params.useraddressid === "new"}
        route={[{ "User Addresses": "/admin/user-addresses" }]}
        renderForm={UserAddressDetailForm}
        onSave={adminSaveUserAddress}
        onDelete={adminDeleteUserAddress}
      />
    </>
  );
}

export function UserAddressDetailRows({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<UserAddress>): JSX.Element {
  return (
    <>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>First Name</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="firstName"
              id="firstName"
              value={getField("firstName")}
              onChange={(event) => changeField("firstName", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="lastName"
              id="lastName"
              value={getField("lastName")}
              onChange={(event) => changeField("lastName", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Address</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="address1"
              id="address1"
              value={getField("address1")}
              onChange={(event) => changeField("address1", event)}
            />
          </FormGroup>
          <FormGroup>
            <Input
              readOnly={readOnly}
              type="text"
              name="address2"
              id="address2"
              value={getField("address2")}
              onChange={(event) => changeField("address2", event)}
            />
          </FormGroup>
          <FormGroup>
            <Input
              readOnly={readOnly}
              type="text"
              name="address3"
              id="address3"
              value={getField("address3")}
              onChange={(event) => changeField("address3", event)}
            />
          </FormGroup>
          <FormGroup>
            <Input
              readOnly={readOnly}
              type="text"
              name="address4"
              id="address4"
              value={getField("address4")}
              onChange={(event) => changeField("address4", event)}
            />
          </FormGroup>
          <FormGroup>
            <Input
              readOnly={readOnly}
              type="text"
              name="address5"
              id="address5"
              value={getField("address5")}
              onChange={(event) => changeField("address5", event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Post Code</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="postalCode"
              id="postalCode"
              value={getField("postalCode")}
              onChange={(event) => changeField("postalCode", event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Country</Label>
            <CountrySelect
              id="country"
              name="country"
              value={getField("country")}
              isDisabled={readOnly}
              onChange={(newValue) =>
                changeFieldValue("country", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Phone</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={getField("phoneNumber")}
              onChange={(event) => changeField("phoneNumber", event)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

function UserAddressDetailForm(props: FormCallbacks<UserAddress>) {
  const { getField, changeFieldValue, readOnly } = props;
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="userId">User</Label>
            <UserSelect
              admin
              isDisabled={readOnly}
              id="userId"
              value={getField("userId")}
              onChange={(newValue) =>
                changeFieldValue("userId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>

      <UserAddressDetailRows {...props} />

      <AdminCommonDetailRow<UserAddress>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
