import React, { FormEvent, MouseEvent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import FormErrorText from "../components/FormErrorText";
import { WorkingOverlay } from "../components/LoadingOverlay";
import { StyledSelect } from "../components/StyledSelect";
import { CombinedStore } from "../state/combinedStore";
import familyActions from "../state/family.actions";
import { FamilyUser } from "../state/family.model";
import { useModalAutofocus } from "../utils/dom";
import {
  findSelectOptionByValue,
  getSelectOptionByValue,
} from "../utils/reactSelect";

const ENABLE_SCHOOL_FIELDS = false;

interface SelectChildModalProps {
  isOpen: boolean;
  onToggle: () => void;
  onDeselectSlot: () => void;
  onAddChild: () => void;
  onChildSelected: (child: FamilyUser) => void;
  isSkuSelected: boolean;
  childs: FamilyUser[]; // Named childs instead of children to avoid eslint complaints
}

export function SelectChildModal({
  isOpen,
  onToggle,
  onAddChild,
  onDeselectSlot,
  onChildSelected,
  childs,
  isSkuSelected,
}: SelectChildModalProps): JSX.Element {
  const onAddChildClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onAddChild();
    },
    [onAddChild]
  );
  const onDeselectSlotClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onDeselectSlot();
    },
    [onDeselectSlot]
  );
  const onChildClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>, child: FamilyUser) => {
      event.preventDefault();
      onChildSelected(child);
    },
    [onChildSelected]
  );

  return (
    <Modal isOpen={isOpen} centered size="sm">
      <ModalHeader toggle={onToggle}>Select Child</ModalHeader>
      <ModalBody className="">
        {childs.map((child) => {
          return (
            <div key={child.id} className="mb-2 text-center">
              <Button
                color="primary"
                onClick={(event) => onChildClicked(event, child)}
              >
                {child.firstName} {child.lastName}
              </Button>
            </div>
          );
        })}

        {isSkuSelected ? (
          <div className="mt-4 mb-2 text-center">
            <Button color="danger" onClick={onDeselectSlotClicked}>
              Deselect this Time
            </Button>
          </div>
        ) : null}

        <div className="mt-4 mb-2 text-center">
          <Button color="secondary" onClick={onAddChildClicked}>
            {childs.length ? "Add Another Child" : "Add Child"}
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onToggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const SCHOOL_YEAR_OPTIONS = [
  { value: "0", label: "Foundation/Reception" },
  { value: "1", label: "Year 1" },
  { value: "2", label: "Year 2" },
  { value: "3", label: "Year 3" },
  { value: "4", label: "Year 4" },
  { value: "5", label: "Year 5" },
  { value: "6", label: "Year 6" },
];

export function AddChildModal({
  isOpen,
  onToggle,
  onChildAdded,
}: {
  isOpen: boolean;
  onToggle: () => void;
  onChildAdded: (child: FamilyUser) => void;
}): JSX.Element {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [school, setSchool] = useState("");
  const [schoolYear, setSchoolYear] = useState<string | null>(null);

  function onInputChange(
    event: FormEvent<HTMLInputElement>,
    setter: (newValue: string) => void
  ) {
    setter(event.currentTarget.value);
  }

  const [errors, setErrors] = useState<Record<string, string>>({});

  const dispatch = useDispatch();
  const addChildState = useSelector<CombinedStore>(
    (store) => store.family.addChildState
  );
  const [error, setError] = useState("");

  const onAddChildClicked = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const errors: Record<string, string> = {};

      if (ENABLE_SCHOOL_FIELDS) {
        if (
          !schoolYear ||
          !findSelectOptionByValue(SCHOOL_YEAR_OPTIONS, schoolYear)
        ) {
          errors["schoolYear"] = "Please select";
        }
      }

      if (firstName.trim().length === 0) {
        errors["firstName"] = "We need your child's full name";
      }
      if (lastName.trim().length === 0) {
        errors["lastName"] = "We need your child's full name";
      }

      setErrors(errors);
      if (Object.keys(errors).length) {
        return;
      }

      dispatch(
        familyActions.addChild(
          firstName,
          lastName,
          { school, schoolYear },
          (error, child) => {
            if (child) {
              onChildAdded(child);
            }
            if (error) {
              setError(String(error));
            }
          }
        )
      );
    },
    [firstName, lastName, schoolYear, onChildAdded, school, dispatch]
  );

  const clearForm = useCallback(() => {
    setFirstName("");
    setLastName("");
    setSchoolYear("");
    setSchool("");
  }, []);

  const autofocus = useModalAutofocus(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="position-relative"
      onOpened={clearForm}
    >
      <WorkingOverlay working={addChildState === "started"}>
        <ModalHeader toggle={onToggle}>Add Child</ModalHeader>
        <ModalBody>
          {error ? <Alert color="danger">{error}</Alert> : null}
          <FormGroup>
            <p>Enter your child{`'`}s name.</p>
          </FormGroup>
          <FormGroup>
            <Label>First Name</Label>
            <Input
              innerRef={autofocus}
              type="text"
              onChange={(event) => onInputChange(event, setFirstName)}
              value={firstName}
              autoComplete="give-name"
              autoCapitalize="words"
              spellCheck={false}
            />
            <FormErrorText error={errors.firstName} />
          </FormGroup>
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              type="text"
              onChange={(event) => onInputChange(event, setLastName)}
              value={lastName}
              autoComplete="family-name"
              autoCapitalize="words"
              spellCheck={false}
            />
            <FormErrorText error={errors.lastName} />
          </FormGroup>
          {ENABLE_SCHOOL_FIELDS && (
            <>
              <FormGroup>
                <Label>School</Label>
                <Input
                  type="text"
                  onChange={(event) => onInputChange(event, setSchool)}
                  value={school}
                />
                <FormErrorText error={errors.school} />
              </FormGroup>
              <FormGroup>
                <Label>School Year</Label>
                <StyledSelect
                  id="schoolYear"
                  options={SCHOOL_YEAR_OPTIONS}
                  value={
                    schoolYear
                      ? getSelectOptionByValue(SCHOOL_YEAR_OPTIONS, schoolYear)
                      : null
                  }
                  onChange={(newValue) =>
                    setSchoolYear(
                      newValue && "value" in newValue ? newValue.value : null
                    )
                  }
                />
                <FormErrorText error={errors.schoolYear} />
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onToggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={onAddChildClicked}>
            Add Child
          </Button>
        </ModalFooter>
      </WorkingOverlay>
    </Modal>
  );
}
