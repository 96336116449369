import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { blankPaymentMethod, PaymentMethod } from "./paymentMethods.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchPaymentMethodsResults extends UseFetchManyResults {
  paymentMethods: PaymentMethod[];
  numberOfPaymentMethods: number;
  totalNumberOfPaymentMethods: number;
}

interface UseFetchPaymentMethodResults extends UseFetchOneResults {
  paymentMethod: PaymentMethod;
}

const keys: FetcherKeys<
  UseFetchPaymentMethodsResults,
  UseFetchPaymentMethodResults
> = {
  service: "payment-methods",
  totalNumber: "totalNumberOfPaymentMethods",
  numberOf: "numberOfPaymentMethods",
  records: "paymentMethods",
  record: "paymentMethod",
};

export async function fetchPaymentMethods(
  props: FetchManyProps
): Promise<FetchManyResults<PaymentMethod>> {
  return fetchMany<PaymentMethod>(props, keys.service);
}

export function useFetchPaymentMethods(
  props: FetchManyProps
): UseFetchPaymentMethodsResults {
  return useFetchMany(props, fetchPaymentMethods, keys);
}

export async function fetchPaymentMethod(
  props: FetchOneProps
): Promise<PaymentMethod> {
  return fetchOne<PaymentMethod, UseFetchPaymentMethodResults>(
    props,
    blankPaymentMethod,
    keys
  );
}

export function useFetchPaymentMethod(
  props: FetchOneProps
): UseFetchPaymentMethodResults {
  return useFetchOne(props, fetchPaymentMethod, blankPaymentMethod, keys);
}

async function savePaymentMethod(
  original: PaymentMethod,
  changes: Partial<PaymentMethod>,
  $role: true | false | string
): Promise<PaymentMethod> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deletePaymentMethod(
  original: PaymentMethod,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSavePaymentMethod = makeSaver(savePaymentMethod, true);
export const adminDeletePaymentMethod = makeDeleter(deletePaymentMethod, true);
export const userSavePaymentMethod = makeSaver(savePaymentMethod, false);
export const userDeletePaymentMethod = makeDeleter(deletePaymentMethod, false);
