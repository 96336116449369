import React from "react";
import { CustomInput, FormGroup, Input, Label } from "reactstrap";
import DateTimeInput from "../components/DateTimeInput";
import config from "../config";
import ProductAttributeOptionSelect from "../selects/ProductAttributeOptionSelect";
import ProductAttributeSelect from "../selects/ProductAttributeSelect";
import { useFetchProductAttribute } from "../state/productAttributes.api";
import { ProductAttributeType } from "../state/productAttributes.model";
import { Id } from "../state/types";
import OrganisationMemberSelect from "./OrganisationMemberSelect";

export default function AttributeValueFields({
  productAttributeId,
  onProductAttributeIdChange,
  productAttributeOptionId,
  onProductAttributeOptionIdChange,
  readOnly,
  value,
  onValueChange,
}: {
  productAttributeId: Id | undefined;
  onProductAttributeIdChange: (newValue: Id | undefined) => void;
  productAttributeOptionId: Id | undefined;
  onProductAttributeOptionIdChange: (newValue: Id | undefined) => void;
  readOnly?: boolean;
  value: string | undefined;
  onValueChange: (newValue: string) => void;
}): JSX.Element {
  const { fetching, productAttribute } = useFetchProductAttribute({
    admin: true,
    maxAge: 2000,
    id: productAttributeId ? +productAttributeId : 0,
  });
  return (
    <>
      <FormGroup>
        <Label for="productAttributeId">Attribute</Label>
        <ProductAttributeSelect
          admin
          isDisabled={readOnly}
          id="productAttributeId"
          value={productAttributeId}
          onChange={onProductAttributeIdChange}
        />
      </FormGroup>
      <FormGroup>
        {fetching ? null : productAttribute.type ===
          ProductAttributeType.OPTION ? (
          <>
            <Label for="productAttributeOptionId">Option</Label>
            <ProductAttributeOptionSelect
              query={{
                productAttributeId: productAttributeId,
              }}
              admin
              isDisabled={readOnly}
              id="productAttributeOptionId"
              value={productAttributeOptionId}
              onChange={onProductAttributeOptionIdChange}
            />
          </>
        ) : productAttribute.type === ProductAttributeType.DATE ? (
          <>
            <Label for="value">Date (DD/MM/YYYY)</Label>
            <DateTimeInput
              readOnly={readOnly}
              name="value"
              id="value"
              value={value}
              onChange={(newValue) => onValueChange(String(newValue))}
              callbacks={config.DATE_CALLBACKS}
            />
          </>
        ) : productAttribute.type === ProductAttributeType.DATE_TIME ? (
          <>
            <Label for="value">
              Date and Time (DD/MM/YYYY hh:mm:ss, 24 hour)
            </Label>
            <DateTimeInput
              readOnly={readOnly}
              callbacks={config.DATE_TIME_CALLBACKS}
              name="value"
              id="value"
              value={value}
              onChange={(newValue) => onValueChange(String(newValue))}
            />
          </>
        ) : productAttribute.type ===
          ProductAttributeType.ORGANISATION_MEMBER ? (
          <>
            <Label for="value">Organisation Member</Label>
            <OrganisationMemberSelect
              admin
              isDisabled={readOnly}
              onChange={(newValue) => onValueChange(String(newValue))}
              value={value}
            />
          </>
        ) : productAttribute.type === ProductAttributeType.EMAIL ? (
          <>
            <Label for="value">Email Address</Label>
            <Input
              readOnly={readOnly}
              type="email"
              name="value"
              id="value"
              value={value}
              onChange={(event) => onValueChange(event.currentTarget.value)}
            />
          </>
        ) : productAttribute.type === ProductAttributeType.YESNO ? (
          <>
            <Label for="value">Yes or No</Label>
            <FormGroup check>
              <CustomInput
                type="checkbox"
                name="value"
                id="value"
                checked={value === "1"}
                onChange={(event) =>
                  onValueChange(event.currentTarget.checked ? "1" : "0")
                }
                label={`Yes`}
              />
            </FormGroup>
          </>
        ) : (
          <>
            <Label for="value">Value</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="value"
              id="value"
              value={value}
              onChange={(event) => onValueChange(event.currentTarget.value)}
            />
          </>
        )}
      </FormGroup>
    </>
  );
}
