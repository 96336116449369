import React from "react";
import { RouteComponentProps } from "react-router";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import { StyledSelect } from "../components/StyledSelect";
import OrganisationSelect from "../selects/OrganisationSelect";
import {
  adminDeletePaymentMethod,
  adminSavePaymentMethod,
  useFetchPaymentMethod,
} from "../state/paymentMethods.api";
import { PaymentMethod } from "../state/paymentMethods.model";
import { useLocationId } from "../utils/queryStringState";
import { getSelectOptionByValue } from "../utils/reactSelect";

interface ParamProps {
  paymentmethodid: string;
}

export default function PaymentMethodDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.paymentmethodid);
  const { paymentMethod, fetching, error } = useFetchPaymentMethod({
    id,
    admin: true,
  });

  return (
    <DetailScreen<PaymentMethod>
      defaults={
        (location.state as { defaults?: Partial<PaymentMethod> })?.defaults
      }
      onIdChange={setId}
      fetching={fetching}
      error={error}
      original={paymentMethod}
      isNew={match.params.paymentmethodid === "new"}
      route={[{ "Payment Methods": "/admin/payment-methods" }]}
      renderForm={PaymentMethodDetailForm}
      onSave={adminSavePaymentMethod}
      onDelete={adminDeletePaymentMethod}
    />
  );
}

const SERVICE_OPTIONS = [
  { value: "", label: "Unknown" },
  { value: "worldpay", label: "WorldPay WCC" },
  { value: "paypal", label: "PayPal" },
];

function PaymentMethodDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<PaymentMethod>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="organisationId">Organisation</Label>
            <OrganisationSelect
              admin
              isDisabled={readOnly}
              id="organisationId"
              value={getField("organisationId")}
              onChange={(newValue) =>
                changeFieldValue("organisationId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="title"
              id="title"
              value={getField("title")}
              onChange={(event) => changeField("title", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="service">Service</Label>
            <StyledSelect
              isDisabled={readOnly}
              id="service"
              options={SERVICE_OPTIONS}
              value={getSelectOptionByValue<string>(
                SERVICE_OPTIONS,
                getField("service")
              )}
              onChange={(newValue) =>
                changeFieldValue(
                  "service",
                  null,
                  newValue && "value" in newValue ? newValue.value : ""
                )
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <Label>Options</Label>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="isPublic"
              id="isPublic"
              checked={getField("isPublic")}
              onChange={(event) => changeField("isPublic", event)}
              label="Is Public"
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              disabled={readOnly}
              type="checkbox"
              name="isTest"
              id="isTest"
              checked={getField("isTest")}
              onChange={(event) => changeField("isTest", event)}
              label="Is Test"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="apiEndpoint">API Endpoint</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="apiEndpoint"
              id="apiEndpoint"
              value={getField("apiEndpoint")}
              onChange={(event) => changeField("apiEndpoint", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="apiMerchantId">API Merchant ID</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="apiMerchantId"
              id="apiMerchantId"
              value={getField("apiMerchantId")}
              onChange={(event) => changeField("apiMerchantId", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="apiClientId">API Client ID</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="apiClientId"
              id="apiClientId"
              value={getField("apiClientId")}
              onChange={(event) => changeField("apiClientId", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="apiClientSecret">API Client Secret</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="apiClientSecret"
              id="apiClientSecret"
              value={getField("apiClientSecret")}
              onChange={(event) => changeField("apiClientSecret", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="apiCancelUrl">API Cancel URL</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="apiCancelUrl"
              id="apiCancelUrl"
              value={getField("apiCancelUrl")}
              onChange={(event) => changeField("apiCancelUrl", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="apiReturnUrl">API Return URL</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="apiReturnUrl"
              id="apiReturnUrl"
              value={getField("apiReturnUrl")}
              onChange={(event) => changeField("apiReturnUrl", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<PaymentMethod>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
