import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import config from "../config";
import CustomerSelect from "../selects/CustomerSelect";
import OrderStateSelect from "../selects/OrderStateSelect";
import { useFetchOrders } from "../state/orders.api";
import { Order, OrderState } from "../state/orders.model";
import { Id } from "../state/types";
import { formatUser } from "../state/users.model";
import { formatPrice } from "../utils/decimal";
import { useQueryStringState } from "../utils/queryStringState";

export default function OrderListPage({
  customerId: forceCustomerId,
}: {
  customerId?: Id;
}): JSX.Element {
  const nested = forceCustomerId !== undefined;
  const prefix = nested ? "or_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [customerId, setCustomerId] = useQueryStringState<Id | undefined>(
    "customerId",
    0,
    prefix
  );
  const [orderState, setOrderState] = useQueryStringState<
    OrderState | undefined
  >("orderState", undefined, prefix);

  const { orders, numberOfPages, fetching, error } = useFetchOrders({
    page,
    search,
    admin: true,
    query: {
      customerId: forceCustomerId || customerId || undefined,
      state: orderState,
    },
  });

  return (
    <TableScreen<Order>
      defaults={{
        ...(forceCustomerId ? { customerId: forceCustomerId } : {}),
      }}
      fetching={fetching}
      error={error}
      title="Orders"
      singular="Order"
      columns={[
        { title: "ID" },
        { title: "State" },
        { title: "Customer" },
        { title: "Confirmation" },
        { title: "Total" },
      ]}
      list={orders}
      row={(item) => [
        item.id,
        item.state || "created",
        formatUser(item.customer?.user),
        item.confirmationSentAt
          ? format(parseISO(item.confirmationSentAt), config.DATE_TIME_FORMAT)
          : "",
        formatPrice(item.total || ""),
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/orders/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <TableFilter label="Customer">
              <CustomerSelect
                admin
                value={customerId}
                onChange={setCustomerId}
              />
            </TableFilter>
          )}
          <TableFilter label="Order State">
            <OrderStateSelect value={orderState} onChange={setOrderState} />
          </TableFilter>
        </>
      )}
    />
  );
}
