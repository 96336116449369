import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEvent, ReactNode, useCallback } from "react";
import { NavLink as RouterNavLink, useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "./PageTitle.scss";

export interface RouteItem {
  [visibleName: string]: string; // visibleName to URI
}

interface Props {
  title: string;
  children?: ReactNode;
  route?: RouteItem[];
  renderPageLinks?: ReactNode;
  className?: string;
  disableBackButton?: boolean;
}

export default function PageTitle({
  title,
  children,
  route,
  renderPageLinks,
  className,
  disableBackButton,
}: Props): JSX.Element {
  const history = useHistory();

  const onBackButtonClicked = useCallback(() => {
    history.goBack();
  }, [history]);

  const canGoBack = history.location.pathname !== "/" && !disableBackButton;

  return (
    <div className="PageTitle-container my-1">
      <div className={`PageTitle-title my-2 ${className || ""}`}>
        {route ? (
          <Breadcrumb listClassName="mb-0">
            {canGoBack && <BackButton onClick={onBackButtonClicked} />}
            {route.map((item) =>
              Object.keys(item).map((key) => (
                <BreadcrumbItem key={key}>
                  {item[key] ? (
                    <RouterNavLink to={item[key]}>{key}</RouterNavLink>
                  ) : (
                    <>{key}</>
                  )}
                </BreadcrumbItem>
              ))
            )}
            <BreadcrumbItem>{title}</BreadcrumbItem>
          </Breadcrumb>
        ) : (
          <Breadcrumb listClassName="mb-0">
            {canGoBack && <BackButton onClick={onBackButtonClicked} />}
            <BreadcrumbItem>{title}</BreadcrumbItem>
          </Breadcrumb>
        )}
        {renderPageLinks}
      </div>
      <div className="PageTitle-accessories my-2">
        {children ? children : null}
      </div>
    </div>
  );
}

export function BackButton({ onClick }: { onClick: () => void }): JSX.Element {
  const onBackButtonClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClick();
    },
    [onClick]
  );

  return (
    <button className="back-button" onClick={onBackButtonClicked}>
      <FontAwesomeIcon icon={faAngleLeft} size="lg" />
    </button>
  );
}
