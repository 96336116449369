import { ProductAttributeOptionPick } from "./productAttributeOptions.model";
import { ProductAttributePick } from "./productAttributes.model";
import { Id, ModelBase } from "./types";

export interface ProductAttributeOptionProperty extends ModelBase {
  parentProductAttributeOptionId: Id;
  parentProductAttributeOption?: ProductAttributeOptionPick;
  productAttributeId: Id;
  product_attribute?: ProductAttributePick;
  productAttributeOptionId?: Id;
  product_attribute_option?: ProductAttributeOptionPick;
  value?: string;
}

export const blankProductAttributeOptionProperty: ProductAttributeOptionProperty = {
  id: 0,
  parentProductAttributeOptionId: 0,
  productAttributeId: 0,
};
