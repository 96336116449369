import Decimal from "decimal.js";

function isDigit(c: string): boolean {
  return c >= "0" && c <= "9";
}

export function formatNumber(
  n: string | number | Decimal,
  minDecimals: number,
  digitGroup = 3,
  groupSeparator = ","
): string {
  if (n === "" || n === null || n === undefined) {
    return "";
  }

  const s: string = "" + n;

  let integerEnd: number = s.indexOf(".");
  let decimalsCount: number;
  if (integerEnd < 0) {
    integerEnd = s.length;
    decimalsCount = 0;
  } else {
    decimalsCount = s.length - integerEnd - 1;
  }

  let integerBegin: number = integerEnd;
  while (integerBegin-- > 0) {
    if (!isDigit(s.charAt(integerBegin))) {
      break;
    }
  }

  ++integerBegin;

  let result: string = s.slice(0, integerBegin);

  let digitCount: number = integerEnd - integerBegin;
  let nextSeparator: number = digitGroup <= 0 ? -1 : digitCount % digitGroup;
  if (nextSeparator === 0) {
    nextSeparator = digitGroup;
  }

  while (digitCount--) {
    if (nextSeparator === 0) {
      nextSeparator = digitGroup - 1;
      result += groupSeparator;
    } else {
      --nextSeparator;
    }

    result += s.charAt(integerEnd - 1 - digitCount);
  }

  result += s.slice(integerEnd);

  if (decimalsCount < minDecimals) {
    if (integerEnd === s.length) {
      result += ".";
    }

    const addDecimals: number = minDecimals - decimalsCount;
    for (let i = 0; i < addDecimals; ++i) {
      result += "0";
    }
  }

  return result;
}

export function fixBadNumber(n: string | number): string {
  return ("" + n).replace(/[^-0-9.]/g, "");
}

export function roundNumber(
  n: string | number | Decimal,
  decimals: number
): string {
  if (!(n instanceof Decimal)) {
    try {
      n = new Decimal(fixBadNumber(n));
    } catch {
      n = new Decimal(0);
    }
  }
  return (n as Decimal)
    .toDecimalPlaces(decimals, Decimal.ROUND_HALF_EVEN)
    .toString();
}

export function formatCurrency(
  n: string | number | Decimal,
  digits = 2
): string {
  return formatNumber(roundNumber(n, digits), digits);
}

export function formatPrice(n: string | number | Decimal): string {
  return `£${formatNumber(roundNumber(n, 2), 2)}`;
}

export function isZero(n: string | number | Decimal): boolean {
  if (n === null || n === undefined) {
    return true;
  }

  if (typeof n === "number") {
    return n === 0;
  }

  try {
    return new Decimal(n).isZero();
  } catch (error) {
    return true;
  }
}
