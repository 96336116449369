import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import AttributeValueFields from "../selects/AttributeValueFields";
import OrderLineSelect from "../selects/OrderLineSelect";
import {
  adminDeleteOrderLineProperty,
  adminSaveOrderLineProperty,
  useFetchOrderLineProperty,
} from "../state/orderLineProperties.api";
import { OrderLineProperty } from "../state/orderLineProperties.model";
import { useLocationId } from "../utils/queryStringState";

interface ParamProps {
  orderlinepropertyid: string;
}

export default function OrderLinePropertyDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.orderlinepropertyid);
  const { orderLineProperty, fetching, error } = useFetchOrderLineProperty({
    id,
    admin: true,
  });

  return (
    <DetailScreen<OrderLineProperty>
      defaults={
        (location.state as { defaults?: Partial<OrderLineProperty> })?.defaults
      }
      onIdChange={setId}
      fetching={fetching}
      error={error}
      original={orderLineProperty}
      isNew={match.params.orderlinepropertyid === "new"}
      route={[{ "Order Line Properties": "/admin/order-line-properties" }]}
      renderForm={OrderLinePropertyDetailForm}
      onSave={adminSaveOrderLineProperty}
      onDelete={adminDeleteOrderLineProperty}
    />
  );
}

function OrderLinePropertyDetailForm({
  getField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<OrderLineProperty>) {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="orderLineId">Order Line</Label>
            <OrderLineSelect
              admin
              isDisabled={readOnly}
              id="orderLineId"
              value={getField("orderLineId")}
              onChange={(newValue) =>
                changeFieldValue("orderLineId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AttributeValueFields
            readOnly={readOnly}
            productAttributeId={getField("productAttributeId")}
            onProductAttributeIdChange={(newValue) =>
              changeFieldValue("productAttributeId", null, newValue)
            }
            productAttributeOptionId={getField("productAttributeOptionId")}
            onProductAttributeOptionIdChange={(newValue) =>
              changeFieldValue("productAttributeOptionId", null, newValue)
            }
            value={getField("value")}
            onValueChange={(newValue) =>
              changeFieldValue("value", null, newValue)
            }
          />
        </Col>
      </Row>
      <AdminCommonDetailRow<OrderLineProperty>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
