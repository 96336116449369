import React from "react";
import { Alert, Col, Row } from "reactstrap";
import config from "../config";

export default function WarnIE(): JSX.Element | null {
  if (!config.IS_IE) {
    return null;
  }

  return (
    <Row>
      <Col>
        <Alert color="warning" className="mt-2">
          Internet Explorer may not work correctly. If possible, please use a
          modern browser.
        </Alert>
      </Col>
    </Row>
  );
}
