import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import AdminCommonDetailRow from "../components/AdminCommonDetailRow";
import DetailScreen, { FormCallbacks } from "../components/DetailScreen";
import CustomerSelect from "../selects/CustomerSelect";
import OrderSelect from "../selects/OrderSelect";
import ProductSkuSelect from "../selects/ProductSkuSelect";
import UserSelect from "../selects/UserSelect";
import { useCombinedStore } from "../state/combinedStore";
import {
  adminDeleteOrderLine,
  adminSaveOrderLine,
  useFetchOrderLine,
} from "../state/orderLines.api";
import { OrderLine } from "../state/orderLines.model";
import { useLocationId } from "../utils/queryStringState";
import OrderLinePropertyListPage from "./OrderLinePropertyListPage";
import ProductSkuPropertyListPage from "./ProductSkuPropertyListPage";

interface ParamProps {
  orderlineid: string;
}

export default function OrderLineDetailPage({
  match,
  location,
}: RouteComponentProps<ParamProps>): JSX.Element {
  const [id, setId] = useLocationId(match.params.orderlineid);
  const { orderLine, fetching, error } = useFetchOrderLine({ id, admin: true });

  return (
    <>
      <DetailScreen<OrderLine>
        defaults={
          (location.state as { defaults?: Partial<OrderLine> })?.defaults
        }
        onIdChange={setId}
        fetching={fetching}
        error={error}
        original={orderLine}
        isNew={match.params.orderlineid === "new"}
        route={[{ "Order Lines": "/admin/order-lines" }]}
        renderForm={(props) => <OrderLineDetailForm {...props} />}
        onSave={adminSaveOrderLine}
        onDelete={adminDeleteOrderLine}
      />

      {!!id && orderLine.productSkuId && (
        <ProductSkuPropertyListPage productSkuId={orderLine.productSkuId} />
      )}
      {!!id && <OrderLinePropertyListPage orderLineId={id} />}
    </>
  );
}

function OrderLineDetailForm({
  getField,
  changeField,
  changeFieldValue,
  readOnly,
}: FormCallbacks<OrderLine>) {
  const superuser = useCombinedStore(
    (store) => store.login.permissions.superuser
  );

  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="orderId">Order</Label>
            <OrderSelect
              admin
              isDisabled={readOnly}
              id="orderId"
              value={getField("orderId")}
              onChange={(newValue) =>
                changeFieldValue("orderId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="productSkuId">Product SKU</Label>
            <ProductSkuSelect
              admin
              isDisabled={readOnly}
              id="productSkuId"
              value={getField("productSkuId")}
              onChange={(newValue) =>
                changeFieldValue("productSkuId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="customerId">Recipient Customer</Label>
            <CustomerSelect
              admin
              isDisabled={readOnly}
              id="customerId"
              value={getField("customerId")}
              onChange={(newValue) =>
                changeFieldValue("customerId", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        {superuser && (
          <Col md={6}>
            <FormGroup>
              <Label for="userId">Recipient User</Label>
              <UserSelect
                admin
                isDisabled={readOnly}
                id="userId"
                value={getField("userId")}
                onChange={(newValue) =>
                  changeFieldValue("userId", null, newValue)
                }
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="unitCost">Unit Cost</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="unitCost"
              id="unitCost"
              value={getField("unitCost")}
              onChange={(event) => changeField("unitCost", event)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="quantity">Quantity</Label>
            <Input
              readOnly={readOnly}
              type="text"
              name="quantity"
              id="quantity"
              value={getField("quantity")}
              onChange={(event) => changeField("quantity", event)}
            />
          </FormGroup>
        </Col>
      </Row>
      <AdminCommonDetailRow<OrderLine>
        getField={getField}
        changeFieldValue={changeFieldValue}
      />
    </Form>
  );
}
