import { Id, ModelBase } from "./types";

/// Anything with a ? was added in a later API version
export interface Upload extends ModelBase {
  userId: Id;
  organisationId: Id;
  description: string;
  originalName: string;
  uri: string;
  viewAs?: string;
  link?: string;
  files?: unknown;
  user?: {
    id: Id;
    firstName: string;
    lastName: string;
  };

  thumbnailUri?: string;
  videoUri?: string;
  garageId?: Id;
  width?: number;
  height?: number;
  size?: number;
  mimeType?: string;
  mediaConvertJobId?: string;
  processingState?: "" | "PROCESSING" | "ERROR";
}

export const blankUpload: Upload = {
  id: 0,
  userId: 0,
  organisationId: 0,
  description: "",
  originalName: "",
  uri: "",
};
