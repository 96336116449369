import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import React, {
  useCallback,
  useMemo,
  useState,
  FormEvent,
  MouseEvent,
  Fragment,
} from "react";
import {
  Alert,
  Button,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { WorkingOverlay } from "../components/LoadingOverlay";
import TableScreen, { TableFilter } from "../components/TableScreen";
import ProductSelect from "../selects/ProductSelect";
import api from "../state/api";
import {
  formatAttributeOrThrow,
  getProductAttributeFormatHint,
  ProductAttributeType,
} from "../state/productAttributes.model";
import { useFetchProduct } from "../state/products.api";
import { Product, ProductProductProperty } from "../state/products.model";
import { ProductSkuProperty } from "../state/productSkuProperties.model";
import { useFetchProductSkus } from "../state/productSkus.api";
import { ProductSku } from "../state/productSkus.model";
import { Id } from "../state/types";
import { formatPrice } from "../utils/decimal";
import { useQueryStringState } from "../utils/queryStringState";

// function formatSkuProperties(props: ProductSkuProductSkuProperty[]): string {
//   // if (true) return JSON.stringify(props);
//   const strings: string[] = [];

//   for (const prop of props) {
//     strings.push(`${prop.product_attribute?.title || ''}: ${prop.product_attribute_option?.title || prop.value}`);
//   }

//   return strings.join(', ');
// }

export default function ProductSkuListPage({
  productId: forceProductId,
}: {
  productId?: Id;
}): JSX.Element {
  const nested = forceProductId !== undefined;
  const prefix = nested ? "prsk_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [productId, setProductId] = useQueryStringState<Id | undefined>(
    "productId",
    0,
    prefix
  );

  const { productSkus, numberOfPages, fetching, error } = useFetchProductSkus({
    page,
    search,
    admin: true,
    query: {
      productId: forceProductId || productId || undefined,
    },
  });

  const [createCombinationsVisible, setCreateCombinationsVisible] = useState(
    false
  );

  const onCreateCombinationsClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setCreateCombinationsVisible(true);
    },
    [setCreateCombinationsVisible]
  );

  const onHideCreateCombinations = useCallback(() => {
    setCreateCombinationsVisible(false);
  }, [setCreateCombinationsVisible]);

  return (
    <>
      <TableScreen<ProductSku>
        defaults={{
          ...(forceProductId ? { productId: forceProductId } : {}),
        }}
        nested={nested}
        fetching={fetching}
        error={error}
        title="Product SKUs"
        singular="Product SKU"
        columns={[
          { title: "ID" },
          { title: "Product" },
          { title: "Price" },
          { title: "Stock" },
          { title: "Properties" },
        ]}
        list={productSkus}
        row={(item) => [
          item.id,
          item.product?.title,
          formatPrice(item.price || item.product?.price || ""),
          item.stock || "0",
          // item.product_sku_properties ? formatSkuProperties(item.product_sku_properties) : '',
          item.all_properties || "",
        ]}
        rowId={(item) => `${item.id}`}
        detailPath="/admin/product-skus/:id"
        currentPage={page}
        numberOfPages={numberOfPages}
        onSelectPage={setPage}
        search={search}
        onSearchChange={setSearch}
        renderFilters={() => (
          <>
            {!nested && (
              <TableFilter label="Product">
                <ProductSelect
                  admin
                  value={productId}
                  onChange={setProductId}
                />
              </TableFilter>
            )}
          </>
        )}
        renderButtons={() => (
          <Button color="primary" onClick={onCreateCombinationsClicked}>
            Create Combinations
          </Button>
        )}
      />
      <CreateCombinationsForm
        isOpen={createCombinationsVisible}
        onHide={onHideCreateCombinations}
        productId={forceProductId}
      />
    </>
  );
}

async function createCombinations(
  product: Product,
  checked: { [id: string]: { [id: string]: boolean } },
  text: { [id: string]: string }
) {
  type VaryingProperty = {
    property: ProductProductProperty;
    selected: (number | string)[];
    isOption: boolean;
  };
  const varyingProperties: VaryingProperty[] = [];
  let total = 1;
  for (const property of product.product_properties || []) {
    if (!property.product_attribute) {
      throw new Error(`Product attributes not provided by server`);
    }
    const attribute = property.product_attribute;
    if (property.product_attribute?.type === ProductAttributeType.OPTION) {
      const checkedForProperty = checked[property.id] || {};
      const selected = [];
      for (const option of property.product_attribute
        ?.product_attribute_options || []) {
        if (checkedForProperty[option.id]) {
          selected.push(option.id);
        }
      }

      if (selected.length) {
        varyingProperties.push({ property, selected, isOption: true });
        total *= selected.length;
      }
    } else {
      const lines = (text[property.id] || "").split("\n").map((s) => s.trim());
      if (lines.length && lines[lines.length - 1] === "") {
        lines.pop();
      }

      if (lines.length) {
        const selected = lines.map((line) =>
          formatAttributeOrThrow(attribute, line)
        );
        varyingProperties.push({ property, selected, isOption: false });
        total *= selected.length;
      }
    }
  }

  if (!varyingProperties.length) {
    throw new Error(`No combinations selected`);
  }

  const skuService = api.service("product-skus");
  const skuPropsService = api.service("product-sku-properties");

  for (let i = 0; i < total; i++) {
    const sku = await skuService.create({ stock: 1, productId: product.id });

    const props = [];
    let base = i;
    for (const vp of varyingProperties) {
      const n = base % vp.selected.length;
      base -= n;
      base /= vp.selected.length;
      props.push(vp.selected[n]);

      const skuProp: Partial<ProductSkuProperty> = {
        productSkuId: sku.id,
        productAttributeId: vp.property.productAttributeId,
      };

      if (vp.isOption) {
        skuProp.productAttributeOptionId = +vp.selected[n];
      } else {
        skuProp.value = String(vp.selected[n]);
      }

      await skuPropsService.create(skuProp);
    }
  }
}

interface CreateCombinationsFormProps {
  isOpen: boolean;
  onHide: () => void;
  productId?: Id;
}

function CreateCombinationsForm({
  isOpen,
  onHide,
  productId: forceProductId,
}: CreateCombinationsFormProps): JSX.Element {
  const [working, setWorking] = useState(false);
  const [error, setError] = useState<unknown>();

  const [productId, setProductId] = useState<Id | undefined>(
    forceProductId || 0
  );

  const { product, error: fetchProductError } = useFetchProduct({
    id: productId,
    admin: true,
  });

  type CheckedType = { [id: string]: { [id: string]: boolean } };
  const [checked, setChecked] = useState<CheckedType>({});

  type TextType = { [id: string]: string };
  const [text, setText] = useState<TextType>({});

  const onCheckboxChange = useCallback(
    (event: FormEvent<HTMLInputElement>, propertyId: Id, optionId: Id) => {
      setChecked({
        ...checked,
        [propertyId]: {
          ...((checked || {})[propertyId] || {}),
          [optionId]: event.currentTarget.checked,
        },
      });
    },
    [checked, setChecked]
  );

  const onTextChange = useCallback(
    (event: FormEvent<HTMLInputElement>, propertyId: Id) => {
      setText({
        ...text,
        [propertyId]: event.currentTarget.value,
      });
    },
    [text, setText]
  );

  const totalCombinations = useMemo(() => {
    let total = 1;

    for (const property of product.product_properties || []) {
      if (property.product_attribute?.type === ProductAttributeType.OPTION) {
        const checkedForProperty = checked[property.id] || {};
        const count = (
          property.product_attribute?.product_attribute_options || []
        ).reduce((value, option) => {
          return value + (checkedForProperty[option.id] ? 1 : 0);
        }, 0);
        total *= Math.max(count, 1);
      } else {
        const lines = (text[property.id] || "")
          .split("\n")
          .map((s) => s.trim());
        const count =
          lines.length -
          (lines.length && lines[lines.length - 1] === "" ? 1 : 0);
        total *= Math.max(count, 1);
      }
    }

    return total;
  }, [text, checked, product]);

  const onConvertFromdateLineTimeLineFormatClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>, propertyId: Id) => {
      event?.preventDefault();

      setText({
        ...text,
        [propertyId]: convertFromDateLineTimeLineFormat(text[propertyId] || ""),
      });
    },
    [text]
  );

  function onCreateClicked(
    event: MouseEvent<HTMLButtonElement>,
    product: Product,
    checked: CheckedType,
    text: TextType
  ) {
    event.preventDefault();

    setWorking(true);

    createCombinations(product, checked, text)
      .then(onHide)
      .catch(setError)
      .finally(() => setWorking(false));
  }

  return (
    <Modal size="lg" isOpen={isOpen}>
      <ModalHeader toggle={working ? undefined : onHide}>
        Create Combinations
      </ModalHeader>
      <ModalBody className="position-relative">
        <WorkingOverlay working={working}>
          {error ? <Alert color="danger">{String(error)}</Alert> : null}
          {fetchProductError ? (
            <Alert color="danger">{String(fetchProductError)}</Alert>
          ) : null}
          {forceProductId === undefined && (
            <Row>
              <Col>
                <FormGroup>
                  <Label>Product</Label>
                  <ProductSelect value={productId} onChange={setProductId} />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              {(product.product_properties || [])
                .filter(
                  (_property) =>
                    true /*!isUserProductPropertyType(property.type)*/
                )
                .map((property) => {
                  return (
                    <Fragment key={property.id}>
                      <h4>{property.product_attribute?.title}</h4>
                      {property.product_attribute?.type ===
                      ProductAttributeType.OPTION ? (
                        <>
                          <p>Tick the options you want to create</p>
                          <Row>
                            {(
                              property.product_attribute
                                ?.product_attribute_options || []
                            ).map((option) => {
                              return (
                                <Col lg={6} key={option.id}>
                                  <FormGroup>
                                    <FormGroup check>
                                      <CustomInput
                                        type="checkbox"
                                        checked={
                                          (checked[property.id] || {})[
                                            option.id
                                          ]
                                            ? true
                                            : false
                                        }
                                        onChange={(event) =>
                                          onCheckboxChange(
                                            event,
                                            property.id,
                                            option.id
                                          )
                                        }
                                        label={option.title}
                                        id={`option-cb-${option.id}`}
                                      />
                                    </FormGroup>
                                  </FormGroup>
                                </Col>
                              );
                            })}
                          </Row>
                        </>
                      ) : (
                        <>
                          <FormGroup>
                            <Label>
                              Enter values, one per line (
                              {property.product_attribute
                                ? getProductAttributeFormatHint(
                                    property.product_attribute
                                  )
                                : ""}
                              )
                            </Label>
                            <Input
                              type="textarea"
                              style={{ height: "9rem" }}
                              value={text[property.id] || ""}
                              onChange={(event) =>
                                onTextChange(event, property.id)
                              }
                            />
                            <Button
                              size="sm"
                              className="mt-2"
                              color="tertiary"
                              onClick={(event) =>
                                onConvertFromdateLineTimeLineFormatClicked(
                                  event,
                                  property.id
                                )
                              }
                            >
                              Convert from date line, time line format
                            </Button>
                          </FormGroup>
                        </>
                      )}
                    </Fragment>
                  );
                })}
            </Col>
          </Row>
        </WorkingOverlay>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onHide} disabled={working}>
          Cancel
        </Button>
        <Button
          disabled={!productId || working}
          color="primary"
          onClick={(event) => onCreateClicked(event, product, checked, text)}
        >
          Create {totalCombinations} {totalCombinations === 1 ? "SKU" : "SKUs"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function convertFromDateLineTimeLineFormat(text: string) {
  const output: string[] = [];

  let date: Date | undefined;

  for (const line of text.split(/[\r\n]/)) {
    const trimmed = line.trim();

    const parsedDateTime = parse(trimmed, "d/M/yyyy H:m", 0);
    if (isValid(parsedDateTime)) {
      output.push(format(parsedDateTime, "dd/M/yyyy HH:mm"));
      continue;
    }

    const parsedDate = parse(trimmed, "d/M/yyyy", 0);
    if (isValid(parsedDate)) {
      date = parsedDate;
      continue;
    }

    const parsedTime = parse(trimmed, "H:m", 0);
    if (isValid(parsedTime)) {
      if (!date) {
        output.push(trimmed);
        continue;
      }

      const newLine = new Date(date);
      newLine.setHours(parsedTime.getHours());
      newLine.setMinutes(parsedTime.getMinutes());
      output.push(format(newLine, "dd/MM/yyyy HH:mm"));
      continue;
    }

    if (trimmed !== "") {
      alert(trimmed);
      return text;
    }
  }

  return output.join("\n");
}
