import {
  fetchUserAddress,
  fetchUserAddresses,
} from "../state/userAddresses.api";
import { blankUserAddress, UserAddress } from "../state/userAddresses.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

const format = (item: UserAddress): string =>
  `${[
    item.firstName,
    item.lastName,
    item.address1,
    item.address2,
    item.address3,
    item.address4,
    item.address5,
    item.postalCode,
  ].join(" ")}`;

export default function UserAddressSelect(
  props: GenericSelectProps<UserAddress>
): JSX.Element {
  return GenericSelect<UserAddress>(
    props,
    fetchUserAddress,
    fetchUserAddresses,
    "user-addresses",
    blankUserAddress,
    format
  );
}
