import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import {
  blankProductAttribute,
  ProductAttribute,
} from "./productAttributes.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchProductAttributesResults extends UseFetchManyResults {
  productAttributes: ProductAttribute[];
  numberOfProductAttributes: number;
  totalNumberOfProductAttributes: number;
}

interface UseFetchProductAttributeResults extends UseFetchOneResults {
  productAttribute: ProductAttribute;
}

const keys: FetcherKeys<
  UseFetchProductAttributesResults,
  UseFetchProductAttributeResults
> = {
  service: "product-attributes",
  totalNumber: "totalNumberOfProductAttributes",
  numberOf: "numberOfProductAttributes",
  records: "productAttributes",
  record: "productAttribute",
};

export async function fetchProductAttributes(
  props: FetchManyProps
): Promise<FetchManyResults<ProductAttribute>> {
  return fetchMany<ProductAttribute>(props, keys.service);
}

export function useFetchProductAttributes(
  props: FetchManyProps
): UseFetchProductAttributesResults {
  return useFetchMany(props, fetchProductAttributes, keys);
}

export async function fetchProductAttribute(
  props: FetchOneProps
): Promise<ProductAttribute> {
  return fetchOne<ProductAttribute, UseFetchProductAttributeResults>(
    props,
    blankProductAttribute,
    keys
  );
}

export function useFetchProductAttribute(
  props: FetchOneProps
): UseFetchProductAttributeResults {
  return useFetchOne(props, fetchProductAttribute, blankProductAttribute, keys);
}

async function saveProductAttribute(
  original: ProductAttribute,
  changes: Partial<ProductAttribute>,
  $role: true | false | string
): Promise<ProductAttribute> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteProductAttribute(
  original: ProductAttribute,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveProductAttribute = makeSaver(saveProductAttribute, true);
export const adminDeleteProductAttribute = makeDeleter(
  deleteProductAttribute,
  true
);
export const userSaveProductAttribute = makeSaver(saveProductAttribute, false);
export const userDeleteProductAttribute = makeDeleter(
  deleteProductAttribute,
  false
);
