import React from "react";
import TableScreen from "../components/TableScreen";
import { useFetchPaymentMethods } from "../state/paymentMethods.api";
import { PaymentMethod } from "../state/paymentMethods.model";
import { useQueryStringState } from "../utils/queryStringState";
import config from "../config";

export default function PaymentMethodListPage(): JSX.Element {
  const [page, setPage] = useQueryStringState("page", 0);
  const [search, setSearch] = useQueryStringState("search", "");

  const {
    paymentMethods,
    numberOfPages,
    fetching,
    error,
  } = useFetchPaymentMethods({ page, search, admin: true });

  return (
    <TableScreen<PaymentMethod>
      defaults={{
        organisationId: config.ORGANISATION_ID,
      }}
      fetching={fetching}
      error={error}
      title="Payment Methods"
      singular="Payment Method"
      columns={[{ title: "ID" }, { title: "Organisation" }, { title: "Title" }]}
      list={paymentMethods}
      row={(item) => [item.id, item.organisation?.name, item.title]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/payment-methods/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
    />
  );
}
