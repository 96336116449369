import React, { useCallback } from "react";
import { fetchProductSku, fetchProductSkus } from "../state/productSkus.api";
import { blankProductSku, ProductSku } from "../state/productSkus.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

const format = (item: ProductSku): string =>
  `#${item.id} ${item.product?.title || ""} ${item.all_properties || ""}`;

export default function ProductSkuSelect(
  props: GenericSelectProps<ProductSku>
): JSX.Element {
  const renderAfter = useCallback(
    (sku: ProductSku) => (
      <div className={sku.stock < 0 ? "text-danger" : ""}>
        {sku.stock} in stock
      </div>
    ),
    []
  );

  return GenericSelect<ProductSku>(
    {
      ...props,
      query: {
        ...(props.query || {}),
        $all_properties: "values",
      },
      renderAfter,
    },
    fetchProductSku,
    fetchProductSkus,
    "product-skus",
    blankProductSku,
    format
  );
}
