import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  FetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  UseFetchManyResults,
  useFetchOne,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { notifyUpdateListeners } from "../updateListeners";
import { makeSaver, makeDeleter } from "./types";
import api from "./api";
import { blankUserAddress, UserAddress } from "./userAddresses.model";

interface UseFetchUserAddressesResults extends UseFetchManyResults {
  userAddresses: UserAddress[];
  numberOfUserAddresses: number;
  totalNumberOfUserAddresses: number;
}

interface UseFetchUserAddressResults extends UseFetchOneResults {
  userAddress: UserAddress;
}

const keys: FetcherKeys<
  UseFetchUserAddressesResults,
  UseFetchUserAddressResults
> = {
  service: "user-addresses",
  totalNumber: "totalNumberOfUserAddresses",
  numberOf: "numberOfUserAddresses",
  records: "userAddresses",
  record: "userAddress",
};

export async function fetchUserAddresses(
  props: FetchManyProps
): Promise<FetchManyResults<UserAddress>> {
  return fetchMany<UserAddress>(props, keys.service);
}

export function useFetchUserAddresses(
  props: FetchManyProps
): UseFetchUserAddressesResults {
  return useFetchMany(props, fetchUserAddresses, keys);
}

export async function fetchUserAddress(
  props: FetchOneProps
): Promise<UserAddress> {
  return fetchOne<UserAddress, UseFetchUserAddressResults>(
    props,
    blankUserAddress,
    keys
  );
}

export function useFetchUserAddress(
  props: FetchOneProps
): UseFetchUserAddressResults {
  return useFetchOne(props, fetchUserAddress, blankUserAddress, keys);
}

async function saveUserAddress(
  original: UserAddress,
  changes: Partial<UserAddress>,
  $role: true | false | string
): Promise<UserAddress> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteUserAddress(
  original: UserAddress,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveUserAddress = makeSaver(saveUserAddress, true);
export const adminDeleteUserAddress = makeDeleter(deleteUserAddress, true);
export const userSaveUserAddress = makeSaver(saveUserAddress, false);
export const userDeleteUserAddress = makeDeleter(deleteUserAddress, false);
