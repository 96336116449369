import { useEffect, useState } from "react";

interface ListenersForRecordType {
  byHandle: { [handle: string]: (...params: unknown[]) => void };
  next: number;
  lastUpdated: number;
}

const listenersByRecordType: {
  [recordType: string]: ListenersForRecordType;
} = {};

export type Handle = { handle: string; recordType: string };

export function addUpdateListener(
  recordType: string,
  onUpdateRequired: () => void
): Handle {
  if (!listenersByRecordType[recordType]) {
    listenersByRecordType[recordType] = {
      byHandle: {},
      next: 0,
      lastUpdated: 0,
    };
  }

  const listeners = listenersByRecordType[recordType];

  const handle = String(listenersByRecordType[recordType].next++);

  listeners.byHandle[handle] = onUpdateRequired;

  return { handle, recordType };
}

export function removeUpdateListener(handle: Handle): void {
  if (!listenersByRecordType[handle.recordType]) {
    return;
  }

  const listeners = listenersByRecordType[handle.recordType];

  if (listeners.byHandle[handle.handle]) {
    delete listeners.byHandle[handle.handle];
  }
}

export function notifyUpdateListeners(
  recordType: string,
  ...params: unknown[]
): void {
  const listeners = listenersByRecordType[recordType];
  if (!listeners) {
    return;
  }

  listeners.lastUpdated = Date.now();

  Object.keys(listeners.byHandle).forEach((handle) => {
    const value = listeners.byHandle[handle];
    // Allow handlers to be removed during each callback by checking the handle still exists
    value && value(...params);
  });
}

export function getLastUpdateMillis(recordType: string): number {
  const listeners = listenersByRecordType[recordType];

  return listeners ? listeners.lastUpdated : 0;
}

export function useUpdateListener(recordType: string): number {
  const [lastRefreshed, setLastRefreshed] = useState(
    getLastUpdateMillis(recordType)
  );

  useEffect(() => {
    const handle = addUpdateListener(recordType, () => {
      setLastRefreshed(Date.now());
    });

    return () => removeUpdateListener(handle);
  }, [recordType]);

  return lastRefreshed;
}
