import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  FetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  UseFetchManyResults,
  useFetchOne,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { notifyUpdateListeners } from "../updateListeners";
import { makeSaver, makeDeleter } from "./types";
import api from "./api";
import { blankProductSku, ProductSku } from "./productSkus.model";

interface UseFetchProductSkusResults extends UseFetchManyResults {
  productSkus: ProductSku[];
  numberOfProductSkus: number;
  totalNumberOfProductSkus: number;
}

interface UseFetchProductSkuResults extends UseFetchOneResults {
  productSku: ProductSku;
}

const keys: FetcherKeys<
  UseFetchProductSkusResults,
  UseFetchProductSkuResults
> = {
  service: "product-skus",
  totalNumber: "totalNumberOfProductSkus",
  numberOf: "numberOfProductSkus",
  records: "productSkus",
  record: "productSku",
};

export async function fetchProductSkus(
  props: FetchManyProps
): Promise<FetchManyResults<ProductSku>> {
  return fetchMany<ProductSku>(props, keys.service);
}

export function useFetchProductSkus(
  props: FetchManyProps
): UseFetchProductSkusResults {
  return useFetchMany(props, fetchProductSkus, keys);
}

export async function fetchProductSku(
  props: FetchOneProps
): Promise<ProductSku> {
  return fetchOne<ProductSku, UseFetchProductSkuResults>(
    props,
    blankProductSku,
    keys
  );
}

export function useFetchProductSku(
  props: FetchOneProps
): UseFetchProductSkuResults {
  return useFetchOne(props, fetchProductSku, blankProductSku, keys);
}

async function saveProductSku(
  original: ProductSku,
  changes: Partial<ProductSku>,
  $role: true | false | string
): Promise<ProductSku> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deleteProductSku(
  original: ProductSku,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveProductSku = makeSaver(saveProductSku, true);
export const adminDeleteProductSku = makeDeleter(deleteProductSku, true);
export const userSaveProductSku = makeSaver(saveProductSku, false);
export const userDeleteProductSku = makeDeleter(deleteProductSku, false);
