export function endsWithSlash(s: string): boolean {
  return s.lastIndexOf("/") === s.length - 1;
}

export function pathJoin(part1: string, ...parts: string[]): string {
  let output = part1;

  for (const part of parts) {
    if (endsWithSlash(output)) {
      if (part.indexOf("/") === 0) {
        output += part.substr(1);
      } else {
        output += part;
      }
    } else {
      if (part.indexOf("/") !== 0) {
        output += "/";
      }
      output += part;
    }
  }

  return output;
}
