import { fetchProduct, fetchProducts } from "../state/products.api";
import { blankProduct, Product } from "../state/products.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

const format = (item: Product): string => `#${item.id} ${item.title}`;

export default function ProductSelect(
  props: GenericSelectProps<Product>
): JSX.Element {
  return GenericSelect<Product>(
    props,
    fetchProduct,
    fetchProducts,
    "products",
    blankProduct,
    format
  );
}
