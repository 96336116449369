import React, { FormEvent, useCallback, useState } from "react";
import { Col, Collapse, FormGroup, Label, Row } from "reactstrap";
import DateTimeInput from "../components/DateTimeInput";
import { FormCallbacks } from "../components/DetailScreen";
import config from "../config";
import { ModelBase } from "../state/types";

type AdminCommonRecord = Omit<ModelBase, "id">;

export default function AdminCommonDetailRow<Type extends AdminCommonRecord>({
  getField,
  changeFieldValue,
}: Pick<FormCallbacks<Type>, "getField" | "changeFieldValue">): JSX.Element {
  const [showJson, setShowJson] = useState(false);
  const onViewJsonClicked = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setShowJson((current) => !current);
    },
    []
  );

  return (
    <>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="createdAt">Created</Label>
            <DateTimeInput
              readOnly
              callbacks={config.DATE_TIME_CALLBACKS}
              name="createdAt"
              id="createdAt"
              value={getField("createdAt")}
              onChange={(newValue) =>
                changeFieldValue("createdAt", null, newValue)
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="updatedAt">Updated</Label>
            <DateTimeInput
              callbacks={config.DATE_TIME_CALLBACKS}
              readOnly
              name="updatedAt"
              id="updatedAt"
              value={getField("updatedAt")}
              onChange={(newValue) =>
                changeFieldValue("updatedAt", null, newValue)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      {!config.IS_PRODUCTION && (
        <Row>
          <Col>
            <Collapse isOpen={showJson}>
              <pre>{JSON.stringify(getField("$original"), null, "  ")}</pre>
            </Collapse>
            <button className="link-button" onClick={onViewJsonClicked}>
              {showJson ? "Hide JSON" : "View JSON"}
            </button>
          </Col>
        </Row>
      )}
    </>
  );
}
