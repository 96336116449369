export function isTrue(s: unknown): boolean {
  if (!s) {
    return false;
  }
  switch (`${s}`.toLowerCase()) {
    case "1":
    case "yes":
    case "true":
      return true;
    default:
      return false;
  }
}

export function getErrorDescription(error: unknown): string {
  if (!error) {
    return "";
  }

  if (
    error instanceof Error ||
    typeof (error as Record<string, unknown>).message === "string"
  ) {
    return (error as Record<string, unknown>).message as string;
  }

  return String(error);
}

export function formatBoolean(value: unknown): string {
  return value ? "yes" : "no";
}

export function spaceship<T>(a: T, b: T): number {
  return a < b ? -1 : b > a ? 1 : 0;
}
