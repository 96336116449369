import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { blankPayment, Payment } from "./payments.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchPaymentsResults extends UseFetchManyResults {
  payments: Payment[];
  numberOfPayments: number;
  totalNumberOfPayments: number;
}

interface UseFetchPaymentResults extends UseFetchOneResults {
  payment: Payment;
}

const keys: FetcherKeys<UseFetchPaymentsResults, UseFetchPaymentResults> = {
  service: "payments",
  totalNumber: "totalNumberOfPayments",
  numberOf: "numberOfPayments",
  records: "payments",
  record: "payment",
};

export async function fetchPayments(
  props: FetchManyProps
): Promise<FetchManyResults<Payment>> {
  return fetchMany<Payment>(props, keys.service);
}

export function useFetchPayments(
  props: FetchManyProps
): UseFetchPaymentsResults {
  return useFetchMany(props, fetchPayments, keys);
}

export async function fetchPayment(props: FetchOneProps): Promise<Payment> {
  return fetchOne<Payment, UseFetchPaymentResults>(props, blankPayment, keys);
}

export function useFetchPayment(props: FetchOneProps): UseFetchPaymentResults {
  return useFetchOne(props, fetchPayment, blankPayment, keys);
}

async function savePayment(
  original: Payment,
  changes: Partial<Payment>,
  $role: true | false | string
): Promise<Payment> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deletePayment(original: Payment, $role: true | false | string) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSavePayment = makeSaver(savePayment, true);
export const adminDeletePayment = makeDeleter(deletePayment, true);
export const userSavePayment = makeSaver(savePayment, false);
export const userDeletePayment = makeDeleter(deletePayment, false);
