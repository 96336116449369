import keys from "lodash/keys";
import React, {
  FormEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ButtonSpacer } from "../components/ButtonSpacer";
import FormErrorText from "../components/FormErrorText";
import { LoadingOverlay } from "../components/LoadingOverlay";
import { LogoCardBody, LogoCardHeader } from "../components/LogoCardHeader";
import { CombinedStore } from "../state/combinedStore";
import loginActions from "../state/login.actions";
import { ApiState } from "../state/types";
import { useAutofocus } from "../utils/dom";
import { usePrevious } from "../utils/hooks";

export default function RegisterScreen(): JSX.Element {
  const onRegistered = useCallback(() => {
    /* no op */
  }, []);

  return (
    <Container className="mt-5 mb-5 RegisterScreen">
      <Row className="justify-content-center">
        <Col xs="auto">
          <RegisterForm onRegistered={onRegistered} />
        </Col>
      </Row>
    </Container>
  );
}

interface RegisterFormProps {
  onRegistered?: () => void;
  onLoggedIn?: () => void;
  onCancel?: () => void;
  onLoginClicked?: () => void;
}

export function RegisterForm({
  onRegistered,
  onCancel,
  onLoginClicked,
  onLoggedIn,
}: RegisterFormProps): JSX.Element {
  const registrationState = useSelector<CombinedStore, ApiState | undefined>(
    (store) => store.login.createState
  );
  const previousRegistrationState = usePrevious(registrationState);
  useEffect(() => {
    if (
      registrationState === "completed" &&
      previousRegistrationState !== "completed"
    ) {
      onRegistered && onRegistered();
    }
  }, [registrationState, onRegistered, previousRegistrationState]);

  const isLoggedIn = useSelector<CombinedStore>(
    (store) => store.login.isLoggedIn
  );
  const wasLoggedIn = usePrevious(isLoggedIn);
  useEffect(() => {
    if (isLoggedIn && !wasLoggedIn) {
      onLoggedIn && onLoggedIn();
    }
  }, [isLoggedIn, wasLoggedIn, onLoggedIn]);

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isPosted, setIsPosted] = useState(false);
  const [errors, setErrors] = useState<Record<string, unknown>>({});

  const onSubmit = useCallback(
    (event: FormEvent<HTMLElement>) => {
      event.preventDefault();

      const errors: Record<string, unknown> = {};

      if (email.indexOf("@") < 1) {
        errors["email"] = "Please enter an email address";
      }
      if (password.length < 8) {
        errors["password"] = "Your password must be at least 8 characters";
      } else if (password2 !== password) {
        errors["password2"] = "Passwords do not match";
      }

      if (firstName.trim() === "") {
        errors["firstName"] = "We need your full name";
      }
      if (lastName.trim() === "") {
        errors["lastName"] = "We need your full name";
      }

      setErrors(errors);
      setIsPosted(true);
      if (keys(errors).length > 0) {
        return;
      }

      dispatch(
        loginActions.createAccount(email, { password, firstName, lastName })
      );
    },
    [email, password, password2, firstName, lastName, dispatch]
  );

  const onTryAgain = useCallback(
    (event: FormEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setIsPosted(false);
      setErrors({});
    },
    [setIsPosted, setErrors]
  );

  const onLoginLinkClicked = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      onLoginClicked && onLoginClicked();
    },
    [onLoginClicked]
  );

  const autofocus = useAutofocus();

  return (
    <Form onSubmit={onSubmit}>
      <Card className="position-relative">
        <LogoCardHeader />
        <LogoCardBody className="p-4">
          {isPosted && registrationState === "failed" ? (
            <>
              <p>
                We were unable to complete your registration request. You may{" "}
                <a href="/" onClick={onTryAgain}>
                  try again
                </a>
                .
              </p>
            </>
          ) : isPosted && registrationState === "completed" && !onLoggedIn ? (
            <>
              <h4>Thanks for registering!</h4>
              {isLoggedIn ? null : (
                <p>
                  You may now <Link to="/login">sign in</Link>.
                </p>
              )}
            </>
          ) : (
            <>
              <h4 className="text-center">Create your account</h4>
              <p className="text-center">
                Please use your own name, as we{`'`}ll ask for your child{`'`}s
                details later
              </p>
              <FormGroup>
                <Label for="firstName">First name</Label>
                <Input
                  innerRef={autofocus}
                  type="text"
                  name="firstName"
                  onChange={(event) => setFirstName(event.currentTarget.value)}
                  value={firstName}
                  autoComplete="given-name"
                  autoCapitalize="words"
                />
                <FormErrorText error={isPosted && errors.firstName} />
              </FormGroup>
              <FormGroup>
                <Label for="lastName">Last name</Label>
                <Input
                  type="text"
                  name="lastName"
                  onChange={(event) => setLastName(event.currentTarget.value)}
                  value={lastName}
                  autoComplete="family-name"
                  autoCapitalize="words"
                />
                <FormErrorText error={isPosted && errors.lastName} />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  onChange={(event) => setEmail(event.currentTarget.value)}
                  value={email}
                  autoComplete="email"
                />
                <FormErrorText error={isPosted && errors.email} />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  type="password"
                  name="password"
                  onChange={(event) => setPassword(event.currentTarget.value)}
                  value={password}
                  autoCapitalize="off"
                  spellCheck={false}
                />
                <FormErrorText error={isPosted && errors.password} />
              </FormGroup>
              <FormGroup>
                <Label for="password2">Password again, to confirm</Label>
                <Input
                  type="password"
                  name="password2"
                  onChange={(event) => setPassword2(event.currentTarget.value)}
                  value={password2}
                  autoCapitalize="off"
                  spellCheck={false}
                />
                <FormErrorText error={isPosted && errors.password2} />
              </FormGroup>
              <div className="text-center mt-4">
                {onCancel && (
                  <>
                    <Button color="secondary" onClick={onCancel}>
                      Cancel
                    </Button>
                    <ButtonSpacer />
                  </>
                )}
                <Button type="submit" color="primary">
                  Register
                </Button>
              </div>
            </>
          )}
        </LogoCardBody>
        <CardFooter className="text-center">
          Already have an account?{" "}
          <Link to="/login" onClick={onLoginClicked && onLoginLinkClicked}>
            Sign in
          </Link>
        </CardFooter>
        {(registrationState === "started" ||
          (isPosted && registrationState === "completed" && onLoggedIn)) && (
          <LoadingOverlay />
        )}
      </Card>
    </Form>
  );
}
