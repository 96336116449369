import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import { DateTimeInputCallbacks } from "./components/DateTimeInput";
import { invalidDateToNumberOrUndefined } from "./utils/datetime";

const ORGANISATION_ID = 1;

const IS_IE =
  !!window.MSInputMethodContext &&
  !!((document as unknown) as Record<string, unknown>).documentMode;

// This will be used if using a hardcoded API root, otherwise it'll be replaced by generateAutoAPIRoot
const API_PATH = `rockleyapp-api/v1`;

const IS_PRODUCTION = process.env.NODE_ENV === "production";

const USE_SOCKETIO = !IS_IE && IS_PRODUCTION;

const ENABLE_LOGGING = !IS_PRODUCTION;

const STATE_VERSION = 8;
const STATE_KEY = `rockleyapp_v${STATE_VERSION}`;

const DATE_TIME_FORMAT = `dd/MM/yyyy HH:mm:ss`;
const DATE_TIME_FORMAT_WITHOUT_SECONDS = "dd/MM/yyyy HH:mm";

const DATE_FORMAT = `dd/MM/yyyy`;
const DATE_FORMAT_WITH_DAY = "E dd MMM yyyy";

const SERVER_DATE_FORMAT = "yyyy-MM-dd";
const SERVER_DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

const PAYMENT_METHOD_ID = 1;

const DATE_CALLBACKS: DateTimeInputCallbacks = {
  hasTime: false,
  formatForDisplay: (date: number): string => format(date, DATE_FORMAT),
  parseFromDisplay: (date: string): number | undefined =>
    invalidDateToNumberOrUndefined(parse(date, DATE_FORMAT, 0)),
  format: (date: number): string => format(date, SERVER_DATE_FORMAT),
  parse: (date: string): number | undefined =>
    invalidDateToNumberOrUndefined(parse(date, SERVER_DATE_FORMAT, 0)),
};

const DATE_TIME_CALLBACKS: DateTimeInputCallbacks = {
  hasTime: true,
  formatForDisplay: (date: number): string => {
    return format(date, DATE_TIME_FORMAT);
  },
  parseFromDisplay: (date: string): number | undefined => {
    const parsed = parse(date, DATE_TIME_FORMAT, 0);
    return isValid(parsed) ? +parsed : undefined;
  },
  format: (date: number): string => {
    return new Date(date).toISOString();
  },
  parse: (date: string): number | undefined => {
    const parsed = parseISO(date);
    return isValid(parsed) ? +parsed : undefined;
  },
};

const config = {
  ORGANISATION_ID,
  IS_IE,
  API_PATH,
  IS_PRODUCTION,
  USE_SOCKETIO,
  ENABLE_LOGGING,
  STATE_VERSION,
  STATE_KEY,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  PAYMENT_METHOD_ID,
  DATE_TIME_CALLBACKS,
  DATE_CALLBACKS,
  DATE_FORMAT_WITH_DAY,
  DATE_TIME_FORMAT_WITHOUT_SECONDS,
  SERVER_DATE_FORMAT,
  SERVER_DATE_TIME_FORMAT,
};

export default config;
