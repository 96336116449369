import { ProductAttributeOptionPick } from "./productAttributeOptions.model";
import { ProductAttributePick } from "./productAttributes.model";
import { Id, ModelBase } from "./types";

/// Anything with a ? was added in a later API version
export interface ProductSkuProperty extends ModelBase {
  productSkuId: Id;
  product_sku?: {
    id: Id;
    product?: {
      id: Id;
      title: string;
    };
  };
  productAttributeId: Id;
  product_attribute?: ProductAttributePick;
  productAttributeOptionId?: Id;
  product_attribute_option?: ProductAttributeOptionPick;
  value?: string;
}

export const blankProductSkuProperty: ProductSkuProperty = {
  id: 0,
  productSkuId: 0,
  productAttributeId: 0,
};
