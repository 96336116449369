import api from "./api";
import { AsyncAction } from "./combinedStore";
import {
  LoginAction,
  LoginActions,
  PatchCreatingUserLoginAction,
  SetNewUserTokenLoginAction,
} from "./login.reducer";
import { User, UserPreferences } from "./users.model";

/**
 * Create a new account. If an account with that email address already
 * exists, will faill with a BadRequest.
 *
 * @param email Email address for the new account
 * @param options Optional data for the new user record. If a password is
 *  specified, the user won't receive the kind of verification email
 *  needed by initialiseAccount.
 * @param callback Called with undefined on success, or an error
 */
function createAccount(
  email: string,
  options: {
    firstName?: string;
    lastName?: string;
    password?: string;
  },
  callback?: (error: unknown, user?: User) => void
): AsyncAction<LoginAction> {
  return async (dispatch) => {
    try {
      await api.authentication.reset();
    } catch (error) {
      console.log(`Couldn't reset authentication`, error);
    }

    try {
      dispatch({ type: LoginActions.LOGOUT_COMPLETED });
      dispatch({ type: LoginActions.CREATE_STARTED, email });

      const user = await api.service("users").create({
        email,
        ...options,
      });

      console.log(`Created user`, user);

      if (options.password) {
        dispatch(
          logIn(email, options.password, (error, user) => {
            if (error || !user) {
              console.log(`Couldn't log-in new user`, error);
              dispatch({ type: LoginActions.CREATE_FAILED, error });
              callback && callback(error || true);
              return;
            }

            dispatch({ type: LoginActions.CREATE_COMPLETED, user });
            callback && callback(null, user);
          })
        );
      } else {
        dispatch({ type: LoginActions.CREATE_COMPLETED, user });
        callback && callback(null, user);
      }
    } catch (error) {
      console.log(`Couldn't create user`, error);
      dispatch({ type: LoginActions.CREATE_FAILED, error });
      callback && callback(error || true);
    }
  };
}

function setNewUserToken(token: string): SetNewUserTokenLoginAction {
  return { type: LoginActions.SET_NEW_USER_TOKEN, token };
}

/**
 * Log the user in and get an access token and JWT.
 *
 * @param email Email address of the user's account
 * @param password Password
 * @param callback Called with undefined on success, or an error
 */
function logIn(
  email: string,
  password: string,
  callback?: (error: unknown | undefined, user?: User) => void
): AsyncAction<LoginAction> {
  return async (dispatch) => {
    dispatch({ type: LoginActions.LOGIN_STARTED });
    try {
      await api.authenticate({
        strategy: "local",
        email,
        password,
      });

      const { user, accessToken } = await api.get("authentication");

      dispatch({ type: LoginActions.LOGIN_COMPLETED, user, accessToken });
      callback && callback(undefined, user);
    } catch (error) {
      dispatch({ type: LoginActions.LOGIN_FAILED, error });
      callback && callback(error || true);
    }
  };
}

/**
 * Logout in a server friendly fashion
 * @param callback Called with undefined on success, or an error
 */
function logOut(
  callback?: (error: unknown | undefined) => void
): AsyncAction<LoginAction> {
  return async (dispatch) => {
    dispatch({ type: LoginActions.LOGOUT_STARTED });

    try {
      await api.logout();
      callback && callback(undefined);
    } catch (error) {
      dispatch({ type: LoginActions.LOGOUT_FAILED, error });
      callback && callback(error);
    } finally {
      try {
        await api.authentication.reset();
      } catch (error) {
        // Ignore it
      }
    }

    dispatch({ type: LoginActions.LOGOUT_COMPLETED });
  };
}

function patchCreatingUser(patch: Partial<User>): PatchCreatingUserLoginAction {
  return { type: LoginActions.PATCH_CREATING_USER, patch };
}

/**
 * Uses the new user token to do a password reset on a new account and
 * simultaneously update the user from the creatingUser object.
 *
 * @param username Username to create
 * @param password Password to set
 * @param callback Called with undefined on success, or an error
 */
function initialiseAccount(
  username: string,
  password: string,
  callback?: (error: unknown | undefined) => void
): AsyncAction<LoginAction> {
  return async (dispatch, getState) => {
    dispatch({ type: LoginActions.INITIALISE_ACCOUNT_STARTED });

    const token = getState().login.newUserToken;
    const creatingUser = getState().login.creatingUser;
    if (!token) {
      throw new Error("Missing token");
    }
    if (!creatingUser?.email) {
      throw new Error("Missing email address");
    }

    try {
      await api.service("user-verification").create({
        action: "reset-password",
        token,
        newPassword: password,
        patch: {
          firstName: creatingUser?.firstName,
          lastName: creatingUser?.lastName,
          username,
        },
      });

      dispatch({ type: LoginActions.LOGIN_STARTED });

      try {
        await api.authenticate({
          strategy: "local",
          email: creatingUser?.email,
          password,
        });
      } catch (loginError) {
        dispatch({ type: LoginActions.LOGIN_FAILED, error: loginError });
        throw loginError;
      }

      const { user, accessToken } = await api.get("authentication");

      dispatch({ type: LoginActions.LOGIN_COMPLETED, user, accessToken });

      dispatch({ type: LoginActions.INITIALISE_ACCOUNT_COMPLETED });
      callback && callback(null);
    } catch (error) {
      dispatch({ type: LoginActions.INITIALISE_ACCOUNT_FAILED, error });
      callback && callback(error || true);
    }
  };
}

/**
 * Asks the server to re-send the user's verification email. Only works for
 * accounts that haven't already been verified.
 *
 * @param email Email address we're trying to verify
 * @param callback Called with undefined on success, or an error
 */
function resendVerificationToken(
  email: string,
  callback?: (erorr: undefined | unknown) => void
): AsyncAction<LoginAction> {
  return async (dispatch) => {
    dispatch({ type: LoginActions.RESEND_VERIFICATION_STARTED, email });

    try {
      await api.service("user-verification").create({
        action: "resend-verification",
        email,
      });

      dispatch({
        type: LoginActions.RESEND_VERIFICATION_COMPLETED,
      });
      callback && callback(undefined);
    } catch (error) {
      dispatch({ type: LoginActions.RESEND_VERIFICATION_FAILED, error });
      callback && callback(error || true);
    }
  };
}

/**
 * Verify a token. Marks the user's account as verified.
 *
 * @param token Token sent by the server to the user's email address
 * @param callback Receives an error or a User
 */
function verifyToken(
  token: string,
  callback?: (erorr: undefined | unknown, user?: User) => void
): AsyncAction<LoginAction> {
  return async (dispatch) => {
    dispatch({ type: LoginActions.VERIFY_TOKEN_STARTED, token });

    try {
      await api.service("user-verification").create({
        action: "verify-token",
        token,
      });

      dispatch({
        type: LoginActions.VERIFY_TOKEN_COMPLETED,
      });
      callback && callback(undefined);
    } catch (error) {
      dispatch({ type: LoginActions.VERIFY_TOKEN_FAILED, error });
      callback && callback(error || true);
    }
  };
}

/**
 * Send a password reset email.
 *
 * @param email Email to send password reset to
 * @param callback Called with undefined on success, or an error
 */
function requestPasswordReset(
  email: string,
  callback?: (error: unknown | undefined) => void
): AsyncAction<LoginAction> {
  return async (dispatch) => {
    dispatch({ type: LoginActions.REQUEST_PASSWORD_RESET_STARTED, email });

    try {
      await api.service("user-verification").create({
        action: "send-password-reset",
        email,
      });

      dispatch({
        type: LoginActions.REQUEST_PASSWORD_RESET_COMPLETED,
      });
      callback && callback(undefined);
    } catch (error) {
      dispatch({ type: LoginActions.REQUEST_PASSWORD_RESET_FAILED, error });
      callback && callback(error || true);
    }
  };
}

/**
 * Reset a user's password.
 *
 * @param token The token sent to the user after they requested a password reset
 * @param newPassword The new password to set
 * @param callback Called with undefined on success, or an error
 */
function resetPassword(
  token: string,
  newPassword: string,
  callback?: (error: unknown | undefined) => void
): AsyncAction<LoginAction> {
  return async (dispatch) => {
    dispatch({ type: LoginActions.PASSWORD_RESET_STARTED });

    try {
      await api.service("user-verification").create({
        action: "reset-password",
        token,
        newPassword,
      });

      dispatch({
        type: LoginActions.PASSWORD_RESET_COMPLETED,
      });
      callback && callback(undefined);
    } catch (error) {
      dispatch({ type: LoginActions.PASSWORD_RESET_FAILED, error });
      callback && callback(error || true);
    }
  };
}

/**
 * Reauthenticate, which updates the user record.
 * Does not issue a LOGOUT_COMPLETED.
 *
 * @param callback Called with undefined on success, or an error
 */
function reauthenticate(
  callback?: (error: unknown | undefined, user?: User) => void
): AsyncAction<LoginAction> {
  return async (dispatch) => {
    dispatch({ type: LoginActions.REAUTHENTICATE_STARTED });

    try {
      await api.authentication.reAuthenticate(true);

      const { user, accessToken } = await api.get("authentication");

      dispatch({ type: LoginActions.LOGIN_COMPLETED, user, accessToken });

      dispatch({ type: LoginActions.REAUTHENTICATE_COMPLETED });
      callback && callback(undefined);
    } catch (error) {
      dispatch({ type: LoginActions.LOGOUT_STARTED });
      dispatch({ type: LoginActions.LOGOUT_COMPLETED });
      dispatch({ type: LoginActions.REAUTHENTICATE_FAILED, error });
      callback && callback(error || true);
    }
  };
}

function queueMessage(message: string): LoginAction {
  return { type: LoginActions.QUEUE_MESSAGE, message: { message } };
}

function dequeueMessage(): LoginAction {
  return { type: LoginActions.DEQUEUE_MESSAGE };
}

/**
 * Patch the user's User record. Lots of restrictions on the server as to
 * what can be patched.
 *
 * @param patch Partial object containing the patch
 * @param callback Called with undefined on success, or an error
 */
function patchUser(
  patch: Partial<User>,
  callback?: (error: unknown | undefined, user?: Partial<User>) => void
): AsyncAction<LoginAction> {
  return async (dispatch, getState) => {
    dispatch({ type: LoginActions.PATCH_USER_STARTED });

    const userId = getState().login.user.id;

    try {
      const user = (await api.service("users").patch(userId, patch)) as User;

      dispatch({ type: LoginActions.PATCH_USER_COMPLETED, updatedUser: user });
      callback && callback(undefined, user);
    } catch (error) {
      dispatch({ type: LoginActions.PATCH_USER_FAILED, error });
      callback && callback(error || true);
    }
  };
}

function requestEmailChange(
  email: string,
  password: string,
  newEmail: string,
  callback?: (error: unknown | undefined) => void
): AsyncAction<LoginAction> {
  return async (dispatch) => {
    dispatch({ type: LoginActions.REQUEST_EMAIL_CHANGE_STARTED });

    try {
      await api.service("user-verification").create({
        action: "change-email",
        email,
        password,
        newEmail,
      });

      dispatch({
        type: LoginActions.REQUEST_EMAIL_CHANGE_COMPLETED,
      });
      callback && callback(undefined);
    } catch (error) {
      dispatch({ type: LoginActions.REQUEST_EMAIL_CHANGE_FAILED, error });
      callback && callback(error || true);
    }
  };
}

function changePassword(
  currentPassword: string,
  newPassword: string,
  callback?: (error: unknown | undefined) => void
): AsyncAction<LoginAction> {
  return async (dispatch, getState) => {
    dispatch({ type: LoginActions.REQUEST_PASSWORD_CHANGE_STARTED });

    const email = getState().login.user.email;

    try {
      await api.service("user-verification").create({
        action: "change-password",
        email,
        oldPassword: currentPassword,
        newPassword,
      });

      dispatch({
        type: LoginActions.REQUEST_PASSWORD_CHANGE_COMPLETED,
      });
      callback && callback(undefined);
    } catch (error) {
      dispatch({ type: LoginActions.REQUEST_PASSWORD_CHANGE_FAILED, error });
      callback && callback(error || true);
    }
  };
}

function patchPreferences(
  path: string,
  newValue: unknown,
  callback?: (error: unknown | undefined, preferences?: UserPreferences) => void
): AsyncAction<LoginAction> {
  return async (dispatch) => {
    dispatch({ type: LoginActions.PATCH_PREFERENCES_STARTED });

    try {
      const preferences = await api.service("user-preferences").create({
        path,
        newValue,
      });

      dispatch({
        type: LoginActions.PATCH_PREFERENCES_COMPLETED,
        preferences,
      });
      callback && callback(undefined);
    } catch (error) {
      dispatch({ type: LoginActions.PATCH_PREFERENCES_FAILED, error });
      callback && callback(error || true);
    }
  };
}

const loginActions = {
  logIn,
  logOut,
  createAccount,
  setNewUserToken,
  patchCreatingUser,
  initialiseAccount,
  resendVerificationToken,
  requestPasswordReset,
  resetPassword,
  verifyToken,
  queueMessage,
  dequeueMessage,
  reauthenticate,
  patchUser,
  requestEmailChange,
  changePassword,
  patchPreferences,
};

export default loginActions;
