import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CombinedStore, useCombinedStore } from "./combinedStore";
import familyActions from "./family.actions";
import { Family, FamilyUser } from "./family.model";
import { FamilyStore } from "./family.reducer";
import { equalIds, Id } from "./types";

export function useFetchFamily(): {
  family: Family;
  fetching: boolean;
  error?: string;
  children: FamilyUser[];
} {
  const dispatch = useDispatch();
  const userId = useCombinedStore((store) => store.login.user?.id || 0);

  const { family, fetchFamilyState } = useSelector<CombinedStore, FamilyStore>(
    (store) => store.family
  );

  useEffect(() => {
    dispatch(familyActions.fetchFamily());
  }, [dispatch, userId]);

  const children = useMemo(() => {
    const array: FamilyUser[] = [];

    for (const user of family.users) {
      if (user.role === "child") {
        array.push(user);
      }
    }

    return array;
  }, [family]);

  return {
    family,
    fetching: fetchFamilyState === "started",
    error: fetchFamilyState?.error,
    children,
  };
}

export async function getFamilyMemberByUserId(
  store: CombinedStore,
  userId: Id
): Promise<FamilyUser | undefined> {
  const family = store.family.family;

  if (!userId) {
    return undefined;
  }

  for (const user of family.users) {
    if (equalIds(user.id, userId)) {
      return user;
    }
  }

  return undefined;
}
