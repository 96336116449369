import iso3166 from "iso-3166";
import filter from "lodash/filter";

export const COUNTRY_OPTIONS = [
  { value: "GB", label: "United Kingdom" },
  ...filter(iso3166, (entry) => entry.alpha2 !== "GB").map((entry) => ({
    value: entry.alpha2,
    label: entry.name,
  })),
];
