import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Col, Container, Row } from "reactstrap";
import { LogoCardBody, LogoCardHeader } from "../components/LogoCardHeader";
import { CombinedStore } from "../state/combinedStore";
import { usePrevious } from "../utils/hooks";
import { LoginForm } from "./LoginScreen";
import { RegisterForm } from "./RegisterScreen";

export default function LoginOrRegisterScreen(): JSX.Element {
  return (
    <Container className="mt-5 mb-5 LoginScreen">
      <Row className="justify-content-center">
        <Col xs="auto">
          <LoginOrRegisterForm />
        </Col>
      </Row>
    </Container>
  );
}

export interface LoginOrRegisterFormProps {
  onRegisterClicked?: () => void;
  onLoggedIn?: () => void;
  onCancel?: () => void;
  onResetPasswordClicked?: () => void;
  mode?: "" | "login" | "register";
}

export function LoginOrRegisterForm({
  onLoggedIn,
  onCancel,
  onResetPasswordClicked,
  mode,
}: LoginOrRegisterFormProps): JSX.Element {
  const isLoggedIn = useSelector<CombinedStore>(
    (store) => store.login.isLoggedIn
  );
  const wasLoggedIn = usePrevious(isLoggedIn);
  const [currentMode, setCurrentMode] = useState<"" | "login" | "register">(
    mode || ""
  );

  const modeWas = usePrevious(mode);
  useEffect(() => {
    if (mode !== modeWas) {
      setCurrentMode(mode || "");
    }
  }, [mode, modeWas]);

  useEffect(() => {
    if (isLoggedIn && !wasLoggedIn) {
      onLoggedIn && onLoggedIn();
    }
  }, [isLoggedIn, wasLoggedIn, onLoggedIn]);

  const onCancelClicked = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onCancel && onCancel();
    },
    [onCancel]
  );
  const onLoginClicked = useCallback((event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentMode("login");
  }, []);
  const onRegisterClicked = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setCurrentMode("register");
    },
    []
  );

  switch (currentMode) {
    case "login":
      return (
        <LoginForm
          onLoggedIn={() => {
            /* mo op */
          }}
          onCancel={onCancel}
          onRegisterClicked={() => setCurrentMode("register")}
          onResetPasswordClicked={onResetPasswordClicked}
        />
      );

    case "register":
      return (
        <RegisterForm
          onLoggedIn={() => {
            /* mo op */
          }}
          onCancel={onCancel}
          onLoginClicked={() => setCurrentMode("login")}
        />
      );

    default:
      return (
        <Card className="position-relative">
          <LogoCardHeader />
          <LogoCardBody className="p-4">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="lead">
                You need a Rockley Music account to continue
              </p>
              <Button
                onClick={onRegisterClicked}
                className="mx-2 my-2"
                size="lg"
                color="primary"
              >
                Create a Rockley Music account
              </Button>
              <Button
                onClick={onLoginClicked}
                className="mx-2 my-2"
                size="lg"
                color="primary"
              >
                I already have an account
              </Button>
              {onCancel && (
                <Button
                  className="mt-4"
                  color="secondary"
                  onClick={onCancelClicked}
                >
                  Cancel
                </Button>
              )}
            </div>
          </LogoCardBody>
        </Card>
      );
  }
}
