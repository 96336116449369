import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "bootstrap/dist/js/bootstrap.min.js";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min.js";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, useHistory } from "react-router-dom";
import App from "./App";
import "animate.css/animate.min.css";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import store from "./state/store";
import { setScrollY } from "./utils/dom";

function ScrollMemory() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((_location, action) => {
      if (action === "PUSH") {
        setScrollY(0);
      }
      // console.log(
      //   action,
      //   location.pathname,
      //   location.search,
      //   location.key,
      //   location.state
      // );
    });

    return () => unlisten();
  });

  return <></>;
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter forceRefresh={false}>
      <ScrollMemory />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
