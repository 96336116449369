import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import { useFetchProductSkuProperties } from "../state/productSkuProperties.api";
import { ProductSkuProperty } from "../state/productSkuProperties.model";
import { useQueryStringState } from "../utils/queryStringState";
import ProductSelect from "../selects/ProductSelect";
import ProductSkuSelect from "../selects/ProductSkuSelect";
import { Id } from "../state/types";

export default function ProductSkuPropertyListPage({
  productSkuId: forceProductSkuId,
}: {
  productSkuId?: Id;
}): JSX.Element {
  const nested = forceProductSkuId !== undefined;
  const prefix = nested ? "prodsku_" : "";
  const [page, setPage] = useQueryStringState("page", 0, prefix);
  const [search, setSearch] = useQueryStringState("search", "", prefix);
  const [productId, setProductId] = useQueryStringState<Id | undefined>(
    "productId",
    0,
    prefix
  );
  const [productSkuId, setProductSkuId] = useQueryStringState<Id | undefined>(
    "productSkuId",
    0,
    prefix
  );

  const {
    productSkuProperties,
    numberOfPages,
    fetching,
    error,
  } = useFetchProductSkuProperties({
    page,
    search,
    admin: true,
    query: {
      productId: productId || undefined,
      productSkuId: forceProductSkuId || productSkuId || undefined,
    },
  });

  return (
    <TableScreen<ProductSkuProperty>
      defaults={{
        ...(forceProductSkuId ? { productSkuId: forceProductSkuId } : {}),
      }}
      nested={nested}
      fetching={fetching}
      error={error}
      title="Product SKU Properties"
      singular="Product SKU Property"
      columns={[
        { title: "ID" },
        { title: "Product SKU" },
        { title: "Attribute" },
        { title: "Option / Value" },
      ]}
      list={productSkuProperties}
      row={(item) => [
        item.id,
        item.productSkuId,
        item.product_attribute?.title,
        item.product_attribute_option?.title || item.value,
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/product-sku-properties/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          {!nested && (
            <>
              <TableFilter label="Product">
                <ProductSelect
                  admin
                  value={productId}
                  onChange={setProductId}
                />
              </TableFilter>
              <TableFilter label="SKU">
                <ProductSkuSelect
                  admin
                  value={productSkuId}
                  onChange={setProductSkuId}
                />
              </TableFilter>
            </>
          )}
        </>
      )}
    />
  );
}
