import React, { ReactNode } from "react";
import LoginScreen from "../screens/LoginScreen";
import { useCombinedStore } from "../state/combinedStore";

export default function RequireLogin({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const isLoggedIn = useCombinedStore((state) => state.login.isLoggedIn);

  return isLoggedIn ? <>{children}</> : <LoginScreen />;
}
