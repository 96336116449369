import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import { blankOrderLine, OrderLine } from "./orderLines.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchOrderLinesResults extends UseFetchManyResults {
  orderLines: OrderLine[];
  numberOfOrderLines: number;
  totalNumberOfOrderLines: number;
}

interface UseFetchOrderLineResults extends UseFetchOneResults {
  orderLine: OrderLine;
}

const keys: FetcherKeys<UseFetchOrderLinesResults, UseFetchOrderLineResults> = {
  service: "order-lines",
  totalNumber: "totalNumberOfOrderLines",
  numberOf: "numberOfOrderLines",
  records: "orderLines",
  record: "orderLine",
};

export async function fetchOrderLines(
  props: FetchManyProps
): Promise<FetchManyResults<OrderLine>> {
  return fetchMany<OrderLine>(props, keys.service);
}

export function useFetchOrderLines(
  props: FetchManyProps
): UseFetchOrderLinesResults {
  return useFetchMany(props, fetchOrderLines, keys);
}

export async function fetchOrderLine(props: FetchOneProps): Promise<OrderLine> {
  return fetchOne<OrderLine, UseFetchOrderLineResults>(
    props,
    blankOrderLine,
    keys
  );
}

export function useFetchOrderLine(
  props: FetchOneProps
): UseFetchOrderLineResults {
  return useFetchOne(props, fetchOrderLine, blankOrderLine, keys);
}

async function saveOrderLine(
  original: OrderLine,
  changes: Partial<OrderLine>,
  $role: true | false | string
): Promise<OrderLine> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  notifyUpdateListeners("product-skus"); // change an order line changes SKU stock counts
  return newRecord;
}

async function deleteOrderLine(
  original: OrderLine,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSaveOrderLine = makeSaver(saveOrderLine, true);
export const adminDeleteOrderLine = makeDeleter(deleteOrderLine, true);
export const userSaveOrderLine = makeSaver(saveOrderLine, false);
export const userDeleteOrderLine = makeDeleter(deleteOrderLine, false);
