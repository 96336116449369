/// Find an option in a react-select options array
export function findSelectOptionByValue<U, T extends { value: U }>(
  options: T[],
  value: U
): T | undefined {
  for (const option of options) {
    if (option.value === value) {
      return option;
    }
  }

  return undefined;
}

/// Find an option in a react-select options array
export function getSelectOptionByValue<
  U,
  T extends { value: U } = { value: U; label: string }
>(options: T[], value: U | null | undefined): T | undefined {
  if (value === null || value === undefined) {
    return undefined;
  }
  return (
    findSelectOptionByValue(options, value) ||
    (({ value: value, label: value } as unknown) as T)
  );
}
