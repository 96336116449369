import map from "lodash/map";
import omit from "lodash/omit";
import React, { useEffect, useRef } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import config from "../config";
import {
  AnyPaymentRequest,
  WorldPayPaymentRequest,
} from "../state/basket.model";
import CardHeaderTitle from "./CardHeaderTitle";

export default function PaymentRequestRow({
  request,
}: {
  request: AnyPaymentRequest;
}): JSX.Element {
  return (
    <Row>
      <Col lg={6}>
        <Card>
          <CardHeaderTitle>Payment</CardHeaderTitle>
          <CardBody>
            {request.service === "worldpay" && (
              <WorldPayPaymentRequestForm request={request} />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

function WorldPayPaymentRequestForm({
  request,
}: {
  request: WorldPayPaymentRequest;
}) {
  const fields = omit(request, "service", "url");

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      config.IS_PRODUCTION && formRef.current.submit();
    }
  }, [formRef]);

  return (
    <>
      <p>Please press the button to continue to WorldPay</p>
      <form action={request.url} method="POST" ref={formRef}>
        {map(fields, (value, key) => (
          <input type="hidden" name={key} value={String(value)} />
        ))}
        <div className="button-container">
          <Button
            tag="input"
            type="submit"
            color="primary"
            size="lg"
            value="Proceed to Payment..."
          />
        </div>
      </form>
    </>
  );
}
