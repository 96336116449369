import { fetchPayment, fetchPayments } from "../state/payments.api";
import { blankPayment, Payment } from "../state/payments.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

const format = (item: Payment): string => `#${item.id}`;

export default function PaymentSelect(
  props: GenericSelectProps<Payment>
): JSX.Element {
  return GenericSelect<Payment>(
    props,
    fetchPayment,
    fetchPayments,
    "payments",
    blankPayment,
    format
  );
}
