import {
  fetchPaymentMethod,
  fetchPaymentMethods,
} from "../state/paymentMethods.api";
import {
  blankPaymentMethod,
  PaymentMethod,
} from "../state/paymentMethods.model";
import GenericSelect, { GenericSelectProps } from "./GenericSelects";

const format = (item: PaymentMethod): string => `#${item.id} ${item.title}`;

export default function PaymentMethodSelect(
  props: GenericSelectProps<PaymentMethod>
): JSX.Element {
  return GenericSelect<PaymentMethod>(
    props,
    fetchPaymentMethod,
    fetchPaymentMethods,
    "payment-methods",
    blankPaymentMethod,
    format
  );
}
