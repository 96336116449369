import React from "react";
import TableScreen, { TableFilter } from "../components/TableScreen";
import config from "../config";
import UserSelect from "../selects/UserSelect";
import { Id } from "../state/types";
import { useFetchUploads } from "../state/uploads.api";
import { Upload } from "../state/uploads.model";
import { formatUser } from "../state/users.model";
import { useQueryStringState } from "../utils/queryStringState";

export default function UploadListPage(): JSX.Element {
  const [page, setPage] = useQueryStringState("page", 0);
  const [search, setSearch] = useQueryStringState("search", "");
  const [userId, setUserId] = useQueryStringState<Id | undefined>("userId", 0);

  const { uploads, numberOfPages, fetching, error } = useFetchUploads({
    page,
    search,
    admin: true,
    query: {
      userId: userId || undefined,
    },
  });

  return (
    <TableScreen<Upload>
      defaults={{
        organisationId: config.ORGANISATION_ID,
      }}
      fetching={fetching}
      error={error}
      title="Uploads"
      singular="Upload"
      columns={[
        { title: "ID" },
        { title: "User" },
        { title: "Origial Name" },
        { title: "Description" },
        { title: "Location" },
      ]}
      list={uploads}
      row={(item) => [
        item.id,
        formatUser(item.user),
        item.originalName,
        item.description,
        item.uri,
      ]}
      rowId={(item) => `${item.id}`}
      detailPath="/admin/uploads/:id"
      currentPage={page}
      numberOfPages={numberOfPages}
      onSelectPage={setPage}
      search={search}
      onSearchChange={setSearch}
      renderFilters={() => (
        <>
          <TableFilter label="User">
            <UserSelect admin value={userId} onChange={setUserId} />
          </TableFilter>
        </>
      )}
    />
  );
}
