import { notifyUpdateListeners } from "../updateListeners";
import api from "./api";
import {
  FetcherKeys,
  fetchMany,
  FetchManyProps,
  UseFetchManyResults,
  fetchOne,
  FetchOneProps,
  useFetchMany,
  useFetchOne,
  FetchManyResults,
  UseFetchOneResults,
} from "../components/GenericFetchers";
import {
  blankPaymentAssignment,
  PaymentAssignment,
} from "./paymentAssignments.model";
import { makeSaver, makeDeleter } from "./types";

interface UseFetchPaymentAssignmentsResults extends UseFetchManyResults {
  paymentAssignments: PaymentAssignment[];
  numberOfPaymentAssignments: number;
  totalNumberOfPaymentAssignments: number;
}

interface UseFetchPaymentAssignmentResults extends UseFetchOneResults {
  paymentAssignment: PaymentAssignment;
}

const keys: FetcherKeys<
  UseFetchPaymentAssignmentsResults,
  UseFetchPaymentAssignmentResults
> = {
  service: "payment-assignments",
  totalNumber: "totalNumberOfPaymentAssignments",
  numberOf: "numberOfPaymentAssignments",
  records: "paymentAssignments",
  record: "paymentAssignment",
};

export async function fetchPaymentAssignments(
  props: FetchManyProps
): Promise<FetchManyResults<PaymentAssignment>> {
  return fetchMany<PaymentAssignment>(props, keys.service);
}

export function useFetchPaymentAssignments(
  props: FetchManyProps
): UseFetchPaymentAssignmentsResults {
  return useFetchMany(props, fetchPaymentAssignments, keys);
}

export async function fetchPaymentAssignment(
  props: FetchOneProps
): Promise<PaymentAssignment> {
  return fetchOne<PaymentAssignment, UseFetchPaymentAssignmentResults>(
    props,
    blankPaymentAssignment,
    keys
  );
}

export function useFetchPaymentAssignment(
  props: FetchOneProps
): UseFetchPaymentAssignmentResults {
  return useFetchOne(
    props,
    fetchPaymentAssignment,
    blankPaymentAssignment,
    keys
  );
}

async function savePaymentAssignment(
  original: PaymentAssignment,
  changes: Partial<PaymentAssignment>,
  $role: true | false | string
): Promise<PaymentAssignment> {
  const params = {
    query: {
      $role,
    },
  };

  const newRecord = original.id
    ? await api.service(keys.service).patch(original.id, changes, params)
    : await api.service(keys.service).create(changes, params);
  notifyUpdateListeners(keys.service);
  return newRecord;
}

async function deletePaymentAssignment(
  original: PaymentAssignment,
  $role: true | false | string
) {
  const params = {
    query: {
      $role,
    },
  };

  await api.service(keys.service).remove(original.id, params);
  notifyUpdateListeners(keys.service);
}

export const adminSavePaymentAssignment = makeSaver(
  savePaymentAssignment,
  true
);
export const adminDeletePaymentAssignment = makeDeleter(
  deletePaymentAssignment,
  true
);
export const userSavePaymentAssignment = makeSaver(
  savePaymentAssignment,
  false
);
export const userDeletePaymentAssignment = makeDeleter(
  deletePaymentAssignment,
  false
);
